import React from "react";
import { Modal } from "react-bootstrap";
import { getRemainingBalance } from "../../Tournament/utils";

const ConfirmTournamentModal = ({
  isFree,
  handleHideConfirm,
  walletBalance,
  entryFeeSubtract,
}) => {
  return (
    <Modal show={true} onHide={handleHideConfirm} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="money-added">
        <div className="tick-image">
          <img src="/assets/images/tick.svg" alt="" />
        </div>
        <h3 className="challengeCreated"> Invite Accepted !</h3>

        <p className="challengeBetween">
          Congratulations, you have successfully accepted the invite
        </p>

        <p>Wallet Balance</p>
        <h4>
          {" "}
          $ {getRemainingBalance(isFree, walletBalance, entryFeeSubtract)}
        </h4>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmTournamentModal;

import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function getAvailableLobbies() {
  const url =
    baseURL + "players/dashboard/available-lobbies/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function getActiveLobbies() {
  const url =
    baseURL + "players/dashboard/active-lobbies/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function getPreviousLobbies() {
  const url =
    baseURL + "players/dashboard/previous-lobbies/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function callGameFilter(filter) {
  const url =
    baseURL +
    `players/dashboard/available-lobbies/?game=${filter}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import { logout } from "../../services/authService";
import { logoutUser } from "../../store/auth/login/actions";
import { withRouter, useHistory } from "react-router";
import PropTypes from "prop-types";
import {
  getNotificationsList,
  putFriendRequest,
  putNotificationRead,
  removeNotifications,
  putTeamRequest,
} from "../../services/notifications.service";
import {
  ConsoleHelper,
  dateStringTodayTommorrow,
  gamesToCategories,
  handleNotificationNavigate,
} from "../../helper/utilities";
import { useNotifications } from "../../context/NotificationsContext";
import useNotificationSocket from "../../hooks";
import { getConfig } from "../../services/user.service";
import { useConfig } from "../../context/ConfigContext";
import { uniqBy } from "lodash";
import { STRINGS_CONSTANTS } from "../../helper/stringConstants";
import { useGame } from "../../context/GameContext";
import { gamelisting } from "../../services/game.service";

const HeaderNav = (props) => {
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const [loader, setLoader] = useState(false);
  const { dispatch: dispatchContext, state } = useNotifications();
  const { state: configState, dispatch: configDispatch } = useConfig();
  const { dispatch: gameDispatch } = useGame();

  const dispatch = useDispatch();
  const history = useHistory();

  const [spinnerLoaderLogin, setSpinnerLoader] = useState(false);
  const logoutBtn = () => {
    setSpinnerLoader(true);
    logout();
    dispatch(logoutUser());
    setTimeout(() => {
      setSpinnerLoader(false);
    }, 3000);
  };
  const location = useLocation();
  const [Path, setPath] = useState("");
  useEffect(() => {
    setPath(location);
  }, [location]);

  const readNotifications = () => {
    putNotificationRead()
      .then(() => {
        dispatchContext({ type: "UPDATE_UNREAD_COUNT", payload: 0 });
      })
      .catch((err) => ConsoleHelper(err));
  };
  const navigateToNotifications = () => {
    props.history.push("/notifications");
    props?.call();
  };

  useNotificationSocket({
    callbackMessage: (notificationSocketData) => {
      dispatchContext({
        type: "SOCKET_NOTIFICATIONS_DATA",
        payload: notificationSocketData,
      });
    },
  });

  useEffect(() => {
    if (loggedUser && loggedUser.user) {
      fetchNotificationsList();
    }
    if (!state?.game?.allGames?.length) {
      gamelisting()
        .then((res) => {
          gameDispatch({
            type: "UPDATE",
            payload: gamesToCategories(res),
          });
          setSpinnerLoader(false);
        })
        .catch(() => {
          setSpinnerLoader(false);
        });
    }
    if (JSON.stringify(configState.config) == "{}") {
      getConfig().then((res) => {
        configDispatch({ type: "UPDATE_CONFIG", payload: res });
      });
    }
  }, [loggedUser, location.pathname]);

  const fetchNotificationsList = () => {
    const filter = {
      isPaginated: true,
      pageNum: 1,
      pageSize: 4,
      column: "created",
      order: "desc",
    };
    setLoader(true);
    getNotificationsList(filter)
      .then((res) => {
        dispatchContext({
          type: "UPDATE_NOTIFICATIONS_DATA",
          payload: res?.results,
        });
        setLoader(false);
        dispatchContext({
          type: "UPDATE_UNREAD_COUNT",
          payload: res?.notifications,
        });
      })
      .catch((err) => {
        setLoader(false);
        ConsoleHelper(err);
      });
  };

  const handleFriendRequest = (notificationAction, notificationId) => {
    setLoader(true);
    putFriendRequest({ notificationAction, notificationId })
      .then((res) => {
        setLoader(false);
        dispatchContext({ type: "REFETCH", payload: true });
        fetchNotificationsList();
      })
      .catch((err) => {
        setLoader(false);
        ConsoleHelper(err);
      });
  };
  const handleTeamRequest = (notificationAction, teamId, notificationId) => {
    setLoader(true);
    putTeamRequest({ notificationAction, teamId, notificationId })
      .then((res) => {
        setLoader(false);
        dispatchContext({ type: "REFETCH", payload: true });
        fetchNotificationsList();
      })
      .catch((err) => {
        setLoader(false);
        ConsoleHelper(err);
      });
  };
 

  const clearAll = async () => {
    try {
      setLoader(true);
      await removeNotifications({ type: 2, notification: "" });
      setLoader(false);
      fetchNotificationsList();
    } catch (error) {
      setLoader(false);
    }
  };

  const removeSingleNotification = async (id) => {
    try {
      setLoader(true);
      await removeNotifications({ type: 1, notification: id });
      setLoader(false);
      fetchNotificationsList();
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      {spinnerLoaderLogin ? (
        <div class="loading"></div>
      ) : (
        <>
          {loader ? <div className="loading" /> : null}
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link
                  to="/"
                  className={
                    Path.pathname === "/" && Path.hash !== "#community"
                      ? "active"
                      : ""
                  }
                  onClick={() => props?.call()}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/games"
                  className={Path.pathname === "/games" ? "active" : ""}
                  onClick={() => props?.call()}
                >
                  Play
                </Link>
              </li>
              <li>
                <Link
                  to="/ppk"
                  className={Path.pathname === "/ppk" ? "active" : ""}
                  onClick={() => props?.call()}
                >
                  What's PPK<sup>®</sup>?
                </Link>
              </li>

              <li>
                <Link
                  to="/community"
                  className={Path.pathname === "/community" ? "active" : ""}
                  onClick={() => props?.call()}
                >
                  Community
                </Link>
              </li>
              <li>
                <Link
                  to="/tournaments"
                  className={Path.pathname === "/tournaments" ? "active" : ""}
                  onClick={() => props?.call()}
                >
                  Tournaments
                </Link>
              </li>
              {loggedUser && loggedUser.user ? (
                <Dropdown key={location.pathname} autoClose="outside">
                  <Dropdown.Toggle className="notification-dropdown">
                    <Link className="notification" onBlur={readNotifications}>
                      <img
                        src="/assets/images/notification-icon.svg"
                        alt="Notification"
                      />
                      {state?.unreadCount > 0 ? (
                        <span class="badge">{state?.unreadCount}</span>
                      ) : null}
                    </Link>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="notificationDropdown">
                    {state?.notificationsData?.length ? (
                      uniqBy(
                        state?.notificationsData
                          ?.filter(
                            (notification) =>
                              notification?.from_user != loggedUser?.user?.id
                          )
                          ?.slice(0, 4),
                        "id"
                      ).map((notificationItem, idx) => {
                        return (
                          <Dropdown.Item
                            style={
                              idx ==
                              uniqBy(
                                state?.notificationsData
                                  ?.filter(
                                    (notification) =>
                                      notification?.from_user !=
                                      loggedUser?.user?.id
                                  )
                                  ?.slice(0, 4),
                                "id"
                              ).length -
                                1
                                ? { borderBottom: "unset" }
                                : {}
                            }
                            key={notificationItem?.id}
                            onClick={() =>
                              handleNotificationNavigate({history,userData:notificationItem})
                            }
                          >
                            <div className="d-flex notification-box">
                              <img
                                src={
                                  notificationItem?.profile_url &&
                                  notificationItem?.profile_url != "null"
                                    ? notificationItem?.profile_url
                                    : "/assets/images/profile-img.jpg"
                                }
                                alt="Sorting"
                                className="profilepic "
                              />
                              <div className="notification-content">
                                {notificationItem?.message}
                                <p className="notification-date">
                                  {dateStringTodayTommorrow(
                                    notificationItem?.created
                                  )}
                                </p>
                                {notificationItem?.type_of_notification ===
                                1 ? (
                                  <>
                                    <Button
                                      className="decline-btn decAccept-btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleFriendRequest(
                                          false,
                                          notificationItem?.id
                                        );
                                      }}
                                    >
                                      Decline
                                    </Button>
                                    <Button
                                      className="accept-btn decAccept-btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleFriendRequest(
                                          true,
                                          notificationItem?.id
                                        );
                                      }}
                                    >
                                      Accept
                                    </Button>
                                  </>
                                ) : null}
                                {notificationItem?.type_of_notification ===
                                5 ? (
                                  <>
                                    <Button
                                      className="decline-btn decAccept-btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleTeamRequest(
                                          false,
                                          notificationItem?.extras.team_id,
                                          notificationItem?.id
                                        );
                                      }}
                                    >
                                      Decline
                                    </Button>
                                    <Button
                                      className="accept-btn decAccept-btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleTeamRequest(
                                          true,
                                          notificationItem?.extras.team_id,
                                          notificationItem?.id
                                        );
                                      }}
                                    >
                                      Accept
                                    </Button>
                                  </>
                                ) : null}
                              </div>
                              <div className="d-flex align-items-center">
                                <img
                                  style={{ cursor: "pointer" }}
                                  src="/assets/images/notification-remove.svg"
                                  alt="remove-notification-icon"
                                  className="remove-notification"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeSingleNotification(
                                      notificationItem?.id
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <div>
                        <p className="notifications-empty-navbar">
                          No Notifications
                        </p>
                      </div>
                    )}

                    {state?.notificationsData?.length ? (
                      <div>
                        <Button
                          className="accept-btn seeAll-btn notif-clearAll"
                          onClick={clearAll}
                        >
                          Clear All
                        </Button>
                        <Button
                          className="accept-btn seeAll-btn float-end"
                          onClick={navigateToNotifications}
                        >
                          See All
                        </Button>
                      </div>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}

              {loggedUser && loggedUser.user ? (
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-dropper">
                    {loggedUser.user?.username}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="header-dropdown">
                    <Dropdown.Item as="button">
                      <Link to="/myProfile" onClick={() => props?.call()}>
                        <img
                          src="/assets/images/profile-icon.svg"
                          alt="Profile"
                        />
                        <span className="top-profile">My Profile</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <Link to="/dashboard" onClick={() => props?.call()}>
                        <img
                          src="/assets/images/dashboard-icon.svg"
                          alt="Dashboard"
                        />
                        <span className="top-profile dashboard-tab">
                          Dashboard
                        </span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <Link
                        to={{
                          pathname: "/myProfile",
                          data: "settingsTab",
                        }}
                        onClick={() => props?.call()}
                      >
                        <img
                          src="/assets/images/dropdown-password.svg"
                          alt="Change password"
                        />
                        <span className="top-profile dashboard-tab">
                          Change Password
                        </span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <Link
                        to={{
                          pathname: "/myProfile",
                          data: "referfriend",
                        }}
                        onClick={() => props?.call()}
                      >
                        <img
                          src="/assets/images/referral1-icon.svg"
                          alt="Refer friend"
                        />
                        <span className="top-profile dashboard-tab">
                          Refer a friend
                        </span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <div onClick={logoutBtn}>
                        <img
                          src="/assets/images/logout-icon.svg"
                          alt="Logout"
                        />
                        <span className="top-profile">Logout</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <li>
                    <Link
                      to="/signin"
                      className={Path.pathname === "/signin" ? "active" : ""}
                      onClick={() => props?.call()}
                    >
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/signup"
                      className="rn-btn"
                      onClick={() => props?.call()}
                    >
                      {STRINGS_CONSTANTS.signup + " Free "}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};
HeaderNav.propTypes = {
  call: PropTypes.func,
};
export default withRouter(HeaderNav);

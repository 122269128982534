import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import CountDown from "./CountDown";
import CountDownStopEnrollment from "./CountDownStopEnrollment";
import EnrollButton from "./EnrollButton";
import ReactTooltip from "react-tooltip";
import { gameTypeEnum, getCardBackground } from "../../helper/utilities";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
class LobbyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimerReached: false,
      isTimerReached3: false,
      isEnrollTeamTimerReached: false,
    };
  }

  timerEnded(isComplete) {
    this.setState({
      isTimerReached: isComplete,
    });
  }
  timerEnded3(isComplete) {
    this.setState({
      isTimerReached3: isComplete,
    });
  }
  render() {
    return (
      <>
        <div className="col mb-4 left-card">
          {this.props.is_verified ? (
            <div
              className={`${getCardBackground(
                this.props?.game?.slug
              )} verified-lobby ${
                this.props.is_enrolled ? "enrolled-card" : ""
              }`}
            >
              <div className="card-body">
                <div class="verified-badge">
                  <img
                    src="/assets/images/verified-lobby-badge.svg"
                    alt="verified-lobby-badge"
                    data-tip
                    data-for="loginTip1"
                  />
                  <ReactTooltip id="loginTip1" place="left" effect="solid">
                    Verified Lobby
                  </ReactTooltip>
                </div>
                {this.props?.is_password_protected ? (
                  <div class="verified-badge lockBadge">
                    <img
                      className="pt-4 pe-4"
                      src="/assets/images/lock.svg"
                      alt="protected-lobby-badge"
                      data-tip
                      data-for="loginTip1"
                    />
                    <ReactTooltip id="loginTip1" place="left" effect="solid">
                      Protected Lobby
                    </ReactTooltip>
                  </div>
                ) : null}
                <h3 className="card-title lobbyTitle">{this.props.name}</h3>
                <div className="playing-information ValorantNewCard">
                  <div className="left-side">
                    <p className="entry-fees tags">
                      <span class="entry">Entry</span> ${this.props.entry_fee}
                      {this.props.free_lobby ? " Free Entry" : ""}
                    </p>
                    <p className="tags">
                      <span>
                        {" "}
                        {this.props?.mode == 1
                          ? "Enrolled Players"
                          : "Enrolled Teams"}
                      </span>{" "}
                      {this.props?.mode == 1
                        ? this.props.enrolled_players || "-"
                        : this.props.enrolled_teams || "-"}
                    </p>
                    <p className="tags ValorantMInPlayer">
                      <span>Min Players</span> {this.props.min_players}
                    </p>
                    <p className="tags">
                      <span>Max Players</span> {this.props.max_players}
                    </p>
                    <p className="tags">
                      <span>Status</span>
                      <LobbyStatus data={this.props} />
                    </p>
                    <p className="tags">
                      <span>Map</span>{" "}
                      <span>{this.props?.gamemap || "N/A"}</span>
                    </p>
                    <p className="tags">
                      <span>Game Type</span>{" "}
                      {gameTypeEnum[this.props?.game_type]}
                    </p>
                  </div>
                  <div className="right-side">
                    <p className="tags-one winning-money-mb">
                      <span>Win</span>

                      <span class="info-icon">
                        <img
                          src="/assets/images/info-icon.svg"
                          alt="info"
                          class="info-image"
                          data-tip
                          data-for="loginTip"
                        />
                      </span>
                      <ReactTooltip id="loginTip" place="right" effect="solid">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? "Actual amount paid to the team"
                          : "Actual amount paid to the user"}
                      </ReactTooltip>
                      <span className="pay-kill win-amount blue-color valorantWinning">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? `$${this.props.reward}/Winning Team`
                          : `$${this.props.reward} Paid Every Kill`}
                      </span>
                    </p>
                    <p className="tags-one ValorantDate">
                      <span>Date</span>
                      {"  "}
                      {new Date(this.props.countdown_time).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric", // numeric, 2-digit
                          year: "numeric", // numeric, 2-digit
                          month: "short", // numeric, 2-digit, long, short, narrow
                        }
                      )}
                    </p>
                    <p className="tags-one">
                      {" "}
                      <span>Start Time</span> {this.props.start_time}
                    </p>

                    <p className="tags-one">
                      {" "}
                      <span>Last Entry</span> {this.props.last_entry_time}
                    </p>
                    <p className="tags-one">
                      {" "}
                      <span>Lobby Mode</span> {this.props.modetag}
                    </p>
                    <p className="tags-one">
                      <span>Enrolled</span>{" "}
                      {this.props.is_enrolled ? "Yes" : "No"}
                    </p>
                    <p className="tags-one">
                      <span>Region</span> {this.props?.region || "N/A"}
                    </p>
                  </div>
                </div>

                <div
                  className={
                    !this.state.isTimerReached
                      ? "start-time start-wrapper timerStart"
                      : "start-time start-wrapper without-timer"
                  }
                >
                  {!this.state.isTimerReached ? (
                    <div className="start-in">
                      <CountDown
                        server_current_datetime={this.props.countdown_time}
                        time_reached={(e) => {
                          this.timerEnded(e);
                        }}
                        stopEnrollTeam={(isComplete) => {
                          if (this.state.isEnrollTeamTimerReached == false) {
                            this.setState({ isEnrollTeamTimerReached: true });
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {!this.state.isTimerReached3 ? (
                    <div className="start-in d-none">
                      <h2>
                        <CountDownStopEnrollment
                          server_current_datetime3={this.props.countdown_time}
                          time_reached3={(e) => {
                            this.timerEnded3(e);
                          }}
                        />
                      </h2>
                    </div>
                  ) : null}
                  <div
                    className={
                      this.props.current_status === 3
                        ? "previously-played"
                        : "start-game enroll"
                    }
                  >
                    <Link
                      to={{
                        pathname: this.props.match.url + "/" + this.props.id,
                        data: this.props,
                      }}
                      className="btn btn-primary enroll-button"
                    >
                      View Details
                    </Link>
                    {this.props.current_status === 3 ? (
                      ""
                    ) : (
                      <EnrollButton
                        {...this.props}
                        isTimeCompleted={this.state.isTimerReached}
                        stop={this.state.isTimerReached3}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`${getCardBackground(this.props?.game?.slug)} ${
                this.props.is_enrolled ? "enrolled-card" : ""
              }`}
            >
              <div className="card-body">
                {this.props?.is_password_protected ? (
                  <div class="verified-badge lockBadge">
                    <img
                      className="p-4"
                      src="/assets/images/lock.svg"
                      alt="protected-lobby-badge"
                      data-tip
                      data-for="loginTip1"
                    />
                    <ReactTooltip id="loginTip1" place="left" effect="solid">
                      Protected Lobby
                    </ReactTooltip>
                  </div>
                ) : null}
                <h3 className="card-title lobbyTitle">{this.props.name}</h3>
                <div className="playing-information ValorantNewCard">
                  <div className="left-side">
                    <p className="entry-fees tags">
                      <span class="entry">Entry</span> ${this.props.entry_fee}{" "}
                      {this.props.free_lobby ? " Free Entry" : ""}
                    </p>
                    <p className="tags">
                      <span>
                        {" "}
                        {this.props?.mode == 1
                          ? "Enrolled Players"
                          : "Enrolled Teams"}
                      </span>{" "}
                      {this.props?.mode == 1
                        ? this.props.enrolled_players || "-"
                        : this.props.enrolled_teams || "-"}
                    </p>
                    <p className="tags-one winner-money">
                      <span>Win</span>

                      <span class="info-icon">
                        <img
                          src="/assets/images/info-icon.svg"
                          alt="info"
                          class="info-image"
                          data-tip
                          data-for="loginTip"
                        />
                      </span>
                      <ReactTooltip id="loginTip" place="right" effect="solid">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? "Actual amount paid to the team"
                          : "Actual amount paid to the user"}
                      </ReactTooltip>
                      <span className="pay-kill win-amount blue-color valorantWinning">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? `$${this.props.reward}/Winning Team`
                          : `$${this.props.reward} Paid Every Kill`}
                      </span>
                    </p>
                    <p
                      className={
                        this.props.game.slug === "valorant"
                          ? "tags ValorantMInPlayer"
                          : "tags"
                      }
                    >
                      <span>Min Players</span> {this.props.min_players}
                    </p>
                    <p className="tags">
                      <span>Max Players</span> {this.props.max_players}
                    </p>
                    <p className="tags">
                      <span>Status</span>
                      <LobbyStatus data={this.props} />
                    </p>
                    <p className="tags">
                      <span>Map</span>{" "}
                      <span className="lobbyMap">
                        {this.props?.gamemap || "N/A"}
                      </span>
                    </p>

                    <p className="tags">
                      <span>Game Type</span>{" "}
                      {gameTypeEnum[this.props?.game_type]}
                    </p>
                  </div>
                  <div className="right-side">
                    <p className="tags-one winning-money-mb">
                      <span>Win</span>

                      <span class="info-icon">
                        <img
                          src="/assets/images/info-icon.svg"
                          alt="info"
                          class="info-image"
                          data-tip
                          data-for="loginTip"
                        />
                      </span>
                      <ReactTooltip id="loginTip" place="right" effect="solid">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? "Actual amount paid to the team"
                          : "Actual amount paid to the user"}
                      </ReactTooltip>
                      <span className="pay-kill win-amount blue-color valorantWinning">
                        {this.props.game.slug === "valorant" ||
                        this.props.game.slug === "call-of-duty-warzone"
                          ? `$${this.props.reward}/Winning Team`
                          : `$${this.props.reward} Paid Every Kill`}
                      </span>
                    </p>
                    <p className="tags-one ValorantDate">
                      <span>Date</span>
                      {"  "}
                      {new Date(this.props.countdown_time).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric", // numeric, 2-digit
                          year: "numeric", // numeric, 2-digit
                          month: "short", // numeric, 2-digit, long, short, narrow
                        }
                      )}
                    </p>

                    <p className="tags-one">
                      {" "}
                      <span>Start Time</span> {this.props.start_time}
                    </p>
                    <p className="tags-one">
                      {" "}
                      <span>Last Entry</span> {this.props.last_entry_time}
                    </p>
                    <p className="tags-one">
                      {" "}
                      <span>Lobby Mode</span> {this.props.modetag}
                    </p>
                    <p className="tags-one">
                      <span>Enrolled</span>{" "}
                      {this.props.is_enrolled ? "Yes" : "No"}
                    </p>
                    <p className="tags-one">
                      <span>Region</span> {this.props?.region || "N/A"}
                    </p>
                  </div>
                </div>

                <div
                  className={
                    !this.state.isTimerReached
                      ? "start-time start-wrapper timerStart"
                      : "start-time start-wrapper without-timer"
                  }
                >
                  {!this.state.isTimerReached ? (
                    <div className="start-in">
                      <CountDown
                        server_current_datetime={this.props.countdown_time}
                        time_reached={(e) => {
                          this.timerEnded(e);
                        }}
                        stopEnrollTeam={(isComplete) => {
                          if (this.state.isEnrollTeamTimerReached == false) {
                            this.setState({ isEnrollTeamTimerReached: true });
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {!this.state.isTimerReached3 ? (
                    <div className="start-in d-none">
                      <h2>
                        <CountDownStopEnrollment
                          server_current_datetime3={this.props.countdown_time}
                          time_reached3={(e) => {
                            this.timerEnded3(e);
                          }}
                        />
                      </h2>
                    </div>
                  ) : null}
                  <div
                    className={
                      this.props.current_status === 3
                        ? "previously-played"
                        : "start-game enroll"
                    }
                  >
                    <Link
                      to={{
                        pathname:
                          this.props.location.pathname === "/dashboard"
                            ? this.props.game.slug + "/lobby/" + this.props.id
                            : this.props.match.url + "/" + this.props.id,
                        data: this.props,
                      }}
                      className="btn btn-primary enroll-button"
                    >
                      View Details
                    </Link>
                    {this.props.current_status === 3 ? (
                      ""
                    ) : (
                      <EnrollButton
                        {...this.props}
                        isTimeCompleted={this.state.isTimerReached}
                        stop={this.state.isTimerReached3}
                        isEnrollTeamTimerReached={
                          this.state.isEnrollTeamTimerReached
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

LobbyCard.propTypes = {
  callLobbies: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  game: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  is_verified: PropTypes.bool,
  is_enrolled: PropTypes.bool,
  reward: PropTypes.number,
  start_date: PropTypes.string,
  start_time: PropTypes.string,
  last_entry_time: PropTypes.string,
  entry_fee: PropTypes.number,
  create_date: PropTypes.string,
  current_status: PropTypes.number,
  enrolled_players: PropTypes.number,
  enrolled_teams: PropTypes.number,
  max_players: PropTypes.number,
  min_players: PropTypes.number,
  rules: PropTypes.string,
  status: PropTypes.number,
  countdown_time: PropTypes.string,
  start_datetime: PropTypes.string,
  end_datetime: PropTypes.string,
};

export default withRouter(LobbyCard);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ParticipantItem from "./ParticipantItem";
function Team(props) {
  const { user } = useSelector((state) => state);
  const [userProfile, setUserProfile] = useState({});
  const [playersInTeam, setPlayersInteam] = useState(
    props?.item?.teammates.length
  );
  useEffect(() => {
    let tempPlayerInTemp = 0;
    props.item.teammates.forEach((teammate) => {
      if (teammate.status !== "Left") {
        tempPlayerInTemp += 1;
      }

      if (teammate?.is_owner && teammate?.uid == user.id) {
        props.setOwner(true);
      }
      if (teammate?.uid == user.id || teammate?.id == user.id) {
        setUserProfile(teammate);
      }
    });
    setPlayersInteam(tempPlayerInTemp);
  }, [props?.item]);

  return (
    <div className="participantsBox">
      <div>
        <ParticipantItem
          user={user}
          owner={props.owner}
          timerCompleted={props.timerCompleted}
          {...props}
          participantData={props.item}
          userProfile={userProfile}
          canModify={!props.canModify}
          playersInTeam={playersInTeam}
        />
      </div>
    </div>
  );
}

export default Team;

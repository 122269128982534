import { Modal, Tab, Tabs, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  getTeams,
  getChallengesById,
  openChallengeAccept,
  closedChallengeAction,
} from "../../services/challenges.service";
import AddToWallet from "../payment/AddToWallet";
import PaymentComponent from "../PaymentMethod";
import Select from "react-select";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Parallax } from "react-parallax";
import ChallengeParticipants from "./ChallengeParticipants";
import PlayerStats from "./PlayerStats";
import ReactJson from "react-json-view";
import toastify from "../../helper/toastify";
import {
  challengeStatus,
  gamerTagInfo,
  isChallengeActive,
} from "../../helper/utilities";
import { getUserDetail, getWalletBalance } from "../../services/user.service";
import { useSelector } from "react-redux";
import { useGame } from "../../context/GameContext";
import CountDown from "./CountDown";
import CountDownTeams from "./CountDownTeams";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
import TeamPlayersList from "../common/TeamPlayersList";

const ChallengesDetail = (props) => {
  const [challengeDetails, setChallengeDetails] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { slug, lobbyId } = useParams();
  const [gameSlug, setGameSlug] = useState("");
  const [errMsg, setErrorMsg] = useState("");
  const [teams, setTeam] = useState([]);
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const { state } = useGame();
  const games = state?.game?.allGames;
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [gamertag, setGamerTag] = useState(null);
  const [user, setUser] = useState([]);
  const [walletBalance, setwalletBalance] = useState(null);
  const [entryFeeSubtract, setEntryFeeSubtract] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState({});
  const [canModify, setCanModify] = useState(true);
  const [canReplace, setCanReplace] = useState(true);
  const [canEnroll, setCanEnroll] = useState(true);
  const [challengeAcceptConfirmModal, setChallengeAcceptConfirmModal] =
    useState(false);
  useEffect(() => {
    window.addEventListener("beforeunload", componentCleanup);
    if (loggedUser?.user) {
      checkBalance();
      getUserDetail().then((res) => {
        setUser(res);
      });
    }
    if (lobbyId > 0) {
      getChallenges();
    } else {
      history.goBack();
    }

    return () => {
      componentCleanup();
      window.removeEventListener("beforeunload", componentCleanup);
    };
  }, [loggedUser?.user, lobbyId]);

  useEffect(() => {
    setSelectedGame(games?.find((item) => item.id == challengeDetails.game));
  }, [games, challengeDetails]);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  const checkBalance = () => {
    getWalletBalance().then((res) => {
      setwalletBalance(res.wallet_balance);
    });
  };

  const getChallenges = async () => {
    setGameSlug(slug);
    try {
      setLoader(true);
      const res = await getChallengesById(lobbyId);
      setChallengeDetails(res);
      const teamList = res.challenge_players;
      const ids = teamList?.map((input) =>
        input.teams === null ? [] : input.teams.team_id
      );
      const uniqIds = [...new Set(ids)];
      const combineSameTeamIds = uniqIds?.map((id) => {
        return teamList.filter((input, idx) =>
          input.teams === null ? input[idx] : input.teams.team_id === id
        );
      });
      const currentUser = combineSameTeamIds?.flat()?.find((teamMember) => {
        return teamMember?.username == loggedUser.user?.username;
      });
      setCurrentPlayer(currentUser);
      setTeam(combineSameTeamIds);
      setLoader(false);
    } catch (error) {
      if (error.length === 11) {
        history.push("/pageNotFound");
      }
      setErrorMsg(error);
      setLoader(false);
    }
  };
  const [addtoWallet, setAddToWallet] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showTeamSelectModel, setShowTeamSelectModal] = useState(false);
  const [playerTeams, setPlayerTeams] = useState([]);
  const [loader, setLoader] = useState(false);
  const [team, selectedTeam] = useState(null);
  const handleCancel = () => setShowPaymentModal(false);
  const handleSubmit = async ({ payActiveIndex, bonus, setisLoading }) => {
    try {
      const isOpenChallenge = challengeDetails.challenge_mode == 1;
      const is_paying_full = payActiveIndex == 1 ? true : false;
      setisLoading(true);
      setLoader(true);
      if (isOpenChallenge) {
        // means accept invite by creator member
        await openChallengeAccept({
          challengeId: challengeDetails.id,
          body: {
            is_paying_full,
            bonus,
            team_id: team.id,
            action: "accept",
          },
        });
        setEntryFeeSubtract(
          is_paying_full
            ? challengeDetails.entry_fee * challengeDetails.mode
            : challengeDetails.entry_fee
        );
      }
      // since closed challenge it means it will accept by opponent creator or member or challenge creator member
      else {
        await closedChallengeAction({
          challengeId: challengeDetails.id,
          body: {
            challenge: challengeDetails.id,
            match: challengeDetails.id,
            from_user: currentPlayer?.player_id,
            to_user: currentPlayer?.player_id,
            leader: true,
            team_id: currentPlayer?.teams?.team_id,
            action: "accept",
            is_paying_full,
            bonus,
          },
        });
        setEntryFeeSubtract(
          is_paying_full
            ? challengeDetails.entry_fee * challengeDetails.mode
            : challengeDetails.entry_fee
        );
      }
      setLoader(false);
      setisLoading(false);
      setShowPaymentModal(false);
      setChallengeAcceptConfirmModal(true);
    } catch (error) {}
    setLoader(false);
    setisLoading(false);
  };
  const openAddToWallet = () => {
    setAddToWallet(true);
  };

  const closeAddToWallet = () => {
    setAddToWallet(false);
  };

  const getTeamsList = async ({ game_id, mode }) => {
    try {
      setLoader(true);
      const res = await getTeams({ game_id, mode });
      setLoader(false);
      setPlayerTeams(res);
    } catch (error) {}
  };
  const handleDeclineChallenge = (challenge) => {
    setChallengeDetails(challenge);
    setShowDeclineModal(true);
  };
  const handleAcceptChallenge = (challenge) => {
    setChallengeDetails(challenge);
    getTeamsList({ game_id: challenge.game, mode: challenge.mode });
    setShowPaymentModal(true);
    if (!challenge?.action_type?.team_id && challenge.challenge_mode != 2) {
      setShowTeamSelectModal(true);
    }
  };

  const handleNoPay = (challenge) => {
    setChallengeDetails(challenge);
    setShowAcceptModal(true);
  };

  const handleAcceptNoPay = async () => {
    try {
      setLoader(true);
      await closedChallengeAction({
        challengeId: challengeDetails.id,
        body: {
          ...challengeDetails.action_type,
          action: "accept",
          is_paying_full: false,
          bonus: false,
        },
      });
      setLoader(false);
      setShowAcceptModal(false);
      setChallengeAcceptConfirmModal(true);
    } catch (error) {
      setLoader(false);
    }
  };

  const declineChallenge = async () => {
    try {
      setLoader(true);
      const challengePayload = challengeDetails.action_type?.team_id
        ? challengeDetails.action_type
        : {
            challenge: challengeDetails.id,
            match: challengeDetails.id,
            from_user: currentPlayer?.player_id,
            to_user: currentPlayer?.player_id,
            leader: true,
            team_id: currentPlayer?.teams?.team_id,
          };
      const res = await closedChallengeAction({
        challengeId: challengeDetails.id,
        body: {
          ...challengePayload,
          action: "decline",
          is_paying_full: false,
          bonus: false,
        },
      });
      if (res) {
        if (challengeDetails?.action_type?.leader) {
          toastify("success", "Challenge declined sucessfully");
        } else {
          toastify("success", "Invite declined sucessfully");
        }
      }
      setLoader(false);
      setShowDeclineModal(false);
      getChallenges();
    } catch (error) {
      setLoader(false);
    }
  };
  const goBack = () => {
    history.push({
      pathname: `/${gameSlug}/lobby`,
      state: { challengeTab: true },
    });
  };
  const [isTimerReached, setTimerReached] = useState(false);
  const ChallengeCountdownEnded = (isComplete) => {
    setTimerReached(isComplete);
  };

  const componentCleanup = () => {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    window.history.replaceState({}, document.title);
  };
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/games-banner.png"}
          strength={500}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">
                      Challenge Detail
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {loader ? (
        <div class="loading"></div>
      ) : (
        <section className="lobby-detail challenge-detail">
          {challengeDetails !== null ? (
            challengeDetails && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="file-complaint-wrapper">
                      <div className="back-btn" onClick={() => goBack()}>
                        <span>
                          <img src="/assets/images/back-arrow.svg" alt="back" />
                        </span>
                        <span className="back">Back</span>
                      </div>
                    </div>
                    <div className="card">
                      <div className="detail-box">
                        <div className="image-box">
                          <div>
                            <img
                              src={
                                gameSlug === "call-of-duty-warzone"
                                  ? "/assets/images/warzone-lobby-detail.jpg"
                                  : gameSlug === "pubg"
                                  ? "https://rvygamiplatstg.blob.core.windows.net/rvy-gamiplat-dev/pubg.jpg"
                                  : gameSlug === "fortnite"
                                  ? "/assets/images/fortnite-lobby-detail.png"
                                  : gameSlug === "pubg-mobile"
                                  ? "/assets/images/pubg-mobile-lobby-detail.png"
                                  : gameSlug === "cod-mobile"
                                  ? "/assets/images/cod-mobile-lobby-detail.png"
                                  : gameSlug === "valorant"
                                  ? "/assets/images/ValorantDetail.png"
                                  : "/assets/images/Apex-lobby-detail.png"
                              }
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="game-details">
                          <div className="d-flex align-items-center justify-content-between lobby-detail-page">
                            <h2 className="m-0 me-2 lobbyDetailName">
                              {challengeDetails?.challenge_type == 1
                                ? "Open Challenge"
                                : challengeDetails.versus}
                            </h2>
                            {challengeDetails.creator ? (
                              <CreatorActionsView
                                challengeDetails={challengeDetails}
                              />
                            ) : (
                              <MemberView
                                canEnroll={canEnroll}
                                canModify={canModify}
                                challengeDetails={challengeDetails}
                                handleAcceptChallenge={handleAcceptChallenge}
                                handleNoPay={handleNoPay}
                                handleDeclineChallenge={handleDeclineChallenge}
                                currentPlayer={currentPlayer}
                              />
                            )}
                          </div>

                          <div className="entry-rates d-md-none">
                            <span>Entry : </span>${challengeDetails?.entry_fee}{" "}
                            Per player entry fee
                          </div>

                          <div className="information-wrapper">
                            <div className="date-time-box">
                              <p class="d-md-none">
                                {" "}
                                <span>Win</span>
                                <span class="info-icon">
                                  <img
                                    src="/assets/images/info-icon.svg"
                                    alt="info"
                                    class="info-image"
                                    data-tip
                                    data-for="loginTip"
                                  />
                                </span>
                                <ReactTooltip
                                  id="loginTip"
                                  place="right"
                                  effect="solid"
                                >
                                  {gameSlug === "valorant" ||
                                  gameSlug === "apex-legends" ||
                                  gameSlug === "call-of-duty-warzone"
                                    ? "Actual amount paid to the team"
                                    : "Actual amount paid to the user"}
                                </ReactTooltip>{" "}
                                <span className="lobby-detail-player weight-bold blue-color">
                                  {" "}
                                  {gameSlug === "valorant" ||
                                  gameSlug === "call-of-duty-warzone" ||
                                  gameSlug === "apex-legends"
                                    ? `$${
                                        challengeDetails?.winning
                                          ? challengeDetails?.winning
                                          : 0
                                      }/Winning Team`
                                    : `$${
                                        challengeDetails?.winning
                                          ? challengeDetails?.winning
                                          : 0
                                      } Paid Every Kill`}
                                </span>
                              </p>
                              <p>
                                <span>Date </span>
                                <span>
                                  {""}
                                  {new Date(
                                    challengeDetails?.countdown_time
                                  ).toLocaleDateString("en-US", {
                                    day: "numeric", // numeric, 2-digit
                                    year: "numeric", // numeric, 2-digit
                                    month: "short", // numeric, 2-digit, long, short, narrow
                                  })}
                                </span>
                              </p>
                              <p>
                                <span>Start Time </span>
                                <span>{challengeDetails?.match_time}</span>
                              </p>
                              <p>
                                <span>Team Mode</span>
                                {""}
                                <span className="lobby-detail-player">
                                  {challengeDetails.modetag}
                                </span>
                              </p>
                              <p>
                                <span>Last Entry Time</span>
                                {""}
                                <span className="lobby-detail-player">
                                  {challengeDetails.last_entry_time}
                                </span>
                              </p>
                            </div>
                            <div className="date-time-box">
                              <p class="d-none d-sm-none d-md-block">
                                {""}
                                <span>Win</span>
                                <span class="info-icon">
                                  <img
                                    src="/assets/images/info-icon.svg"
                                    alt="info"
                                    class="info-image"
                                    data-tip
                                    data-for="loginTip"
                                  />
                                </span>
                                <ReactTooltip
                                  id="loginTip"
                                  place="right"
                                  effect="solid"
                                >
                                  {gameSlug === "valorant" ||
                                  gameSlug === "call-of-duty-warzone" ||
                                  gameSlug === "apex-legends"
                                    ? "Actual amount paid to the team"
                                    : "Actual amount paid to the user"}
                                </ReactTooltip>{" "}
                                <span className="lobby-detail-player weight-bold blue-color">
                                  {""}
                                  {gameSlug === "valorant" ||
                                  gameSlug === "call-of-duty-warzone" ||
                                  gameSlug === "apex-legends"
                                    ? `$${
                                        challengeDetails?.winning
                                          ? challengeDetails?.winning
                                          : 0
                                      }/Winning Team`
                                    : `$${
                                        challengeDetails?.winning
                                          ? challengeDetails?.winning
                                          : 0
                                      } Paid Every Kill`}
                                </span>
                              </p>

                              <p className="min-player-desktop">
                                <span> Status </span>
                                {""}
                                <span className="lobby-detail-player challengeRight">
                                  <LobbyStatus data={challengeDetails} />
                                </span>
                              </p>
                              <p className="min-player-md">
                                <span> Status </span>
                                <LobbyStatus data={challengeDetails} />
                              </p>

                              <p className="min-player-desktop">
                                <span> Map </span>
                                {""}
                                <span className="lobby-detail-player challengeRight">
                                  {challengeDetails.gamemap || "N/A"}
                                </span>
                              </p>

                              <p className="min-player-md">
                                <span> Map </span>
                                {""}
                                <span className="lobby-detail-player challengeRighttablet">
                                  {challengeDetails.gamemap || "N/A"}
                                </span>
                              </p>

                              <p className="min-player-desktop">
                                <span> Game Type </span>
                                {""}
                                <span className="lobby-detail-player challengeRight">
                                  Traditional
                                </span>
                              </p>
                              <p className="min-player-md">
                                <span> Game Type </span>
                                <span className="lobby-detail-player challengeRight">
                                  Traditional
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="enroll-wrapper">
                        <div className="enroll-box">
                          <div className="entry-rates">
                            <span>Entry : </span>${challengeDetails?.entry_fee}{" "}
                            Per player entry fee
                          </div>
                        </div>
                        {/* Enroll box */}
                        <div className="enroll-box">
                          <div className="enrolled">
                            {challengeDetails.countdown_time === undefined ? (
                              <></>
                            ) : (
                              <>
                                {challengeDetails.challenge_mode == 3 ||
                                challengeDetails.challenge_mode == 4 ||
                                challengeDetails.challenge_mode == 5 ||
                                isTimerReached ? (
                                  ""
                                ) : (
                                  <CountDown
                                    server_current_datetime={
                                      challengeDetails?.countdown_time
                                    }
                                    time_reached={(e) => {
                                      ChallengeCountdownEnded();
                                    }}
                                    stopEnrollTeam={(isComplete) => {
                                      if (canEnroll) {
                                        setCanEnroll(false);
                                      }
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Tabs
                  defaultActiveKey={
                    location?.state?.participantTabActive
                      ? location?.state?.participantTabActive
                      : "stats"
                  }
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  {
                    <Tab eventKey="stats" title="Stats">
                      <PlayerStats
                        player_stats={teams}
                        game_data={challengeDetails}
                        type={"challenge"}
                      />
                    </Tab>
                  }
                  {
                    <Tab eventKey="officialScores" title="Official scoring">
                      <div className="boxx">
                        <p>
                          All prize money is calculated based on the official
                          scoring
                        </p>
                        <ReactJson
                          src={
                            challengeDetails?.official_stats === null
                              ? { noDataFound: "No data found" }
                              : challengeDetails?.official_stats
                          }
                          theme="brewer"
                          onEdit={false}
                          onAdd={false}
                          onDelete={false}
                          enableClipboard={false}
                          displayDataTypes={false}
                        />
                      </div>
                    </Tab>
                  }
                  {currentPlayer?.id &&
                  currentPlayer?.invite_status != "Rejected" &&
                  currentPlayer?.invite_status != "Left" ? (
                    <Tab eventKey="participants" title="Participants">
                      <div className="participantsHeader d-flex justify-content-end">
                        <CountDownTeams
                          server_current_datetime={
                            challengeDetails?.countdown_time
                          }
                          owner={currentPlayer.leader}
                          setCanModify={setCanModify}
                          setCanReplace={setCanReplace}
                        />
                      </div>
                      <ChallengeParticipants
                        lobbyData={challengeDetails}
                        participantData={teams}
                        getChallenges={getChallenges}
                        currentPlayer={currentPlayer}
                        openAddToWallet={openAddToWallet}
                        games={games}
                        canReplace={canReplace}
                        canModify={canModify}
                      />
                    </Tab>
                  ) : null}
                </Tabs>
              </div>
            )
          ) : (
            <h5 style={{ textAlign: "center" }}> {errMsg}</h5>
          )}
        </section>
      )}
      {challengeAcceptConfirmModal ? (
        <>
          <Modal
            show={true}
            onHide={() => {
              getChallenges();
              setChallengeAcceptConfirmModal(false);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="money-added">
              <div className="tick-image">
                <img src="/assets/images/tick.svg" alt="" />
              </div>
              <h3 className="challengeCreated">
                {(
                  challengeDetails.challenge_mode == 2
                    ? challengeDetails?.action_type?.leader
                    : !challengeDetails.action_type.team_id
                )
                  ? "Challenge"
                  : "Invite"}{" "}
                Accepted !
              </h3>

              <p className="challengeBetween">
                Congratulations, your have successfully accepted the{" "}
                {(
                  challengeDetails.challenge_mode == 2
                    ? challengeDetails?.action_type?.leader
                    : !challengeDetails.action_type.team_id
                )
                  ? "challenge"
                  : "invite"}{" "}
              </p>

              <p>Wallet Balance</p>
              <h4>${(walletBalance - entryFeeSubtract)?.toFixed(2)}</h4>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      {showPaymentModal ? (
        addtoWallet ? (
          <Modal
            show={true}
            onHide={closeAddToWallet}
            contentClassName="qr-verification-wrapper"
            backdrop="static"
          >
            <AddToWallet
              closeModal={closeAddToWallet}
              gameName={
                games.filter((game) => challengeDetails.game == game.id)?.[0]
                  ?.name
              }
            />
          </Modal>
        ) : showTeamSelectModel ? (
          <Modal
            show={true}
            onHide={() => {
              setShowPaymentModal(false);
              setShowTeamSelectModal(false);
              selectedTeam(null);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Team</Modal.Title>
            </Modal.Header>

            <Modal.Body className="make-payment mt-4">
              <Link
                className="redirect-link mb-2"
                to={{
                  pathname: `/myprofile`,
                  state: {
                    createTeam: true,
                    mode: challengeDetails?.mode,
                    game: challengeDetails?.game,
                  },
                }}
              >
                + Create New Team
              </Link>
              <Select
                className="react-select-container-teammates chooseTeam mt-2"
                classNamePrefix="react-select-teammates"
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose Team"
                isSearchable
                onChange={(teamToBeSelected) => {
                  selectedTeam(teamToBeSelected);
                }}
                value={team}
                options={playerTeams.map((teamItem) => ({
                  ...teamItem,
                  value: teamItem.id,
                  label: teamItem.name,
                }))}
              />
              {team?.players?.length ? (
                <TeamPlayersList players={team?.players} />
              ) : null}
            </Modal.Body>
            <Modal.Footer className="total-money total delete-footer registerCancelBtn">
              <Button
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(false);
                  setShowTeamSelectModal(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(true);
                  setShowTeamSelectModal(false);
                }}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={true} onHide={handleCancel} backdrop="static">
            <PaymentComponent
              entry_fee={challengeDetails.entry_fee}
              mode={challengeDetails.mode}
              selectedGame={
                games.filter((game) => challengeDetails.game == game.id)?.[0]
              }
              free_lobby={false}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              openAddToWallet={openAddToWallet}
              creatorPay={!challengeDetails?.action_type?.team_id}
            />
          </Modal>
        )
      ) : null}
      {showDeclineModal ? (
        <Modal
          show
          onHide={() => {
            setShowDeclineModal(false);
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Decline Challenge?</p>
              <p className="leave-modal-text">
                Are you sure you want to decline the challenge?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <Button
              className="cancel-btn"
              onClick={() => {
                setShowDeclineModal(false);
              }}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={() => declineChallenge()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {showAcceptModal ? (
        <Modal show onHide={() => setShowAcceptModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Accept Invitation?</p>
              <p className="leave-modal-text">
                Are you sure you want to accept the team's invitation?
              </p>
              <div className="d-flex justify-content-evenly participantItemGamer">
                <div>
                  <p>Game's Name</p>
                  <h6 style={{ color: "white" }}>{selectedGame?.name}</h6>
                </div>
                <div className="displayGamerTag">
                  <p>Display Name </p>{" "}
                  <div className="gamertag-edit">
                    <h6>{gamertag ? gamertag : "-"}</h6>
                    <Link
                      to={{
                        pathname: "/myProfile",
                        data: "settingsTab",
                        settingsKey: "gameIds",
                      }}
                      className="ml-4"
                    >
                      <img
                        src="/assets/images/edit-gamertag-payment-popup.svg"
                        alt="edit-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <div
              className="pb-3"
              data-tip
              data-for="loginTip"
              style={{ cursor: "pointer" }}
            >
              <span class="info-icon">
                <img
                  src="/assets/images/info-icon.svg"
                  alt="info"
                  class="info-image"
                />
                <span className="gamerTagInfoMessage">
                  {gamerTagInfo(
                    games.filter(
                      (game) => challengeDetails.game == game.id
                    )?.[0]
                  )}
                </span>
              </span>
              <div className="helper-image-container enrollHelperImage">
                <ReactTooltip id="loginTip" place="top" effect="solid">
                  <img
                    src={
                      games.filter(
                        (game) => challengeDetails.game == game.id
                      )?.[0]?.helper_image
                    }
                    alt="helper-image"
                  />
                </ReactTooltip>{" "}
              </div>
            </div>
            <Button
              className="cancel-btn"
              onClick={() => setShowAcceptModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={handleAcceptNoPay}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default ChallengesDetail;

const CreatorActionsView = ({ challengeDetails }) => {
  return !isChallengeActive(challengeDetails.challenge_mode) ? (
    <Button variant="info" disabled={true}>
      Challenge {challengeStatus[challengeDetails.challenge_mode]}
    </Button>
  ) : null;
};

const MemberView = ({
  challengeDetails,
  handleDeclineChallenge,
  handleAcceptChallenge,
  handleNoPay,
  canModify,
  canEnroll,
  currentPlayer,
}) => {
  const versusChallenge = challengeDetails.challenge_mode == 2;
  return !isChallengeActive(challengeDetails.challenge_mode) ? (
    <Button variant="info" disabled={true}>
      Challenge {challengeStatus[challengeDetails.challenge_mode]}
    </Button>
  ) : !currentPlayer?.id &&
    versusChallenge &&
    !challengeDetails.enrolled &&
    !challengeDetails.rejected ? (
    <Button variant="danger" disabled={true}>
      Removed
    </Button>
  ) : (challengeDetails.payment_required || !challengeDetails.enrolled) &&
    (!challengeDetails?.rejected ||
      (challengeDetails?.rejected && challengeDetails.challenge_mode == 1)) ? (
    <div className="challengeDetailsPage">
      {!challengeDetails?.action_type?.team_id &&
      challengeDetails.challenge_mode == 1 ? null : (
        <Button
          className="rejectButton"
          variant="secondary"
          onClick={() => handleDeclineChallenge(challengeDetails)}
          disabled={
            challengeDetails?.rejected && challengeDetails.challenge_mode == 2
              ? true
              : challengeDetails?.action_type?.team_id
              ? !canModify
              : !canEnroll
          }
        >
          Decline
        </Button>
      )}
      {!(
        challengeDetails.challenge_mode == 3 ||
        challengeDetails.challenge_mode == 4 ||
        challengeDetails.challenge_mode == 5
      ) ? (
        <Button
          className="acceptButton"
          variant="secondary"
          onClick={() => {
            if (
              challengeDetails?.action_type?.team_id
                ? challengeDetails.payment_required
                : true
            ) {
              handleAcceptChallenge(challengeDetails);
            } else {
              handleNoPay(challengeDetails);
            }
          }}
          disabled={
            challengeDetails?.rejected && challengeDetails.challenge_mode == 2
              ? true
              : challengeDetails?.action_type?.team_id
              ? !canModify
              : !canEnroll
          }
        >
          Accept
        </Button>
      ) : null}
    </div>
  ) : challengeDetails.enrolled ? (
    <Button variant="success" disabled={true}>
      {challengeDetails?.action_type?.leader ? "Challenge" : "Invite"} Accepted
    </Button>
  ) : challengeDetails?.rejected && challengeDetails.challenge_mode == 2 ? (
    <Button variant="danger" disabled={true}>
      {!challengeDetails?.action_type?.team_id ? "Challenge" : "Invite"}{" "}
      Rejected
    </Button>
  ) : null;
};

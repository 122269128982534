import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { redeemAmount } from "../../services/user.service";
import { useConfig } from "../../context/ConfigContext";

function Paypal(props) {
  const { state } = useConfig();
  const {
    paypal_redeem_charge_percent: REDEEM_PERCENT_CHARGES,
    paypal_redeem_charge_value: REDEEM_PAYOUT_CHARGES,
  } = state.config;
  const validationSchema = Yup.object().shape({
    paypal: Yup.string()
      .typeError("PayPal id is required, please add it first from edit profile")
      .required("PayPal id is required, please add it first from edit profile"),
    amount: Yup.number()
      .typeError("Please enter a valid amount")
      .positive("Amount cannot be 0")
      .test(
        "invalid-decimal",
        "Amount doesn't have more than 2 decimal points",
        (value) => (value + "").match(/^(\d+(\.\d{1,2})?)$/)
      )
      .required("Amount is required")
      .min(10, "Amount has to be more than or equal to $10")
      .max(+props.wallet_balance, (obj) => {
        if (obj.max < 10) {
          return `Your wallet balance is low :$${props.wallet_balance}, Minimum withdrawal amount has to be more than or equal to $10`;
        } else {
          return `Your wallet balance :$${props.wallet_balance}, Amount has to be less than or equal to $${props.wallet_balance} but greater than or equal to $10`;
        }
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { paypal: props.paypal_id },
    resolver: yupResolver(validationSchema),
  });

  const onRedeemMoneyPaypal = async (data) => {
    props.setisLoading(true);
    await redeemAmount({
      amount: data.amount,
      payment_type: "paypal",
      payment_mode: "normal",
    })
      .then((res) => {
        props.setisLoading(false);
        props.setisRedeemProcessDone(true);
        props.setdeductedAmount(data.amount);
        props.setupdatedWallet(res.amount);
        props.fetchUserData();
      })
      .catch(() => {
        props.setisLoading(false);
        props.setisRedeemProcessDone(false);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onRedeemMoneyPaypal)}>
        <Form.Group className="paypal" controlId="formGroupText">
          <p> PayPal Id : {props.paypal_id || "NA"} </p>
          <Form.Control style={{ display: "none" }} {...register("paypal")} />
          {errors.paypal?.message && (
            <div className="invalid-feedback d-block">
              {errors.paypal?.message}
            </div>
          )}
        </Form.Group>
        <h3 className="text-white payment-method-bold mt-4 mb-2">
          Amount to be Redeemed
        </h3>
        <div className="input-money">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
            <Form.Control
              placeholder="Amount"
              {...register("amount")}
              name="amount"
              className={`${errors.amount ? "is-invalid" : ""}`}
            />
          </InputGroup>
          {errors.amount?.message && (
            <div className="invalid-feedback d-block">
              {errors.amount?.message}
            </div>
          )}
        </div>
        <p class="deposit-note">
          {" "}
          Note: Fee of {REDEEM_PERCENT_CHARGES}%
          {REDEEM_PAYOUT_CHARGES ? "+ $" + REDEEM_PAYOUT_CHARGES : ""} would be
          charged on redeeming funds.
        </p>
        <Modal.Footer className="redeem-button mt-2">
          <Button
            className="flex-grow-1"
            variant="primary"
            type="submit"
            disabled={props.isLoading}
          >
            {props.isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Redeem"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default Paypal;

import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import toastify from "../../helper/toastify";
import {
  ConsoleHelper,
  enrollEventListener,
  gamerTagInfo,
} from "../../helper/utilities";
import {
  postFriendRequest,
  postReplaceTeamMate,
  callLeaveTeam,
} from "../../services/teams.service";
import {
  getUserDetail,
  verifyLobbyPassword,
} from "../../services/user.service";
import AddTeamMate from "./AddTeamMate";
import MakePayment from "./MakePayment";
import { gamelisting } from "../../services/game.service";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

const ParticipantItem = (props) => {
  const [loader, setLoader] = useState(false);
  const [password, setPassWord] = useState("");
  const passwordRef = useRef(null);
  const [openProtectedModal, setopenProtectedModal] = useState(false);
  const [selectedTeamMates, setSelectedTeamMates] = useState([]);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [replaceId, setReplaceId] = useState(null);
  const [replaceFriend, setReplaceFriend] = useState(null);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [openUpdateProfile, setOpenUpdateProfileModal] = useState(false);
  const [messageOnModal, setMessgaeOnModal] = useState("");
  const [makeIndividualPayment, setMakeIndividualPayment] = useState(false);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [playerId, setPlayerId] = useState("");
  const [replaceLeaveMember, setReplaceLeaveMember] = useState(false);
  const loggedUser = useSelector((state) => state);
  const [user, setUser] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [gamertag, setGamerTag] = useState(null);
  const [spinnerLoaderReplace, setSpinnerLoaderReplace] = useState(false);
  const [participantData, setParticipantData] = useState([]);
  const handleDeclineInvitation = () => {
    const payload = {
      status: "reject",
      promo_choice: false,
      lobbyId: props?.participantData?.team?.lobby,
      teamId: props?.participantData?.team.id,
    };
    setLoader(true);
    postFriendRequest(payload)
      .then((res) => {
        props.getLobbies();
        props.getParticipants();
        setLoader(false);
        setShowDeclineModal(false);
        localStorage.setItem(
          "enroll-event",
          "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
        );
      })
      .catch((err) => {
        ConsoleHelper(err);
        setLoader(false);
        setShowDeclineModal(false);
      });
  };
  const profilePhone =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).phone;
  const profileAddress =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).address.full;

  const handleAcceptNoPay = () => {
    const payload = {
      status: "accept",
      promo_choice: false,
      lobbyId: props?.participantData?.team?.lobby,
      teamId: props?.participantData?.team.id,
    };
    setLoader(true);
    postFriendRequest(payload)
      .then((res) => {
        props.getLobbies();
        props.getParticipants();
        ConsoleHelper(res);
        setLoader(false);
        setShowAcceptModal(false);
        localStorage.setItem(
          "enroll-event",
          "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
        );
      })
      .catch((err) => {
        ConsoleHelper(err);
        setLoader(false);
        setShowAcceptModal(false);
      });
  };
  const handleAcceptInvitation = (data) => {
    if (profilePhone === "" || profilePhone === null) {
      setOpenUpdateProfileModal(true);
      setMessgaeOnModal("Please Update your profile");
    } else if (profileAddress === "") {
      setOpenUpdateProfileModal(true);
      setMessgaeOnModal("Please Verify your phone number");
    } else {
      if (data) {
        setShowAcceptModal(true);
      } else {
        setMakeIndividualPayment(true);
      }
    }
  };
  const closeProfileCheckModal = () => {
    setOpenUpdateProfileModal(false);
    setMessgaeOnModal("");
  };
  useEffect(() => {
    enrollEventListener();
  }, [replaceFriend]);

  const comparator = (x) => (x.is_owner ? -1 : 1);
  const closeMakePaymentModal = () => {
    setMakeIndividualPayment(false);
  };
  const onEnrollComplete = () => {
    closeMakePaymentModal();
    toastify("success", "Payment made successfully");
  };
  const leaveTeam = () => {
    setSpinnerLoader(true);
    const payload = {
      lobby_id: props?.participantData?.team?.lobby,
      team_id: props?.participantData?.team.id,
    };
    callLeaveTeam(payload)
      .then((res) => {
        props.getLobbies();
        props.getParticipants();
        setSpinnerLoader(false);
        setShowLeaveModal(false);
        localStorage.setItem(
          "enroll-event",
          "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
        );
      })
      .catch((err) => {
        ConsoleHelper(err);
        setSpinnerLoader(false);
        setShowLeaveModal(false);
      });
  };
  const payByCreator = (data) => {
    setMakeIndividualPayment(true);
    setPlayerId(data?.uid);
  };

  async function handlePassword(e) {
    try {
      setSpinnerLoader(true);
      const res = await verifyLobbyPassword({
        lobby_password: passwordRef.current.value?.trim(),
        lobby_id: props.lobbyData?.id,
      });
      if (res) {
        toastify("success", res.Message);
        setopenProtectedModal(false);
        handleAcceptInvitation(props.userProfile?.has_paid);
      }
    } catch (error) {
      ConsoleHelper({ error });
    } finally {
      setSpinnerLoader(false);
    }
  }

  useEffect(() => {
    getGamesList();
    if (loggedUser?.user) {
      getUserDetail().then((res) => {
        setUser(res);
      });
    }
  }, []);

  useEffect(() => {
    setSelectedGame(
      games?.find((item) => item.id == props?.lobbyData?.game?.id)
    );
  }, [games]);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  const getGamesList = () => {
    gamelisting().then((res) => {
      setGames(res);
    });
  };

  useEffect(() => {
    setParticipantData(props?.participantData?.teammates?.sort(comparator));
  }, [props?.participantData?.teammates]);

  return (
    <>
      {/* Creator view */}
      {props.owner ? (
        <div className="participantsHeader participnt-box">
          <div className="teamName d-flex w-100">
            <div className="team-name">
              <h5>{props.item?.team.name}</h5>
            </div>
            <div className="team-enrollment">
              {props.item?.team.has_paid_total ? (
                <span>
                  <img
                    src="/assets/images/enrollment-completed-icon.svg"
                    alt="enrollment-completed-icon"
                  />
                  <span>Enrollment Completed</span>
                </span>
              ) : (
                <span>
                  Enrollment Pending (
                  {props.item?.team?.total_paid_yet /
                    props.lobbyData?.entry_fee}
                  /{props.lobbyData.mode})
                </span>
              )}
            </div>
          </div>
          <div className="teams-user-status">
            <div className="row creator-view">
              <div className="teams-header mb-3">
                <span>USERNAME</span>
              </div>
              <div className="teams-header teams-status">
                <span>STATUS</span>
              </div>
            </div>
            {participantData?.map((data, id) => {
              return (
                <div className="row mb-2 mt-3 userTeams" key={data.username}>
                  <div className="teams-header">
                    <span
                      className={`${
                        data.status === "Left" ? "leftMember" : ""
                      }`}
                    >
                      {data.first_name + " " + data.last_name}
                    </span>
                  </div>
                  <div
                    className={`teams-header teams-status ${
                      data.is_owner ? "creator-text" : ""
                    } participants-teams`}
                  >
                    <span
                      className={`${data.is_owner ? "teams-creator" : ""} ${
                        data.status === "Left" ? "leftMember" : ""
                      } `}
                    >
                      {" "}
                      {data.is_owner ? "Creator" : data?.status}
                    </span>
                  </div>
                  {props.owner && !data.is_owner ? (
                    <div className="teamsButtons">
                      {!data?.has_paid && data.status == "Pending" ? (
                        <Button
                          className="declineAction member-decline makePaymentBtn"
                          onClick={() => payByCreator(data)}
                          disabled={props.canModify}
                        >
                          <span>Make Payment</span>
                        </Button>
                      ) : null}
                      {data.status == "Left" &&
                      props.item?.team.has_paid_total &&
                      props.playersInTeam >= props.lobbyData.mode ? null : (
                        <Button
                          className="declineAction memberReplaceBtn"
                          onClick={() => {
                            setReplaceId(data?.id ? data?.id : data?.uid);
                            setShowReplaceModal(true);
                            setReplaceLeaveMember(data.status == "Left");
                          }}
                          disabled={
                            (data?.pay_type === 2 &&
                              data?.status === "Accepted") ||
                            props.canModify ||
                            !props.canReplace
                          }
                        >
                          Replace
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="col mb-4 left-card">
          <div className="card card_teams">
            <div className="card-body members-cards">
              <div className="memberHeader">
                <h3 className="card-title">{props.item?.team.name}</h3>
                <div className="team-enrollment">
                  {props.item?.team.has_paid_total ? (
                    <span style={{ color: "white" }}>
                      <img
                        src="/assets/images/enrollment-completed-icon.svg"
                        alt="enrollment-completed-icon"
                      />
                      <span>Enrollment Completed</span>
                    </span>
                  ) : (
                    <span style={{ color: "white" }}>
                      {props.fullView ? "Enrollment Pending " : ""}(
                      {props.item?.team?.total_paid_yet /
                        props.lobbyData?.entry_fee}
                      /{props.lobbyData.mode})
                    </span>
                  )}
                </div>
              </div>
              <div className="teams-information  display-flex d-flex w-100">
                <div className="left-side">
                  <p className="teamMember-info">USER NAME</p>
                  {participantData?.map((data, id) => {
                    return data?.status !== "Left" ? (
                      <p key={data.username} className="otherTeamMembers">
                        {data.first_name + " " + data.last_name}
                      </p>
                    ) : null;
                  })}
                </div>
                <div className="right-side">
                  <p className="teamMember-info">STATUS</p>
                  {participantData?.map((data, id) => {
                    return data?.status !== "Left" ? (
                      <p
                        key={data.id}
                        className={`otherTeamMembers ${
                          data.is_owner ? "teams-creator" : ""
                        }`}
                      >
                        {" "}
                        {data.is_owner ? "Creator" : data?.status}
                      </p>
                    ) : null;
                  })}
                </div>
              </div>
              <div className="membersButtons otherTeamMembers d-flex">
                {props.userProfile?.status == "Accepted" ? (
                  <Button
                    className="declineAction memberLeaveBtn"
                    onClick={() => setShowLeaveModal(true)}
                    style={{ backgroundColor: "#00ccff" }}
                    disabled={props.canModify}
                  >
                    Leave
                  </Button>
                ) : (
                  <>
                    <Button
                      className="declineAction member-decline"
                      onClick={() => setShowDeclineModal(true)}
                      disabled={props.canModify}
                    >
                      Decline
                    </Button>
                    <Button
                      className="declineAction memberAccept"
                      onClick={() => {
                        if (props?.lobbyData.is_password_protected) {
                          setopenProtectedModal(true);
                        } else {
                          handleAcceptInvitation(props.userProfile?.has_paid);
                        }
                      }}
                      disabled={props.canModify}
                    >
                      Accept
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showReplaceModal ? (
        <Modal
          show={true}
          onHide={() => setShowReplaceModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Replace Teammate</Modal.Title>
          </Modal.Header>

          <Modal.Body className="make-payment">
            <AddTeamMate
              lobbyId={props?.participantData?.team?.lobby}
              minTeamMatesRequired={1}
              canRegisterTeamMate={true}
              setCanRegisterTeamMate={() => {}}
              selectedTeamMates={selectedTeamMates}
              setSelectedTeamMates={setSelectedTeamMates}
              showError={false}
              replace={true}
              setReplaceFriend={setReplaceFriend}
              replaceId={replaceLeaveMember ? null : replaceId}
              teamId={props?.participantData?.team.id}
            />
          </Modal.Body>
          <Modal.Footer className="total-money total delete-footer registerCancelBtn">
            <Button
              variant="primary"
              onClick={() => setShowReplaceModal(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                const payload = {
                  lobbyId: props?.participantData?.team?.lobby,
                  teamId: props?.participantData?.team.id,
                  removeId: replaceId,
                  newId: replaceFriend.id
                    ? replaceFriend.id
                    : replaceFriend.uid,
                };
                setLoader(true);
                setSpinnerLoaderReplace(true);
                postReplaceTeamMate(payload)
                  .then((res) => {
                    toastify("success", "Member replaced successfully");
                    setLoader(false);
                    setSpinnerLoaderReplace(false);
                    props.getLobbies();
                    props.getParticipants();
                    setShowReplaceModal(false);
                    localStorage.setItem(
                      "enroll-event",
                      "enroll" +
                        window.crypto.getRandomValues(new Uint32Array(1))[0]
                    );
                  })
                  .catch((err) => {
                    ConsoleHelper(err);
                    setLoader(false);
                    setSpinnerLoaderReplace(false);
                    setShowReplaceModal(false);
                  });
              }}
              disabled={!replaceFriend || spinnerLoaderReplace}
            >
              {spinnerLoaderReplace ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Replace"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {spinnerLoader ? (
        <div class="loading"></div>
      ) : showLeaveModal ? (
        <Modal show onHide={() => setShowLeaveModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Leave Team?</p>
              <p className="leave-modal-text">
                Are you sure you want to leave the team? There will be no
                refunds
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer mb-4">
            <Button
              className="cancel-btn"
              onClick={() => setShowLeaveModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={() => leaveTeam()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {showDeclineModal ? (
        <Modal show onHide={() => setShowDeclineModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Decline Invitation?</p>
              <p className="leave-modal-text">
                Are you sure you want to decline the team's invitation?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <Button
              className="cancel-btn"
              onClick={() => setShowDeclineModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={handleDeclineInvitation}>
              {loader ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {showAcceptModal ? (
        <Modal show onHide={() => setShowAcceptModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Accept Invitation?</p>
              <p className="leave-modal-text">
                Are you sure you want to accept the team's invitation?
              </p>
              <div className="d-flex justify-content-evenly participantItemGamer">
                <div>
                  <p>Game's Name</p>
                  <h6 style={{ color: "white" }}>{selectedGame?.name}</h6>
                </div>
                <div className="displayGamerTag">
                  <p>Display Name </p>{" "}
                  <div className="gamertag-edit">
                    <h6>{gamertag ? gamertag : "-"}</h6>
                    <Link
                      to={{
                        pathname: "/myProfile",
                        data: "settingsTab",
                        settingsKey: "gameIds",
                      }}
                      className="ml-4"
                    >
                      <img
                        src="/assets/images/edit-gamertag-payment-popup.svg"
                        alt="edit-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <div
              className="pb-3"
              data-tip
              data-for="loginTip"
              style={{ cursor: "pointer" }}
            >
              <span class="info-icon">
                <img
                  src="/assets/images/info-icon.svg"
                  alt="info"
                  class="info-image"
                />
                <span className="gamerTagInfoMessage">
                  {gamerTagInfo(selectedGame)}
                </span>
              </span>
              <div className="helper-image-container enrollHelperImage">
                <ReactTooltip id="loginTip" place="top" effect="solid">
                  <img src={selectedGame?.helper_image} alt="helper-image" />
                </ReactTooltip>{" "}
              </div>
            </div>
            <Button
              className="cancel-btn"
              onClick={() => setShowAcceptModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={handleAcceptNoPay}>
              {loader ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {openUpdateProfile ? (
        <Modal show onHide={() => closeProfileCheckModal()} backdrop="static">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <div className="tick-image">
              <img src="/assets/images/tick.svg" alt="" />
            </div>
            <h3>{messageOnModal}</h3>
          </Modal.Body>
        </Modal>
      ) : null}
      {
        /*Individual payment*/
        makeIndividualPayment ? (
          <MakePayment
            {...props}
            closePaymentModal={() => {
              closeMakePaymentModal();
            }}
            playerUID={playerId}
            onEnrollComplete={onEnrollComplete}
            payForPlayer={true}
            games={games}
          />
        ) : null
      }

      <Modal
        show={openProtectedModal}
        onHide={() => setopenProtectedModal(false)}
        backdrop="static"
      >
        {" "}
        <Modal.Header closeButton>
          <h2 className="text-left">Lobby password</h2>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-1">
            <Form.Control
              type="text"
              placeholder="Enter Your Lobby Password"
              name="amount"
              ref={passwordRef}
              onChange={(e) => setPassWord(e.target.value)}
            />
          </InputGroup>
          <Button
            variant="primary"
            className="communityButton invite-btn"
            disabled={!password?.trim()}
            onClick={handlePassword}
          >
            {spinnerLoader ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Continue"
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ParticipantItem;

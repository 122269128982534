import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import sanitizeHtml from "sanitize-html";
import {
  gamelisting,
  gameFlowAllListing,
  gameFlowListing,
} from "../../services/game.service";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import Select from "react-select";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: 200,
    background: "#0b0b0b",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#00ccff" : "#979797",
    boxShadow: null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "white" : "#00ccff",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    background: "#0b0b0b",
    padding: 0,
    width: 200,
    border: "1px solid #979797",
    overflow: "hidden",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
    zIndex: 1,
    color: "#fff",
    width: 200,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    // override border radius to match the box
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
    width: 150,
  }),
};
const GameFlow = () => {
  const [loading, setLoading] = useState(false);
  const [demoList, setDemoList] = useState([]);
  const [lobbyGame, setLobbyGame] = useState(0);

  const [gamesList, setGamesList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [slug, setSlug] = useState("");
  useEffect(() => {
    logoutEventPublicRoutes();
  }, [gamesList]);

  useEffect(() => {
    gamelisting().then((res) => {
      const appendItem = [
        { name: "All games", id: 0, game_status: 1, slug: "AllGames" },
      ];
      const finalSelectList = [...appendItem, ...res];
      setGamesList(
        finalSelectList
          .map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }))
          .filter((game) => game.game_status == 1)
      );
    });
    getList();
  }, [slug]);
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const getList = async () => {
    if (slug === "" || slug === "AllGames") {
      const model = {
        limit: limit,
        offset: offset,
        q: searchText,
      };
      await gameFlowAllListing(model)
        .then((res) => {
          setDemoList(res.results);
          setLoading(false);
          setTotalCount(res.count);
          if (res.next === null) {
            setLimit(6);
            setOffset(0);
          } else {
            getNextGameFlowPage(res)
          }
        })
        .catch((_) => {
          setLoading(false);
        });
    } else {
      const filter = {
        game_slug: slug,
        q: searchText,
        limit: limit,
        offset: 0,
      };
      await gameFlowListing(filter)
        .then((res) => {
          setDemoList(res.results);
          setLoading(false);
          setTotalCount(res.count);
          if (res.next === null) {
            setLimit(6);
            setOffset(0);
          } else {
            getNextGameFlowPage(res)
          }
        })
        .catch((_) => {
          setLoading(false);
        });
    }
  };
  const onLobbyGameChange = (event) => {
    setLobbyGame(event);
    setSlug(event.slug);
  };

  const [searchText, setSearchText] = useState("");
  const [crossIcon, setShowCrossIcon] = useState(false);
  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setShowCrossIcon(event.target.value === "" ? false : true);
  };
  const [playing, setPlaying] = useState(false);
  const startPlaying = () => {
    setPlaying(true);
  };
  const [loading1, setLoading1] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const fetchMoreData = () => {
    if (totalCount <= demoList.length) {
      setHasMore(false);
      return;
    } else {
      if (slug === "" || slug === "AllGames") {
        setLoading1(true);
        const model = {
          limit: limit,
          offset: offset,
          q: searchText,
        };
        setTimeout(() => {
          gameFlowAllListing(model)
            .then((res) => {
              setDemoList(demoList.concat(res.results));
              setLoading1(false);
              setTotalCount(res.count);
              if (res.next === null) {
                setLimit(6);
                setOffset(0);
              } else {
                getNextGameFlowPage(res)
              }
            })
            .catch((_) => {});
        }, 500);
      } else {
        const filter = {
          game_slug: slug,
          q: searchText,
          limit: limit,
          offset: offset,
        };
        gameFlowListing(filter)
          .then((res) => {
            setDemoList(res.results);
            setLoading(false);
            setTotalCount(res.count);
            if (res.next === null) {
              setLimit(6);
              setOffset(0);
            } else {
              getNextGameFlowPage(res)
            }
          })
          .catch((_) => {
            setLoading(false);
          });
      }
    }
  };

  const getSearchedData = (empty = false) => {
    if (slug === "" || slug === "AllGames") {
      setLoading1(true);
      const model = {
        limit: limit,
        offset: 0,
        q: empty ? "" : searchText,
      };
      gameFlowAllListing(model)
        .then((res) => {
          setDemoList(res.results);
          setLoading1(false);
          setTotalCount(res.count);
          if (res.next === null) {
            setLimit(6);
            setOffset(0);
          } else {
            getNextGameFlowPage(res)
          }
        })
        .catch((_) => {});
    } else {
      const filter = {
        game_slug: slug,
        q: empty ? "" : searchText,
        limit: limit,
        offset: 0,
      };
      gameFlowListing(filter)
        .then((res) => {
          setDemoList(res.results);
          setLoading(false);
          setTotalCount(res.count);
          if (res.next === null) {
            setLimit(6);
            setOffset(0);
          } else {
            getNextGameFlowPage(res)
          }
        })
        .catch((_) => {
          setLoading(false);
        });
    }
  };
  const clearList = () => {
    setSearchText("");
    getSearchedData(true);
    setShowCrossIcon(false);
  };

  const getNextGameFlowPage = (res) => {
    const url = new URL(res.next);
    const limitURL = url.searchParams.get("limit");
    const offsetURL = url.searchParams.get("offset");
    setLimit(limitURL);
    setOffset(offsetURL);
  }

  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax profile-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">Demos</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <section className="lobby-section GameFlow lobby-section-fullwidth">
        <div className="container gameFlowSection">
          <div className="row">
            <div className="col">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="lobby-dropdowns justify-content-between">
                    <div className="lobby demos-filter">
                      <h2>Game</h2>
                      <p>Game</p>
                      <Select
                        options={gamesList}
                        onChange={onLobbyGameChange}
                        styles={customStyles}
                        isSearchable={false}
                        value={gamesList.find(
                          ({ value }) => value === lobbyGame
                        )}
                      />
                    </div>
                    <div className="d-flex gameFlow-searchBar">
                      <input
                        className="form-control search-bar"
                        type="text"
                        placeholder="How to play Apex"
                        onChange={(e) => handleSearch(e)}
                        value={searchText}
                      />
                      <span>
                        {crossIcon ? (
                          <button
                            type="button"
                            onClick={() => clearList()}
                            className="crossGameFlow"
                          >
                            &times;
                          </button>
                        ) : null}
                        <button
                          className="invite-btn search-goto-btn"
                          onClick={() => getSearchedData()}
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-2">
                    {loading ? (
                      <div class="loading"></div>
                    ) : demoList.length === 0 ? (
                      <h5>No demo(s) yet</h5>
                    ) : (
                      <InfiniteScroll
                        dataLength={demoList.length} //This is important field to render the next data
                        next={() => fetchMoreData()}
                        hasMore={hasMore}
                        loader={loading1 ? <h4>Loading...</h4> : null}
                      >
                        {demoList.map((data, idx) => {
                          const encodedImage =
                            data.game.game_demo_background_image.replace(
                              " ",
                              "%20"
                            );
                          return (
                            <>
                              <div className="col">
                                <div className="card mb-5">
                                  <div
                                    className="card-body d-flex"
                                    style={{
                                      backgroundImage: `url(${encodedImage})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div className="game-content">
                                      <h2 className="gameFlow-title">
                                        {idx + 1}. {data.title}
                                      </h2>
                                      <p
                                        className="gameflow-text"
                                        dangerouslySetInnerHTML={{
                                          __html: sanitizeHtml(data.content),
                                        }}
                                      ></p>
                                    </div>
                                    <div className="video-content">
                                      <h2 className="gameFlow-titleMobile">
                                        {idx + 1}. {data.title}
                                      </h2>
                                      <div className="gameflow-video">
                                        <ReactPlayer
                                          className="video-js"
                                          url={data.video}
                                          // video location
                                          controls // gives the front end video controls
                                          width="100%"
                                          height="200px"
                                          light={true}
                                          playing={playing}
                                          playIcon={
                                            <div className="play-button">
                                              <img
                                                src="/assets/images/play-button.svg"
                                                alt="play"
                                                onClick={startPlaying}
                                              />
                                            </div>
                                          }
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload", //<- this is the important bit
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GameFlow;

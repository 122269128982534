import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeclineInviteModal = ({ hideDeclineModal, handleDeclineTournament }) => {
  return (
    <Modal show onHide={hideDeclineModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="invite-body text-center leave-modal">
        <div className="refer-friend-icons">
          <p className="remove-heading invite-para">Decline Tournament?</p>
          <p className="leave-modal-text">
            Are you sure you want to decline the tournament?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="delete-footer">
        <Button className="cancel-btn" onClick={hideDeclineModal}>
          Cancel
        </Button>
        <Button className="yes-btn" onClick={handleDeclineTournament}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeclineInviteModal;

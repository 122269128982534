import React from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import { Parallax } from "react-parallax";
import { GAMES_CONSTANTS } from "../../helper/stringConstants";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "/assets/images/sign-in.jpg",
    category: "AVAILABLE NOW",
    title: GAMES_CONSTANTS.cod,
  },
  {
    textPosition: "text-center",
    bgImage: "/assets/images/sign-up.jpg",
    category: "",
    title: GAMES_CONSTANTS.pubg,
  },
];
const AuthWrapper = (props) => {
  const queryString = props.data?.location?.pathname;
  return (
    <div className="slider-activation">
      <Slider className="rn-slick-dot dot-light" {...slideSlick}>
        {queryString === "/signin" ? (
          <Parallax
            bgImage={SlideList[0].bgImage}
            strength={1}
            bgClassName="home-banner-parallax"
          >
            <div
              className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center signin-image`}
              data-black-overlay="8"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${SlideList[0].textPosition}`}>
                      {SlideList[0].category ? (
                        <span>{SlideList[0].category}</span>
                      ) : (
                        ""
                      )}
                      {SlideList[0].title ? (
                        <h1 className="title theme-gradient">
                          {SlideList[0].title}
                        </h1>
                      ) : (
                        ""
                      )}
                      {SlideList[0].description ? (
                        <p className="description">
                          {SlideList[0].description}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        ) : (
          <Parallax
            bgImage={SlideList[1].bgImage}
            strength={1}
            bgClassName="home-banner-parallax"
          >
            <div
              className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center signup-image`}
              data-black-overlay="8"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${SlideList[1].textPosition}`}>
                      {SlideList[1].category ? (
                        <span>{SlideList[1].category}</span>
                      ) : (
                        ""
                      )}
                      {SlideList[1].title ? (
                        <h1 className="title theme-gradient">
                          {SlideList[1].title}
                        </h1>
                      ) : (
                        ""
                      )}
                      {SlideList[1].description ? (
                        <p className="description">
                          {SlideList[1].description}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        )}
      </Slider>
    </div>
  );
};
export default AuthWrapper;

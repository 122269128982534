import React, { useEffect, useMemo } from "react";
import { Parallax } from "react-parallax";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import "./Tournament.scss";
import { useGame } from "../../context/GameContext";
import {
  ConsoleHelper,
  gameTypeFilters,
  getModeLabelsSelect,
  tournamentFilters,
} from "../../helper/utilities";
import { getTournaments } from "../../services/tournament.service";
import TournamentList from "./TournamentList";
import { useState } from "react";
import Paginator from "../../helper/paginator/Paginator";
import { Placeholder } from "react-bootstrap";

const Tournament = (props) => {
  const { state } = useGame();
  const [totalCount, settotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const modeObjStore = state?.game?.modeGameTypeTournamentObj;
  const { search, pathname } = useLocation();
  const history = useHistory();
  const url = new URLSearchParams(search);
  const pageSize = 25;
  const [pageNum, setPageNum] = useState(1);
  const game = url.get("game");
  const mode = url.get("mode");
  const type = url.get("type");
  const gameTypeMode = url.get("gametype");
  const [tournaments, setTournaments] = useState([]);
  const handleGameChange = (gameObj) => {
    url.set("game", gameObj.value);
    url.delete("mode");
    history.replace({ pathname, search: url.toString() });
  };
  const handleTypeChange = (typeObj) => {
    url.set("type", typeObj.value);
    history.replace({ pathname, search: url.toString() });
  };

  const handleGameTypeChange = (gametypeObj) => {
    url.set("gametype", gametypeObj.value);
    history.replace({ pathname, search: url.toString() });
  };

  const handleModeChange = (modeObj) => {
    url.set("mode", modeObj.value);
    history.replace({ pathname, search: url.toString() });
  };

  const defaultGameType = useMemo(() => {
    const selectedMode = gameTypeFilters?.filter((gameTypeFilter) => {
      return gameTypeFilter.value == gameTypeMode;
    })?.[0];
    if (selectedMode) return selectedMode;
    return gameTypeFilters?.[0];
  }, [gameTypeMode]);

  const defaultGame = useMemo(() => {
    const games = state?.game?.modeObjTournaments?.[defaultGameType?.value];
    if (!game) return games?.[0];
    const selectedGame = games?.filter((traditionalGame) => {
      return traditionalGame.id == game;
    })?.[0];
    if (selectedGame) return selectedGame;
    return games?.[0];
  }, [game, state?.game?.modeObjTournaments, defaultGameType]);

  const defaultMode = useMemo(() => {
    const modes = getModeLabelsSelect(
      defaultGameType,
      modeObjStore?.[defaultGame?.slug]
    );

    const selectedMode = modes?.filter((modeGame) => {
      return modeGame.value == mode;
    })?.[0];
    if (selectedMode) return selectedMode;
    return modes?.[0];
  }, [defaultGame, defaultGameType, mode, modeObjStore]);

  const defaultType = useMemo(() => {
    const selectedMode = tournamentFilters?.filter((status) => {
      return status.value == type;
    })?.[0];
    if (selectedMode) return selectedMode;
    return tournamentFilters?.[0];
  }, [type]);

  // gameTypeFilters
  const fetchTournaments = async () => {
    setLoading(true);
    try {
      const { results } = await getTournaments({
        mode: defaultMode?.value,
        game: defaultGame?.value,
        type: defaultType?.value,
        gameType: defaultGameType?.value,
        pageNum,
        pageSize,
      });
      setTournaments(results);
      settotalCount(results.total);
      setLoading(false);
    } catch (error) {
      ConsoleHelper(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, [
    defaultGame?.value,
    defaultMode?.value,
    defaultType?.value,
    defaultGameType?.value,
  ]);
  const handlePageClick = (newPageNum) => {
    setPageNum(newPageNum);
  };
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/tournaments.svg"}
          strength={1000}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">
                      Tournaments
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {/*Tournament list*/}
      <section className="lobby-section tournament-section challenge-section">
        <div className="container">
          <h4 className="tournament-title">Tournaments</h4>
          <hr
            style={{ backgroundColor: "#979797", opacity: 1, marginBottom: 0 }}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="lobby-dropdowns tournament-dropdown">
                {/* defaultGameType */}
                <div className="lobby tournament">
                  <label>Game Type</label>
                  <Select
                    defaultValue={defaultGameType}
                    className="react-select-container-teammates chooseTeam"
                    classNamePrefix="react-select-teammates"
                    options={gameTypeFilters?.filter(
                      (gameType) => gameType.value != 2
                    )}
                    onChange={handleGameTypeChange}
                    isSearchable={false}
                  />
                </div>
                <div className="lobby tournament">
                  <label>Game</label>
                  <Select
                    key={defaultGame?.value + "-" + defaultGameType?.value}
                    defaultValue={defaultGame}
                    className="react-select-container-teammates chooseTeam"
                    classNamePrefix="react-select-teammates"
                    options={
                      defaultGameType?.value == 1 &&
                      defaultGame?.slug == "apex-legends"
                        ? state?.game?.modeObjTournaments?.[
                            defaultGameType?.value
                          ]?.filter((val) => val != 6)
                        : state?.game?.modeObjTournaments?.[
                            defaultGameType?.value
                          ]
                    }
                    onChange={handleGameChange}
                    isSearchable={false}

                  />
                </div>
                <div className="lobby  tournament">
                  <label>Mode</label>
                  <Select
                    key={defaultGame?.value + "-" + defaultGameType?.value}
                    className="react-select-container-teammates chooseTeam"
                    classNamePrefix="react-select-teammates"
                    defaultValue={defaultMode}
                    options={getModeLabelsSelect(
                      defaultGameType,
                      modeObjStore?.[defaultGame?.slug]
                    )?.filter((val) => val?.[defaultGameType?.value])}
                    onChange={handleModeChange}
                    isSearchable={false}

                  />
                </div>
                <div className="lobby  tournament">
                  <label>Tournament Status</label>
                  <Select
                    defaultValue={defaultType}
                    className="react-select-container-teammates chooseTeam"
                    classNamePrefix="react-select-teammates"
                    options={tournamentFilters}
                    onChange={handleTypeChange}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <p>
              <Placeholder animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
            </p>
          ) : (
            <TournamentList
              data={tournaments}
              fetchTournaments={fetchTournaments}
              defaultType={defaultType}
            />
          )}

          <Paginator
            totalCount={totalCount}
            pageSize={pageSize}
            pageClick={handlePageClick}
          />
        </div>
      </section>
    </>
  );
};
export default Tournament;

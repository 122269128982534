import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function gamelisting() {
  const url = baseURL + "games/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function gameLobbyListing(filters) {
  const url =
    baseURL + `games/${filters.game_slug}/lobby/`;

  const params = new URLSearchParams();

  if (filters.isPaginated) {
    if (filters.type) params.append("lobby", filters.type);
    if (filters.pageSize) params.append("page_size", filters.pageSize);
    if (filters.pageNum) params.append("page", filters.pageNum);
    if (filters?.rewardAmount) params.append("type", filters?.rewardAmount);
    if (filters?.mode) params.append("mode", filters?.mode);
    if (filters?.gameType) params.append("gametype", filters?.gameType);
  } else {
    params.append("no_page", filters.isPaginated);
    if (filters?.rewardAmount) params.append("type", filters?.rewardAmount);
  }

  const headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers, params });
}

export async function gameLobbyDetail(game_slug, lobbyId) {
  const url =
    baseURL + `games/${game_slug}/lobby/${lobbyId}/`;

  const headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers });
}

export async function participantList(game_slug, lobbyId) {
  const url =
    baseURL +
    `games/${game_slug}/lobby/${lobbyId}/teammates/`;

  const headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers });
}
export async function gameFlowListing(filters) {
  const params = new URLSearchParams();
  if (filters?.q.length < 0) {
    if (filters?.limit) params.append("limit", filters?.limit);
    if (filters?.offset) params.append("&offset", filters?.offset);
  } else {
    if (filters?.limit) params.append("limit", filters?.limit);
    if (filters?.offset) params.append("offset", filters?.offset);
    if (filters?.q) params.append("q", filters?.q);
  }
  const headers = {
    "Content-Type": "application/json",
  };
  const url =
    baseURL + `games/${filters.game_slug}/game-demo/`;
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers, params });
}

export async function gameFlowAllListing(model) {
  const params = new URLSearchParams();
  if (model?.q.length < 0) {
    if (model?.limit) params.append("limit", model?.limit);
    if (model?.offset) params.append("&offset", model?.offset);
  } else {
    if (model?.limit) params.append("limit", model?.limit);
    if (model?.offset) params.append("offset", model?.offset);
    if (model?.q) params.append("q", model?.q);
  }
  const url = baseURL + `games/game-demos/`;
  const headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers, params });
}

import React, { useState, useCallback } from "react";
import { Button, Row, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import toastify from "../../helper/toastify.jsx";
import { getUsers, sendInvite } from "../../services/user.service";
import { debounce } from "lodash";
import { ConsoleHelper } from "../../helper/utilities";
import "./searchTeamMate.scss";
const SearchTeammate = (props) => {
  const [spinnerLoader, setSpinnerLoadder] = useState(false);
  const [userNames, setUserNames] = useState([]);
  const [showAllUserNames, setShowAllUserNames] = useState(false);
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), []);

  const handleSelect = (value) => {
    setShowAllUserNames(!showAllUserNames);
  };
  const handleInvite = (value) => {
    const body = {
      username: value,
    };
    setSpinnerLoadder(true);
    sendInvite(body, props.TeamId)
      .then((res) => {
        toastify("success", "Invite sent successfully");
        setSpinnerLoadder(false);
        props.onInviteSend();
      })
      .catch((err) => {
        ConsoleHelper(err);
        setSpinnerLoadder(false);
      });
  };

  function handleSearch(value) {
    if (value.length != 0) {
      setShowAllUserNames(true);
      setSpinnerLoadder(true);
      getUsers(value)
        .then((res) => {
          setSpinnerLoadder(false);
          setUserNames(res);
        })
        .catch(() => {
          setSpinnerLoadder(false);
        });
    } else {
      setUserNames([]);
      setShowAllUserNames(false);
    }
  }

  return (
    <>
      (
      <div className="player-details">
        <Form>
          <Row className="margins editCreateTeam">
            <Form.Control
              type="search"
              name="teamName"
              placeholder="Search by Username"
              onChange={(e) => debouncedHandleSearch(e.target.value)}
              className="newTeamEdit"
            />
          </Row>
        </Form>
        {spinnerLoader ? (
          <div className="search-loader">
            <span className="spinner-border spinner-border-md mt-3"></span>
          </div>
        ) : showAllUserNames ? (
          <ListGroup className="addTeamMatesListing">
            {userNames.length === 0 ? (
              <ListGroup.Item className="createList">
                No data found
              </ListGroup.Item>
            ) : (
              userNames &&
              userNames.map((data, idx) => {
                return (
                  <ListGroup.Item
                    className="d-flex justify-content-between searchTeamList"
                    key={data.username}
                    onClick={() => handleSelect(data.username)}
                  >
                    <p>{data.username}</p>
                    <Button
                      className=""
                      onClick={() => handleInvite(data.username)}
                    >
                      +
                    </Button>
                  </ListGroup.Item>
                );
              })
            )}
          </ListGroup>
        ) : null}
      </div>
      )
    </>
  );
};

export default SearchTeammate;

import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function getNotificationsList({
  isPaginated,
  pageNum,
  pageSize,
  column,
  order,
}) {
  const url = baseURL + "players/notifications/";
  const params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
    if (column) params.append("column", column);
    if (order) params.append("order", order);
  } else {
    params.append("no_page", isPaginated);
  }

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function putFriendRequest({ notificationId, notificationAction }) {
  const url =
    baseURL +
    `players/notification-status/${notificationId}/`;

  const body = {
    status: notificationAction,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

export async function putNotificationRead() {
  const url = baseURL + `players/notification-read/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, { headers });
}

export async function removeNotifications({ type, notification }) {
  const url = baseURL + "players/remove-notifications/";
  const body = {
    type,
    notification,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

// players/remove-notifications/

// players/notification-status
// players/notification-read/

//team invite
export async function putTeamRequest({
  notificationAction,
  teamId,
  notificationId,
}) {
  const url =
    baseURL +
    `players/versus/${teamId}/notification/${notificationId}/`;

  const body = {
    action: notificationAction ? "accept" : "cancel",
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function getTournaments({
  pageSize,
  pageNum,
  mode,
  type,
  game,
  gameType,
}) {
  const url = baseURL + "tournament/players/";
  const params = new URLSearchParams();
  params.append("page_size", pageSize);
  params.append("page", pageNum);
  if (mode) params.append("mode", mode);
  if (type) params.append("type", type);
  if (game) params.append("game", game);
  if (gameType) params.append("gameType", gameType);
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function enrollTournament({ tournament_id, data }) {
  const url = baseURL + `tournament/${tournament_id}/enroll/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function getTournamentById(tournamentId) {
  const url =
    process.env.REACT_APP_APIURL + `tournament/${tournamentId}/players/`;

  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers });
}
export async function actionOnTournamentInvite({ tournamentId, body }) {
  const url =
    process.env.REACT_APP_APIURL + `tournament/${tournamentId}/accept/`;
  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}
export async function getTeams({ game_id, mode, creatorTeam }) {
  const url = process.env.REACT_APP_APIURL + "players/challenges-team/";

  let headers = {
    "Content-Type": "application/json",
  };
  let params = new URLSearchParams();
  params.append("game_id", game_id);
  params.append("mode", mode);
  params.append("creator_team", creatorTeam);

  return await axiosApi.get(url, { headers, params });
}
export async function addRank({ tournamentId, body }) {
  const url =
    process.env.REACT_APP_APIURL + `tournament/${tournamentId}/add-team_rank/`;

  let headers = {
    "Content-Type": "application/json",
  };
  return await axiosApi.post(url, body, { headers });
}
export async function findCommonTeamInTournament(tournamentId, teamId) {
  const url =
    process.env.REACT_APP_APIURL +
    `tournament/${tournamentId}/common-team/${teamId}/`;

  let headers = {
    "Content-Type": "application/json",
  };
  return await axiosApi.get(url, { headers });
}
export async function getBracketsTempalate(bracketId) {
  const url =
    process.env.REACT_APP_APIURL + `tournament/iframe/${bracketId}/template/`;

  let headers = {
    "Content-Type": "application/json",
  };
  let params = new URLSearchParams();
  params.append("type", "fe");
  return await axiosApi.get(url, { headers, params });
}

import React from "react";
import { Modal } from "react-bootstrap";
import ViewMoreTeamTable from "../Table/ViewMoreTeamTable";
function ViewMoreTeamsModal({ show, teamList, closeModal }) {
  return (
    <Modal
      show={show}
      onHide={closeModal}
      scrollable={true}
      className="custom-modal w-706"
      backdrop="static"
    >
      <Modal.Header closeButton tag="h4">
        <Modal.Title className="text-uppercase">Teams</Modal.Title>
      </Modal.Header>

      <Modal.Body className="events">
        <div className="view-teams">
          <ViewMoreTeamTable teamList={teamList} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ViewMoreTeamsModal;

import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import toastify from "../../helper/toastify";
import {
  ConsoleHelper,
  dateStringTodayTommorrow,
  handleNotificationNavigate,
} from "../../helper/utilities";
import {
  putFriendRequest,
  putTeamRequest,
} from "../../services/notifications.service";
function NotificationCard({
  userData = {},
  fetchNotificationsList = () => {},
  setLoader = () => {},
  pageNum = null,
  removeSingleNotification = () => {},
  isFriendRequest = false,
}) {
  const history = useHistory();
  const handleFriendRequest = (e, notificationAction, notificationId) => {
    e.stopPropagation();
    setLoader(true);
    putFriendRequest({ notificationAction, notificationId })
      .then((res) => {
        setLoader(false);
        fetchNotificationsList(pageNum === 1 ? true : false);
        toastify(
          "success",
          notificationAction
            ? "Friends request accepted"
            : "Friend request rejected"
        );
      })
      .catch((err) => {
        setLoader(false);
        toastify("error", "Something went wrong");
      });
  };
  const handleTeamRequest = (e, notificationAction, teamId, notificationId) => {
    e.stopPropagation();
    setLoader(true);
    putTeamRequest({ notificationAction, teamId, notificationId })
      .then((res) => {
        ConsoleHelper(res);
        setLoader(false);
        fetchNotificationsList(pageNum === 1 ? true : false);
        toastify(
          "success",
          notificationAction ? "Team request accepted" : "Team request rejected"
        );
      })
      .catch((err) => {
        setLoader(false);
        ConsoleHelper(err);
        toastify("error", "Something went wrong");
      });
  };
  return (
    <div
      className="d-flex notification-box notification-borderBottom"
      style={userData?.type_of_notification != 3 ? { cursor: "pointer" } : {}}
      onClick={() => {
        handleNotificationNavigate({history,userData});
      }}
    >
      <div className="notificationPicture">
        <img
          src={
            userData?.profile_url && userData?.profile_url != "null"
              ? userData?.profile_url
              : "/assets/images/profile-img.jpg"
          }
          alt="Sorting"
          className="profilepic"
        />
      </div>

      <div className="notification-content notificationHeading">
        {userData?.message}
        <p className="notification-date">
          {dateStringTodayTommorrow(userData?.created)}
        </p>
        <div className="d-flex notification-bottom-btn">
          {userData?.type_of_notification == 1 || isFriendRequest ? (
            <>
              {" "}
              <Button
                className="decline-btn decAccept-btn notif-list"
                onClick={(e) => handleFriendRequest(e, false, userData?.id)}
              >
                Decline
              </Button>
              <Button
                className="accept-btn decAccept-btn notif-list"
                onClick={(e) => handleFriendRequest(e, true, userData?.id)}
              >
                Accept
              </Button>
            </>
          ) : null}
          {userData?.type_of_notification == 5 ? (
            <>
              {" "}
              <Button
                className="decline-btn decAccept-btn notif-list"
                onClick={(e) =>
                  handleTeamRequest(
                    e,
                    false,
                    userData?.extras.team_id,
                    userData?.id
                  )
                }
              >
                Decline
              </Button>
              <Button
                className="accept-btn decAccept-btn notif-list"
                onClick={(e) =>
                  handleTeamRequest(
                    e,
                    true,
                    userData?.extras.team_id,
                    userData?.id
                  )
                }
              >
                Accept
              </Button>
            </>
          ) : null}
        </div>
      </div>
      {!isFriendRequest ? (
        <div className="d-flex align-items-center removeNotificationList">
          <img
            style={{ cursor: "pointer" }}
            src="/assets/images/notification-remove-icon.svg"
            className="remove-notification"
            alt="remove-notification-icon"
            onClick={(e) => {
              e.stopPropagation();
              removeSingleNotification(userData?.id);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(NotificationCard);

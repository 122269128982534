import React from "react";
import { Modal } from "react-bootstrap";
import { appendSuffixToTournamentPosition } from "../../../helper/utilities";

const ShowMorePrizesModal = ({ show, details, handleHideShowMorePrizes }) => {
  return (
    <Modal show={show} onHide={handleHideShowMorePrizes} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="money-added tournament-section prize-mony-popup">
        <h3>Prizes</h3>
        <div className="d-flex prizes">
          {details?.price?.map((item, idx) => {
            return (
              <div key={idx} className="prize-div" id={idx}>
                <p className="mb-0 color-white">
                  {`${item.position}${appendSuffixToTournamentPosition(
                    item.position
                  )}`}{" "}
                  Prize
                </p>
                <p className="mb-0 theme-color">$ {item.price}</p>
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowMorePrizesModal;

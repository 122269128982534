import React, {useState } from "react";
import { Parallax } from "react-parallax";
import NotificationList from "./NotificationList";

function Notifications() {
  const [loader, setLoader] = useState(false);

  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        {loader ? (
          <div class="loading"></div>
        ) : (
          <>
            <Parallax
              bgImage={"/assets/images/header-background.png"}
              strength={500}
              bgClassName="page-banner-parallax profile-banner-parallax"
            >
              <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1 className="title game-title theme-gradient">
                          Notifications
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          </>
        )}
      </div>
      <NotificationList setLoader ={setLoader} />
    </>
  );
}

export default Notifications;

import React from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import dateToUtc from "../../helper/dateToUtc";
import { useConfig } from "../../context/ConfigContext";

const CountDownStopEnrollment = (props) => {
  const {state} = useConfig();
  const minutesToSubtract = state?.config?.solo_greyout;
  const currentDate = new Date(props.server_current_datetime3);
  const subtractDate = new Date(
    currentDate.getTime() - minutesToSubtract * 60000
  );
  const endTime = dateToUtc(subtractDate);
  const countdownTimer = ({ days, hours, minutes, seconds, completed }) => {
    timeReached3(completed);
    if (completed) {
      return "";
    } else {
      return (
        <span style={{ fontSize: "18px", textTransform: "none" }}>
          Starts in{" "}
          <span className="timer-of-start">
            {days ? days + "D " : null}
            {hours ? hours + "H " : null}
            {minutes ? minutes + "M " : null}
            {seconds}S
          </span>
        </span>
      );
    }
  };

  const timeReached3 = (completed) => {
    if (completed) props.time_reached3(completed);
  };
  return (
    <Countdown
      date={endTime}
      renderer={countdownTimer}
      onComplete={timeReached3}
    />
  );
};

CountDownStopEnrollment.propTypes = {
  start_date: PropTypes.string,
  end_time: PropTypes.string,
  server_current_datetime3: PropTypes.string,
  start_datetime: PropTypes.string,
  time_reached3: PropTypes.func,
};

export default CountDownStopEnrollment;

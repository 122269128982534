import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";

const ManageCookies = () => {
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(true);
  const [third, setThird] = useState(true);
  const [fourth, setFourth] = useState(true);
  useEffect(() => {
    setFirst(
      Cookies.get("first") === "false" || Cookies.get("first") === undefined
        ? false
        : true
    );
    setSecond(Cookies.get("second") === "false" ? false : true);
    setThird(Cookies.get("third") === "false" ? false : true);
    setFourth(Cookies.get("fourth") === "false" ? false : true);
  }, []);

  const onChangeFirst = (e) => {
    const check = e.target.checked;
    Cookies.set("first", check);
    setFirst(check);
  };
  const onChangeSecond = (e) => {
    const check = e.target.checked;
    Cookies.set("second", check);
    setSecond(check);
  };
  const onChangeThird = (e) => {
    const check = e.target.checked;
    Cookies.set("third", check);
    setThird(check);
  };
  const onChangeFourth = (e) => {
    const check = e.target.checked;
    Cookies.set("fourth", check);
    setFourth(check);
  };
  const handleSubmit = () => {
    Cookies.set("Accept", "true", { expires: 62 });
    window.location.replace("/home");
  };

  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax profile-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">Cookies</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <section class="manage-cookies">
        <div class="container">
          <div class="row">
            <div class="col">
              <p>
                Cookies are small amounts of data stored in seperate files
                within your computer's internet browser. Cookies are recorded
                and ready by the websites you visit, and by the companies (3rd
                parties) that show advertisements on websites, so that they can
                recognize the same client (browser). Ryvals uses cookies, web
                beacons and others similiar technologies for the following
                general purposes:
              </p>
              <div>
                <Form>
                  <Form.Group
                    className="check-wrapper"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Essential"
                      onChange={onChangeFirst}
                      checked={first}
                    />

                    <p>
                      These are cookies that are required for the operation of
                      our website. They include, for example, cookies that
                      enable you to log-in. They enables us to personalize our
                      content for you and deliver relevant experience.
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="check-wrapper"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Analytical/Performance"
                      onChange={onChangeSecond}
                      checked={second}
                    />
                    <p>
                      These cookies record your visit to the website, the pages
                      you have visited, and the links you have followed. Ryvals
                      will use this information to make our website and the
                      advertising displayed on it more relevant to your
                      interests.
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="check-wrapper"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Targeting/Advertising"
                      onChange={onChangeThird}
                      checked={third}
                    />
                    <p>
                      Used to recognize and count the number of visitors and to
                      see how visitors move around our website when they are
                      using it. This lets Ryvals improve the way the website
                      works, for example by helping to ensure that users are
                      easily finding what they are looking for.
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="check-wrapper"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Third Party"
                      onChange={onChangeFourth}
                      checked={fourth}
                    />
                    <p>
                      Ryvals may allow business partners to use cookies or other
                      similar technologies on or outside the website for the
                      same purposes identified above, including collecting
                      information about your online activities over time and
                      across different websites, applications, and/or devices.
                    </p>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ManageCookies;

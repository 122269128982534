import {
  LOGGED_IN_USER,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
} from "./actionTypes";

export const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    payload: user,
  };
};

export const loggedInUser = () => {
  return {
    type: LOGGED_IN_USER,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

import React, { Component } from "react";
import { Parallax } from "react-parallax";
import {
  gamelisting,
  gameLobbyDetail,
  participantList,
} from "../../services/game.service";
import { Button, Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import sanitizeHtml from "sanitize-html";

import PropTypes from "prop-types";
import CountDown from "./CountDown";
import { withRouter } from "react-router-dom";
import EnrollButton from "./EnrollButton";
import ComplaintForm from "./ComplaintForm";
import PlayerStats from "./PlayerStats";
import ReactJson from "react-json-view";
import CountDown2 from "./CountDown2";
import CountDownStopEnrollment from "./CountDownStopEnrollment";
import toastify from "../../helper/toastify.jsx";

import ReactTooltip from "react-tooltip";
import Participants from "./Participants";
import {
  logoutEventPublicRoutes,
  enrollEventListener,
  durationAsString,
  convertMilliSecToTimeAndDate,
  gameTypeEnum,
} from "../../helper/utilities";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
import { getUserDetail } from "../../services/user.service";
class LobbyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      game_slug: this.props.match.params.slug,
      lobbyId: this.props.match.params.lobbyId,
      lobbyData: null,
      isTimerReached: false,
      isTimerReached2: false,
      isTimerReached3: false,
      isEnrollTeamTimerReached: false,
      show: false,
      visible: false,
      visibility: false,
      visibility1: false,
      visibility2: false,
      isTeam: true,
      errMsg: "",
      participantData: null,
      games: [],
      key: "",
      user: "",
    };
    this.goBack = this.goBack.bind(this);
  }

  handleModal() {
    this.setState({ show: !this.state.show });
  }
  handleModal1() {
    this.setState({ visible: !this.state.visible });
  }
  handleModal2() {
    this.setState({ visibility: !this.state.visibility });
  }
  handleModal3() {
    this.setState({ visibility1: !this.state.visibility1 });
  }
  handleModal4() {
    this.setState({ visibility2: !this.state.visibility2 });
  }

  componentCleanup() {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    window.history.replaceState({}, document.title);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup);

    this.setState({
      game_slug: this.props.match.params.slug,
      lobbyId: this.props.match.params.lobbyId,
    });
    if (this.state.lobbyId) {
      this.getLobbies();
      this.getParticipant();
      this.getUser();
    }
    this.getGamesList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.lobbyId != this.props.match.params.lobbyId) {
      this.setState(
        {
          game_slug: this.props.match.params.slug,
          lobbyId: this.props.match.params.lobbyId,
        },
        () => {
          if (this.state.lobbyId) {
            this.getLobbies();
            this.getParticipant();
          }
        }
      );
    }
    logoutEventPublicRoutes();
    enrollEventListener();
  }
  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup); // remove the event handler for normal unmounting
    if (this.timeout) clearTimeout(this.timeout);
  }

  goBack() {
    this.props.history.push(
      this.props?.location?.data?.location?.pathname === "/dashboard"
        ? "/dashboard"
        : this.props?.location?.data === "/dashboard"
        ? "/dashboard"
        : `/${this.state.game_slug}/lobby`
    );
  }

  async getLobbies(params = false) {
    await gameLobbyDetail(
      this.state.game_slug,
      params ? this.props.match.params : this.state.lobbyId
    )
      .then((res) => {
        this.setState({
          lobbyData: res,
          key:
            Array.isArray(res?.player_stats) && res?.player_stats?.length
              ? "stats"
              : "rules",
        });
      })
      .catch((err) => {
        toastify("error", err);
        this.setState({
          lobbyData: null,
          errMsg: err,
        });
      });
    this.getParticipant();
  }

  timerEnded(isComplete) {
    this.setState({
      isTimerReached: isComplete,
    });
  }

  timerEnded2(isComplete) {
    this.setState({
      isTimerReached2: isComplete,
    });
  }
  timerEnded3(isComplete) {
    this.setState({
      isTimerReached3: isComplete,
    });
  }

  async getParticipant() {
    const loggedUser = localStorage.getItem("userProfile");
    if (loggedUser !== null) {
      await participantList(this.state.game_slug, this.state.lobbyId)
        .then((res) => {
          this.setState({
            participantData: res,
          });
        })
        .catch((err) => {
          toastify("error", err);
          this.setState({
            participantData: null,
          });
        });
    } else {
      return;
    }
  }
  async getGamesList() {
    gamelisting().then((res) => {
      this.setState({ games: res });
    });
  }
  async getUser() {
    const loggedUser = localStorage.getItem("userProfile");
    if (loggedUser !== null) {
      await getUserDetail().then((res) => {
        this.setState({ user: res.profile.free_lobby_limit });
      });
    }
  }
  render() {
    return (
      <>
        <div className="slider-activation slider-creative-agency profile-slider">
          <Parallax
            bgImage={"/assets/images/games-banner.png"}
            strength={500}
            bgClassName="page-banner-parallax"
          >
            <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner text-center">
                      <h1 className="title game-title theme-gradient">
                        Lobby Detail
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        {/*Lobby Detail*/}
        <section className="lobby-detail">
          {this.state.lobbyData !== null ? (
            this.state.lobbyData && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="file-complaint-wrapper">
                      <div className="back-btn" onClick={this.goBack}>
                        <span>
                          <img src="/assets/images/back-arrow.svg" alt="back" />
                        </span>
                        <span className="back">Back</span>
                      </div>
                      {localStorage.getItem("userProfile") === null ? null : (
                        <div className="complaint-box">
                          {this.state.lobbyData?.current_status === 3 &&
                          this.state.lobbyData?.is_enrolled ? (
                            !this.state.isTimerReached2 ? (
                              <Button
                                variant="secondary"
                                onClick={() => this.handleModal()}
                              >
                                File a Complaint
                              </Button>
                            ) : null
                          ) : null}
                          {!this.state.isTimerReached2 ? (
                            <div className="start-in d-none">
                              <h2>
                                <CountDown2
                                  server_current_datetime2={
                                    this.state.lobbyData?.compliant_time
                                  }
                                  time_reached2={(e) => {
                                    this.timerEnded2(e);
                                  }}
                                />
                              </h2>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="card">
                      <div className="detail-box">
                        <div className="image-box">
                          <div>
                            <img
                              src={
                                this.state.lobbyData?.game.slug ===
                                "call-of-duty-warzone"
                                  ? "/assets/images/warzone-lobby-detail.jpg"
                                  : this.state.lobbyData?.game.slug === "pubg"
                                  ? "https://rvygamiplatstg.blob.core.windows.net/rvy-gamiplat-dev/pubg.jpg"
                                  : this.state.lobbyData?.game.slug ===
                                    "fortnite"
                                  ? "/assets/images/fortnite-lobby-detail.png"
                                  : this.state.lobbyData?.game.slug ===
                                    "pubg-mobile"
                                  ? "/assets/images/pubg-mobile-lobby-detail.png"
                                  : this.state.lobbyData?.game.slug ===
                                    "cod-mobile"
                                  ? "/assets/images/cod-mobile-lobby-detail.png"
                                  : this.state.lobbyData?.game.slug ===
                                    "valorant"
                                  ? "/assets/images/ValorantDetail.png"
                                  : "/assets/images/Apex-lobby-detail.png"
                              }
                              alt="logo"
                              //"/assets/images/lobby-apex.png"
                            />
                          </div>
                        </div>
                        <div className="game-details">
                          <div className="d-flex align-items-center">
                            <h2 className="m-0 me-2 mt-3 mt-sm-0">
                              {this.state.lobbyData?.name}
                            </h2>
                            {this.state.lobbyData?.is_password_protected ? (
                              <div class="verified-badge mt-1 mt-sm-0">
                                <img
                                  src="/assets/images/lock.svg"
                                  alt="verified-lobby-badge"
                                  data-tip
                                  data-for="loginTip1"
                                />
                                <ReactTooltip
                                  id="loginTip1"
                                  place="right"
                                  effect="solid"
                                >
                                  Protected Lobby
                                </ReactTooltip>
                              </div>
                            ) : null}
                          </div>

                          <div className="entry-rates d-md-none">
                            <span>Entry : </span>$
                            {this.state.lobbyData?.entry_fee}
                            {this.state.lobbyData?.free_lobby
                              ? " Free entry"
                              : ""}
                          </div>

                          <div className="information-wrapper">
                            <div className="date-time-box">
                              <p class="d-md-none">
                                {" "}
                                <span>Win</span>
                                <span class="info-icon">
                                  <img
                                    src="/assets/images/info-icon.svg"
                                    alt="info"
                                    class="info-image"
                                    data-tip
                                    data-for="loginTip"
                                  />
                                </span>
                                <ReactTooltip
                                  id="loginTip"
                                  place="right"
                                  effect="solid"
                                >
                                  {this.state.lobbyData?.game.slug ===
                                    "valorant" ||
                                  this.state.lobbyData?.game.slug ===
                                    "call-of-duty-warzone"
                                    ? "Actual amount paid to the team"
                                    : "Actual amount paid to the user"}
                                </ReactTooltip>{" "}
                                <span className="lobby-detail-player blue-color valorantWinning valDetailwinning">
                                  {" "}
                                  {this.state.lobbyData?.game.slug ===
                                    "valorant" ||
                                  this.state.lobbyData?.game.slug ===
                                    "call-of-duty-warzone"
                                    ? `$${this.state.lobbyData?.reward}/Winning Team`
                                    : `$${this.state.lobbyData?.reward} Paid Every Kill`}
                                </span>
                              </p>
                              <p className="ValorantMInPlayer valdetailDate">
                                <span>Date </span>
                                <span>
                                  {""}
                                  {new Date(
                                    this.state.lobbyData?.countdown_time
                                  ).toLocaleDateString("en-US", {
                                    day: "numeric", // numeric, 2-digit
                                    year: "numeric", // numeric, 2-digit
                                    month: "short", // numeric, 2-digit, long, short, narrow
                                  })}
                                </span>
                              </p>
                              <p className="min-player-desktop">
                                <span>Start Time </span>
                                <span>{this.state.lobbyData?.start_time}</span>
                              </p>

                              <p className="min-player-desktop">
                                <span>Last Entry</span>
                                <span>
                                  {this.state.lobbyData?.last_entry_time}
                                </span>
                              </p>
                              <p className="min-player-desktop">
                                <span>Lobby Mode</span>{" "}
                                <span className="lobby-detail-player">
                                  {this.state?.lobbyData?.modetag}
                                </span>
                              </p>
                              <p className="min-player-md">
                                <span>Min Players</span>
                                {""}
                                <span>{this.state.lobbyData?.min_players}</span>
                              </p>
                              <p className="min-player-md">
                                <span>Max Players</span>
                                {""}
                                <span>{this.state.lobbyData?.max_players}</span>
                              </p>
                              <p className="min-player-md">
                                <span>
                                  {" "}
                                  {this.state.lobbyData?.mode == 1
                                    ? "Enrolled Players"
                                    : "Enrolled Teams"}
                                </span>{" "}
                                <span>
                                  {this.state.lobbyData?.mode == 1
                                    ? this.state.lobbyData?.enrolled_players ||
                                      "-"
                                    : this.state.lobbyData?.enrolled_teams ||
                                      "-"}
                                </span>
                              </p>
                              <p className="min-player-md">
                                <span> Game Type</span>{" "}
                                <span className="GameType-lobby">
                                  {
                                    gameTypeEnum[
                                      this.state.lobbyData?.game_type
                                    ]
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="date-time-box">
                              <p className="min-player-desktop">
                                <span>Min Players</span>
                                <span className="min-player-mobile-view">
                                  {this.state.lobbyData?.min_players}
                                </span>
                              </p>
                              <p className="min-player-desktop">
                                <span>Max Players</span>
                                <span className="lobby-detail-player">
                                  {this.state.lobbyData?.max_players}
                                </span>
                              </p>
                              <p className="min-player-desktop">
                                <span>
                                  {this.state.lobbyData?.mode == 1
                                    ? "Enrolled Players"
                                    : "Enrolled Teams"}
                                </span>
                                <span className="lobby-detail-player">
                                  {this.state.lobbyData?.mode == 1
                                    ? this.state.lobbyData?.enrolled_players ||
                                      "-"
                                    : this.state.lobbyData?.enrolled_teams ||
                                      "-"}
                                </span>
                              </p>

                              <p className="min-player-desktop">
                                <span> Game Type</span>
                                <span className="GameType-lobby">
                                  {
                                    gameTypeEnum[
                                      this.state.lobbyData?.game_type
                                    ]
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="date-time-box">
                              <p class="d-none d-sm-none d-md-block">
                                {" "}
                                <span>Win</span>
                                <span class="info-icon">
                                  <img
                                    src="/assets/images/info-icon.svg"
                                    alt="info"
                                    class="info-image"
                                    data-tip
                                    data-for="loginTip"
                                  />
                                </span>
                                <ReactTooltip
                                  id="loginTip"
                                  place="right"
                                  effect="solid"
                                >
                                  {this.state.lobbyData?.game.slug ===
                                    "valorant" ||
                                  this.state.lobbyData?.game.slug ===
                                    "call-of-duty-warzone"
                                    ? "Actual amount paid to the team"
                                    : "Actual amount paid to the user"}
                                </ReactTooltip>{" "}
                                <span className="lobby-detail-player blue-color valorantWinning valDetailwinning">
                                  {" "}
                                  {this.state.lobbyData?.game.slug ===
                                    "valorant" ||
                                  this.state.lobbyData?.game.slug ===
                                    "call-of-duty-warzone"
                                    ? `$${this.state.lobbyData?.reward}/Winning Team`
                                    : `$${this.state.lobbyData?.reward} Paid Every Kill`}
                                </span>
                              </p>
                              <p className="min-player-desktop">
                                <span> Status </span>{" "}
                                <LobbyStatus
                                  data={this.state.lobbyData}
                                  activeClasses="active-dot"
                                />
                              </p>
                              <p className="min-player-md">
                                <span> Status </span>
                                <LobbyStatus data={this.state.lobbyData} />
                              </p>
                              <p className="min-player-desktop">
                                <span> Map </span>
                                {""}
                                {this.state.lobbyData?.gamemap || "N/A"}
                              </p>
                              <p className="min-player-md">
                                <span> Map </span>
                                {""}
                                {this.state.lobbyData?.gamemap || "N/A"}
                              </p>
                              <p className="min-player-desktop">
                                <span> Region </span>
                                {""}
                                {this.state.lobbyData?.region || "N/A"}
                              </p>
                              <p className="min-player-md">
                                <span> Region </span>
                                {""}
                                {this.state.lobbyData?.region || "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="enroll-wrapper lobby-enroll-wrapper">
                        <div className="enroll-box">
                          <div>
                            <span className="gameName">
                              {this.state.lobbyData?.name}
                            </span>
                          </div>
                          <div className="entry-rates">
                            <span>Entry : </span>$
                            {this.state.lobbyData?.entry_fee}
                            {this.state.lobbyData?.free_lobby
                              ? " Free entry"
                              : ""}
                          </div>
                        </div>
                        <div className="enroll-box">
                          <div className="enrolled">
                            {this.state.isTimerReached ? (
                              ""
                            ) : (
                              <CountDown
                                server_current_datetime={
                                  this.state?.lobbyData?.countdown_time
                                }
                                time_reached={(e) => {
                                  this.timerEnded(e);
                                }}
                                stopEnrollTeam={(isComplete) => {
                                  if (
                                    this.state.isEnrollTeamTimerReached !==
                                    isComplete
                                  ) {
                                    this.setState({
                                      isEnrollTeamTimerReached: isComplete,
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="enrolled d-none">
                            {this.state.isTimerReached3 ? (
                              ""
                            ) : (
                              <CountDownStopEnrollment
                                server_current_datetime3={
                                  this.state?.lobbyData?.countdown_time
                                }
                                time_reached3={(e) => {
                                  this.timerEnded3(e);
                                }}
                              />
                            )}
                          </div>
                          <div className="enrolled">
                            <EnrollButton
                              {...this.state.lobbyData}
                              isTimeCompleted={this.state.isTimerReached}
                              onEnrollComplete={() => this.getLobbies()}
                              stop={this.state.isTimerReached3}
                              isEnrollTeamTimerReached={
                                this.state.isEnrollTeamTimerReached
                              }
                              games={this.state.games}
                              userDataForFreeLimitCount={this.state.user}
                            />
                          </div>
                        </div>
                        {this.state.lobbyData?.match_stats === null ? (
                          ""
                        ) : Object.keys(this.state.lobbyData?.match_stats)
                            .length === 0 ? (
                          ""
                        ) : this.state.lobbyData.game.slug === "valorant" &&
                          this.state.key === "stats" ? (
                          <div className="enroll-box valorantWins">
                            <div>
                              <span className="gameName valorantGame">
                                {
                                  this.state.lobbyData.match_stats.teams[0]
                                    .teamId
                                }
                                <p>
                                  {
                                    this.state.lobbyData.match_stats.teams[0]
                                      .roundsWon
                                  }
                                </p>
                              </span>
                              <span className="teamSepration">:</span>
                              <span className="gameName valorantGameRight">
                                {
                                  this.state.lobbyData.match_stats.teams[1]
                                    .teamId
                                }
                                <p>
                                  {
                                    this.state.lobbyData.match_stats.teams[1]
                                      .roundsWon
                                  }
                                </p>
                              </span>
                              <div className="valorantDateTime">
                                {convertMilliSecToTimeAndDate(
                                  this.state.lobbyData?.match_stats.matchInfo
                                    .gameStartMillis
                                )}

                                <p>
                                  {durationAsString(
                                    this.state.lobbyData?.match_stats.matchInfo
                                      .gameLengthMillis
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Tabs
                  defaultActiveKey={
                    this.props.location?.state?.participantTabActive &&
                    this.state.lobbyData?.mode != -1 &&
                    this.state.lobbyData?.is_registered
                      ? "participants"
                      : Array.isArray(this.state.lobbyData?.player_stats) &&
                        this.state.lobbyData?.player_stats?.length
                      ? "stats"
                      : "rules"
                  }
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(k) =>
                    this.setState({
                      key: k,
                    })
                  }
                >
                  {
                    <Tab eventKey="stats" title="Stats">
                      <PlayerStats
                        player_stats={this.state.lobbyData?.player_stats}
                        game_data={this.state.lobbyData?.game}
                        isTournament={false}
                      />
                    </Tab>
                  }
                  {
                    <Tab eventKey="officialScores" title="Official scoring">
                      <div className="boxx">
                        <p>
                          All prize money is calculated based on the official
                          scoring
                        </p>
                        <ReactJson
                          src={
                            this.state.lobbyData?.match_stats === null
                              ? { noDataFound: "No data found" }
                              : this.state.lobbyData?.match_stats
                          }
                          theme="brewer"
                          onEdit={false}
                          onAdd={false}
                          onDelete={false}
                          enableClipboard={false}
                          displayDataTypes={false}
                        />
                      </div>
                    </Tab>
                  }

                  <Tab eventKey="rules" title="Rules">
                    <div className="boxx">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(this.state.lobbyData?.rules),
                        }}
                      ></p>
                    </div>
                  </Tab>
                  {this.state.lobbyData?.mode !== 1 ? (
                    <Tab eventKey="participants" title="Participants">
                      <Participants
                        lobbyData={this.state.lobbyData}
                        participantData={this.state.participantData}
                        timerCompleted={this.state.isTimerReached}
                        isTimeCompleted={this.state.isTimerReached}
                        onEnrollComplete={() => {
                          this.getLobbies();
                          this.getParticipant();
                        }}
                        getLobbies={() => this.getLobbies()}
                        getParticipants={() => this.getParticipant()}
                        stop={this.state.isTimerReached3}
                      />
                    </Tab>
                  ) : null}
                </Tabs>
              </div>
            )
          ) : (
            <h5 style={{ textAlign: "center" }}>{this.state.errMsg}</h5>
          )}
        </section>
        {/*Lobby Detail*/}
        <Modal
          show={this.state.show}
          onHide={() => this.handleModal()}
          backdrop="static"
        >
          <ComplaintForm
            {...this.state.lobbyData}
            game_slug={this.state.game_slug}
            onComplete={() => this.handleModal()}
          />
        </Modal>
      </>
    );
  }
}
LobbyDetail.propTypes = {
  callLobbies: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  game: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  is_verified: PropTypes.bool,
  is_enrolled: PropTypes.bool,
  reward: PropTypes.number,
  start_date: PropTypes.string,
  start_time: PropTypes.string,
  last_entry_time: PropTypes.string,
  entry_fee: PropTypes.number,
  create_date: PropTypes.string,
  current_status: PropTypes.number,
  enrolled_players: PropTypes.number,
  enrolled_teams: PropTypes.number,
  max_players: PropTypes.number,
  min_players: PropTypes.number,
  rules: PropTypes.string,
  status: PropTypes.number,
  isTimeCompleted: PropTypes.bool,
};
export default withRouter(LobbyDetail);

import React, { useEffect, useState } from "react";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import {Spinner } from "react-bootstrap";
import { faqlisting } from "../../services/faq.service";
import PPKQuestion from "./PPKQuestion";


const FaqList = () => {
  useEffect(() => {
    logoutEventPublicRoutes();
  }, []);

  const [PPKList,setPPKList] = useState([])
  const [loading, setLoading] = useState(false);
  const getList = () => {
    setLoading(true);
    faqlisting({type:"PPK"})
      .then((res) => {
        setPPKList(res);
        setLoading(false);
       
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() =>{
    getList()
  },[])

  return (
    <>
    <div className="newPPK-section">
      <section className="ppk-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12 col-md-6">
              <div className="ppk-banner-content">
                <h2>What is PPK®?</h2>
                <h4>It’s the next evolution in online gaming tournaments.</h4>
                <p>Here are a list of FAQ’s to help you get started with Ryvals.com & PPK®.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      {loading ? (
        <Spinner animation="grow" />
      ) : (
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3 ppk-columns">
            {PPKList?.[0]?.category?.questions?.map((ppkData, idx) => {
              return <PPKQuestion key={idx} ppkData={ppkData} />;
            })}
          </div>
        </div>
      )}
    </div>
    </>
  );
};
export default FaqList;

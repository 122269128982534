import { Modal, Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import {
  actionOnTournamentInvite,
  getTournamentById,
  getTeams,
} from "../../../services/tournament.service";
import { useHistory, useParams } from "react-router-dom";
import toastify from "../../../helper/toastify";
import {
  getUserDetail,
  getWalletBalance,
} from "../../../services/user.service";
import { useSelector } from "react-redux";
import { useGame } from "../../../context/GameContext";
import TournamentEnroll from "../Modal/TournamentEnroll";
import DetailsPagesHeader from "../../common/DetailsPagesHeader";
import DetailsImage from "../../common/DetailsImage";
import TournamentInfo from "./TournamentInfo";
import TournamentDetailEnrollAction from "./TournamentDetailEnrollAction";
import UpdateProfileModal from "../../common/UpdateProfileModal";
import AcceptInviteModal from "../../common/Modal/AcceptInviteModal";
import DeclineInviteModal from "../../common/Modal/DeclineInviteModal";
import AddWalletModal from "../../common/Modal/AddWalletModal";
import PaymentComponent from "../../PaymentMethod";
import ConfirmTournamentModal from "../../common/Modal/ConfirmTournamentModal";
import PrizeTab from "../TournamentTab/PrizeTab";
import TournamentTeamsTab from "../TournamentTab/TournamentTeamsTab";
import ViewBracket from "../TournamentTab/ViewBracket";
import TournamentMatchInfoTab from "../TournamentTab/TournamentMatchInfoTab";
import StandingsTab from "../TournamentTab/StandingsTab";

import sanitizeHtml from "sanitize-html";
import ViewManualCreatedBracket from "../TournamentTab/ViewManualCreatedBracket";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const TournamentDetail = (props) => {
  const queryParam = useLocation().search;
  const history = useHistory();
  const profilePhone =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).phone;
  const profileAddress =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).address.full;
  const promoBonus =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).profile?.bonus_balance;
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const { state } = useGame();
  const games = state?.game?.allGames;
  const { slug, tournamentId } = useParams();
  const [tournamentDetails, setTournamentDetails] = useState({});
  const [errMsg, setErrorMsg] = useState("");
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [gamertag, setGamerTag] = useState(null);
  const [user, setUser] = useState([]);
  const [walletBalance, setwalletBalance] = useState(null);
  const [entryFeeSubtract, setEntryFeeSubtract] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState([]);
  const [canEnroll, setCanEnroll] = useState(true);
  const [tournamentAcceptConfirmModal, setTournamentAcceptConfirmModal] =
    useState(false);
  useEffect(() => {
    window.addEventListener("beforeunload", componentCleanup);
    if (loggedUser?.user && tournamentId > 0) {
      checkBalance();
      getUserDetail().then((res) => {
        setUser(res);
      });
      getTournament();
      localStorage.removeItem("previousPath");
    } else {
      history.goBack();
    }

    return () => {
      componentCleanup();
      window.removeEventListener("beforeunload", componentCleanup);
    };
  }, [loggedUser?.user, tournamentId]);
  useEffect(() => {
    if (!loggedUser.user) {
      localStorage.setItem("previousPath", window.location.pathname);
      history.push({
        pathname: "/signin",
        state: { prevPath: window.location.pathname },
      });
    }
  }, []);
  useEffect(() => {
    setSelectedGame(games?.find((item) => item.slug == tournamentDetails.slug));
  }, [games, tournamentDetails]);
  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  const checkBalance = () => {
    getWalletBalance().then((res) => {
      setwalletBalance(res.wallet_balance);
    });
  };
  const getTournament = async () => {
    try {
      setLoader(true);
      const res = await getTournamentById(tournamentId);
      setTournamentDetails(res);
      if (props.match.params.slug === res.slug) {
        if (res.participants.length !== 0) {
          const currentplayer = res.participants.map((element) => {
            return element.players.filter((e) => {
              return e.user;
            });
          });
          var filtered = currentplayer.filter((e) => e.length);
          setCurrentPlayer(filtered.flat()[0]);
        } else {
          setCurrentPlayer([]);
        }
      } else {
        props.history.push({
          pathname: `/tournaments/${res.slug}/${tournamentId}`,
        });
      }

      setLoader(false);
    } catch (error) {
      if (error.length === 11 || error.length === 21) {
        history.push("/pageNotFound");
      }
      setErrorMsg(error);
      setLoader(false);
    }
  };
  const [addtoWallet, setAddToWallet] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showTeamSelectModel, setShowTeamSelectModal] = useState(false);
  const [playerTeams, setPlayerTeams] = useState([]);
  const [loader, setLoader] = useState(false);
  const openAddToWallet = () => {
    setAddToWallet(true);
  };

  const closeAddToWallet = () => {
    setAddToWallet(false);
  };

  const getTeamsList = async ({ game_id, mode, creatorTeam }) => {
    try {
      setLoader(true);
      const res = await getTeams({ game_id, mode, creatorTeam });
      setLoader(false);
      setPlayerTeams(res);
    } catch (error) {}
  };
  const handledeclineTournament = (tournamentDecline) => {
    setTournamentDetails(tournamentDecline);
    setShowDeclineModal(true);
  };
  const [payForYourself, setPayForYourselfPaymentModal] = useState(false);
  const handleAcceptTournament = (tournamentAccpt) => {
    setTournamentDetails(tournamentAccpt);
    callGetTeamList(tournamentAccpt);

    setPayForYourselfPaymentModal(true);
  };

  const handleNoPay = (tournamentAccptWithNoPay) => {
    setTournamentDetails(tournamentAccptWithNoPay);
    setShowAcceptModal(true);
    setBalance({
      promo_choice: false,
      promo: parseInt(promoBonus),
      wallet: walletBalance,
      payingForTeam: false,
    });
  };

  const handleAcceptNoPay = async () => {
    try {
      setLoader(true);
      await actionOnTournamentInvite({
        tournamentId: tournamentDetails.id,
        body: {
          team_id: currentPlayer.team,
          action: "accept",
          is_paying_full: false,
          bonus: false,
        },
      });
      setBalance({
        promo_choice: false,
        promo: parseInt(promoBonus),
        wallet: walletBalance,
        payingForTeam: false,
      });
      setLoader(false);
      setShowAcceptModal(false);
      setTournamentAcceptConfirmModal(true);
    } catch (error) {
      setLoader(false);
    }
  };
  const declineTournament = async () => {
    try {
      setLoader(true);
      const res = await actionOnTournamentInvite({
        tournamentId: tournamentDetails.id,
        body: {
          team_id: currentPlayer.team,
          action: "reject",
          is_paying_full: false,
          bonus: false,
        },
      });
      if (res) {
        toastify("success", "Invite declined sucessfully");
      }
      setLoader(false);
      setShowDeclineModal(false);
      getTournament();
    } catch (error) {
      setLoader(false);
    }
  };
  const [isTimerReached, setTimerReached] = useState(false);
  const ChallengeCountdownEnded = (isComplete) => {
    setTimerReached(isComplete);
  };

  const componentCleanup = () => {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    window.history.replaceState({}, document.title);
  };
  const game = useMemo(() => {
    return state?.game?.allGames.filter(
      (gameObj) => gameObj?.slug == tournamentDetails.slug
    )?.[0];
  }, [tournamentDetails.slug, state?.game?.allGames.length]);
  const [updateProfile, setUpdateProfile] = useState(false);

  const handleEnroll = () => {
    if (!loggedUser.user) {
      localStorage.setItem("previousPath", window.location.pathname);
      history.push({
        pathname: "/signin",
        state: { prevPath: window.location.pathname },
      });
      return;
    } else if (profilePhone === "" || profilePhone === null) {
      setUpdateProfile(true);
    } else {
      setUpdateProfile(false);
      callGetTeamList(tournamentDetails);

      setShowPaymentModal(true);
      setShowTeamSelectModal(true);
    }
  };
  const hideEnrollModal = () => {
    getTournament();
  };
  const hideUpdateProfile = () => {
    setUpdateProfile(false);
  };
  const handleHideAcceptModal = () => {
    setShowAcceptModal(false);
  };
  const handleHideDeclineModal = () => {
    setShowDeclineModal(false);
  };
  const handleHideConfirm = () => {
    getTournament();
    setTournamentAcceptConfirmModal(false);
  };
  const handleCancel = () => setPayForYourselfPaymentModal(false);
  const [balance, setBalance] = useState({});
  const handleSubmit = async ({
    payActiveIndex,
    bonus,
    promoBonus,
    setisLoading,
  }) => {
    const is_paying_full = payActiveIndex == 1 ? true : false;
    try {
      setisLoading(true);
      setLoader(true);
      const res = await actionOnTournamentInvite({
        tournamentId: tournamentDetails.id,
        body: {
          team_id: currentPlayer.team,
          action: "accept",
          is_paying_full: is_paying_full,
          bonus: bonus,
        },
      });
      setBalance({
        promo_choice: bonus,
        promo: parseInt(promoBonus),
        wallet: walletBalance,
        payingForTeam: payActiveIndex === 1,
      });
      setisLoading(false);
      setLoader(false);
      setShowAcceptModal(false);
      setPayForYourselfPaymentModal(false);
      setTournamentAcceptConfirmModal(true);
      setEntryFeeSubtract(
        is_paying_full
          ? tournamentDetails.entry_fee * tournamentDetails.player_per_team
          : tournamentDetails.entry_fee
      );
      if (res) {
        toastify("success", "Invite accepted sucessfully");
        getTournament();
      }
    } catch (error) {
      setisLoading(false);
      setLoader(false);
    }
  };
  const reCallApi = () => {
    getTournament();
  };
  const callGetTeamList = (detail) => {
    if (game === undefined) {
      const gameid = games.filter(
        (gameObj) => gameObj?.slug === tournamentDetails.slug
      )?.[0];
      getTeamsList({
        game_id: gameid?.id,
        mode: detail.player_per_team,
        creatorTeam: "1",
      });
    } else {
      getTeamsList({
        game_id: game?.id,
        mode: detail.player_per_team,
        creatorTeam: "1",
      });
    }
  };
  return (
    <>
      <DetailsPagesHeader headerText="TOURNAMENT DETAILS" />
      {loader ? (
        <div class="loading"></div>
      ) : (
        <section className="lobby-detail tournament-detail-section">
          {tournamentDetails !== null ? (
            tournamentDetails && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="file-complaint-wrapper">
                      <div
                        className="back-btn"
                        onClick={() => history.goBack()}
                      >
                        <span>
                          <img src="/assets/images/back-arrow.svg" alt="back" />
                        </span>
                        <span className="back">Back</span>
                      </div>
                    </div>
                    <div className="card">
                      <div className="detail-box">
                        <DetailsImage slug={slug} />
                        <TournamentInfo
                          slug={slug}
                          details={tournamentDetails}
                          currentPlayer={currentPlayer}
                          canEnroll={canEnroll}
                          handleAcceptTournament={handleAcceptTournament}
                          handleAcceptNoPay={handleAcceptNoPay}
                          handleNoPay={handleNoPay}
                          handledeclineTournament={handledeclineTournament}
                        />
                      </div>
                      <TournamentDetailEnrollAction
                        details={tournamentDetails}
                        isTimerReached={isTimerReached}
                        ChallengeCountdownEnded={ChallengeCountdownEnded}
                        canEnroll={canEnroll}
                        setCanEnroll={setCanEnroll}
                        handleEnroll={handleEnroll}
                        reCallApi={reCallApi}
                      />
                    </div>
                  </div>
                </div>

                <Tabs
                  defaultActiveKey={
                    props.location?.state?.result ? "STANDINGS" : "MATCH INFO"
                  }
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="MATCH INFO" title="MATCH INFO">
                    <div className="boxx">
                      {tournamentDetails?.match_info?.length === 0 ? (
                        <h2>Match info not available yet</h2>
                      ) : (
                        <TournamentMatchInfoTab
                          roundData={tournamentDetails?.rounds}
                          gameType={tournamentDetails.game_type}
                          teamsInMatchesInfo={tournamentDetails.match_info}
                        />
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="BRACKETS" title="BRACKETS">
                    <div className="boxx">
                      {tournamentDetails?.rounds === null ||
                      tournamentDetails?.rounds?.length === 0 ? (
                        <h2>Brackets not generated yet</h2>
                      ) : (
                        <>
                          {tournamentDetails.game_type === "PPK" ? (
                            <>
                              <div className="bracket_header">
                                <h4>{tournamentDetails.name}</h4>
                                <p>{tournamentDetails.bracket}</p>
                              </div>
                              <ViewManualCreatedBracket
                                details={tournamentDetails.match_info}
                              />
                            </>
                          ) : (
                            <ViewBracket
                              bracketId={
                                tournamentDetails?.rounds?.[0]?.bracket_cloud_id
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="STANDINGS" title="STANDINGS">
                    <div className="boxx">
                      {tournamentDetails?.standings?.length === 0 ? (
                        <h2>Standings not available yet</h2>
                      ) : (
                        <StandingsTab
                          standingsData={tournamentDetails?.standings}
                        />
                      )}
                    </div>
                  </Tab>
                  {tournamentDetails?.price?.length === 0 ? null : (
                    <Tab eventKey="PRIZE" title="PRIZE">
                      <PrizeTab prizelist={tournamentDetails?.price} />
                    </Tab>
                  )}
                  {tournamentDetails.is_enrolled ? (
                    <Tab eventKey="TEAMS" title="TEAMS">
                      {tournamentDetails?.participants?.length === 0 ? (
                        <h2>No teams enrolled yet</h2>
                      ) : (
                        <TournamentTeamsTab
                          teamData={tournamentDetails?.participants}
                          isTournamentFree={tournamentDetails?.free}
                        />
                      )}
                    </Tab>
                  ) : null}
                  <Tab eventKey="GAMERULES" title="GAME RULES">
                    <div className="boxx">
                      {tournamentDetails?.game_rules === "" ? (
                        <h2>Game rules not found</h2>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(tournamentDetails?.game_rules),
                          }}
                        ></p>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="TOURNAMENTRULES" title="TOURNAMENT RULES">
                    <div className="boxx">
                      {tournamentDetails?.tournament_rules === "" ? (
                        <h2>Tournament rules not found</h2>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              tournamentDetails?.tournament_rules
                            ),
                          }}
                        ></p>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            )
          ) : (
            <h5 style={{ textAlign: "center" }}> {errMsg}</h5>
          )}
        </section>
      )}
      {tournamentAcceptConfirmModal ? (
        <ConfirmTournamentModal
          isFree={tournamentDetails.free}
          details={tournamentDetails}
          handleHideConfirm={handleHideConfirm}
          walletBalance={balance}
          entryFeeSubtract={entryFeeSubtract}
        />
      ) : null}
      {showDeclineModal ? (
        <DeclineInviteModal
          hideDeclineModal={handleHideDeclineModal}
          handleDeclineTournament={declineTournament}
        />
      ) : null}
      {showAcceptModal ? (
        <AcceptInviteModal
          selectedGame={selectedGame}
          gamertag={gamertag}
          games={games}
          details={tournamentDetails}
          handleAcceptNoPay={handleAcceptNoPay}
          hideAcceptModal={handleHideAcceptModal}
        />
      ) : null}
      {updateProfile ? (
        <UpdateProfileModal
          updateProfile={updateProfile}
          hideUpdateProfile={hideUpdateProfile}
          profilePhone={profilePhone}
          profileAddress={profileAddress}
        />
      ) : (
        <TournamentEnroll
          hideEnrollModal={hideEnrollModal}
          tournament={tournamentDetails}
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
          showTeamSelectModel={showTeamSelectModel}
          setShowTeamSelectModal={setShowTeamSelectModal}
          playerTeams={playerTeams}
          game={game}
          mode={tournamentDetails.player_per_team}
        />
      )}
      {payForYourself ? (
        addtoWallet ? (
          <AddWalletModal
            closeAddToWallet={closeAddToWallet}
            games={games}
            details={tournamentDetails}
          />
        ) : (
          <Modal show={true} onHide={handleCancel} backdrop="static">
            <PaymentComponent
              entry_fee={tournamentDetails.entry_fee}
              mode={tournamentDetails.player_per_team}
              selectedGame={
                games.filter((game) => tournamentDetails.slug == game.slug)?.[0]
              }
              free_lobby={tournamentDetails.free}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              openAddToWallet={openAddToWallet}
              creatorPay={false}
            />
          </Modal>
        )
      ) : null}
    </>
  );
};

export default TournamentDetail;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { profileProgressData } from "../../helper/utilities";
import { orderBy } from "lodash";

function ProgressProfile(props) {
  const [data, setData] = useState(profileProgressData);
  const [completeCount, setCompleteCount] = useState(1);
  const checkIsComplete = (progressItem) => progressItem?.complete;
  const getPercentageComplete = (total, complete) => (100 / total) * complete;

  const handleProgressItemClick = (name = "") => {
    switch (name) {
      case "avatar": {
        props.showisEditPictureMod();
        break;
      }
      case "gameid": {
        props.openGamerTagMenu();
        break;
      }
      case "profile":
        props.openEditProfile();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // setCompleteCount
    setCompleteCount(data?.filter(checkIsComplete)?.length);
  }, [data]);

  useEffect(() => {
    const newProgressData = data.map((progressItem) => {
      const temp = { ...progressItem };
      if (progressItem?.name == "avatar") {
        temp["complete"] = !(
          props?.userData.profile?.avatar === "" ||
          props?.userData.profile?.avatar === "null" ||
          props?.userData.profile?.avatar === null
        );
      }
      // if game id has one length then fill bar
      else if (progressItem?.name == "gameid") {
        temp["complete"] = !!props?.userData?.game_accounts?.length;
      } else if (progressItem?.name == "profile") {
        temp["complete"] = !(
          props?.userData.phone === null || props?.userData.address.full === ""
        );
      }
      return temp;
    });
    setData(orderBy(newProgressData, ["complete", "order"], ["desc", "asc"]));
  }, [props?.userData]);

  return data?.length ? (
    completeCount == data?.length ? null : (
      <div className="progressBar">
        <div style={{ display: "flex", gap: "5px", width: "100%" }}>
          {data?.map((item, idx) => {
            return (
              <div
                class="progress"
                style={{ cursor: item?.complete ? "" : "pointer" }}
                key={idx}
                data-tip
                data-for={item?.name}
                onClick={
                  item?.complete
                    ? null
                    : () => handleProgressItemClick(item?.name)
                }
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{
                    width: item?.complete ? "100%" : "0%",
                  }}
                  aria-valuenow={item?.complete ? "100" : "0"}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>

                <ReactTooltip
                  id={item?.name}
                  place="top"
                  effect="solid"
                  className="progressBar-tooltip"
                >
                  <p>
                    {item?.complete ? item?.completeMessage : item?.message}
                  </p>
                </ReactTooltip>
              </div>
            );
          })}
        </div>
        {completeCount == data?.length ? null : (
          <div className="mt-3 profileBar-completeMessage">
            <h4>
              {getPercentageComplete(data?.length, completeCount)}% Complete
            </h4>
          </div>
        )}
      </div>
    )
  ) : null;
}

export default ProgressProfile;

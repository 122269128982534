import React, { Component } from "react";
import { Link } from "react-router-dom";
import { linkToSocials, mail } from "../../constants/constantData";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrivacyModal: false,
      termsData: [],
      privacyData: [],
    };
  }

  render() {
    return (
      <>
        {/* Start Contacts-Section Area */}
        <section className="contacts-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-12 col-md-4">
                <div className="ryvalsLogoDesc">
                  <img src="/assets/images/footer-logo.svg" alt="Ryvals logo" />
                  <p className="horizontal-line">
                    All content, games titles, trade names and/or trade dress,
                    trademarks, artwork and associated imagery are trademarks
                    and/or copyright material of their respective owners
                  </p>
                </div>
              </div>

              <div className="col-lg-2 col-sm-12 col-md-3">
                <div className="column-block">
                  <h3>Contact Info</h3>
                  <div className="info-wrapper">
                    <div className="location">
                      <a href={`mailto:${mail.support}`}>
                        <span className="icon-text">{mail.support}</span>
                      </a>
                    </div>
                    <div className="location">
                      <a href={`mailto:${mail.feedback}`}>
                        <span className="icon-text mail-text">
                          {mail.feedback}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-12 col-md-2">
                <div className="column-block">
                  <h3 className="quick mid-link">Quick Links</h3>
                  <div className="quick-links-wrapper">
                    <div className="quick-link policy-link">
                      <a href="/demos">
                        <span>How to join lobbies</span>
                      </a>
                    </div>
                    <div className="quick-link policy-link">
                      <a
                        href={linkToSocials.discord}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Discord link</span>
                      </a>
                    </div>
                    <div className="quick-link policy-link">
                      <a href="/faq">
                        <span>FAQ</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12 col-md-3">
                <div className="column-block">
                  <h3>Follow Us</h3>
                  <div className="social-wrapper">
                    <div className="socials">
                      <a
                        href={linkToSocials.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/twitter-icon.svg"
                            alt="Twiiter-icon"
                          />
                        </span>
                      </a>
                    </div>

                    <div className="socials">
                      <a
                        href={linkToSocials.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/instagram-icon.svg"
                            alt="Instagram-icon"
                          />
                        </span>
                      </a>
                    </div>

                    <div className="socials">
                      <a
                        href={linkToSocials.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/youtube-icon.svg"
                            alt="youtube-icon"
                          />
                        </span>
                      </a>
                    </div>

                    <div className="socials">
                      <a
                        href={linkToSocials.twitch}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/twitch-icon.svg"
                            alt="twitch-icon"
                          />
                        </span>
                      </a>
                    </div>

                    <div className="socials">
                      <a
                        href={linkToSocials.discord}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/discord-icon.svg"
                            alt="discord-icon"
                          />
                        </span>
                      </a>
                    </div>

                    <div className="socials">
                      <a
                        href={linkToSocials.tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src="/assets/images/tiktok-icon.svg"
                            alt="Tiktok-icon"
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contacts-Section Area*/}

        {/* Start Footer Area */}
        <section className="footer">
          <div className="container">
            <div className="row align-items-center last-footer">
              <div className="col-sm-12 col-md-6 col-lg-6 rights-reserved order-2 order-md-1">
                <p>© {new Date().getFullYear()} RYVALS. All Rights Reserved.</p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 order-1 order-md-2 pb-3 pb-md-0 rights-wrapper">
                <div className="terms-conditions-wrapper footer-terms">
                  <div className="guidelines-box conditions-box">
                    <Link
                      to="/terms-of-use"
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      Terms and Conditions
                    </Link>
                  </div>
                  <div className="guidelines-box policy-box">
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="guidelines-box brand-box">
                    <Link to={"/california-privacy"} style={{ color: "#fff" }}>
                      California Privacy
                    </Link>
                  </div>

                  <div className="guidelines-box brand-box">
                    <Link to={"/BrandGuidelines"} style={{ color: "#fff" }}>
                      Media
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Footer;

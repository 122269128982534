import React, { useState } from "react";
import { Parallax } from "react-parallax";

import Slider from "react-slick";
const BrandGuidelines = () => {
  const [active, setActive] = useState("0");
  const handleKey = (value) => {
    if (value === "0") {
      setActive(0);
      document.getElementById("introduction-media").scrollIntoView();
    } else if (value === "1") {
      setActive(1);
      document.getElementById("logo-media").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "2") {
      setActive(2);
      document.getElementById("versions").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "3") {
      setActive(3);
      document.getElementById("proportions").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "4") {
      setActive(4);
      document.getElementById("size").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "5") {
      setActive(5);
      document.getElementById("misuse").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "6") {
      setActive(6);
      document.getElementById("guidelines").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (value === "7") {
      setActive(7);
      document.getElementById("font").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else {
      setActive(8);
      document.getElementById("formats").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">Media</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <section class="brand-slider">
        <Slider {...settings}>
          <div>
            {" "}
            <label
              className={active == "0" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("0")}
              style={{ cursor: "pointer" }}
            >
              Introduction
            </label>
          </div>
          <div>
            <label
              className={active == "1" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("1")}
              style={{ cursor: "pointer" }}
            >
              Our logo
            </label>
          </div>
          <div>
            {" "}
            <label
              className={active == "2" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("2")}
              style={{ cursor: "pointer" }}
            >
              Logo Versions
            </label>
          </div>
          <div>
            <label
              className={active == "3" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("3")}
              style={{ cursor: "pointer" }}
            >
              Proportions
            </label>
          </div>
          <div>
            <label
              className={active == "4" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("4")}
              style={{ cursor: "pointer" }}
            >
              Logo Space and Size
            </label>
          </div>
          <div>
            <label
              className={active == "5" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("5")}
              style={{ cursor: "pointer" }}
            >
              Logo Misuse
            </label>
          </div>
          <div>
            <label
              className={active == "6" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("6")}
              style={{ cursor: "pointer" }}
            >
              Our Color Palette guideline
            </label>
          </div>
          <div>
            <label
              className={active == "7" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("7")}
              style={{ cursor: "pointer" }}
            >
              Primary Font
            </label>
          </div>
          <div>
            {" "}
            <label
              className={active == "8" ? "label-Active" : "label-InActive"}
              onClick={() => handleKey("8")}
              style={{ cursor: "pointer" }}
            >
              Digital Formats
            </label>
          </div>
        </Slider>
      </section>
      <div class="brand-guidelines-wrapper" id="introduction-media">
        <section class="introduction">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="introduction-wrapper">
                  <p>
                    Welcome to the official brand guidelines of the RYVALS brand
                    and assets. This document is intended to educate anyone who
                    is responsible for creating internal or external
                    communications using the RYVALS brand. It is important that
                    we all share a basic understanding of how and when to use
                    our identity. These Identity Standards are intended to
                    introduce you to the basic usage. We want to make it easy
                    for you to integrate RYVALS in all media formats while
                    respecting our brand and legal/licensing restrictions.
                  </p>
                  <ul class="intro-list">
                    <li>
                      The RYVALS logo should be prominent and visible in all
                      visual communications.
                    </li>
                    <li>
                      It should not be placed with another graphic or edited to
                      create a new image.
                    </li>
                    <li>
                      For legibility, keep the area around the logo clear.
                    </li>
                    <li>
                      The RYVALS logo colors can not be changed or modified. Use
                      only the provided official images.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="our-logo" id="logo-media">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Our logo</h2>
                <div class="logo-wrapper">
                  <img src="assets/images/brand-our-logo.png" alt="logo" />
                </div>
                <p>
                  We are very proud of our logo, and we require that you follow
                  these guidelines to ensure it always looks its best.
                  Masterbrand logo and slogan.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="logo-versions" id="versions">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Logo Versions</h2>
                <div class="logo-wrapper">
                  <img src="assets/images/logo-versions.png" alt="logo" />
                </div>
                <ul class="logo-versions-list">
                  <li>
                    The RYVALS Logo should be used mostly with the Cyan/ Aqua
                    and Black colors.
                  </li>
                  <li>
                    The RYVALS Logo can be used on dark or white image
                    backgounds with high contrast between them.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section class="proportions" id="proportions">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Proportions</h2>
                <div class="logo-wrapper">
                  <img src="assets/images/proportions-logo.png" alt="logo" />
                </div>
                <ul class="proportions-list">
                  <li>
                    The RYVALS Logo has a neat proportion ofl:1/1.8 width. These
                    proportions were chosen carefully and they are not to be
                    changed.
                  </li>
                  <li>
                    The Icon has a perfect square proportion ofl:l Square Ratio
                    and acts as the Favicon as well.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section class="space-size" id="size">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Logo space and size</h2>
                <div class="clear-space-wrapper">
                  <div>
                    <img src="assets/images/space-size-logo.png" alt="logo" />
                  </div>
                  <div>
                    <h3>Clear Space</h3>
                    <p>
                      Clear space is the area surrounding the global signature
                      and Icon that must be kept free of any elements, including
                      text, graphics, borders, or other logos. The minimum clear
                      space required for the preferred global signature is equal
                      to "x", or the height and width of the RYVALS Icon.
                    </p>
                    <h3>Minimum Size</h3>
                    <ul class="space-size-list">
                      <li>
                        Establishing a minimum size ensures there is no
                        distortion to the RYVALS logo.
                      </li>
                      <li>
                        The RYVALS logo should never be smaller than 160px in
                        digital or 45mm in print.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="logo-misuse" id="misuse">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Logo misuse</h2>
                <div class="no-exception-wrapper">
                  <div>
                    <h3>No Exception</h3>
                    <ul class="logo-misuse-list">
                      <li>
                        It is important that the appearance of the RYVALS logo
                        remain consistent
                      </li>
                      <li>
                        The logo should not be misinterpreted, modified, or
                        added to.
                      </li>
                      <li>
                        No attempt should be made to alter the logo in any way.
                      </li>
                      <li>
                        RYVALS logo orientation, color and composition should
                        always remain as indicated in this document, there are
                        no exceptions.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img src="assets/images/red-ryval.png" alt="logo" />
                    <p>A few examples of logo misuse.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="palette" id="guidelines">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Our Color Palette guideline</h2>
                <div class="palette-wrapper">
                  <div>
                    <img src="assets/images/palette-logo.png" alt="logo" />
                  </div>
                  <div>
                    <ul class="palette-list">
                      <li>
                        The colors selected for the RYVALS signature logo
                        reflect the company's values.
                      </li>
                      <li>
                        The colors have been carefully chosen and are not to be
                        altered in any way.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="primary-font" id="font">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Primary Font</h2>
                <div>
                  <img src="assets/images/primary-logo.png" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="digital" id="formats">
          <div class="container">
            <div class="row">
              <div class="col">
                <h2>Digital Formats</h2>
                <div class="digital-wrapper">
                  <div>
                    <img src="assets/images/digital.png" alt="logo" />
                  </div>
                  <div>
                    <img src="assets/images/digital.png" alt="logo" />
                  </div>
                </div>
                <p>Applications launching soon</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default BrandGuidelines;

import React from "react";
import ReactTooltip from "react-tooltip";

function LobbyCardHeader({ data }) {
  return (
    <>
      {data.is_verified ? (
        <div class="verified-badge">
          <img
            src="/assets/images/verified-lobby-badge.svg"
            alt="verified-badge"
          />
        </div>
      ) : null}
      {data?.is_password_protected ? (
        <div class="verified-badge">
          <img
            className="pt-4 pe-4"
            src="/assets/images/lock.svg"
            alt="protected-lobby-badge"
            data-tip
            data-for="loginTip1"
          />
          <ReactTooltip id="loginTip1" place="left" effect="solid">
            Protected Lobby
          </ReactTooltip>
        </div>
      ) : null}
    </>
  );
}

export default LobbyCardHeader;

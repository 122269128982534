import React from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import dateToUtc from "../../helper/dateToUtc";


const CountDown2 = (props) => {
  const minutesToAdd = 10;
  const currentDate = new Date(props.server_current_datetime2);
  const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  const endTime = dateToUtc(futureDate);
  const countdownTimer = ({ days, hours, minutes, seconds, completed }) => {
    timeReached2(completed);
    if (completed) {
      return "";
    } else {
      return (
        <span style={{ fontSize: "18px", textTransform: "none" }}>
          Starts in{" "}
          <span className="timer-of-start">
            {days ? days + "D " : null}
            {hours ? hours + "H " : null}
            {minutes ? minutes + "M " : null}
            {seconds}S
          </span>
        </span>
      );
    }
  };

  const timeReached2 = (completed) => {
    if (completed) props.time_reached2(completed);
  };

  return (
    <Countdown
      date={endTime}
      renderer={countdownTimer}
      onComplete={timeReached2}
    />
  );
};

CountDown2.propTypes = {
  server_current_datetime2: PropTypes.string,
  time_reached2: PropTypes.func,
};

export default CountDown2;

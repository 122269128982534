import React from "react";
import { Modal } from "react-bootstrap";
import { getRemainingBalance } from "../utils";
const SuccessEnrollModal = ({
  showSuccessModal,
  walletBalance,
  entryFeeSubtract,
  hideSuccessModal,
  isFree,
}) => {
  return (
    <>
      <Modal
        show={showSuccessModal}
        onHide={hideSuccessModal}
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="money-added">
          <div className="tick-image">
            <img src="/assets/images/tick.svg" alt="" />
          </div>
          <h3 className="challengeCreated">Tournament Enrolled</h3>

          <p className="challengeBetween">
            Congratulations, your have successfully enrolled in the tournament
          </p>
          {/* {isFree ? null : (
            <>
              {" "} */}
          <p>Wallet Balance</p>
          <h4>
            {getRemainingBalance(isFree, walletBalance, entryFeeSubtract)}
          </h4>
          {/* </>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessEnrollModal;

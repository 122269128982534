// export default RegisterTeam
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ConsoleHelper } from "../../helper/utilities";
import { getTeams } from "../../services/tournament.service";
import TeamPlayersList from "../common/TeamPlayersList";
const RegisterTeam = (props) => {
  const [dropDownOption, setDropDownOption] = useState(null);
  const [options, setOptions] = useState([]);
  const callPreTeams = async () => {
    try {
      const game_id = props.game.id;
      const mode = props.mode;
      const res = await getTeams({ game_id, mode, creatorTeam: "1" });
      setOptions(res);
    } catch (error) {
      ConsoleHelper(error, ">>>>>");
    }
  };

  useEffect(() => {
    callPreTeams();
  }, []);

  return (
    <div className="add-teammate-container">
      <label>Team Name</label>
      <Select
        className="react-select-container-teammates"
        classNamePrefix="react-select-teammates"
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder="Team Name"
        isSearchable
        isClearable
        onChange={(item) => {
          if (item) {
            setDropDownOption(item);
            props.setTeamMates(item?.players);
            props.setTeamSuggestId(item?.value);
          } else {
            setDropDownOption(null);
            props.setTeamMates([]);
            props.setTeamSuggestId(null);
          }
        }}
        options={options.map((data) => {
          return {
            ...data,
            label: data.name,
            value: data.id,
            teamMates: data.teammates,
            player_count: data?.player_count,
          };
        })}
        value={dropDownOption}
      />
      {dropDownOption?.players?.length ? (
        <TeamPlayersList players={dropDownOption?.players} />
      ) : null}
    </div>
  );
};

export default RegisterTeam;

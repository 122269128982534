import React, { useState } from "react";
import PPKTeamsModal from "../Modal/PPKTeamsModal";
const ViewManualCreatedBracket = ({ details }) => {
  const [show, setShow] = useState(false);
  const [teamsInRound, setTeamsInRound] = useState([]);
  const [matchNumber, setMatchNumber] = useState("");
  const handleMatches = (teams, matchNo) => {
    setShow(teams.length !== 0);
    setTeamsInRound(teams);
    setMatchNumber(matchNo);
  };
  const handleClose = () => {
    setShow(false);
    setTeamsInRound([]);
    setMatchNumber("");
  };
  return (
    <div className="round-brackets-preview">
      <div className="bracket-ctn">
        <div className="round-comments">
          {details &&
            details.map((rounds, index) => {
              return (
                <div className="round comment" id={index}>
                  <h4 className="text-capitalize">Round {rounds.round}</h4>
                </div>
              );
            })}
        </div>
        <div className="matches no-matches">
          {details &&
            details.map((rounds, index) => {
              return (
                <div className={`round column`}>
                  {rounds.matches.length === 0 ? (
                    <h6> Matches not generated yet</h6>
                  ) : null}
                </div>
              );
            })}
        </div>
        
        <div className="matches">
          {details &&
            details.map((rounds, index) => {
              return (
                <div className={`round column-${index + 1}`}>
                  {rounds.matches.length === 0 ? 
                  null
                   : 
                    rounds.matches.map((submatch, idx) => {
                      return (
                        <div className="match">
                          <div class="bracket"></div>
                          <div className="match-holder">
                            <div
                              className="competitors"
                              id={idx}
                              onClick={() =>
                                handleMatches(
                                  submatch.teams,
                                  submatch.match_number
                                )
                              }
                            >
                              <span className="text-capitalize">
                                Match {submatch.match_number}
                              </span>
                              <span>
                                {submatch.teams.length}{" "}
                                {submatch.teams.length <= 1 ? "Team" : "Teams"}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <PPKTeamsModal
        show={show}
        teams={teamsInRound}
        match={matchNumber}
        closeModal={handleClose}
      />
    </div>
  );
};

export default ViewManualCreatedBracket;

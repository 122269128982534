import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toastify from "../../../helper/toastify.jsx";
import { addRank } from "../../../services/tournament.service.js";
const AddRankForm = ({
  tournamentDetails,
  show,
  hideAddRankFormModal,
  reCallApi,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    playeRank: Yup.string()
      .matches(
        /^(?!-)[A-Za-z0-9\s!"#$%&'()*+,.\/:;<=>?@[\]^_`{|}~-]*$/,
        "Invalid, Please add valid rank"
      )
      .required("Rank is required"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formData, setFormData] = useState();
  const onContinue = async (data) => {
    setShowConfirmModal(true);
    setFormData(data);
  };
  const onSubmit = async () => {
    let body = {
      player_rank: formData.playeRank,
    };
    showLoader(true);
    seterrorMsg(null);
    await addRank({ tournamentId: tournamentDetails.id, body })
      .then((res) => {
        toastify("success", "Rank Added Successfully");
        showLoader(false);
        hideAddRankFormModal();
        reCallApi();
        setShowConfirmModal(false);
      })
      .catch((err) => {
        setShowConfirmModal(false);
        showLoader(false);
        seterrorMsg(err);
      });
  };
  const showLoader = (load) => {
    setisLoading(load);
  };
  const handleReset = () => {
    reset();
    hideAddRankFormModal();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleReset}
        className="add-rank-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Rank</Modal.Title>
        </Modal.Header>
        <div className="player-details">
          <Form>
            <Modal.Body className="file-complaint-wrapper">
              <Form.Group
                className="input-field"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Control
                  id="rank"
                  type="text"
                  name="playeRank"
                  placeholder="Enter Rank"
                  {...register("playeRank")}
                  className={`input-field ${
                    errors.playeRank ? "is-invalid" : ""
                  }`}
                />
                {errors.playeRank?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.playeRank?.message}
                  </div>
                )}
              </Form.Group>
              {errorMsg && (
                <div className="invalid-feedback d-block my-2">{errorMsg}</div>
              )}
              <p className="gamertag-prompt">
                {`* Please enter the rank associated with your ${tournamentDetails.game} account`}
              </p>
            </Modal.Body>
            <Modal.Footer className="delete-footer gamertag-prompt-header d-flex">
              <Button
                type="button"
                className="cancel-btn"
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="yes-btn"
                disabled={isLoading}
                onClick={handleSubmit(onContinue)}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm mr-4"></span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(!showConfirmModal)}
        className=" confirm-rank"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Rank</Modal.Title>
        </Modal.Header>
        <div className="player-details">
          <Form>
            <Modal.Body className="file-complaint-wrapper">
              <p className="gamertag-prompt">
                Rank cannot be changed ones added .<br />
                Please confirm to proceed.
              </p>
            </Modal.Body>
            <Modal.Footer className="delete-footer gamertag-prompt-header d-flex">
              <Button
                type="button"
                className="cancel-btn"
                onClick={() => setShowConfirmModal(!showConfirmModal)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="yes-btn"
                disabled={isLoading}
                onClick={onSubmit}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm mr-4"></span>
                ) : (
                  "Confirm"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddRankForm;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { gamerTagInfo } from "../../../helper/utilities";

const AcceptInviteModal = ({
  selectedGame,
  gamertag,
  games,
  details,
  handleAcceptNoPay,
  hideAcceptModal,
}) => {
  const gameDetails = games.filter((game) => details.slug === game.slug)?.[0];
  return (
    <Modal show onHide={hideAcceptModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="invite-body text-center leave-modal">
        <div className="refer-friend-icons">
          <p className="remove-heading invite-para">Accept Invitation?</p>
          <p className="leave-modal-text">
            Are you sure you want to accept the team's invitation?
          </p>
          <div className="d-flex justify-content-evenly participantItemGamer">
            <div>
              <p>Game's Name</p>
              <h6 style={{ color: "white" }}>{selectedGame?.name}</h6>
            </div>
            <div className="displayGamerTag">
              <p>Display Name </p>{" "}
              <div className="gamertag-edit">
                <h6>{gamertag ? gamertag : "-"}</h6>

                <Link
                  to={{
                    pathname: "/myProfile",
                    data: "settingsTab",
                    settingsKey: "gameIds",
                  }}
                  className="ml-4"
                >
                  <img
                    src="/assets/images/edit-gamertag-payment-popup.svg"
                    alt="edit-icon"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="delete-footer">
        <div
          className="pb-3"
          data-tip
          data-for="loginTip"
          style={{ cursor: "pointer" }}
        >
          <span class="info-icon">
            <img
              src="/assets/images/info-icon.svg"
              alt="info"
              class="info-image"
            />
            <span className="gamerTagInfoMessage">
              {gamerTagInfo(
                games.filter((game) => gameDetails.slug === game.slug)?.[0]
              )}
            </span>
          </span>
          <div className="helper-image-container enrollHelperImage">
            <ReactTooltip id="loginTip" place="top" effect="solid">
              <img
                src={
                  games.filter((game) => gameDetails.slug === game.slug)?.[0]
                    ?.helper_image
                }
                alt="helper-game-id-example"
              />
            </ReactTooltip>{" "}
          </div>
        </div>

        <Button className="cancel-btn" onClick={hideAcceptModal}>
          Cancel
        </Button>
        <Button className="yes-btn" onClick={handleAcceptNoPay}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptInviteModal;

function dateToUtc(datetime) {
  let date;
  if (typeof datetime === "string") date = new Date(datetime);
  else if (typeof datetime === "number") date = new Date(datetime);
  else date = datetime;
  return Date?.UTC(
    date?.getUTCFullYear(),
    date?.getUTCMonth(),
    date?.getUTCDate(),
    date?.getUTCHours(),
    date?.getUTCMinutes(),
    date?.getUTCSeconds()
  );
}
export default dateToUtc;

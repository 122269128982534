import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function getUserDetail() {
  const url = baseURL + "players/profile/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers }).then((res) => {
    localStorage.setItem("userProfile", JSON.stringify(res));
    return res;
  });
}

export async function editUserDetail(model) {
  const url = baseURL + "players/profile/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, model, { headers });
}

export async function addGamerTag(model) {
  const url = baseURL + "players/game-account/";

  const body = {
    game_id: model.game_id,
    gaming_account: model.gaming_account,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
export async function editGamerTag(model) {
  const url =
    baseURL + `players/game-account/${model.game_id}/`;

  const body = {
    gaming_account: model.gaming_account,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

export async function enrollForGame(lobbyId, playerIP, promoChoice) {
  const url = baseURL + "players/lobby/enroll/";

  const body = {
    lobby_id: lobbyId,
    player_ip: playerIP,
    promo_choice: promoChoice,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function fileComplaint(model) {
  const url = baseURL + "players/file-complaint/";

  const body = model;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function getEnrolledUsers(game_slug, lobbyId) {
  const url =
    baseURL +
    `games/${game_slug}/lobby/${lobbyId}/enrolled-players/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function getWalletBalance() {
  const url = baseURL + "players/wallet-balance/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function getCountries() {
  const url = baseURL + "config/countries/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function getStates(id) {
  const url = baseURL + `config/regions/${id}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function resendOTPapi(model) {
  const url = baseURL + "players/otp/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, model, { headers });
}
export async function verifyOTP(model) {
  const url = baseURL + `players/profile/verify/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, model, { headers });
}

export async function addAmountToWallet(stripeToken, stripeAmount) {
  const url = baseURL + `payments/add-funds/`;

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    stripe_token: stripeToken,
    amount: stripeAmount,
  };

  return await axiosApi.put(url, body, { headers });
}

export async function createPaymentIntent(amount) {
  const url = baseURL + `payments/create-payment-intent/`;

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    amount: amount,
  };

  return await axiosApi.post(url, body, { headers });
}

export async function getKycStatus() {
  const url = baseURL + `players/kyc-status/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function redeemAmount({ amount, payment_mode, payment_type }) {
  const url = baseURL + `payments/redeem-funds/`;

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    amount: amount,
    payment_type,
    payment_mode,
  };

  return await axiosApi.put(url, body, { headers });
}

export async function walletHistory({ isPaginated, pageNum, pageSize }) {
  const params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
  } else {
    params.append("no_page", isPaginated);
  }

  const url = baseURL + `players/wallet-history/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params }).then((response) => {
    if (isPaginated) return { data: response?.results, total: response?.total };
    else return response;
  });
}
export async function getReferData({
  isPaginated,
  pageNum,
  pageSize,
  column,
  order,
}) {
  const params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
    if (column) params.append("column", column);
    if (order) params.append("order", order);
  } else {
    params.append("no_page", isPaginated);
  }

  const url = baseURL + "players/refer-friend/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params }).then((response) => {
    if (isPaginated) return { data: response, total: response?.total };
    else return response;
  });
}
export async function uploadAvatarImage(image) {
  const url = baseURL + `players/profile/avatar/`;

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    image: image,
  };

  return await axiosApi.put(url, body, { headers });
}

export async function getAzureToken() {
  const url = baseURL + "config/azure/sas/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function getPublicKeyStripe() {
  const url = baseURL + `payments/add-funds/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function changeUserPassword(data) {
  const url = baseURL + `accounts/change-password/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, data, { headers });
}
export async function addPayPalID(body) {
  const url = baseURL + `players/paypal-update/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}
export async function socialLogin(data) {
  const url = baseURL + "players/link-account/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, data, { headers });
}


export async function getSearchData(filterModel) {
  const params = new URLSearchParams();
  if (filterModel.isPaginated) {
    if (filterModel.searchTerm && filterModel.searchTerm !== "")
      params.append("query", filterModel.searchTerm);

    if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

    if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  } else {
    params.append("no_page", filterModel.isPaginated);
  }

  const url = baseURL + "nimda/all-username/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function getFriendLists({
  isPaginated,
  pageNum,
  pageSize,
  order_by,
}) {
  const params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
    if (order_by) params.append("order_by", order_by);
  } else {
    params.append("no_page", isPaginated);
  }
  const url = baseURL + "players/friends/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function protectAccountData(data) {
  const url = baseURL + "players/private-profile/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, data, { headers });
}

export async function disconnectSocials(data) {
  const url = baseURL + "players/disconnect-account/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, data, { headers });
}

export async function sendFriendRequest(id) {
  const url = baseURL + "players/friends/";
  const body = {
    user_id: id,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function cancelFriendRequest(id) {
  const url = baseURL + `players/cancel-request/${id}/`;
  const body = {
    user_id: id,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}
export async function sendAddTofavourites(id) {
  const url = baseURL + "players/add-favourites/";
  const body = {
    user_id: id,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
export async function removeFriendFromList(id) {
  const url = baseURL + `players/remove-friend/${id}/`;
  const body = {
    status: "true",
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

export async function replaceFavFriend(id, data) {
  const url = baseURL + `players/update-favourites/${id}/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, data, { headers });
}

export async function getPlayerProfile(filter) {
  const params = new URLSearchParams();

  if (filter.isPaginated) {
    if (filter.pageSize) params.append("page_size", filter.pageSize);
    if (filter.pageNum) params.append("page", filter.pageNum);
  } else {
    params.append("no_page", filter.isPaginated);
  }

  const url =
    baseURL + `players/profile-permission/${filter.id}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function sendDelfavourites(id) {
  const url = baseURL + `players/delete-favourites/${id}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.delete(url, { headers });
}

export async function enrollTeamForGame(
  lobbyId,
  playerIP,
  promoChoice,
  is_paying_full,
  name,
  mode,
  team_id
) {
  const url = baseURL + "players/lobby/register-team/";

  const body = {
    lobby_id: lobbyId,
    player_ip: playerIP,
    promo_choice: promoChoice,
    // 2 means pay for yourself
    is_paying_full: is_paying_full === 2 ? false : true,
    name,
    mode,
    team_id: team_id,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function getConfig() {
  const url = baseURL + "nimda/config/";

  return await axiosApi.get(url);
}

export async function notificationSettings(settings) {
  const url = baseURL + `players/profile/notification/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, settings, { headers });
}

export async function getFriendsRequests({
  isPaginated,
  pageNum,
  pageSize,
  column,
  order,
}) {
  const url = baseURL + "players/friend-requests/";

  const params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
    if (column) params.append("column", column);
    if (order) params.append("order", order);
  } else {
    params.append("no_page", isPaginated);
  }
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function verifyLobbyPassword({ lobby_password, lobby_id }) {
  const url =
    baseURL +
    `players/lobby/${lobby_id}/password-verification/`;
  const body = {
    lobby_password,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
// Team api's
export async function getTeamLists({ pageNum, pageSize }) {
  const params = new URLSearchParams();
  if (pageSize) params.append("page_size", pageSize);
  if (pageNum) params.append("page", pageNum);
  const url = baseURL + "players/versus-team/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}
export async function createTeam(body) {
  const url = baseURL + "players/versus-team/";
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
export async function deleteTeamById(id) {
  const url = baseURL + `players/versus-team/${id}/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.delete(url, { headers });
}
export async function getTeamById(id) {
  const url = baseURL + `players/versus-team/${id}/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function editTeam(model, id) {
  const url = baseURL + `players/versus-team/${id}/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, model, { headers });
}
export async function getUsers(name) {
  //changed /
  const url =
    baseURL + `players/versus/usernames/?name=${name}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function sendInvite(model, id) {
  const url =
    baseURL + `players/versus-team/${id}/invite/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, model, { headers });
}
export async function removeTeamMate(teamId, teamMateId) {
  const url =
    baseURL +
    `players/versus/${teamId}/player/${teamMateId}/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, { headers });
}

export async function gRecaptchaVerify(model) {
  const url = baseURL + `players/captcha/verify/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, model, { headers });
}
export async function getAccessBlock(amount) {
  const url =
    baseURL +
    `payments/add-funds-shift4/?amount=${amount}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function sendShift4DataSuccessForFurtherProcessing(payload) {
  const url = baseURL + `payments/add-funds-shift4/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, payload, { headers });
}

export async function canCreateZeroFeesChallenge(player_id) {
  //changed /
  const url =
    baseURL + `players/free-challenge/${player_id}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

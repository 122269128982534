import React, { useEffect } from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import dateToUtc from "../../helper/dateToUtc";
import moment from "moment";
import { useConfig } from "../../context/ConfigContext";
const CountDown = (props) => {
  const { state } = useConfig();
  const button_grey_out_time = props.isTournament
    ? state?.config?.tournament_greyout
    : state?.config?.button_grey_out_time;
  useEffect(() => {
    if (!button_grey_out_time) {
      enrollTeamStop(false);
    } else if (
      moment(props.server_current_datetime).diff(moment(), "seconds") <
      button_grey_out_time * 60
    ) {
      enrollTeamStop(true);
    } else {
      enrollTeamStop(false);
      if (
        moment(props.server_current_datetime).diff(moment(), "seconds") >= 86400
      )
        return;
      setTimeout(() => {
        enrollTeamStop(true);
      }, (moment(props.server_current_datetime).diff(moment(), "seconds") - button_grey_out_time * 60) * 1000);
    }
  }, [props.server_current_datetime, button_grey_out_time]);
  const endTime = dateToUtc(props.server_current_datetime);
  const countdownTimer = ({ days, hours, minutes, seconds, completed }) => {
    timeReached(completed);
    if (completed) {
      return "";
    } else {
      return (
        <>
          <h2 className="lobbyDetail">
            <span style={{ fontSize: "18px", textTransform: "none" }}>
              Starts in{" "}
            </span>
          </h2>
          <h2 className="lobbyDetailTimer">
            <span className="timer-of-start">
              {days ? days + "D " : null}
              {hours ? hours + "H " : null}
              {minutes ? minutes + "M " : null}
              {seconds}S
            </span>
          </h2>
        </>
      );
    }
  };

  const timeReached = (completed) => {
    if (completed) props.time_reached(completed);
  };

  const enrollTeamStop = (stop) => {
    if (stop && props.stopEnrollTeam) props.stopEnrollTeam(stop);
  };

  return (
    <Countdown
      date={endTime}
      renderer={countdownTimer}
      onComplete={timeReached}
    />
  );
};

CountDown.propTypes = {
  start_date: PropTypes.string,
  end_time: PropTypes.string,
  server_current_datetime: PropTypes.string,
  start_datetime: PropTypes.string,
  time_reached: PropTypes.func,
  stopEnrollTeam: PropTypes.func,
};

export default CountDown;

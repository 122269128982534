import React, { createContext } from "react";
export const GameContext = createContext();
const initialState = {
  game: {
    ppkGames: [],
    killRaceGames: [],
    traditionalGames: [],
    allGames:[],
    modeObj:{}
  },
};
function countReducer(state = initialState, { type, payload }={}) {
  if (type == "UPDATE") {
    return {
      ...state,
      game: payload,
    };
  } else {
    throw new Error(`Unhandled action type: ${type}`);
  }
}
function GameProvider({ children }) {
  const [state, dispatch] = React.useReducer(countReducer, initialState);
  const value = { state, dispatch };
  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}
function useGame() {
  const context = React.useContext(GameContext);
  if (context === undefined) {
    throw new Error("useGame must be used within a CountProvider");
  }
  return context;
}

export { GameProvider, useGame };

import React, { useEffect } from "react";
import TournamentCard from "./TournamentCard";
import { useSelector } from "react-redux";

const TournamentList = ({ data, fetchTournaments, defaultType }) => {
  const loggedUser = useSelector((state) => state);
  useEffect(() => {
    if (loggedUser?.user) {
      localStorage.removeItem("previousPath");
    }
  }, []);
  return (
    <>
      <div className="row row-cols-1 row-cols-md-2 ">
        {data.length === 0 ? (
          <h5>No tournaments yet</h5>
        ) : (
          RenderTournamentCards(data, fetchTournaments)
        )}
      </div>
    </>
  );
};
export default TournamentList;

export const RenderTournamentCards = (data, fetchTournaments) => {
  return data?.map((tournamentLobby) => {
    return (
      <TournamentCard
        data={tournamentLobby}
        key={tournamentLobby.id}
        fetchTournaments={fetchTournaments}
      />
    );
  });
};

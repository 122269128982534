import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { forgotpassword, forgotusername } from "../../services/authService.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthWrapper from "./AuthWrapper.jsx";
import toastify from "../../helper/toastify.jsx";
import { withRouter } from "react-router";
import {
  PLACEHOLDERS,
  STRINGS_CONSTANTS,
  VALIDATIONS_ERROR,
} from "../../helper/stringConstants.js";

const ForgotPassword = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);
  const search = useLocation().search;
  const isUsernameForgot =
    new URLSearchParams(search).get("type") === "username";

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(VALIDATIONS_ERROR.invalidEmail)
      .required(VALIDATIONS_ERROR.requiredEmail),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit(data) {
    seterrorMsg(null);
    showLoader(true);
    if (isUsernameForgot) forgotname(data.email);
    else forgotpass(data.email);
  }

  async function forgotname(email) {
    await forgotusername(email)
      .then((res) => {
        showLoader(false);
        toastify("success", res.message);
      })
      .catch((err) => {
        showLoader(false);
        seterrorMsg(err);
      });
  }

  async function forgotpass(email) {
    await forgotpassword(email)
      .then((res) => {
        showLoader(false);
        toastify("success", res.message);
      })
      .catch((err) => {
        showLoader(false);
        seterrorMsg(err);
      });
  }

  function showLoader(isLoad) {
    setisLoading(isLoad);
  }

  return (
    <Fragment>
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <div className="contact-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 image-class">
                  <AuthWrapper />
                </div>
                <div className="col-lg-6 form-class">
                  <div className="section-title form-columns text-left mb--50">
                    <div className="forgot-password-image">
                      <img
                        src="/assets/images/forgot-password.svg"
                        alt="forgot password"
                      />
                    </div>
                    <h2 className="title sign-in-title">
                      {isUsernameForgot
                        ? STRINGS_CONSTANTS.forgotUsername
                        : STRINGS_CONSTANTS.forgotPassword}
                    </h2>
                    <p className="description continue password-email">
                      {isUsernameForgot
                        ? STRINGS_CONSTANTS.requestUsername
                        : STRINGS_CONSTANTS.requestPassword}
                    </p>
                  </div>
                  <div className="form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                      <label htmlFor="item01">
                        <input
                          type="text"
                          name="email"
                          id="item01"
                          placeholder={PLACEHOLDERS.emailAddress}
                          {...register("email")}
                          className={`${errors.email ? "is-invalid" : ""}`}
                          autoComplete="off"
                        />
                      </label>
                      {errors.email?.message && (
                        <div className="invalid-feedback d-block">
                          {errors.email?.message}
                        </div>
                      )}

                      {errorMsg && (
                        <div className="invalid-feedback d-block my-2">
                          {errorMsg}
                        </div>
                      )}

                      <button
                        className="rn-button-style--2 btn-solid sendmail-btn"
                        type="submit"
                        id="mc-embedded-subscribe"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm mr-4"></span>
                        )}
                        {STRINGS_CONSTANTS.sendEmail}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default withRouter(ForgotPassword);

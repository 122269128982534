import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import publicIp from "public-ip";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useGame } from "../../context/GameContext";
import { Spinner } from "react-bootstrap";
import toastify from "../../helper/toastify";
const GamesList = () => {
  const { state } = useGame();
  const [spinnerLoadder, setSpinnerLoadder] = useState(false);
  useEffect(() => {
    logoutEventPublicRoutes();
    IpFetch();
  }, []);
  const [playerIp, setPlayerIp] = useState(null);
  const IpFetch = async () => {
    try {
      
      setSpinnerLoadder(true);
      await publicIp
        .v4()
        .then((res) => {
          setPlayerIp(res);
          setSpinnerLoadder(false);
        })
        .catch((err) => {
          setSpinnerLoadder(false);
        });
    } catch (error) {
      toastify("error", error.message+" ,Please try again after disabling your adblocker if enabled");
    }
  };
  const [key, setKey] = useState("ppk");
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/games-banner.png"}
          strength={500}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner text-center`}>
                    <h1 className="title theme-gradient">Games</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5 play-wrapper games-sections newPPK-Traditional">
          <div className="container">
            <div className="row creative-service ">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  {spinnerLoadder ? (
                    <Spinner animation="grow" />
                  ) : (
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="mb-3 nav-justified mt-5"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                    >
                      <Tab
                        eventKey="ppk"
                        title="PPK"
                        className={key == "ppk" ? "playNewCategories" : ""}
                      >
                        <RenderGameType
                          games={state.game.ppkGames}
                          playerIp={playerIp}
                        />
                      </Tab>
                      <Tab
                        eventKey="traditional"
                        title="TRADITIONAL"
                        className={key == "traditional" ? "traditionalTab" : ""}
                      >
                        <RenderGameType
                          games={state.game.traditionalGames}
                          playerIp={playerIp}
                          isTraditional={true}
                        />
                      </Tab>

                      <Tab
                        eventKey="killRace"
                        title="KILL RACE"
                        className={key == "killRace" ? "killRaceTab" : ""}
                      >
                        <RenderGameType
                          games={state.game.killRaceGames}
                          playerIp={playerIp}
                        />
                      </Tab>
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GamesList;

export const RenderGameType = ({ games, playerIp, isTraditional = false }) => {
  return games.length === 0
    ? ""
    : games.map((game) => {
        return (
          <div
            className="col-6 col-xs-12 col-xs-6 col-sm-6 col-md-3 col-lg-2 tradnew"
            key={game.id}
          >
            <Link
              to={{
                pathname: `/${game.slug}/lobby`,
                state: {
                  ipAddress: playerIp,
                  challengeTab: isTraditional,
                },
              }}
              style={game?.game_status == 2 ? { pointerEvents: "none" } : null}
            >
              <div className="sliders apexLegendsBox">
                <div className="sliders apexLegendsBox">
                  <img
                    src={
                      isTraditional && game.slug == "apex-legends"
                        ? game.traditional_image
                        : game.background_image
                    }
                    className="card-img"
                    alt="..."
                  />
                </div>
                <h6 className="newBlue-color">{game.name}</h6>
              </div>
            </Link>
          </div>
        );
      });
};

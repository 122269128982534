import React, { useState } from "react";
import LobbyStatus from "../../LobbyCurrentStatus/LobbyStatus";
import { Button } from "react-bootstrap";
import {
  appendSuffixToTournamentPosition,
  dateFormatTournament,
} from "../../../helper/utilities";
import ShowMorePrizesModal from "../Modal/ShowMorePrizesModal";
import { getTournamentModeTag } from "../utils";
import { useGame } from "../../../context/GameContext";
const TournamentInfo = ({
  slug,
  details,
  currentPlayer,
  canEnroll,
  handleAcceptTournament,
  handleAcceptNoPay,
  handleNoPay,
  handledeclineTournament,
}) => {
  const { state } = useGame();
  const inviteStatusIsempty = JSON.stringify(details?.invite_status) === "{}";
  const isInvitePending =
    JSON.stringify(details?.invite_status) === "{}"
      ? false
      : details.invite_status?.status === 3;

  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(true);
  };
  const handleHideShowMorePrizes = () => {
    setShowMore(false);
  };

  const modeTag = getTournamentModeTag(
    state?.game?.modeGameTypeTournamentObj?.[slug],
    details?.player_per_team
  );
  return (
    <div className="game-details">
      <h2 className="">{details.name}</h2>

      {details?.price?.length === 0 ? null : (
        <div className="d-flex prizes w-100 mt-4">
          {details?.price?.slice(0, 3).map((item, idx) => {
            return (
              <div key={idx} className="prize-div" id={idx}>
                <p className="mb-0">
                  {`${item.position}${appendSuffixToTournamentPosition(
                    item.position
                  )}`}{" "}
                  Prize
                </p>
                <p className="mb-0 theme-default-color">$ {item.price}</p>
              </div>
            );
          })}
          {details?.price?.length > 3 ? (
            <Button
              onClick={handleShowMore}
              variant="link"
              className="theme-color"
            >
              View more
            </Button>
          ) : null}
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between lobby-detail-page tournament_detail_page mt-0">
        {details.is_enrolled ||
        !details.have_invite ||
        details.invite_status?.status === 2 ? null : (
          <MemberView
            canEnroll={canEnroll}
            //canModify={canModify}
            tournamentDetails={details}
            handleAcceptTournament={handleAcceptTournament}
            handleAcceptNoPay={handleAcceptNoPay}
            handleNoPay={handleNoPay}
            handledeclineTournament={handledeclineTournament}
            currentPlayer={currentPlayer}
          />
        )}
        {inviteStatusIsempty || details.leader || isInvitePending ? null : (
          <div className="challengeDetailsPage  tournamentDetailsPage d-flex accept-decline">
            <Button
              variant={
                details.invite_status?.status === 1 ? "success" : "danger"
              }
              disabled={true}
            >
              {details.invite_status?.status === 1 ? "Accepted" : "Rejected"}
            </Button>
          </div>
        )}
      </div>

      <div className="playing-information ValorantNewCard d-flex w-100 mt-4">
        <div className="left-side">
          <p className="entry-box tags">
            <span className="tags enrolled-box">Game</span>
            <span className="count-box dollar-wrapper">{details.game}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Entry Fee / Player</span>
            <span className=" count-box dollar-wrapper">
              $ {details.entry_fee} {details.free ? " Free Entry" : ""}
            </span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Min Players</span>
            <span className="count-box dollar-wrapper">
              {details.player_per_team}
            </span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Min Teams</span>
            <span className="count-box dollar-wrapper">{details.min_team}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Max Teams</span>
            <span className="count-box dollar-wrapper">{details.max_team}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">No. of Enrolled Teams</span>
            <span className="count-box dollar-wrapper">
              {details.total_teams_enrolled}
            </span>
          </p>
        </div>
        <div className="right-side ">
          <p className="entry-box tags">
            <span className="tags enrolled-box">Status</span>
            <span>
              <LobbyStatus data={details} />
            </span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Date</span>
            <span>{dateFormatTournament(details.start_date)}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Start Time</span>
            <span>{details.start_time}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">End Time</span>
            <span>{details.end_time || "NA"}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Last Entry Time</span>
            <span>{details.last_entry_time}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Game Type</span>
            <span>{details.game_type}</span>
          </p>
          <p className="entry-box tags">
            <span className="tags enrolled-box">Mode</span>
            <span>{modeTag}</span>
          </p>
        </div>
      </div>

      <ShowMorePrizesModal
        show={showMore}
        details={details}
        handleHideShowMorePrizes={handleHideShowMorePrizes}
      />
    </div>
  );
};

export default TournamentInfo;
const MemberView = ({
  tournamentDetails,
  handledeclineTournament,
  handleAcceptTournament,
  handleAcceptNoPay,
  handleNoPay,
  canModify,
  canEnroll,
  currentPlayer,
}) => {
  return (
    <div className="challengeDetailsPage  tournamentDetailsPage d-flex accept-decline">
      <Button
        className="rejectButton"
        variant="secondary"
        onClick={() => handledeclineTournament(tournamentDetails)}
        disabled={!canEnroll}
      >
        Decline
      </Button>
      <Button
        className="acceptButton"
        variant="secondary"
        onClick={() => {
          if (currentPlayer.has_paid) {
            handleNoPay(tournamentDetails);
          } else {
            handleAcceptTournament(tournamentDetails);
          }
        }}
        disabled={!canEnroll}
      >
        Accept
      </Button>
    </div>
  );
};

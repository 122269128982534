import React from "react";

const DetailsImage = ({ slug }) => {
  return (
    <div className="image-box">
      <div>
        <img
          src={
            slug === "call-of-duty-warzone"
              ? "/assets/images/warzone-lobby-detail.jpg"
              : slug === "pubg"
              ? "https://rvygamiplatstg.blob.core.windows.net/rvy-gamiplat-dev/pubg.jpg"
              : slug === "fortnite"
              ? "/assets/images/fortnite-lobby-detail.png"
              : slug === "pubg-mobile"
              ? "/assets/images/pubg-mobile-lobby-detail.png"
              : slug === "cod-mobile"
              ? "/assets/images/cod-mobile-lobby-detail.png"
              : slug === "valorant"
              ? "/assets/images/ValorantDetail.png"
              : "/assets/images/Apex-lobby-detail.png"
          }
          alt="logo"
        />
      </div>
    </div>
  );
};

export default DetailsImage;

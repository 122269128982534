import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { ConsoleHelper, notificationSettingEnum } from "../../helper/utilities";
import { notificationSettings } from "../../services/user.service";

function Notifications({ profileData }) {
  const [settings, setSettings] = useState([]);
  useEffect(() => {
    setSettings(
      Object.fromEntries(
        Object.entries(profileData).filter(([key, value]) =>
          key?.includes("noti")
        )
      )
    );
  }, [profileData]);

  return (
    <div className="notification-setting-container">
      <NotificationHeader />
      <NotificationSetttings settings={settings} />
    </div>
  );
}

export const NotificationHeader = () => {
  return (
    <div className="notification-setting-header mb-4">
      <div className="notification-type">
        <h4>Notification Type</h4>
      </div>
      <div className="options notification-options">
        <h4>Yes</h4>
        <h4>No</h4>
      </div>
    </div>
  );
};

export const NotificationSetttings = ({ settings }) => {
  const [notificationsSettings, setNotificationsSettings] = useState(settings);
  useEffect(() => {
    setNotificationsSettings(settings);
  }, [settings]);

  const handleNotificationEnable = async (label) => {
    try {
      const temp = { ...notificationsSettings };
      temp[label] = true;
      await notificationSettings(temp);
      setNotificationsSettings(temp);
    } catch (error) {
      ConsoleHelper(error);
    }
  };

  const handleNotificationDisable = async (label) => {
    try {
      const temp = { ...notificationsSettings };
      temp[label] = false;
      await notificationSettings(temp);
      setNotificationsSettings(temp);
    } catch (error) {
      ConsoleHelper(error);
    }
  };
  return Object.keys(notificationsSettings).map((notification) => {
    return (
      <NotificationSettingItem
        label={notification}
        data={notificationsSettings[notification]}
        handleNotificationEnable={handleNotificationEnable}
        handleNotificationDisable={handleNotificationDisable}
      />
    );
  });
};

export const NotificationSettingItem = ({
  label,
  data,
  handleNotificationEnable,
  handleNotificationDisable,
}) => {

  return (
    <div className="notification-setting-item mb-4">
      <div>
        <h4>{notificationSettingEnum[label]}</h4>
      </div>
      <div className="options">
        <Form.Check
          type="radio"
          aria-label="radio 1"
          className="friendPopup"
          defaultChecked={data}
          name={notificationSettingEnum[label]}
          onChange={() => handleNotificationEnable(label)}
        />
        <Form.Check
          type="radio"
          aria-label="radio 1"
          className="friendPopup"
          defaultChecked={!data}
          name={notificationSettingEnum[label]}
          onChange={() => handleNotificationDisable(label)}
        />
      </div>
    </div>
  );
};

export default Notifications;

import React, { useState, useEffect, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  addAmountToWallet,
  getCountries,
  getPublicKeyStripe,
  getStates,
  getWalletBalance,
  //getAccessBlock,
} from "../../services/user.service.js";
import { Button, Modal, Form, Col, Spinner } from "react-bootstrap";
import AddToWallet from "../payment/AddToWallet";
import PropTypes from "prop-types";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getTotalTake } from "./Utility.js";
import PaymentMethods from "./PaymentMethods.js";
//import PaymentShiftFour from "./PaymentShiftFour.jsx";
const PaymentForm = (data) => {
  const history = useHistory();
  const stripe = useStripe();
  const cardData = useRef(null);
  const elements = useElements();
  // const [shift4InitialParameters, setShift4InitialParameters] = useState([]);
  // const [shift4SavedCards, setShift4SavedCards] = useState();
  // const [shift4ErrorResponse, setShift4ErrorResponse] = useState();
  useEffect(() => {
    setCountryOptionLoader(true);
    getCountries()
      .then((res) => {
        setCountries(res);
        setCountryOptionLoader(false);
      })
      .catch((err) => {
        setCountryOptionLoader(false);
      });
  }, []);
  const [amount, setAmount] = useState(
    data?.data?.location?.state === undefined
      ? 10
      : data?.data?.location?.state.amount
  );
  useEffect(() => {
    getWalletBalancePlayer();
    setFormState(1);
  }, [amount]);
  const [defaultPaymentGateway, setDefaultPaymentGateway] = useState("");
  const getWalletBalancePlayer = async (_) => {
    try {
      const res = await getWalletBalance();
     setDefaultPaymentGateway(res.payment_gateway);
      // if (res.payment_gateway === "shift4") {
      //   getAccessBlock(amount)
      //     .then((acessRes) => {
      //       if (acessRes.access_block.i4go_responsecode === "503") {
      //         setShift4ErrorResponse(acessRes.access_block.i4go_response);
      //       } else {
      //         setShift4InitialParameters(acessRes.access_block);
      //         setShift4SavedCards(
      //           acessRes.saved_cards === undefined ? [] : acessRes.saved_cards
      //         );
      //       }
      //     })
      //     .catch((err) => {});
      // }
    } catch (error) {}
  };
  const [countries, setCountries] = useState([]);
  const [countriesOptionLoader, setCountryOptionLoader] = useState(false);
  const [stateOptionLoader, setStateOptionLoader] = useState(false);
  const userEmail =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).email;
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required("Email is required"),
    addressLine1: Yup.string().trim().required("Address line 1 is required"),
    addressLine2: Yup.string().notRequired(),
    city: Yup.string().trim().required("City is required"),
    states: Yup.string()
      .min(1, "State  is required")
      .required("State is required"),
    country: Yup.string()
      .min(1, "Country  is required")
      .required("Country is required"),
    postalCode: Yup.string().trim().required("Postal Code is required"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email:
        data?.data?.location?.state?.email === undefined
          ? userEmail
          : data?.data?.location.state.email,
    },
    resolver: yupResolver(validationSchema),
  });

  const [showSuccessModal, setSuccessModal] = useState(false);
  const [formState, setFormState] = useState(1);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [defaultState, setDefaultState] = useState("");
  const [walletBalance, setWalletBalance] = useState(data.walletBalance);

  const prevPath =
    data?.data?.location?.pathname === undefined
      ? ""
      : data?.data?.location?.pathname;
  const [showWalletModal, setShowModal] = useState(false);
  const changeAmountToAdd = () => {
    setShowModal(true);
  };
  const hideWalletMod = () => {
    setShowModal(false);
  };
  const closeSuccessModal = () => {
    setSuccessModal(false);
  };
  const [succesMessage, setSuccessMessage] = useState(false);

  const [walletBalanceNew, setWalletBalanceNew] = useState("");
  function onSubmit(dataCard) {
    setFormState(2);
    cardData.current = dataCard;
  }

  const cardPayment = async (setProcessing) => {
    try {
      const dataCard = cardData.current;
      setProcessing(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const card = elements.getElement(CardElement);
      const model = {
        email: dataCard.email,
        address_country: dataCard.country,
        address_state: dataCard.states,
        address_city: dataCard.city,
        address_zip: dataCard.postalCode,
        address_line1: dataCard.addressLine1,
        address_line2: dataCard.addressLine2,
      };
      const result = await stripe.createToken(card, model);
      if (result.error) {
        // Show error to your customer.
        setProcessing(false);
      } else {
        // Send the token to your server.
        // This function does not exist yet; we will define it in the next step.\
        const res = await addAmountToWallet(result.token.id, amount);
        if (res) {
          successFullCallback({ res, setProcessing });
        } else {
          setProcessing(false);
        }
      }
    } catch (error) {
      setProcessing(false);
    }
  };

  const amount1 = amount;
  const percent = 2.9;
  const total_take = getTotalTake(amount1, percent);
  const fees = total_take.toFixed(2);
  const handleCountry = (e) => {
    if (e.target.value === "") {
      setDefaultCountry(e.target.value);
    } else {
      const cet = countries.filter(
        (countryObj) => countryObj.code2 == e.target.value
      );
      setDefaultCountry(cet[0]?.code2);
      callState(cet[0]?.id);
    }
  };
  const handleState = (e) => {
    if (e.target.value === "") {
      setDefaultState(e.target.value);
    } else {
      const cet = states.filter((stateObj) => stateObj.name == e.target.value);
      setDefaultState(cet[0]?.name);
    }
  };
  const callState = (id) => {
    setStateOptionLoader(true);
    getStates(id).then((res) => {
      setStates(res);
      setStateOptionLoader(false);
    });
  };

  const successFullCallback = ({ res = {}, setProcessing = () => {} }) => {
    setSuccessMessage(true);
    setWalletBalanceNew(res.amount);
    setSuccessModal(true);
    setProcessing(false);
    setTimeout(() => {
      setSuccessModal(false);
      setSuccessMessage(false);
      data.data?.location?.state?.prevPath ===
      `/${data.data.location.state?.gameName?.slug}/lobby`
        ? history.push(data.data.location.state?.prevPath)
        : data.data.location?.state?.prevPath ===
          `/${data.data.location.state?.gameName?.slug}/lobby/${data.data.location.state?.lobbyId}`
        ? history.push(data.data.location?.state?.prevPath)
        : data.data.location?.state?.prevPath === "/dashboard"
        ? history.push(data.data.location?.state?.prevPath)
        : history.push("/games");
    }, 2000);
  };

  useEffect(() => {
    setAmount(
      data?.data?.location?.state === undefined
        ? 10
        : data?.data?.location?.state.amount
    );
  }, [data?.data?.location.state?.amount]);

  useEffect(() => {
    setWalletBalance(data.walletBalance);
  }, [data.walletBalance]);

  const [states, setStates] = useState([]);
  const errorCallback = () => {
    setFormState(1);
  };
  return (
    <>
      <section class="payment-wrapper">
        <div class="container">
          <div class="back-btn">
            {formState == 1 ? (
              <p>
                <img src="/assets/images/back-arrow.svg" alt="back" />
                {data?.data?.location?.state?.prevPath ===
                `/${data?.data?.location?.state?.gameName?.slug}/lobby` ? (
                  <Link
                    to={{
                      pathname: data.data.location?.state?.prevPath,
                    }}
                    className="back-text"
                  >
                    Back
                  </Link>
                ) : data?.data?.location?.state?.prevPath ===
                  `/${data?.data?.location.state?.gameName?.slug}/lobby/${data?.data?.location?.state?.lobbyId}` ? (
                  <Link
                    to={{
                      pathname: data.data.location?.state?.prevPath,
                    }}
                    className="back-text"
                  >
                    Back
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/myProfile",
                      prevAmount: amount,
                      prevPath: prevPath,
                    }}
                    className="back-text"
                  >
                    Back
                  </Link>
                )}
              </p>
            ) : (
              <p>
                <img src="/assets/images/back-arrow.svg" alt="back" />
                <Link onClick={() => setFormState(1)} className="back-text">
                  Back
                </Link>
              </p>
            )}
          </div>
          <div class="row">
            <div class="col col-lg-6 left-col">
              {formState == 1 ? (
                <div className="paymentDetails">
                  <div class="form-wrapper">
                    <h3>Please provide your details</h3>
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                      <div className="provideDetails">
                        <Form.Group
                          as={Col}
                          controlId="formGridEmail"
                          className="col-lg-12 col-md-12 col-sm-12 col-12 first-name-col error-msgs"
                        >
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                            {...register("email")}
                            className={`${errors.email ? "is-invalid" : ""}`}
                            disabled
                          />
                          {errors.email?.message && (
                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="margins error-msgs"
                          controlId="formGridAddress1"
                        >
                          <Form.Control
                            placeholder="Address line 1"
                            name="addressLine1"
                            {...register("addressLine1")}
                            className={`${
                              errors.addressLine1 ? "is-invalid" : ""
                            }`}
                          />{" "}
                          {errors.addressLine1?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.addressLine1?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="margins"
                          controlId="formGridAddress1"
                        >
                          <Form.Control
                            placeholder="Address line 2"
                            name="addressLine2"
                            {...register("addressLine2")}
                            className={`${
                              errors.addressLine2 ? "is-invalid" : ""
                            }`}
                          />{" "}
                          {errors.addressLine2?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.addressLine2?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="formGridCountry"
                          className="select-country margin-bottom error-msgs"
                        >
                          <Form.Select
                            placeholder="Country"
                            as={Col}
                            {...register("country")}
                            className={`${errors.country ? "is-invalid" : ""}`}
                            value={defaultCountry}
                            onChange={(e) => {
                              handleCountry(e);
                            }}
                          >
                            <option value="">Select country</option>
                            {countriesOptionLoader ? (
                              <h6>Loading..</h6>
                            ) : (
                              countries?.map((item, index) => {
                                return (
                                  <option key={index} value={item.code2}>
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </Form.Select>
                          {errors.country?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.country?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="formGridState"
                          className="select-states select-country margin-bottom error-msgs"
                        >
                          <Form.Select
                            placeholder="State"
                            as={Col}
                            {...register("states")}
                            className={`${errors.states ? "is-invalid" : ""}`}
                            value={defaultState}
                            onChange={(e) => {
                              handleState(e);
                            }}
                          >
                            <option value="">Select state</option>
                            {stateOptionLoader ? (
                              <option>Loading..</option>
                            ) : (
                              states?.map((item, index) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </Form.Select>
                          {errors.states?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.states?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="formGridCity"
                          className="error-msgs"
                        >
                          <Form.Control
                            placeholder="City"
                            name="city"
                            {...register("city")}
                            className={`${errors.city ? "is-invalid" : ""}`}
                          />{" "}
                          {errors.city?.message && (
                            <div className="invalid-feedback">
                              {errors.city?.message}
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="formGridPostalCode"
                          className="error-msgs"
                        >
                          <Form.Control
                            placeholder="Postal Code"
                            name="postalCode"
                            {...register("postalCode")}
                            className={`${
                              errors.postalCode ? "is-invalid" : ""
                            }`}
                          />
                          {errors.postalCode?.message && (
                            <div className="invalid-feedback">
                              {errors.postalCode?.message}
                            </div>
                          )}
                        </Form.Group>
                      </div>
                      <Button variant="primary" type="submit">
                        Continue
                      </Button>
                    </form>
                  </div>
                  <p style={{ marginTop: "20px" }}>
                    There is deposit fee of 2.9% of amount plus 30 cents. eg: On
                    depositing $100 into the website, a user would actually be
                    paying $103.20
                  </p>
                </div>
              ) : (
                <>
                  <h3 className="paymentMethods">Payment Methods</h3>
                  {/* {defaultPaymentGateway === "stripe" ? ( */}
                    <PaymentMethods
                      handleCardPayment={cardPayment}
                      setSuccessMessage={setSuccessMessage}
                      amount={amount}
                      setWalletBalanceNew={setWalletBalanceNew}
                      walletBalance={walletBalance}
                      successFullCallback={successFullCallback}
                    />
                  {/* ) : (
                    <PaymentShiftFour
                      handleShift4InitialParameters={shift4InitialParameters}
                      handleShift4SavedCard={shift4SavedCards}
                      amount={amount}
                      setWalletBalanceNew={setWalletBalanceNew}
                      walletBalance={walletBalance}
                      successFullCallback={successFullCallback}
                      errorCallback={errorCallback}
                      errorResponse={shift4ErrorResponse}
                    />
                  )} */}
                </>
              )}
            </div>
            <div class="col col-lg-6 right-col">
              <h3>Your Wallet</h3>
              <div>
                <div class="wallet-wrapper">
                  <div
                    className="d-flex ml-3 wallet-icon-container
                  "
                  >
                    <div class="wallet-icon">
                      <img
                        src="/assets/images/payment-wallet.svg"
                        alt="wallet"
                      />
                    </div>
                    <p>
                      Available Wallet Balance
                      {walletBalanceNew === ""
                        ? walletBalance === undefined ||
                          walletBalance === "0.00"
                          ? " $0.00"
                          : ` $${walletBalance}`
                        : walletBalanceNew}{" "}
                    </p>
                  </div>
                  <h4 className="mt-3">Add Money</h4>
                  <div class="change-amount-wrapper">
                    <div class="amount-box">
                      <div>
                        <h5>{`$${amount}`}</h5>
                      </div>
                      <div>
                        <a
                          onClick={changeAmountToAdd}
                          style={{ cursor: "pointer" }}
                        >
                          Change Amount
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="deposit-amount-wrapper">
                    <h5>Price Breakup</h5>
                    <div class="deposit-amount-box">
                      <h5>Deposit Amount</h5>
                      <h6>${amount}</h6>
                    </div>
                    <div class="deposit-amount-box">
                      <h5>Fees (2.9% + $0.30)</h5>
                      <h6>${(fees - amount).toFixed(2)}</h6>
                    </div>
                    <div class="deposit-amount-box">
                      <h5>Total</h5>
                      <h6>${fees}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showWalletModal}
        onHide={() => hideWalletMod()}
        backdrop="static"
      >
        <AddToWallet
          oldSelectedAmount={amount}
          oldwalletBalance={walletBalance}
          closeModal={() => hideWalletMod()}
        />
      </Modal>
      {succesMessage ? (
        <>
          <Modal
            show={showSuccessModal}
            onHide={() => closeSuccessModal()}
            backdrop="static"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="money-added">
              <div className="tick-image">
                <img src="/assets/images/tick.svg" alt="" />
              </div>
              <h3>${amount} Added to Wallet Successfully</h3>
              <p>Total Balance</p>
              <h4>${walletBalanceNew}</h4>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </>
  );
};
const StripeForm = (props) => {
  const [publicKey, setPublicKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [walletBalance, setWalletBalance] = useState(null);
  const getPublicKey = async (_) => {
    try {
      const res = await getPublicKeyStripe();
      setPublicKey(res.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getWalletBalancePlayer = async (_) => {
    try {
      const res = await getWalletBalance();
      setWalletBalance(res.wallet_balance);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPublicKey();
    getWalletBalancePlayer();
  }, []);
  return loading || !publicKey ? (
    <Spinner animation="grow" />
  ) : (
    <Elements stripe={loadStripe(publicKey)}>
      <PaymentForm data={props} walletBalance={walletBalance} />
    </Elements>
  );
};
AddToWallet.propTypes = {
  closeModal: PropTypes.func,
};
export default withRouter(StripeForm);

import React from "react";
import { Button, Modal } from "react-bootstrap";
const ConfirmModalToAddDuplicateMember = ({
  duplicateMemberFound,
  handleHideDuplicateMemberFound,
  message,
}) => {
  return (
    <>
      <Modal
        show={duplicateMemberFound}
        onHide={handleHideDuplicateMemberFound}
        backdrop="static"
        className=" confirm-rank"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="money-added">
          <div class="warning-icon">
            <img src="/assets/images/warning.svg" alt="warning" />
          </div>
          <h3 className="challengeCreated">Tournament</h3>

          <p className="challengeBetween">
            {/* Some members in this team already exist in one of the already
            enrolled team. */}
            {message}
          </p>
        </Modal.Body>

        <Modal.Footer className="delete-footer">
          <Button
            className="cancel-btn"
            onClick={handleHideDuplicateMemberFound}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModalToAddDuplicateMember;

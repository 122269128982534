import React, { useState } from "react";
import { Link } from "react-router-dom";
import CountDown from "../Lobby/CountDown";
import { Spinner } from "react-bootstrap";
import { getCardBackgroundImage } from "../../helper/utilities";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
import LobbyCardHeader from "./LobbyCardHeader";
import WinnerEntryBox from "../WinnerEntryBox";
const ActiveLobby = (activeLobby) => {
  const [isTimerReached, setTimerReached] = useState(false);
  const timerEnded = (isComplete) => {
    setTimerReached(isComplete);
  };

  const renderAvailableLobbies = () => {
    return activeLobby.activeLobbyInfo?.activeLobby?.length === 0 ? (
      <h5
        style={{
          textTransform: "uppercase",
        }}
      >
        No active lobby{" "}
      </h5>
    ) : (
      activeLobby.activeLobbyInfo.activeLobby.map((data, i) => (
        <div className="col" key={data.id}>
          <div
            className={`${getCardBackgroundImage(
              data.game.slug,
              "active",
              data.is_verified
            )} enrolled-card`}
          >
            <LobbyCardHeader data={data} />
            <div className="card-img-overlay">
              <h3>{data.name}</h3>
              <div className="card-wrapper">
                <div className="dashboard-game-wrapper available-lobbies-wrapper">
                  <div className="entry-box">
                    <div className="entry">
                      Entry<span>:</span>
                    </div>
                    <div className="dollar-box dollar-wrapper blue-color">
                      <span>
                        {" "}
                        ${data.entry_fee} {data.free_lobby ? "Free Entry" : ""}{" "}
                      </span>
                    </div>
                  </div>
                  <WinnerEntryBox data={data} responsiveClassName={"desktop"} />
                  <div className="entry-box">
                    <div className="enrolled-box">
                      {data.mode == 1 ? "Enrolled Players" : "Enrolled Teams "}
                      <span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data.mode == 1
                        ? data?.enrolled_players || "-"
                        : data.enrolled_teams || "-"}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Min Players<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data.min_players}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Max Players<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data.max_players}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Status<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {" "}
                      <LobbyStatus data={data} />
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Map<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      <span className="activeMap">{data.gamemap || "N/A"}</span>
                    </div>
                  </div>
                </div>
                <div className="dashboard-game-wrapper available-lobbies-wrapper">
                  <WinnerEntryBox data={data} responsiveClassName={"phone"} />
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Date<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {new Date(data.countdown_time).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric", // numeric, 2-digit
                          year: "numeric", // numeric, 2-digit
                          month: "short", // numeric, 2-digit, long, short, narrow
                        }
                      )}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Start Time<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {" "}
                      {data.start_time}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Lobby Mode<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data.modetag}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Enrolled<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data?.is_enrolled ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="entry-box">
                    <div className="enrolled-box">
                      Region<span>:</span>
                    </div>
                    <div className="count-box dollar-wrapper">
                      {data.region || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="start-time-wrapper">
                {!isTimerReached ? (
                  <div className="starts-in-box">
                    <h2 className="start-timer2">
                      <CountDown
                        server_current_datetime={data.countdown_time}
                        time_reached={(e) => {
                          timerEnded(e);
                        }}
                      />
                    </h2>
                  </div>
                ) : null}
                <div className="enrolled-box">
                  <Link
                    to={{
                      pathname: data.game.slug + "/lobby/" + data.id,
                      data: window.location.pathname,
                    }}
                    className="btn btn-outline-primary"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      <section className="dashboard active-lobby-dashboard">
        <div className="container available-lobby-container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-end dash-available-lobbies">
                <p className="vip-lobbies">VIP Lobbies</p>
                <p className="user-lobbies">User Enrolled Lobbies</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2">
                {activeLobby.activeLobbyInfo.spinnerLoadder ? (
                  <Spinner animation="grow" />
                ) : (
                  renderAvailableLobbies()
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ActiveLobby;

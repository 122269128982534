import React from "react";
import ReactTooltip from "react-tooltip";
import { ICONS_TEXT } from "../../helper/stringConstants";

function PlayerOfWeek({ players }) {
  const getPlayerProfileImg = (player) => {
    return player === undefined ||
      player?.profile === "null" ||
      player?.profile === null
      ? "/assets/images/community-ryvals.png"
      : player?.profile;
  };
  return (
    <section class="player-of-the-week">
      <div class="container">
        <div class="row justify-content-center">
          <h2>Player of the week</h2>
          <div class="row align-items-end position-row asdf">
            {players.length === 0 ? (
              <h2 style={{ textAlign: "center", padding: "20px" }}>
                No stats for this week yet
              </h2>
            ) : (
              <>
                <div class="col-sm-12 col-md-12 col-lg-4 d-lg-none margin-bottom">
                  <div class="card mobile-card first-position-card">
                    <div class="position-wrapper">
                      <div class="position-number first">1</div>
                    </div>
                    <div class="image-wrapper first-image">
                      <img
                        src={getPlayerProfileImg(players[0])}
                        class="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div class="card-body d-none">
                      <p class="username-mb">-</p>
                    </div>
                    <div class="card-body card-body-mobile">
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/username.svg"
                          className="communityIcons"
                          alt="username"
                          data-tip
                          data-for="iconsUsernameTip"
                        />
                        <ReactTooltip
                          id="iconsUsernameTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.username}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined
                            ? "NA"
                            : players[0].username}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/wining.svg"
                          className="communityIcons"
                          alt="winning"
                          data-tip
                          data-for="iconsWinningTip"
                        />
                        <ReactTooltip
                          id="iconsWinningTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.winnings}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined
                            ? "NA"
                            : ` $${players[0].winnings}`}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/kill.svg"
                          className="communityIcons"
                          alt="kill"
                          data-tip
                          data-for="iconskillTip"
                        />
                        <ReactTooltip
                          id="iconskillTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.kills}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined ? "NA" : players[0].kills}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 margin-bottom">
                  <div class="card mobile-card">
                    <div class="position-wrapper">
                      <div class="position-number second">2</div>
                    </div>
                    <div class="image-wrapper second-position">
                      <img
                        src={getPlayerProfileImg(players[1])}
                        class="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div class="card-body d-none">
                      <p class="username-mb">-</p>
                    </div>
                    <div class="card-body card-body-mobile">
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/username.svg"
                          className="communityIcons"
                          alt="username"
                          data-tip
                          data-for="iconsUsernameTip"
                        />
                        <ReactTooltip
                          id="iconsUsernameTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.username}
                        </ReactTooltip>{" "}
                        <p>
                          {players[1] === undefined
                            ? "NA"
                            : players[1].username}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/wining.svg"
                          className="communityIcons"
                          alt="winning"
                          data-tip
                          data-for="iconsWinningTip"
                        />
                        <ReactTooltip
                          id="iconsWinningTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.winnings}
                        </ReactTooltip>{" "}
                        <p>
                          {players[1] === undefined
                            ? "NA"
                            : ` $${players[1].winnings}`}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/kill.svg"
                          className="communityIcons"
                          alt="kill"
                          data-tip
                          data-for="iconskillTip"
                        />
                        <ReactTooltip
                          id="iconskillTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.kills}
                        </ReactTooltip>{" "}
                        <p>
                          {players[1] === undefined ? "NA" : players[1].kills}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block">
                  <div class="card mobile-card">
                    <div class="position-wrapper">
                      <div class="position-number first">1</div>
                    </div>
                    <div class="image-wrapper first-image">
                      <img
                        src={getPlayerProfileImg(players[0])}
                        class="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div class="card-body d-none">
                      <p class="username-mb">-</p>
                    </div>
                    <div class="card-body card-body-mobile">
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/username.svg"
                          className="communityIcons"
                          alt="username"
                          data-tip
                          data-for="iconsUsernameTip"
                        />
                        <ReactTooltip
                          id="iconsUsernameTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.username}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined
                            ? "NA"
                            : players[0].username}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/wining.svg"
                          className="communityIcons"
                          alt="winning"
                          data-tip
                          data-for="iconsWinningTip"
                        />
                        <ReactTooltip
                          id="iconsWinningTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.winnings}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined
                            ? "NA"
                            : ` $${players[0].winnings}`}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/kill.svg"
                          className="communityIcons"
                          alt="kill"
                          data-tip
                          data-for="iconskillTip"
                        />
                        <ReactTooltip
                          id="iconskillTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.kills}
                        </ReactTooltip>{" "}
                        <p>
                          {players[0] === undefined ? "NA" : players[0].kills}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 margin-bottom">
                  <div class="card mobile-card">
                    <div class="position-wrapper">
                      <div class="position-number third">3</div>
                    </div>
                    <div class="image-wrapper second-position">
                      <img
                        src={getPlayerProfileImg(players[2])}
                        class="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div class="card-body d-none">
                      <p class="username-mb">-</p>
                    </div>
                    <div class="card-body card-body-mobile">
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/username.svg"
                          className="communityIcons"
                          alt="username"
                          data-tip
                          data-for="iconsUsernameTip"
                        />
                        <ReactTooltip
                          id="iconsUsernameTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.username}
                        </ReactTooltip>{" "}
                        <p>
                          {players[2] === undefined
                            ? "NA"
                            : players[2].username}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/wining.svg"
                          className="communityIcons"
                          alt="winning"
                          data-tip
                          data-for="iconsWinningTip"
                        />
                        <ReactTooltip
                          id="iconsWinningTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.winnings}
                        </ReactTooltip>{" "}
                        <p>
                          {players[2] === undefined
                            ? "NA"
                            : ` $${players[2].winnings}`}
                        </p>
                      </div>
                      <div class="details-wrapper">
                        <img
                          src="/assets/images/kill.svg"
                          className="communityIcons"
                          alt="kill"
                          data-tip
                          data-for="iconskillTip"
                        />
                        <ReactTooltip
                          id="iconskillTip"
                          place="bottom"
                          effect="solid"
                        >
                          {ICONS_TEXT.kills}
                        </ReactTooltip>{" "}
                        <p>
                          {players[2] === undefined ? "NA" : players[2].kills}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 d-none">
                  <div class="card fourth-position-card">
                    <div class="position-wrapper fourth d-none d-sm-none d-md-none d-lg-block">
                      <div class="position-number">1</div>
                    </div>

                    <div class="card-body">
                      <div class="fourth-position">
                        <div class="position-mobile one">
                          <p>1</p>
                        </div>
                        <div class="image-box image-wrapper">
                          <img
                            src={getPlayerProfileImg(players[0])}
                            alt="logo"
                          />
                        </div>
                        <div class="details-box">
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/username.svg"
                              className="communityIcons"
                              alt="username"
                              data-tip
                              data-for="iconsUsernameTip"
                            />
                            <ReactTooltip
                              id="iconsUsernameTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.username}
                            </ReactTooltip>{" "}
                            <p>
                              {players[0] === undefined
                                ? "NA"
                                : players[0].username}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/wining.svg"
                              className="communityIcons"
                              alt="winning"
                              data-tip
                              data-for="iconsWinningTip"
                            />
                            <ReactTooltip
                              id="iconsWinningTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.winnings}
                            </ReactTooltip>{" "}
                            <p>
                              {players[0] === undefined
                                ? "NA"
                                : ` $${players[0].winnings}`}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/kill.svg"
                              className="communityIcons"
                              alt="kill"
                              data-tip
                              data-for="iconskillTip"
                            />
                            <ReactTooltip
                              id="iconskillTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.kills}
                            </ReactTooltip>{" "}
                            <p>
                              {players[0] === undefined
                                ? "NA"
                                : players[0].kills}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 d-none">
                  <div class="card fourth-position-card">
                    <div class="position-wrapper fourth d-none d-sm-none d-md-none d-lg-block">
                      <div class="position-number">2</div>
                    </div>

                    <div class="card-body">
                      <div class="fourth-position">
                        <div class="position-mobile second">
                          <p>2</p>
                        </div>
                        <div class="image-box image-wrapper">
                          <img
                            src={getPlayerProfileImg(players[1])}
                            alt="ryvals"
                          />
                        </div>
                        <div class="details-box">
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/username.svg"
                              className="communityIcons"
                              alt="username"
                              data-tip
                              data-for="iconsUsernameTip"
                            />
                            <ReactTooltip
                              id="iconsUsernameTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.username}
                            </ReactTooltip>{" "}
                            <p>
                              {players[1] === undefined
                                ? "NA"
                                : players[1].username}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/wining.svg"
                              className="communityIcons"
                              alt="winning"
                              data-tip
                              data-for="iconsWinningTip"
                            />
                            <ReactTooltip
                              id="iconsWinningTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.winnings}
                            </ReactTooltip>{" "}
                            <p>
                              {players[1] === undefined
                                ? "NA"
                                : ` $${players[1].winnings}`}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/kill.svg"
                              className="communityIcons"
                              alt="kill"
                              data-tip
                              data-for="iconskillTip"
                            />
                            <ReactTooltip
                              id="iconskillTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.kills}
                            </ReactTooltip>{" "}
                            <p>
                              {players[1] === undefined
                                ? "NA"
                                : players[1].kills}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 d-none">
                  <div class="card fourth-position-card">
                    <div class="position-wrapper fourth d-none d-sm-none d-md-none d-lg-block">
                      <div class="position-number">3</div>
                    </div>

                    <div class="card-body">
                      <div class="fourth-position">
                        <div class="position-mobile three">
                          <p>3</p>
                        </div>
                        <div class="image-box image-wrapper">
                          <img
                            src={getPlayerProfileImg(players[2])}
                            alt="logo"
                          />
                        </div>
                        <div class="details-box">
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/username.svg"
                              className="communityIcons"
                              alt="username"
                              data-tip
                              data-for="iconsUsernameTip"
                            />
                            <ReactTooltip
                              id="iconsUsernameTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.username}
                            </ReactTooltip>{" "}
                            <p>
                              {players[2] === undefined
                                ? "NA"
                                : players[2].username}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/wining.svg"
                              className="communityIcons"
                              alt="winning"
                              data-tip
                              data-for="iconsWinningTip"
                            />
                            <ReactTooltip
                              id="iconsWinningTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.winnings}
                            </ReactTooltip>{" "}
                            <p>
                              {players[2] === undefined
                                ? "NA"
                                : ` $${players[2].winnings}`}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/kill.svg"
                              className="communityIcons"
                              alt="kill"
                              data-tip
                              data-for="iconskillTip"
                            />
                            <ReactTooltip
                              id="iconskillTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.kills}
                            </ReactTooltip>{" "}
                            <p>
                              {players[2] === undefined
                                ? "NA"
                                : players[2].kills}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="card fourth-position-card">
                    <div class="position-wrapper fourth">
                      <div class="position-number">4</div>
                    </div>

                    <div class="card-body">
                      <div class="fourth-position">
                        <div class="position-mobile fourth-number d-none">
                          <p>4</p>
                        </div>
                        <div class="image-box image-wrapper">
                          <img
                            src={getPlayerProfileImg(players[3])}
                            alt="ryvals"
                          />
                        </div>
                        <div class="details-box">
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/username.svg"
                              className="communityIcons"
                              alt="username"
                              data-tip
                              data-for="iconsUsernameTip"
                            />
                            <ReactTooltip
                              id="iconsUsernameTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.username}
                            </ReactTooltip>{" "}
                            <p>
                              {players[3] === undefined
                                ? "NA"
                                : players[3].username}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/wining.svg"
                              className="communityIcons"
                              alt="winning"
                              data-tip
                              data-for="iconsWinningTip"
                            />
                            <ReactTooltip
                              id="iconsWinningTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.winnings}
                            </ReactTooltip>{" "}
                            <p>
                              {players[3] === undefined
                                ? "NA"
                                : ` $${players[3].winnings}`}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/kill.svg"
                              className="communityIcons"
                              alt="kill"
                              data-tip
                              data-for="iconskillTip"
                            />
                            <ReactTooltip
                              id="iconskillTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.kills}
                            </ReactTooltip>{" "}
                            <p>
                              {players[3] === undefined
                                ? "NA"
                                : players[3].kills}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="card fourth-position-card">
                    <div class="position-wrapper fourth">
                      <div class="position-number">5</div>
                    </div>
                    <div class="card-body">
                      <div class="fourth-position">
                        <div class="position-mobile fourth-number d-none">
                          <p>5</p>
                        </div>
                        <div class="image-box image-wrapper">
                          <img
                            src={getPlayerProfileImg(players[5])}
                            alt="logo"
                          />
                        </div>
                        <div class="details-box">
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/username.svg"
                              className="communityIcons"
                              alt="username"
                              data-tip
                              data-for="iconsUsernameTip"
                            />
                            <ReactTooltip
                              id="iconsUsernameTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.username}
                            </ReactTooltip>{" "}
                            <p>
                              {players[4] === undefined
                                ? "NA"
                                : players[4].username}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/wining.svg"
                              className="communityIcons"
                              alt="winning"
                              data-tip
                              data-for="iconsWinningTip"
                            />
                            <ReactTooltip
                              id="iconsWinningTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.winnings}
                            </ReactTooltip>{" "}
                            <p>
                              {players[4] === undefined
                                ? "NA"
                                : ` $${players[4].winnings}`}
                            </p>
                          </div>
                          <div class="details-wrapper">
                            <img
                              src="/assets/images/kill.svg"
                              className="communityIcons"
                              alt="kill"
                              data-tip
                              data-for="iconskillTip"
                            />
                            <ReactTooltip
                              id="iconskillTip"
                              place="bottom"
                              effect="solid"
                            >
                              {ICONS_TEXT.kills}
                            </ReactTooltip>{" "}
                            <p>
                              {players[4] === undefined
                                ? "NA"
                                : players[4].kills}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayerOfWeek;

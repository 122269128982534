export const STRINGS_CONSTANTS = {
  signup: "Sign Up",
  signin: "Sign in",
  signinContinue: "Please sign in to continue",
  signWith: "Sign in with",
  signUpWith: "Sign up with",
  thankYou: "Thank You",
  successfull: "Successfull",
  linkExpired:
    "Link is expired, please request again for account verification.",
  requestVerification: "Request Verification",
  accountVerificationMail:
    "Account verification mail has been sent. Please check your inbox.",
  forgotUsername: "Forgot Username",
  forgotPassword: "Forgot Password",
  requestUsername:
    "Enter your email address below to get an email with your username.",
  requestPassword:
    "Enter your email below to receive an email with your username and a link to reset your password",
  sendEmail: "Send Email",
  incompleteSignUpForm:
    "Please fill the registration form below to finish signing up ",
  cookieMessage: "Please enable the third party cookies in your browser.",
  resendLink: "Resend Link",
  legalAgeWarning: "Legal age restriction",
  certifyMinAge: "I certify that I am at least 18 years of age.",
};

export const VALIDATIONS_ERROR = {
  firstNameRequired: "First Name is required",
  firstNameMin: (value) =>
    `First Name requires a minimum of ${value} characters`,
  firstNameMax: (value) =>
    `First Name couldn't have more than ${value} characters`,
  lastNameRequired: "Last Name is required",
  lastNameMin: (value) => `Last Name requires a minimum of ${value} characters`,
  lastNameMax: (value) =>
    `Last Name couldn't have more than ${value} characters`,
  invalidEmail: "Email is invalid",
  requiredEmail: "Email is required",
  requiredPassword: "Password is required",
  userNameRequired: "Username is required",
  userNamePattern:
    "Username must be 4-16 alphanumeric characters and can have ._- special characters. Username may not start with a special character.Username must not contain spaces.",
  passwordRequired: "Password is required",
  passwordPattern:
    "Password allows 8-15 alphanumeric characters with at least 1 digit and 1 special character [@$!%*#?&^_-]",
  confirmPassword: "Please enter confirm password",
  confirmPasswordMatch: "Password and confirm password does not match",
  minUserAge: "Only users who are 18 or older can register ",
  DOBRequired: "Date of birth is required",
  currentPassword:"Current Password is required"
};

export const PLACEHOLDERS = {
  emailAddress: "Email Address",
  emailOrUsername: "Email/Username",
  DOB: "Date of Birth",
};

export const GAMES_CONSTANTS = {
  cod: "CALL OF DUTY",
  pubg: "PUBG BATTLEGROUNDS",
};

export const SOCIAL_ACCOUNTS = {
  discord: "Discord",
  facebook: "Facebook",
  google: "Google",
  twitter: "Twitter",
  twitch: "Twitch",
};

export const ERROR_MESSAGES = {
  bannedLocation: "Your location is banned by Ryvals",
};

export const CARD_BACKGROUNDS_PATHS = {
  "apex-legends": {
    active: "available-lobby-card-apex",
    previousPlayed: "previous-played-lobbies-card apex",
  },
  pubg: {
    active: "available-lobby-card-pubg",
    previousPlayed: "previous-played-lobbies-card pubg",
  },
  fortnite: {
    active: "available-lobby-card-fortnite",
    previousPlayed: "previous-played-lobbies-card fortnite",
  },
  valorant: {
    active: "available-lobby-card-valorant",
    previousPlayed: "previous-played-lobbies-card valorant",
  },
  "call-of-duty-warzone": {
    active: "available-lobby-card-warzone",
    previousPlayed: "previous-played-lobbies-card warzone",
  },
  "pubg-mobile": {
    active: "available-lobby-card-pubg-mobile",
    previousPlayed: "previous-played-lobbies-card pubg-mobile",
  },
  "cod-mobile": {
    active: "available-lobby-card-cod-mobile",
    previousPlayed: "previous-played-lobbies-card cod-mobile",
  },
};
export const ICONS_TEXT = {
  username: "Username",
  winnings: "Winnings",
  kills: "Kills",
};

export const getRemainingBalance = (
  isFree,
  walletBalance,
  entryFeeSubtract
) => {
  const moneyInWallet = walletBalance.wallet < 0 ? 0 : walletBalance.wallet;
  const moneyInPromoWallet = walletBalance.promo < 0 ? 0 : walletBalance.promo;
  const sumOfBothWallet = moneyInWallet + moneyInPromoWallet;
  if (isFree) {
    return `${sumOfBothWallet?.toFixed(2)}`;
  } else {
    return `${(sumOfBothWallet - entryFeeSubtract)?.toFixed(2)}`;
  }
  // return isFree
  //   ? `${(sumOfBothWallet)?.toFixed(2)}`
  //   : // walletBalance.promo_choice
  //     // ? walletBalance.promo < entryFeeSubtract
  //     //   ? `${(
  //     //       walletBalance.promo +
  //     //       walletBalance.wallet -
  //     //       entryFeeSubtract
  //     //     )?.toFixed(2)}`
  //     //   : `${(walletBalance.promo - entryFeeSubtract)?.toFixed(2)}`
  //     // :
  //     `${(
  //       walletBalance.wallet +
  //       walletBalance.promo -
  //       entryFeeSubtract
  //     )?.toFixed(2)}`;
};

export const getTournamentModeTag = (modeTagObj, mode) => {
  return modeTagObj
    ?.filter((modeval, id) => modeval?.value === mode)
    .map((obj) => obj.modetag);
};
export const singleElimination = [
  { value: "brackets", label: "Brackets" },
  // { value: "standings", label: "Standings" },
];
export const doubleElimination = [
  { value: "upper", label: "Winners" },
  { value: "lower", label: "Losers" },
  { value: "final", label: "Championship" },
  // { value: "standings", label: "Standings" },
];
export const roundRobin = [
  { value: "groups", label: "Groups" },
  { value: "matches", label: "Matches" },
];
export const dateTimeFormatterForTournamentMatchinfo = (format) => {
  const [dateString, timeString, timeZoneString] = format.split(" ");
  const [year, month, day] = dateString.split("-");
  const date = new Date(year, month - 1, day);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return `${formattedDate} at ${timeString} ${timeZoneString}`;
};

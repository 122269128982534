import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ActiveLobby from "./ActiveLobby";
import AvailableLobby from "./AvailableLobby";
import PreviousLobby from "./PreviousLobby";
import { Link } from "react-router-dom";
import {
  getAvailableLobbies,
  getActiveLobbies,
  getPreviousLobbies,
} from "../../services/lobbies.service";
import { ConsoleHelper, logoutEventPrivateRoutes } from "../../helper/utilities";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};
const Dashboard = () => {
  const [key, setKey] = useState("available-lobbies");
  const [availableLobby, setAvailableLobby] = useState([]);
  const [activeLobby, setActiveLobby] = useState([]);
  const [previousLobby, setPreviousLobby] = useState([]);

  const [spinnerLoadder, setSpinnerLoader] = useState(false);
  const [spinnerLoadder1, setSpinnerLoader1] = useState(false);
  const [spinnerLoadder2, setSpinnerLoader2] = useState(false);
  useEffect(() => {
    if (key == "available-lobbies") {
      setSpinnerLoader1(true);
      getAvailableLobbies()
        .then((res) => {
          setAvailableLobby(res);
          setSpinnerLoader1(false);
        })
        .catch((err) => {
          ConsoleHelper(err)
        });
    } else if (key == "active-lobby") {
      setSpinnerLoader(true);
      getActiveLobbies()
        .then((res) => {
          setActiveLobby(res);
          setSpinnerLoader(false);
        })
        .catch((err) => {
          ConsoleHelper(err)
        });
    } else {
      setSpinnerLoader2(true);
      getPreviousLobbies()
        .then((res) => {
          const filtered = res.filter((test) => test.current_status === 3);

          setPreviousLobby(filtered);

          setSpinnerLoader2(false);
        })
        .catch((err) => {
          ConsoleHelper(err)
        });
    }
    logoutEventPrivateRoutes();
  }, [key]);
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax profile-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">
                      Dashboard
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      <section className="dashboard">
        <div className="container dash-cont">
          <div className="row">
            <div className="col">
              <div className="back-arrow-button">
                <img src="/assets/images/back-arrow.svg" alt="back-arrow" />
                <Link to={"/games"} className="back-text">
                  Back
                </Link>
              </div>

              <Tabs
                id="uncontrolled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="active-lobby" title="ACTIVE LOBBIES">
                  <ActiveLobby
                    activeLobbyInfo={{ activeLobby, spinnerLoadder }}
                  />
                </Tab>
                <Tab eventKey="available-lobbies" title="AVAILABLE LOBBIES">
                  <AvailableLobby
                    availableLobbyInfo={{ availableLobby, spinnerLoadder1 }}
                  />
                </Tab>
                <Tab
                  eventKey="prevoius-played-lobbies"
                  title="PREVIOUS PLAYED LOBBIES"
                >
                  <PreviousLobby
                    previousLobbyInfo={{ previousLobby, spinnerLoadder2 }}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Dashboard;

import * as React from "react";

const NotificationsContext = React.createContext();

const initialState = {
  fetchNotifications: false,
  unreadCount: 0,
  notificationsData: [],
};

function countReducer(state = initialState, { type, payload }={}) {
  switch (type) {
    case "REFETCH": {
      return { ...state, fetchNotifications: payload };
    }
    case "UPDATE_UNREAD_COUNT": {
      return { ...state, unreadCount: payload };
    }
    case "UPDATE_NOTIFICATIONS_DATA":{
      return {...state,notificationsData:payload}
    }
    case "SOCKET_NOTIFICATIONS_DATA":{
      const temp = state?.notificationsData?.length ?  [...state?.notificationsData] :[]
      temp.unshift(payload)
      return {...state,unreadCount:state.unreadCount +1, notificationsData:temp}
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function NotificationsProvider({ children }) {
  const [state, dispatch] = React.useReducer(countReducer, initialState);
  const value = { state, dispatch };
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error("useNotifications must be used within a CountProvider");
  }
  return context;
}

export { NotificationsProvider, useNotifications };

import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const KycVerification = (props) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>ID Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body className="qr-verification-body">
        <p>
          In order to {props.subheader} player needs to verify identification
        </p>
        <h4>Get yourself verified</h4>
        <h5>
          <a
            href={process.env.REACT_APP_ID_MISSION_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {process.env.REACT_APP_ID_MISSION_URL}
          </a>
        </h5>
        <p>or</p>
        <h6>Scan QR code:</h6>
        <div className="qr-code">
          <img
            src={process.env.REACT_APP_ID_MISSION_QR}
            alt="qr"
            width={200}
          />
        </div>
      </Modal.Body>
    </>
  );
};

KycVerification.propTypes = {
  subheader: PropTypes.string,
};

export default KycVerification;

import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

function Clips({community,playing,startPlaying}) {
  return community?.length === 0 ? (
    <h5
      className="no-kill-streaks"
      style={{
        textTransform: "uppercase",
      }}
    >
      No Clips Added
    </h5>
  ) : (
    community?.map((data, idx) => {
      return (
        <div class="col" key={data.id}>
          <div class="card">
            <div class="video-wrapper">
              <ReactPlayer
                className="video-js"
                url={data.youtube_link}
                controls // gives the front end video controls
                width="100%"
                height="200px"
                light={true}
                playing={playing}
                playIcon={
                  <div className="play-button">
                    <img
                      src="/assets/images/play-button.svg"
                      alt="play"
                      onClick={startPlaying}
                    />
                  </div>
                }
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload", //<- this is the important bit
                    },
                  },
                }}
              />
            </div>
            <div class="card-body">
              <div class="video-details-wrapper">
                <p>{data.username}</p>
                <div>
                  {data.twitter === "" ? null : (
                    <Link
                      to={{
                        pathname: data.twitter,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/images/community-twitter.svg"
                        alt="twitter"
                        className="first-image"
                      />
                    </Link>
                  )}

                  {data.twitch === "" ? null : (
                    <Link
                      to={{
                        pathname: data.twitch,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/images/hangouts.svg"
                        alt="twitch"
                        className="first-image"
                      />
                    </Link>
                  )}
                  {data.youtube === "" ? null : (
                    <Link
                      to={{
                        pathname: data.youtube,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/images/community-youtube.svg"
                        alt="youtube"
                        className="last-image"
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  );
}

export default Clips;

import React, { useState, useEffect, useRef } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  enrollForGame,
  getWalletBalance,
  getUserDetail,
  enrollTeamForGame,
  verifyLobbyPassword,
} from "../../services/user.service";
import { useSelector } from "react-redux";
import AddToWallet from "../payment/AddToWallet";
import { withRouter } from "react-router";
import GuLagInfo from "./GuLagInfo";
import Form from "react-bootstrap/Form";
import publicIp from "public-ip";
import EnrollTeam from "./EnrollTeam";
import { postFriendRequest } from "../../services/teams.service";
import {
  ConsoleHelper,
  enrollEventListener,
  gamerTagInfo,
  redirectToPrevPath,
} from "../../helper/utilities";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import toastify from "../../helper/toastify";
const EnrollButton = (props) => {
  const loggedUser = useSelector((state) => state);
  const [verified, setVerified] = useState(false);
  const [player_ip, setIP] = useState("");
  const [user, setUser] = useState({});
  const [selectedGame, setSelectedGame] = useState(null);
  const [password, setPassWord] = useState("");
  const [isCheckedGamerTag, setIsCheckedGamerTag] = useState(false);
  const [gamertag, setGamerTag] = useState(null);
  const passwordRef = useRef(null);
  const handleOnChange = () => {
    setIsCheckedGamerTag(!isCheckedGamerTag);
  };

  useEffect(() => {
    setSelectedGame(props?.games?.find((item) => item.id == props?.game?.id));
  }, [props?.games]);

  useEffect(() => {
    const userData =
      JSON.parse(localStorage.getItem("userProfile")) !== null
        ? JSON.parse(localStorage.getItem("userProfile"))
        : null;
    if (loggedUser?.user) {
      localStorage.removeItem("previousPath");
      setUser(userData);
    }
    if (userData !== null) {
      setVerified(userData.profile.is_verified);
    } else {
      setVerified(false);
    }
    enrollEventListener();
  }, []);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  const getIp = async () => {
    try {
      const res = await publicIp.v4();
      setIP(res);
    } catch (error) {
      toastify(
        "error",
        error.message +
          " ,Please try again after disabling your adblocker if enabled"
      );
    }
  };
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [spinnerLoaderSingle, setSpinnerLoaderSingle] = useState(false);
  const [openEnrollModal, setopenEnrollModal] = useState(false);
  const [openProtectedModal, setopenProtectedModal] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const [openWalletModal, setopenWalletModal] = useState(false);
  const [openRespawnModal, setopenRespawnModal] = useState(false);

  const [isInsufficientBalance, setisInsufficientBalance] = useState(false);

  const [openEnrollSuccessModal, setopenEnrollSuccessModal] = useState(false);
  const [walletBalance, setwalletBalance] = useState(null);
  const [promo_choice, setCheckPromo] = useState(false);
  const [errorMsg, seterrorMsg] = useState(null);
  const enrollTeamPayloadRef = useRef(null);
  const checkBalance = () => {
    setisInsufficientBalance(false);
    setCheckPromo(false);
    getWalletBalance().then((res) => {
      if (props.is_password_protected) {
        setopenProtectedModal(true);
      } else {
        setopenEnrollModal(true);
      }
      setwalletBalance(res.wallet_balance);
      if (+res.wallet_balance < props.entry_fee) {
        setisInsufficientBalance(true);
      }
    });
  };

  const profilePhone =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).phone;
  const profileAddress =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).address.full;
  const promoBonus =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))?.profile?.bonus_balance
      : "";
  const checkForRemainingFreeLobby =
    props.free_lobby &&
    (props.userDataForFreeLimitCount == 0 ||
      props.userDataForFreeLimitCount?.length === 0);
  const openEnrollModalStatus = () => {
    getIp();
    seterrorMsg(null);
    if (!loggedUser.user) {
      props.history.push(redirectToPrevPath(props.history, props.location));
      return;
    }
    checkBalance();
  };
  const closeEnrollModalStatus = () => {
    setopenEnrollModal(false);
  };

  const openEnrollSuccessModalStatus = () => {
    setopenEnrollSuccessModal(true);
  };
  const closeEnrollSuccessModalStatus = () => {
    setopenEnrollSuccessModal(false);
    if (props.location.pathname !== `/${props.game.slug}/lobby/${props.id}`) {
      props.callLobbies();
    }
    if (props.location.pathname == `/${props.game.slug}/lobby`) {
      props.callLobbies();
    }
    getUserDetail().then((res) => {
      setUser(res);
    });
  };

  const openWalletModalStatus = () => {
    closeEnrollModalStatus();
    setopenWalletModal(true);
  };
  const closeWalletModalStatus = () => {
    setopenWalletModal(false);
  };

  async function handlePassword(e) {
    try {
      setisLoading(true);
      const res = await verifyLobbyPassword({
        lobby_password: passwordRef.current.value?.trim(),
        lobby_id: props.id,
      });
      if (res) {
        toastify("success", res.Message);
        setopenProtectedModal(false);
        setopenEnrollModal(true);
      }
    } catch (error) {
      ConsoleHelper({ error });
    } finally {
      setisLoading(false);
    }
  }

  async function enroll() {
    setisLoading(true);
    setSpinnerLoaderSingle(true);
    setopenRespawnModal(false);
    await enrollForGame(props.id, player_ip, promo_choice)
      .then((res) => {
        if (res !== undefined) {
          setisLoading(false);
          closeEnrollModalStatus();
          openEnrollSuccessModalStatus();
          setSpinnerLoaderSingle(false);
          if (props.match.url === "/dashboard") {
            window.location.reload();
          }
          if (props.onEnrollComplete) {
            props.onEnrollComplete();
          }
          localStorage.setItem(
            "enroll-event",
            "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
          );
        } else {
          setisLoading(false);
          setSpinnerLoaderSingle(false);
        }
      })
      .catch((err) => {
        seterrorMsg(err);
        setisLoading(false);
        setSpinnerLoaderSingle(false);
      });
  }

  function enrollTeam(is_paying_full, team_name, team_members, teamSuggestId) {
    enrollTeamPayloadRef.current = {
      is_paying_full,
      team_name,
      team_members,
      teamSuggestId,
    };
    setopenRespawnModal(true);
    closeEnrollModalStatus();
  }

  async function enrollTeamAPI() {
    setisLoading(true);
    setSpinnerLoader(true);
    setopenRespawnModal(false);
    closeEnrollModalStatus();

    await enrollTeamForGame(
      props.id,
      player_ip,
      promo_choice,
      enrollTeamPayloadRef.current.is_paying_full,
      enrollTeamPayloadRef.current.team_name,
      props?.mode,
      enrollTeamPayloadRef.current.teamSuggestId
    )
      .then((res) => {
        if (res !== undefined) {
          setisLoading(false);
          closeEnrollModalStatus();
          openEnrollSuccessModalStatus();
          setSpinnerLoader(false);
          if (props.match.url === "/dashboard") {
            window.location.reload();
          }
          if (props.onEnrollComplete) {
            props.onEnrollComplete();
          }
          localStorage.setItem(
            "enroll-event",
            "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
          );
        } else {
          setisLoading(false);
          setSpinnerLoader(false);
        }
      })
      .catch((err) => {
        seterrorMsg(err);
        setisLoading(false);
        setSpinnerLoader(false);
      });
  }

  async function acceptInvite() {
    setisLoading(true);
    setSpinnerLoader(true);
    setopenRespawnModal(false);

    const payload = {
      status: "accept",
      promo_choice: promo_choice,
      lobbyId: props?.participantData?.team?.lobby,
      teamId: props?.participantData?.team.id,
    };
    await postFriendRequest(payload)
      .then((res) => {
        if (res !== undefined) {
          setisLoading(false);
          closeEnrollModalStatus();
          openEnrollSuccessModalStatus();
          setSpinnerLoader(false);
          if (props.match.url === "/dashboard") {
            window.location.reload();
          }
          if (props.onEnrollComplete) {
            props.onEnrollComplete();
          }
          localStorage.setItem(
            "enroll-event",
            "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
          );
        } else {
          setisLoading(false);
          setSpinnerLoader(false);
        }
      })
      .catch((err) => {
        seterrorMsg(err);
        setisLoading(false);
        setSpinnerLoader(false);
      });
  }

  const openRespawnModalStatus = () => {
    setopenRespawnModal(true);
    closeEnrollModalStatus();
  };

  const closeRespawnModalStatus = () => {
    setIsCheckedGamerTag(false);
    setopenRespawnModal(false);
    closeEnrollModalStatus();
  };
  const onChangePromo = (e) => {
    const check = e.target.checked;
    setCheckPromo(check);
    if (props.entry_fee > +promoBonus + +walletBalance) {
      setisInsufficientBalance(true);
    } else {
      setisInsufficientBalance(false);
    }
    if (!check && +walletBalance < props.entry_fee) {
      setisInsufficientBalance(true);
    }
  };
  return (
    <>
      {props.current_status !== 3 &&
        props.enrolled_players < props.max_players && (
          <div className="game-btn enrolledTeam">
            {props.is_enrolled ? <p>Enrolled</p> : null}

            {!props.isTimeCompleted && !props.is_enrolled ? (
              <button
                className={
                  props?.enrollText
                    ? "declineAction acceptAction"
                    : "btn btn-primary start-game-btn enrollTeam-btn"
                }
                onClick={openEnrollModalStatus}
                disabled={
                  props?.isEnrollTeamTimerReached && props.mode != 1
                    ? true
                    : props.is_verified
                    ? verified
                      ? props?.stop
                      : !verified
                    : props?.stop
                }
              >
                {props?.mode !== 1
                  ? props?.enrollText
                    ? props.enrollText
                    : "Enroll Team"
                  : "Enroll"}
              </button>
            ) : null}
          </div>
        )}
      {/* make-payment-2 */}
      {spinnerLoader && props.mode != 1 && <div class="loading"></div>}
      <Modal
        show={openEnrollModal}
        onHide={closeEnrollModalStatus}
        backdrop="static"
      >
        {profilePhone === "" || profilePhone === null ? (
          <>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="money-added">
              <div className="tick-image">
                <img src="/assets/images/tick.svg" alt="" />
              </div>
              <h3>
                {profilePhone === "" || profilePhone === null
                  ? profileAddress === ""
                    ? "Please Update your profile"
                    : "Please Verify your phone number"
                  : "Please Update your profile"}
              </h3>
            </Modal.Body>
          </>
        ) : //free lobby code
        checkForRemainingFreeLobby && !props.is_password_protected ? (
          <>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="money-added">
              <div class="warning-icon">
                <img src="/assets/images/warning.svg" alt="warning" />
              </div>
              <h3 className="mt-5">
                Your limit to enroll in free lobbies is exhausted
              </h3>
              <p>Please enroll in the paid lobby or contact Ryvals support.</p>
            </Modal.Body>
          </>
        ) : props?.mode !== 1 && !props?.enrollText ? (
          <EnrollTeam
            {...props}
            walletBalance={walletBalance}
            isInsufficientBalance={isInsufficientBalance}
            promoBonus={promoBonus}
            promo_choice={promo_choice}
            onChangePromo={onChangePromo}
            enroll={enrollTeam}
            openWalletModalStatus={openWalletModalStatus}
            closeEnrollModalStatus={closeEnrollModalStatus}
            spinnerLoader={spinnerLoader}
          />
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="mb-4">Make Payment</Modal.Title>
            </Modal.Header>

            <Modal.Body className="make-payment">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>Wallet</h3>
                  <h4>Total Balance</h4>
                  <h5>${walletBalance}</h5>
                  {props.free_lobby
                    ? null
                    : isInsufficientBalance && (
                        <>
                          <p>Insufficient Balance Add Money in Wallet</p>
                          <button
                            className="btn btn-secondary"
                            onClick={() => openWalletModalStatus()}
                          >
                            Add Money to wallet
                          </button>
                        </>
                      )}
                </div>
                <div className="promo-bonus ">
                  <h3>Promo Bonus</h3>
                  <h4>Total Balance</h4>
                  <h5>${promoBonus}</h5>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        className="make-payment-checkbox"
                        type="checkbox"
                        label="Avail Promo Bonus to enroll into lobby"
                        onChange={onChangePromo}
                        checked={promo_choice}
                        disabled={!parseInt(promoBonus)}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="total-money total">
              <div>
                <h3>{props.game.name}</h3>
                <div className="total-display-box">
                  <div>
                    <p>Total</p>
                    <h6>
                      {props.free_lobby
                        ? " $0.00 (Free)"
                        : `$${props.entry_fee}.00`}
                    </h6>
                  </div>
                  <div>
                    <p>Display Name</p>
                    <div className="gamertag-edit">
                      <h6>{gamertag ? gamertag : "-"}</h6>
                      <Link
                        to={{
                          pathname: "/myProfile",
                          data: "settingsTab",
                          settingsKey: "gameIds",
                        }}
                      >
                        <img
                          src="/assets/images/edit-gamertag-payment-popup.svg"
                          alt="edit-icon"
                          style={{ cursor: "pointer" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="pb-3"
                data-tip
                data-for="helperTip"
                style={{ cursor: "pointer" }}
              >
                <span class="info-icon">
                  <img
                    src="/assets/images/info-icon.svg"
                    alt="info"
                    class="info-image"
                  />
                  <span className="gamerTagInfoMessage">
                    {gamerTagInfo(selectedGame)}
                  </span>
                </span>
                <div className="helper-image-container enrollHelperImage">
                  <ReactTooltip id="helperTip" place="top">
                    <img src={selectedGame?.helper_image} alt="helper-image" />
                  </ReactTooltip>{" "}
                </div>
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={() => openRespawnModalStatus()}
                  disabled={props.free_lobby ? false : isInsufficientBalance}
                >
                  Pay
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
      {/* add to wallet module - start */}
      <Modal
        show={openWalletModal}
        onHide={closeWalletModalStatus}
        contentClassName="qr-verification-wrapper"
        backdrop="static"
      >
        <AddToWallet
          prevPath={props.location.pathname}
          gameName={props.game}
          lobbyId={props.id}
          walletBalance={walletBalance}
          closeModal={closeWalletModalStatus}
        />
      </Modal>
      {spinnerLoaderSingle ? (
        <div class="loading"></div>
      ) : openRespawnModal ? (
        <Modal
          className="respawn-modal"
          show={openRespawnModal}
          onHide={closeRespawnModalStatus}
          backdrop="static"
        >
          <GuLagInfo>
            {errorMsg ? (
              <div className="invalid-feedback d-block">{errorMsg}</div>
            ) : null}
            <div className="d-flex">
              <Form.Check
                className="gamertag-checkbox"
                type="checkbox"
                checked={isCheckedGamerTag}
                onChange={handleOnChange}
              />

              <p className="gamertag-prompt gamertag-text">
                {gamerTagInfo(selectedGame)}
              </p>
            </div>
            <Button
              variant="primary"
              onClick={() =>
                props?.enrollText
                  ? acceptInvite()
                  : props?.mode == 1
                  ? enroll()
                  : enrollTeamAPI()
              }
              disabled={isLoading || !isCheckedGamerTag}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Agree"
              )}
            </Button>
          </GuLagInfo>
        </Modal>
      ) : (
        <Modal
          show={openEnrollSuccessModal}
          onHide={closeEnrollSuccessModalStatus}
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <div className="tick-image">
              <img src="/assets/images/tick.svg" alt="" />
            </div>
            <h3>Successfully Enrolled for {props.game.name} Lobby</h3>
            <p>Wallet Balance</p>
            <h4 className="remainingLobbies">
              $
              {`${(props.free_lobby
                ? parseInt(walletBalance) + parseInt(promoBonus)
                : parseInt(walletBalance) +
                  parseInt(promoBonus) -
                  (props?.mode == 1
                    ? 1
                    : enrollTeamPayloadRef.current?.is_paying_full == 1
                    ? props?.mode
                    : 1) *
                    props.entry_fee
              ).toFixed(2)}`}
            </h4>

            <p>
              {props.free_lobby && !props.is_password_protected
                ? `Remaining Free Lobbies: ${
                    props.userDataForFreeLimitCount - 1
                  }`
                : null}
            </p>
          </Modal.Body>
        </Modal>
      )}

      {/* add to wallet module - end */}
      <Modal
        show={openProtectedModal}
        onHide={() => setopenProtectedModal(false)}
        backdrop="static"
      >
        {" "}
        <Modal.Header closeButton>
          <h2 className="text-left">Lobby password</h2>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-1">
            <Form.Control
              type="text"
              placeholder="Enter Your Lobby Password"
              name="amount"
              ref={passwordRef}
              onChange={(e) => setPassWord(e.target.value)}
            />
          </InputGroup>
          <Button
            variant="primary"
            className="communityButton invite-btn lobbyPasswordContinue buttonFullWidth"
            disabled={!password?.trim()}
            onClick={handlePassword}
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Continue"
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

EnrollButton.propTypes = {
  callLobbies: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  game: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  is_verified: PropTypes.bool,
  is_enrolled: PropTypes.bool,
  reward: PropTypes.number,
  start_date: PropTypes.string,
  start_time: PropTypes.string,
  last_entry_time: PropTypes.string,
  entry_fee: PropTypes.number,
  create_date: PropTypes.string,
  current_status: PropTypes.number,
  enrolled_players: PropTypes.number,
  max_players: PropTypes.number,
  min_players: PropTypes.number,
  rules: PropTypes.string,
  status: PropTypes.number,
  isTimeCompleted: PropTypes.bool,
};

export default withRouter(EnrollButton);

import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";
export async function getCMSData(body) {
  const url = baseURL + `pages/static/${body.title}`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

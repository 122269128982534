import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import CountDown from "./CountDown";
import CountDownTeams from "./CountDownTeams";
import { useSelector } from "react-redux";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
import { getCardBackground, redirectToPrevPath } from "../../helper/utilities";
import ReactTooltip from "react-tooltip";

function ChallengeTableCard({
  challenge,
  games,
  handleDeclineChallenge,
  handleAcceptChallenge,
  handleNoPay,
}) {
  const [isTimerReached, setTimerReached] = useState(false);
  const [canEnroll, setCanEnroll] = useState(true);
  const [canModify, setCanModify] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const currentPlayer = useMemo(
    () => {
      return challenge?.challenge_players?.find((player) => {
        return player.player_id == loggedUser?.user?.id;
      });
    },
    loggedUser?.user?.id,
    challenge?.challenge_players
  );
  const game = games.filter((gameObj) => challenge.game == gameObj.id)?.[0];
  const ChallengeCountdownEnded = (isComplete, id) => {
    setTimerReached(isComplete);
  };
  if (loggedUser) localStorage.removeItem("previousPath");
  const handleDetails = () => {
    if (!loggedUser?.user?.id) {
      return redirectToPrevPath(history, location, true);
    }
    return {
      pathname: window.location.pathname + "/versus/" + challenge.id,
      data: challenge,
    };
  };

  return (
    <div className="col-md-6 left-card mb-4">
      <div
        className={`${getCardBackground(game?.slug)} ${
          challenge.enrolled ? "enrolled-card" : ""
        }`}
      >
        <div className="card-body">
          <h3 className="card-title lobbyTitle w-100">
            {" "}
            {challenge.challenge_type == 1
              ? "Open Challenge"
              : challenge.versus}
          </h3>
          <div className="playing-information ValorantNewCard">
            <div className="left-side">
              <p className="entry-box tags">
                <span className="tags enrolled-box">Game</span>
                <span className="count-box dollar-wrapper theme-color">
                  {game.name}
                </span>
              </p>
              <p className="entry-box tags">
                <span className="tags enrolled-box">Entry</span>
                <span className=" count-box dollar-wrapper">
                  ${challenge.entry_fee}
                </span>
              </p>
              <p className="entry-box tags">
                <span className="tags enrolled-box">Team Mode</span>
                <span className="count-box dollar-wrapper">
                  {challenge.modetag}
                </span>
              </p>
              <p className="entry-box tags">
                <span className="tags enrolled-box">Status</span>
                <LobbyStatus data={challenge} />
              </p>
              <p className="entry-box tags">
                <span className="tags enrolled-box">Map</span>
                <span className="count-box dollar-wrapper">
                  {challenge.gamemap || "N/A"}
                </span>
              </p>
            </div>
            <div className="right-side">
              <p className="entry-box tags">
                <span>Win</span>
                <span class="info-icon">
                  <img
                    src="/assets/images/info-icon.svg"
                    alt="info"
                    class="info-image"
                    data-tip
                    data-for="loginTip"
                  />
                </span>
                <ReactTooltip id="loginTip" place="right" effect="solid">
                  Actual amount paid to the team
                </ReactTooltip>
                <span className="theme-color">
                  {" "}
                  ${challenge.winning}/Winning Team
                </span>
              </p>
              <p className="entry-box tags">
                <span>Date</span>
                <span>{challenge.match_date}</span>
              </p>
              <p className="entry-box tags">
                <span>Start Time</span>
                <span>{challenge.match_time}</span>
              </p>
              <p className="entry-box tags">
                <span>Last Entry</span>
                <span>{challenge.last_entry_time}</span>
              </p>
              <p className="entry-box tags">
                <span>Game Type</span>
                <span>Traditional</span>
              </p>
            </div>
          </div>

          <div class="start-time-wrapper">
            <div class="enrolled-box d-flex">
              {challenge.challenge_mode == 3 ||
              challenge.challenge_mode == 4 ||
              challenge.challenge_mode == 5 ||
              isTimerReached ? null : (
                <div className="challengeStatus">
                  <CountDown
                    server_current_datetime={challenge?.countdown_time}
                    time_reached={(e) => {
                      ChallengeCountdownEnded();
                    }}
                    stopEnrollTeam={(isComplete) => {
                      if (canEnroll) {
                        setCanEnroll(false);
                      }
                    }}
                  />
                  <CountDownTeams
                    challengeList={true}
                    server_current_datetime={challenge?.countdown_time}
                    owner={false}
                    setCanModify={setCanModify}
                    setCanReplace={() => {}}
                  />
                </div>
              )}
              <div className="bottom-btn d-flex">
                <Link to={handleDetails()} className="enroll-button">
                  <Button className="actionsChallenge" variant="primary">
                    View Details
                  </Button>
                </Link>
                {challenge.creator ? null : challenge.enrolled ? (
                  <Button
                    className="actionsChallenge"
                    variant="primary"
                    disabled={true}
                  >
                    Accepted
                  </Button>
                ) : (
                  <>
                    {!(
                      challenge.challenge_mode == 3 ||
                      challenge.challenge_mode == 4 ||
                      challenge.challenge_mode == 5 ||
                      !loggedUser?.user?.id
                    ) ? (
                      <>
                        <Button
                          className="actionsChallenge timerReached"
                          variant="primary"
                          onClick={() => {
                            if (
                              challenge?.action_type?.team_id
                                ? challenge.payment_required
                                : true
                            ) {
                              handleAcceptChallenge(challenge, currentPlayer);
                            } else {
                              handleNoPay(challenge);
                            }
                          }}
                          disabled={
                            challenge?.rejected &&
                            (challenge.team_leader ||
                              challenge.challenge_mode != 1)
                              ? true
                              : challenge?.action_type?.team_id
                              ? !canModify
                              : !canEnroll
                          }
                        >
                          Accept
                        </Button>
                        {!challenge?.action_type?.team_id &&
                        !challenge.team_leader &&
                        challenge.challenge_mode == 1 ? null : (
                          <Button
                            className="actionsChallenge"
                            onClick={() =>
                              handleDeclineChallenge(challenge, currentPlayer)
                            }
                            variant="primary"
                            disabled={
                              challenge?.rejected
                                ? true
                                : challenge?.action_type?.team_id
                                ? !canModify
                                : !canEnroll
                            }
                          >
                            {challenge.rejected ? "Declined" : "Decline"}
                          </Button>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallengeTableCard;

import React, { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import AddToWallet from "../../payment/AddToWallet";
import { Link } from "react-router-dom";
import PaymentComponent from "../../PaymentMethod";
import SuccessEnrollModal from "./SuccessEnrollModal";
import Select from "react-select";
import {
  enrollTournament,
  findCommonTeamInTournament,
} from "../../../services/tournament.service";
import TeamPlayersList from "../../common/TeamPlayersList";
import ConfirmModalToAddDuplicateMember from "./ConfirmModalToAddDuplicateMember";

const TournamentEnroll = ({
  hideEnrollModal,
  tournament,
  showPaymentModal,
  showTeamSelectModel,
  setShowPaymentModal,
  setShowTeamSelectModal,
  playerTeams,
  game,
  mode,
}) => {
  const [addtoWallet, setAddToWallet] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [team, selectedTeam] = useState(null);

  const openAddToWallet = () => {
    setAddToWallet(true);
  };

  const closeAddToWallet = () => {
    setAddToWallet(false);
  };

  const handleCancel = () => {
    selectedTeam(null);
    setShowPaymentModal(false);
  };

  const [balance, setBalance] = useState({});
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
    hideEnrollModal();
    setBalance({});
  };
  const handleSubmit = async ({
    payActiveIndex,
    bonus,
    promoBonus,
    walletBalance,
    setisLoading,
  }) => {
    setBalance({
      promo_choice: bonus,
      promo: parseInt(promoBonus),
      wallet: walletBalance,
      payingForTeam: payActiveIndex === 1,
    });
    try {
      const data = {
        team_id: team.id,
        promo_choice: bonus,
        is_paying_full: payActiveIndex == 2 ? false : true,
      };
      setisLoading(true)
      await enrollTournament({
        tournament_id: tournament.id,
        data,
      });
      setisLoading(false)
      setShowPaymentModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      setisLoading(false)
    }
  };
  const [duplicateMemberFound, setShowIfDuplicateMemberFound] = useState(false);
  const [duplicateMember, setDuplicateMemeber] = useState(false);
  const [duplicateMemberMessage, setDuplicateMemeberMessage] = useState("");
  const handleSelectTeam = async (userSelectedTeam) => {
    const data = await findCommonTeamInTournament(
      tournament.id,
      userSelectedTeam.id
    );
    if (!data.status) {
      selectedTeam(userSelectedTeam);
      setDuplicateMemeber(false);
      setDuplicateMemeberMessage("");
      setShowIfDuplicateMemberFound(false);
    } else {
      selectedTeam(userSelectedTeam);
      setDuplicateMemeber(true);
      setDuplicateMemeberMessage(data.message);
      setShowIfDuplicateMemberFound(true);
    }
  };
  const handleHideDuplicateMemberFound = () => {
    setShowIfDuplicateMemberFound(false);
    setDuplicateMemeberMessage("");
  };

  return (
    <>
      <SuccessEnrollModal
        hideSuccessModal={hideSuccessModal}
        entryFeeSubtract={
          balance.payingForTeam
            ? tournament.entry_fee * mode
            : tournament.entry_fee
        }
        showSuccessModal={showSuccessModal}
        walletBalance={balance}
        isFree={tournament.free}
        mode={mode}
      />

      {showPaymentModal ? (
        addtoWallet ? (
          <Modal
            show={true}
            onHide={closeAddToWallet}
            contentClassName="qr-verification-wrapper"
            backdrop="static"
          >
            <AddToWallet gameName={game?.name} closeModal = {closeAddToWallet}  />
          </Modal>
        ) : showTeamSelectModel ? (
          <Modal
            show={true}
            onHide={() => {
              setShowPaymentModal(false);
              setShowTeamSelectModal(false);
              selectedTeam(null);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Team</Modal.Title>
            </Modal.Header>

            <Modal.Body className="make-payment mt-4">
              <Link
                className="redirect-link mb-2"
                to={{
                  pathname: `/myprofile`,
                  state: {
                    createTeam: true,
                    mode: mode,
                    game: game?.id,
                  },
                }}
              >
                + Create New Team
              </Link>
              <Select
                className="react-select-container-teammates chooseTeam mt-2"
                classNamePrefix="react-select-teammates"
                placeholder="Choose Team"
                isSearchable
                onChange={(teamToBeSelected) => {
                  // selectedTeam(teamToBeSelected);
                  handleSelectTeam(teamToBeSelected);
                }}
                value={team}
                options={playerTeams.map((teamItem) => ({
                  ...teamItem,
                  value: teamItem.id,
                  label: teamItem.name,
                }))}
              />
              {team?.players?.length ? (
                <TeamPlayersList players={team?.players} />
              ) : null}
            </Modal.Body>
            <Modal.Footer className="total-money total delete-footer registerCancelBtn">
              <Button
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(false);
                  setShowTeamSelectModal(false);
                  selectedTeam(null);
                }}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                disabled={!team?.id || duplicateMember}
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(true);
                  setShowTeamSelectModal(false);
                }}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={true} onHide={handleCancel} backdrop="static">
            <PaymentComponent
              entry_fee={tournament.entry_fee}
              mode={mode}
              selectedGame={game}
              free_lobby={tournament.free}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              openAddToWallet={openAddToWallet}
              creatorPay={true}
            />
          </Modal>
        )
      ) : null}
      <ConfirmModalToAddDuplicateMember
        duplicateMemberFound={duplicateMemberFound}
        handleHideDuplicateMemberFound={handleHideDuplicateMemberFound}
        message={duplicateMemberMessage}
      />
    </>
  );
};

export default TournamentEnroll;

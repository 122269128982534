import React, { Fragment, useState, useEffect } from "react";
import { login, twitterUrl, socialLogin } from "../../services/authService.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/auth/login/actions.js";
import AuthWrapper from "./AuthWrapper.jsx";
import ls from "localstorage-slim";
import { Alert } from "react-bootstrap";
import { requestverification } from "../../services/authService.js";
import publicIp from "public-ip";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import toastify from "../../helper/toastify.jsx";
import {
  ConsoleHelper,
  getUrlChanger,
  handleBannedLocationError,
} from "../../helper/utilities.js";
import {
  PLACEHOLDERS,
  SOCIAL_ACCOUNTS,
  STRINGS_CONSTANTS,
  VALIDATIONS_ERROR,
} from "../../helper/stringConstants.js";
const SignIn = (props) => {
  const previousPath = localStorage.getItem("previousPath");
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);
  const [errorMsgToDisplay, seterrorMsgToDisplay] = useState("");
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberMeTrue, setRememberMeTrue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailSent, setisEmailSent] = useState(false);
  const [isLoadingResendLink, setisLoadingResendLink] = useState(false);
  const [player_ip, setIP] = useState("");
  const [twitterLink, setTwitterUrl] = useState("");
  // form validation rules
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("remember"));
    if (data === null && data === false) {
      setRememberMe(false);
    } else {
      const data1 = ls.get("key", { decrypt: true });
      setRememberMe(data);
      setEmail(data1 === null ? "" : data1.email + "");
      setPassword(data1 === null ? "" : data1.password + "");
      setRememberMeTrue(data);
    }
  }, []);
  const getIp = async () => {
    try {
      const res = await publicIp.v4();
      setIP(res);
      const data = {
        redirect: "signup",
      };
      await twitterUrl(data)
        .then((twitterRes) => {
          setTwitterUrl(twitterRes.url);
        })
        .catch((err) => {
          ConsoleHelper(err);
        });
    } catch (error) {
      toastify(
        "error",
        error.message +
          " ,Please try again after disabling your adblocker if enabled"
      );
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATIONS_ERROR.requiredEmail),
    password: Yup.string()
      .transform((x) => (x === "" ? undefined : x))
      .concat(Yup.string().required(VALIDATIONS_ERROR.requiredPassword)),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
      password: password,
    },
    resolver: yupResolver(validationSchema),
  });

  const toggleShow = () => {
    setshowPassword(!showPassword);
  };
  useEffect(() => {
    getIp();
  }, []);
  const [verifyEmail, setEmailForVerification] = useState("");
  async function onSubmit(data) {
    setEmailForVerification(data.email);
    setisEmailSent(false);
    if (rememberMeTrue) {
      const body = {
        email: data.email,
        password: data.password,
        player_ip: player_ip,
      };
      const data1 = {
        email: data.email,
        password: data.password,
        player_ip: player_ip,
      };
      localStorage.setItem("remember", rememberMe);
      ls.set("key", data1, { encrypt: true });
      showLoader(true);
      seterrorMsg(false);
      await login(body)
        .then((res) => {
          dispatch(loginUser(res));
          showLoader(false);
          if (previousPath && previousPath.includes("/tournaments")) {
            props.history.push({
              pathname: previousPath,
            });
          } else if (props.location.state == undefined) {
            props.history.push("/myprofile");
          } else {
            props.history.push({
              path: props.location.state.prevPath,
              state: { challengeTab: props.location.state.challengeTab },
            });
          }
        })
        .catch((err) => {
          showLoader(false);

          seterrorMsg(true);
          seterrorMsgToDisplay(err);
        });
    } else {
      if (rememberMe) {
        const body = {
          email: data.email,
          password: data.password,
          player_ip: player_ip,
        };
        localStorage.setItem("remember", rememberMe);
        ls.set("key", body, { encrypt: true });
        showLoader(true);
        seterrorMsg(false);
        await login(body)
          .then((res) => {
            dispatch(loginUser(res));
            showLoader(false);
            if (previousPath && previousPath.includes("/tournaments")) {
              props.history.push({
                pathname: previousPath,
              });
            } else if (props.location.state == undefined) {
              props.history.push("/myprofile");
            } else {
              props.history.push({
                path: props.location.state.prevPath,
                state: { challengeTab: props.location.state.challengeTab },
              });
            }
          })
          .catch((err) => {
            showLoader(false);
            seterrorMsg(true);
            seterrorMsgToDisplay(err);
          });
      } else {
        const body = {
          email: data.email,
          password: data.password,
          player_ip: player_ip,
        };
        localStorage.setItem("remember", rememberMe);
        showLoader(true);
        seterrorMsg(false);
        await login(body)
          .then((res) => {
            dispatch(loginUser(res));
            showLoader(false);
            if (previousPath && previousPath.includes("/tournaments")) {
              props.history.push({
                pathname: previousPath,
              });
            } else if (props.location.state == undefined) {
              props.history.push("/myprofile");
            } else {
              props.history.push({
                path: props.location.state.prevPath,
                state: { challengeTab: props.location.state.challengeTab },
              });
            }
          })
          .catch((err) => {
            showLoader(false);
            seterrorMsg(true);
            seterrorMsgToDisplay(err);
          });
      }
    }
  }

  function showLoader(isLoad) {
    setisLoading(isLoad);
  }
  const onChangeCheck = (e) => {
    const check = e.target.checked;
    setRememberMe(check);
    if (!check) {
      ls.remove("key");
      setRememberMeTrue(false);
    }
  };

  const redirectToSignUp = (response, provider = "", socialToken = "") => {
    props.history.push({
      pathname: "/signup",
      state: {
        data: response,
        provider,
        socialToken,
      },
    });
  };

  // const responseFacebook = async (response) => {
  //   if (response.status !== "unknown") {
  //     setSpinnerLoader(true);
  //     const data = {
  //       provider: "facebook",
  //       code: response.accessToken,
  //     };
  //     await socialLogin(data)
  //       .then((res) => {
  //         localStorage.setItem("user", JSON.stringify(res.extras));
  //         dispatch(loginUser(res.extras));

  //         if (props.location.state == undefined) {
  //           props.history.push("/myprofile");
  //         } else {
  //           props.history.push({
  //             path: props.location.state.prevPath,
  //             state: { challengeTab: props.location.state.challengeTab },
  //           });
  //         }
  //         setSpinnerLoader(false);
  //       })
  //       .catch((err) => {
  //         if (err.error === "Please verify your account") {
  //           props.history.push({
  //             pathname: "/verifyEmail",
  //             state: { email: err.email },
  //           });
  //         } else {
  //           const str = err;
  //           const words = str.split(" ");
  //           if (words[0] === "An") {
  //             redirectToSignUp(response);
  //           } else if (words.length === 13) {
  //             toastify("success", STRINGS_CONSTANTS.incompleteSignUpForm);
  //             redirectToSignUp(response, words[1], words[0]);
  //           } else {
  //             redirectToSignUp(response);
  //           }
  //         }
  //         setSpinnerLoader(false);
  //       });
  //   } else {
  //     return;
  //   }
  // };
  const responseGoogle = async (response) => {
    if (response.valid || response.valid === undefined) {
      setSpinnerLoader(true);
      const data = {
        provider: "google",
        code: response?.tokenObj?.id_token,
      };
      await socialLogin(data)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.extras));
          dispatch(loginUser(res.extras));
          if (previousPath && previousPath.includes("/tournaments")) {
            props.history.push({
              pathname: previousPath,
            });
          } else if (props.location.state == undefined) {
            props.history.push("/myprofile");
          } else {
            props.history.push({
              path: props.location.state.prevPath,
              state: { challengeTab: props.location.state.challengeTab },
            });
          }
          setSpinnerLoader(false);
        })
        .catch((err) => {
          if (err.error === "Please verify your account") {
            props.history.push({
              pathname: "/verifyEmail",
              state: { email: err.email },
            });
          } else {
            const str = err;
            const words = str.split(" ");
            if (words[0] === "An") {
              redirectToSignUp(response);
            } else if (words.length === 13) {
              toastify("success", STRINGS_CONSTANTS.incompleteSignUpForm);
              props.history.push({
                pathname: "/signup",
                state: {
                  data: {
                    first_name: response?.profileObj?.givenName,
                    last_name: response?.profileObj?.familyName,
                    email: response?.profileObj?.email,
                  },
                  provider: words[1],
                  socialToken: words[0],
                },
              });
            } else {
              props.history.push({
                pathname: "/signup",
                state: {
                  data: {
                    first_name: "",
                    last_name: "",
                    email: "",
                  },
                  provider: "",
                  socialToken: "",
                },
              });
            }
          }

          setSpinnerLoader(false);
        });
    } else {
      return;
    }
  };
  const responseErrorGoogle = (response) => {
    if (
      response.details === "Cookies are not enabled in current environment."
    ) {
      toastify("info", STRINGS_CONSTANTS.cookieMessage);
      setSpinnerLoader(false);
    } else {
      setSpinnerLoader(false);
    }
  };
  const hideEmail = verifyEmail;
  const a = hideEmail.split("@");
  const b = a[0];
  let newstr = "";
  for (const i in b) {
    if (b.length <= 5) {
      if (i > 1) newstr += "*";
      else newstr += b[i];
    } else if (i > 1 && i < b.length - 2) newstr += "*";
    else newstr += b[i];
  }
  const newEmail = newstr + "@" + a[1];

  const resendLink = () => {
    setisEmailSent(false);
    setisLoadingResendLink(true);
    requestverification(verifyEmail)
      .then((res) => {
        setisEmailSent(true);
        setisLoadingResendLink(false);
        setEmailForVerification(res.email);
      })
      .catch((err) => {
        setisEmailSent(false);
        setisLoadingResendLink(false);
        toastify("error", err);
      });
  };

  const urlChanger = getUrlChanger("signup");
  return (
    <Fragment>
      <div id="contact" className="fix">
        {spinnerLoader ? (
          <div class="loading"></div>
        ) : (
          <div className="rn-contact-area ptb--120 bg_color--1">
            <div className="contact-form--1">
              <div className="container">
                <div className="row row--35 align-items-start">
                  <div className="col-lg-6 image-class">
                    <AuthWrapper data={props} />
                  </div>

                  <div className="col-lg-6 form-class">
                    <div className="section-title text-left mb--50">
                      <h2 className="title sign-in-title">
                        {STRINGS_CONSTANTS.signin}
                      </h2>
                      <p className="description continue">
                        {STRINGS_CONSTANTS.signinContinue}
                      </p>
                    </div>

                    <div className="sign-in-bottom-content">
                      <div className="">
                        <div className="social-icons">
                          <div className="grow">
                            <a
                              href={`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${urlChanger}&scope=viewing_activity_read+openid+user:read:email&force_verify=true`}
                            >
                              <img
                                src="/assets/images/Twitch.svg"
                                alt="Twitch"
                                data-tip
                                data-for="socialloginTip"
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.twitch}
                            </ReactTooltip>{" "}
                          </div>
                          {/* <div className="grow">
                            <FacebookLogin
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                              autoLoad={false}
                              fields="first_name, last_name, email, picture, birthday, link"
                              scope="public_profile, email, user_birthday, user_link"
                              callback={responseFacebook}
                              render={(renderProps) => (
                                <img
                                  src="/assets/images/facebook-one.svg"
                                  alt="Facebook"
                                  onClick={renderProps.onClick}
                                  style={{ cursor: "pointer" }}
                                  data-tip
                                  data-for="socialloginTip1"
                                />
                              )}
                              cookie={false}
                              xfbml={false}
                            />
                            <ReactTooltip
                              id="socialloginTip1"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.facebook}
                            </ReactTooltip>{" "}
                          </div> */}
                          <div className="grow">
                            <a
                              href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${urlChanger}&response_type=code&scope=identify%20email`}
                            >
                              <img
                                src="/assets/images/chats.svg"
                                alt="Discord"
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="socialloginTip2"
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip2"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.discord}
                            </ReactTooltip>{" "}
                          </div>

                          <div className="grow">
                            <GoogleLogin
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              autoLoad={false}
                              onSuccess={responseGoogle}
                              onFailure={responseErrorGoogle}
                              cookiePolicy={"single_host_origin"}
                              render={(renderProps) => (
                                <img
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                  src="/assets/images/Google.svg"
                                  alt="Google"
                                  style={{ cursor: "pointer" }}
                                  data-tip
                                  data-for="socialloginTip3"
                                />
                              )}
                            />
                            <ReactTooltip
                              id="socialloginTip3"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.google}
                            </ReactTooltip>{" "}
                          </div>
                          <div className="grow">
                            <a href={twitterLink}>
                              <img
                                src="/assets/images/twitter-one.svg"
                                alt="chats"
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="socialloginTip4"
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip4"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.twitter}
                            </ReactTooltip>{" "}
                          </div>
                        </div>
                        <h3>- Or -</h3>
                        <h4>{STRINGS_CONSTANTS.signWith}</h4>
                      </div>
                    </div>

                    <div className="form-wrapper">
                      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <label htmlFor="item01">
                          <input
                            id="item01"
                            type="text"
                            name="email"
                            placeholder={PLACEHOLDERS.emailOrUsername}
                            {...register("email")}
                            className={`${errors.email ? "is-invalid" : ""}`}
                            autoComplete="off"
                          />
                        </label>
                        {errors.email?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.email?.message}
                          </div>
                        )}
                        <Link to="/forgot-password?type=username">
                          <h6>Forgot Username?</h6>
                        </Link>

                        <label htmlFor="item02">
                          <input
                            id="item02"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            {...register("password")}
                            className={`${errors.password ? "is-invalid" : ""}`}
                            autoComplete="off"
                          />
                          <img
                            onClick={toggleShow}
                            src={`assets/images/${
                              showPassword ? "no-eye" : "eye"
                            }.svg`}
                            alt={showPassword ? "hide" : "show"}
                            title={showPassword ? "hide" : "show"}
                          />
                        </label>
                        {errors.password?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.password?.message}
                          </div>
                        )}
                        <Link to="/forgot-password">
                          <h6>{STRINGS_CONSTANTS.forgotPassword + "?"}</h6>
                        </Link>
                        {errorMsg && (
                          <div className="invalid-feedback d-block my-2">
                            {errorMsgToDisplay.length === 27 ? (
                              <>
                                {errorMsgToDisplay}{" "}
                                <a
                                  onClick={resendLink}
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  <span class="resend-link">
                                    {STRINGS_CONSTANTS.resendLink}
                                  </span>
                                  {isLoadingResendLink && (
                                    <span className="spinner-border spinner-border-sm mr-4 spinner"></span>
                                  )}
                                </a>
                                {isEmailSent ? (
                                  <Alert variant="success" className="mt-4">
                                    <p style={{ color: "black" }}>
                                      {newEmail}
                                      <br></br>
                                      {
                                        STRINGS_CONSTANTS.accountVerificationMail
                                      }
                                    </p>
                                  </Alert>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {handleBannedLocationError(errorMsgToDisplay)}
                              </>
                            )}
                          </div>
                        )}
                        <label htmlFor="item01" className="rememberMe">
                          <div class="remember-me">
                            <Form.Check
                              type="checkbox"
                              label="Remember Me"
                              onChange={onChangeCheck}
                              checked={rememberMe}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </label>
                        <button
                          className="rn-button-style--2 btn-solid"
                          type="submit"
                          id="mc-embedded-subscribe"
                          disabled={isLoading || player_ip === ""}
                        >
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                          )}{" "}
                          {STRINGS_CONSTANTS.signin}
                        </button>
                      </form>
                    </div>

                    <div className="not-a-member">
                      <h6>
                        Not a Member yet?
                        {props.location.state === undefined ? (
                          <Link to="signup">
                            <span className="sign-up">
                              {STRINGS_CONSTANTS.signup}
                            </span>
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: `/signup`,
                              data: { prevPath: props.location.state.prevPath },
                            }}
                          >
                            <span className="sign-up">
                              {STRINGS_CONSTANTS.signup}
                            </span>
                          </Link>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(SignIn);

import React, { useState } from "react";
import { Button, Modal, } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { addPayPalID } from "../../services/user.service";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import toastify from "../../helper/toastify.jsx";
const PaypalAccount = (props) => {
  const [isLoading, setisLoading] = useState(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    paypalId: Yup.string()
      .required("PayPal Id is required")
      .email("Invalid PayPal Id"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paypalId: props.paypalAccount === null ? "" : props.paypalAccount,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const body = {
      paypal_id: data.paypalId,
    };
    setisLoading(true);
    await addPayPalID(body)
      .then((res) => {
        if (res === undefined) {
          setisLoading(false);
        } else {
          props.closePaypalModal();
          setisLoading(false);
          toastify("success", "PayPal ID updated successfully");
        }
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>PayPal ID</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="wallet-body">
          <Form.Group
            className="margins paypal-id error-circle"
            controlId="formGridPaypal"
          >
            <Form.Control
              placeholder="PayPal Email ID"
              name="paypalId"
              {...register("paypalId")}
              className={`${errors.paypalId ? "is-invalid" : ""}`}
            />{" "}
            <span>
              <img
                src="/assets/images/info-icon.svg"
                alt="info"
                class="info-image"
                data-tip
                data-for="loginTip1"
              />
            </span>
            <ReactTooltip id="loginTip1" place="left" effect="solid">
              PayPal ID required for payouts
            </ReactTooltip>
          </Form.Group>
          {errors.paypalId?.message && (
            <div className="invalid-feedback d-block required">
              {errors.paypalId?.message}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="wallet-footer paypal-footer-btn">
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : props.paypalAccount === null ? (
              "Add PayPal Account"
            ) : (
              "Update PayPal Account"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

PaypalAccount.propTypes = {
  closePaypalModal: PropTypes.func,
};

export default PaypalAccount;

import React from "react";
import { Modal } from "react-bootstrap";

const UpdateProfileModal = ({
  updateProfile,
  hideUpdateProfile,
  profilePhone,
  profileAddress,
}) => {
  return (
    <Modal show={updateProfile} onHide={hideUpdateProfile} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="money-added">
        <div className="tick-image">
          <img src="/assets/images/tick.svg" alt="" />
        </div>
        <h3>
          {profilePhone === "" || profilePhone === null
            ? profileAddress === ""
              ? "Please Update your profile"
              : "Please Verify your phone number"
            : "Please Update your profile"}
        </h3>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProfileModal;

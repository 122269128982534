import React from "react";
import { Row } from "react-bootstrap";

function TeamPlayersList({players}) {
  return (
    <Row className="text-white mt-2">
      <h6 className="text-white">Players in team are :</h6>
      <ul className="pl--40">
        {players?.map((player, idx) => {
          return (
            <li key={player.player__username} className="listStyle">
              {player.player__username} ({player.leader ? "CREATOR" : "MEMBER"}){" "}
            </li>
          );
        })}
      </ul>
    </Row>
  );
}

export default TeamPlayersList;

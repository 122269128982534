import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  getUserDetail,
  socialLogin,
  protectAccountData,
  redeemAmount,
} from "../../services/user.service";
import { twitterUrl } from "../../services/authService.js";
import EditProfile from "./EditProfile";
import AddToWallet from "../payment/AddToWallet";
import GameAccount from "./GameAccount";
import PaypalAccount from "./PaypalAccount";
import RedeemFunds from "../redeem/RedeemFunds";
import WalletTransaction from "../transactions/WalletTransaction";
import ChangePassword from "./ChangePassword";
import EditPicture from "./EditPicture";
import ReactTooltip from "react-tooltip";
import { withRouter, Link } from "react-router-dom";
import toastify from "../../helper/toastify.jsx";
import axios from "axios";
import {
  mask,
  logoutEventPrivateRoutes,
  getUrlChanger,
} from "../../helper/utilities";
import ProgressProfile from "./ProgressProfile";
import Notifications from "./Notifications";
import FriendsRequest from "./FriendsRequest";
import TeamModule from "./TeamModule";
import TeamModal from "./TeamModal";
import GameIdsTable from "./GameIdsTable";
import Socials from "./Socials";
import Friends from "./Friends";
import Referrals from "./Referrals";
import { STRINGS_CONSTANTS } from "../../helper/stringConstants";
class MyProfile extends Component {
  game_slug;

  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      show: false,
      showWalletModal: props.location.prevPath == "/payment" ? true : false,
      showRedeemModal: false,
      showInviteModal: false,
      showPaypalModal: false,
      openSeeMoreModal: false,
      userData: null,
      referData: null,
      userAmount: null,
      isGameModalOpen: false,
      gameTag: "",
      gameTagError: null,
      spinnerLoadder: false,
      spinnerLoaderLogin: false,
      key:
        props.location.prevPath == "/payment"
          ? "wallet"
          : props?.location?.data === "settingsTab"
          ? "settingsTab"
          : props?.location?.data === "referfriend"
          ? "referfriend"
          : props.location.data === "playerProfile"
          ? "friends"
          : props.location?.state?.key === "teams"
          ? "teams"
          : "wallet",
      settingsKey:
        props?.location?.data === "settingsTab"
          ? props?.location?.settingsKey == "gameIds"
            ? "gameIds"
            : "changePassword"
          : "gameIds",
      friendsKey: "my-friends",
      prevAmount:
        props.location.prevPath == "/payment"
          ? props.location.prevAmount
          : null,
      isEditPictureModOpen: false,
      urlChanger: getUrlChanger("myprofile"),
      twitterLink: "",
      searchText: "",
      showSuggestion: false,
      isAccountPublic: false,
      openAccountModal: false,
      hideFriend: false,
      disableAddFriend: false,
      hideProfile: false,
      publicLoading: false,
      showFriendTable: false,
      hideAllYourFriendsList: false,
      maskDetails: {
        email: true,
        paypal: true,
        discord: true,
        stripe_id: true,
      },
      showCreateTeamModal: false,
      callTeamApi: false,
      gameName: null,
      gameMode: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxes = this.handleCheckboxes.bind(this);
  }

  handleModal() {
    this.setState({ show: !this.state.show });
  }

  showEditMod() {
    this.setState({ showEditModal: true, spinnerLoadder: true });
    setTimeout(() => {
      this.setState({ spinnerLoadder: false });
    }, 2000);
  }
  hideEditMod() {
    this.setState({ showEditModal: false });
    this.fetchUserData();
  }

  showWalletMod() {
    this.setState({ showWalletModal: true });
  }
  hideWalletMod() {
    this.setState({ showWalletModal: false });
    this.fetchUserData();
  }

  showloader() {
    this.setState({ spinnerLoadder: true });
  }

  hideloader() {
    this.setState({ spinnerLoadder: false });
  }
  showRedeemMod() {
    this.setState({ showRedeemModal: true });
  }
  hideRedeemMod() {
    this.setState({ showRedeemModal: false });
    this.fetchUserData();
  }

  showInviteMod() {
    this.setState({ showInviteModal: true });
  }
  hideInviteMod() {
    this.setState({ showInviteModal: false });
  }

  showPublicMod() {
    this.setState({ showPublicModal: true, openAccountModal: true });
  }
  hidePublicMod() {
    this.setState({
      showPublicModal: false,
      isAccountPublic:
        this.state.hideFriend ||
        this.state.disableAddFriend ||
        this.state.hideProfile
          ? true
          : false,
    });
  }

  openSeeMore() {
    this.setState({ openSeeMoreModal: true });
  }
  hideOpenSeeMoreModal() {
    this.setState({ openSeeMoreModal: false });
  }
  showPaypalMod() {
    this.setState({ showPaypalModal: true });
  }

  handlePaypalModalOpen() {
    this.setState({ showPaypalModal: false });
    this.fetchUserData();
  }
  closeSuccessModal() {
    this.setState({ showSuccessModal: false });
    window.location.replace("/myProfile");
  }
  closeErrorModal() {
    this.setState({ showErrorModal: false });
    window.location.replace("/myProfile");
  }
  showisEditPictureMod() {
    this.setState({ isEditPictureModOpen: true });
  }
  hideisEditPictureMod() {
    this.setState({ isEditPictureModOpen: false });
  }
  hideisEditPictureModAfterUpload() {
    this.setState({ isEditPictureModOpen: false });
    this.fetchUserData();
  }

  handleGameModalOpen() {
    this.setState({ gameTag: "", gameTagError: "" });
    this.setState({ isGameModalOpen: !this.state.isGameModalOpen });
    this.fetchUserData();
  }

  handleChange(event) {
    this.setState({ gameTag: event.target.value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location)
      this.setState({
        key:
          this.props.location.prevPath == "/payment"
            ? "wallet"
            : this.props?.location?.data === "settingsTab"
            ? "settingsTab"
            : this.props?.location?.data === "referfriend"
            ? "referfriend"
            : this.props.location?.state?.key === "teams"
            ? "teams"
            : "wallet",
        settingsKey:
          this.props?.location?.settingsKey == "gameIds"
            ? "gameIds"
            : "changePassword",
      });
    logoutEventPrivateRoutes();
  }

  handleMask(fieldKey) {
    const prevMaskDetails = { ...this.state.maskDetails };
    const newFieldValue = !prevMaskDetails[fieldKey];
    this.setState({
      maskDetails: { ...prevMaskDetails, [fieldKey]: newFieldValue },
    });
  }

  componentCleanup() {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    window.history.replaceState({}, document.title);
  }

  componentDidMount() {
    this.fetchUserData();
    this.fetchTwitterLink();
    //this.fetchFriends();

    window.addEventListener("beforeunload", this.componentCleanup);

    const code = new URLSearchParams(window.location.search).get("code");
    const scope = new URLSearchParams(window.location.search).get("scope");
    const oauth_token = new URLSearchParams(window.location.search).get(
      "oauth_token"
    );
    const oauth_verifier = new URLSearchParams(window.location.search).get(
      "oauth_verifier"
    );

    const stripeOnBoardUrl = new URLSearchParams(window.location.search).get(
      "redirect_url"
    );

    if (stripeOnBoardUrl) {
      window.location.href = stripeOnBoardUrl;
    }

    if (scope !== "" && scope !== null) {
      this.setState({ spinnerLoaderLogin: true });
      axios
        .post(
          `https://id.twitch.tv/oauth2/token?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_CLIENT_SECRET_KEY}&grant_type=authorization_code&redirect_uri=${this.state.urlChanger}&code=${code}`
        )
        .then((res) => {
          this.handleTwitch(res, code);
        })
        .catch((err) => {
          this.setState({ spinnerLoaderLogin: false });
        });
    } else if (oauth_token !== "" && oauth_token !== null) {
      this.setState({ spinnerLoaderLogin: true });
      const data = `${oauth_token}&${oauth_verifier}`;
      this.handleTwitter(data);
    } else if (code !== "" && code !== null) {
      this.setState({ spinnerLoaderLogin: true });
      const data = {
        client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
        client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET_KEY,
        code: code.toString(),
        grant_type: "authorization_code",
        scope: "identify",
        redirect_uri: getUrlChanger("myprofile"),
      };
      const config = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      axios
        .post(
          "https://discord.com/api/oauth2/token",
          new URLSearchParams(data),
          config
        )
        .then((res) => {
          this.handleDiscord(res, code);
        })
        .catch((err) => {
          this.setState({ spinnerLoaderLogin: false });
        });
    } else {
      return;
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup); // remove the event handler for normal unmounting
  }

  fetchUserData() {
    getUserDetail().then((res) => {
      this.setState({
        userData: res,
        hideFriend: res.profile.hide_friend_list,
        disableAddFriend: res.profile.hide_friend_list,
        hideProfile: res.profile.hide_profile_detail,
        isAccountPublic:
          res.profile.hide_friend_list || res.profile.hide_profile_detail
            ? true
            : false,
      });
    });
    if (this?.props?.location?.state?.createTeam) {
      setTimeout(() => {
        this.setState({ showCreateTeamModal: true });
      }, 3000);
    }
    const previousPath = localStorage.getItem("previousPath");
    if (previousPath != null) {
      this.props.history.push({
        pathname: previousPath,
      });
    }
  }

  fetchTwitterLink = async () => {
    const data = {
      redirect: "profile",
    };
    await twitterUrl(data).then((res) => {
      this.setState({ twitterLink: res.url });
    });
  };
  handleResponse = (data) => {
    this.setState({ spinnerLoaderLogin: true });
    socialLogin
      .then((res) => {
        if (res !== undefined) {
          toastify("success", "Facebook account linked successfully");
        }
        this.fetchUserData();
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      })
      .catch((err) => {
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      });
  };

  handleError = (error) => {
    this.props.history.push({
      pathname: "/myprofile",
    });
    this.setState({
      spinnerLoaderLogin: false,
      key: "settingsTab",
      settingsKey: "connectAccount",
    });
  };
  handleTwitch = async (twitchRes, code) => {
    const newToken = twitchRes.data.access_token;
    const data = {
      provider: "twitch",
      code: newToken,
    };

    await socialLogin(data)
      .then((twitchLoginRes) => {
        if (twitchLoginRes !== undefined) {
          toastify("success", "Twitch account linked successfully");
        }
        this.fetchUserData();
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      })
      .catch((err) => {
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      });
  };
  handleDiscord = async (discordRes, code) => {
    const data = {
      provider: "discord",
      code: discordRes.data.access_token,
    };
    await socialLogin(data)
      .then((socialLoginRes) => {
        if (socialLoginRes !== undefined) {
          toastify("success", "Discord account linked successfully");
        }
        this.fetchUserData();
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      })
      .catch((err) => {
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      });
  };
  handleTwitter = async (oauth) => {
    const data = {
      provider: "twitter",
      code: oauth,
    };
    await socialLogin(data)
      .then((twitterLoginRes) => {
        if (twitterLoginRes !== undefined) {
          toastify("success", "Twitter account linked successfully");
        }
        this.fetchUserData();
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      })
      .catch((err) => {
        this.props.history.push({
          pathname: "/myprofile",
        });
        this.setState({
          spinnerLoaderLogin: false,
          key: "settingsTab",
          settingsKey: "connectAccount",
        });
      });
  };
  protectAccount = (check) => {
    this.setState({
      isAccountPublic: check,
      openAccountModal: check,
    });

    this.showPublicMod();
  };
  handleCheckboxes(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onSubmitAccount = async (event) => {
    event.preventDefault();
    this.setState({ publicLoading: true });
    const data = {
      hide_friend: this.state.hideFriend,
      disable_add_friend: this.state.disableAddFriend,
      hide_profile: this.state.hideProfile,
    };
    await protectAccountData(data)
      .then((res) => {
        if (
          this.state.hideFriend ||
          this.state.disableAddFriend ||
          this.state.hideProfile
        ) {
          toastify("success", " Account is set to private account");
        } else {
          toastify("success", "Account is set to public account");
        }
        this.setState({ publicLoading: false });
        this.hidePublicMod();
      })
      .catch((err) => {
        this.setState({ publicLoading: false });
      });
  };
  openGamerTagMenu() {
    this.setState({
      isGameModalOpen: true,
    });
  }
  // Create team modal
  showCreateTeamMod() {
    this.setState({ showCreateTeamModal: true, callTeamApi: false });
  }
  hideCreateTeamModal() {
    this.setState({ showCreateTeamModal: false, gameName: null });
  }
  closeTeamModal(e) {
    this.setState({
      showCreateTeamModal: false,
      key: "teams",
      callTeamApi: true,
      gameName: e.name,
      gameMode: e.mode,
    });
  }
  stripeConnect = async () => {
    try {
      this.setState({ spinnerLoaderLogin: true });
      const { link } = await redeemAmount({
        amount: "50.00",
        payment_type: "stripe",
        payment_mode: "normal",
      });
      this.setState({ spinnerLoaderLogin: false });
      window.location.href = link;
    } catch (error) {
      this.setState({ spinnerLoaderLogin: false });
    }
  };

  hanndleOnBoarding() {
    window.location.href = this.state.userData?.stripe_account?.link;
  }
  cancel() {
    this.setState({
      showCreateTeamModal: false,
      gameName: null,
    });
  }
  ShowLoaderTrue = () => {
    this.setState({ spinnerLoaderLogin: true });
  };
  SetGoogleData = () => {
    this.props.history.push({
      pathname: "/myprofile",
    });
    this.setState({
      spinnerLoaderLogin: false,
      key: "settingsTab",
      settingsKey: "connectAccount",
    });
  };
  SetGoogleErrorData = () => {
    this.setState({
      spinnerLoaderLogin: false,
      key:
        this.props.location.prevPath == "/payment"
          ? "wallet"
          : this.props?.location?.data === "settingsTab"
          ? "settingsTab"
          : this.props?.location?.data === "referfriend"
          ? "referfriend"
          : this.props.location.data === "playerProfile"
          ? "friends"
          : this.props.location?.state?.key === "teams"
          ? "teams"
          : "wallet",
      settingsKey:
        this.props?.location?.settingsKey == "gameIds"
          ? "gameIds"
          : "changePassword",
    });
  };

  
  redirectToSocial = () => {
    this.setState({
      key: "settingsTab",
      settingsKey: "connectAccount",
    });
  };

  render() {
    return (
      <>
        <div className="slider-activation slider-creative-agency profile-slider">
          {this.state.spinnerLoaderLogin ? (
            <div class="loading"></div>
          ) : (
            <Parallax
              bgImage={"/assets/images/header-background.png"}
              strength={500}
              bgClassName="page-banner-parallax profile-banner-parallax"
            >
              <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1 className="title game-title theme-gradient">
                          My Profile
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          )}
        </div>
        {this.state.userData ? (
          <section className="my-profile">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="myprofile-wrapper">
                    <div className="profile-image">
                      <div class="edit-icon profile-edit">
                        <img
                          src="/assets/images/edit-pen.svg"
                          alt="edit"
                          onClick={() => this.showisEditPictureMod()}
                        />
                      </div>
                      <img
                        class="user-image"
                        src={
                          this.state.userData.profile?.avatar === "" ||
                          this.state.userData.profile?.avatar === "null" ||
                          this.state.userData.profile?.avatar === null
                            ? "/assets/images/profile-img.jpg"
                            : this.state.userData.profile?.avatar
                        }
                        alt="profile"
                        style={{ height: "190px", width: "180px" }}
                      />
                      <div class="size-recommendations">
                        <p>
                          Recommended Size:
                          <span>190px x 180px</span>
                        </p>
                      </div>
                    </div>
                    <div className="information">
                      <div className="info-user-wrapper">
                        <div className="username-wrapper">
                          <div className="username">
                            <h2>
                              {this.state.userData?.first_name +
                                " " +
                                this.state.userData?.last_name}
                            </h2>
                            {this.state.userData.inactivity ? (
                              <h3 className="inactive-tag">Inactive</h3>
                            ) : null}
                          </div>
                          {this.state.userData.profile.is_verified ? (
                            <>
                              <div class="verified-user-wrapper">
                                <div className="badge">
                                  <img
                                    src="/assets/images/verification-badge.svg"
                                    alt="badge"
                                  />
                                </div>
                                <div className="lines-wrapper">
                                  <div className="line"></div>
                                </div>
                                <div className="authenticated-wrapper">
                                  <p>Authenticated</p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="edit-profile-button medium-device-button d-flex">
                          <Button
                            className="createTeam"
                            variant="secondary"
                            onClick={() => this.showCreateTeamMod()}
                          >
                            Create Team
                          </Button>
                          <div>
                            <Button
                              variant="secondary"
                              onClick={() => this.showEditMod()}
                            >
                              {this.state.userData.phone === null &&
                              this.state.userData.address.full === ""
                                ? "Complete your profile here"
                                : "Edit profile"}
                            </Button>
                            <div class="progress-bar-wrapper">
                              {this.state.userData.profile?.avatar === "" ||
                              this.state.userData.profile?.avatar === "null" ||
                              this.state.userData.profile?.avatar ===
                                null ? null : this.state.userData.phone ===
                                  null &&
                                this.state.userData.address.full ===
                                  "" ? null : (
                                <>
                                  <label for="file"> 100% Complete</label>
                                  <span class="info-icon">
                                    <img
                                      src="/assets/images/info-icon.svg"
                                      alt="info"
                                      class="info-image"
                                      data-tip
                                      data-for="loginTip"
                                    />
                                  </span>
                                  <ReactTooltip
                                    id="loginTip"
                                    place="left"
                                    effect="solid"
                                  >
                                    Steps for profile completion
                                    <br />
                                    1. {STRINGS_CONSTANTS.signup}
                                    <br />
                                    2. Enter Profile Information
                                    <br />
                                    3. Upload Profile Photo
                                    <br />
                                    4. Enter Game ID
                                    <br />
                                  </ReactTooltip>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{ width: "100%" }}
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center maskDetailsDiv">
                        <h3 className="mb-0">
                          {this.state?.maskDetails.email
                            ? mask(this.state?.userData?.email)
                            : this.state.userData?.email}
                        </h3>
                        <img
                          src={`assets/images/${
                            this.state?.maskDetails.email ? "no-eye" : "eye"
                          }.svg`}
                          onClick={() => this.handleMask("email")}
                          alt=""
                          className="eye-icon"
                        />
                      </div>
                      <div className="activision-discord-wrapper d-none">
                        <div>
                          <h6>Activision:</h6>
                          <p>Soulgamin123</p>
                        </div>
                        <div className="discord">
                          <h6>Discord:</h6>
                          <p>Gaminsoul234</p>
                        </div>
                      </div>
                      <div class="ids-wrapper">
                        <p>
                          <span>Stripe Dashboard :{"   "}</span>
                          {this.state.userData?.stripe_account?.account_id ? (
                            this.state?.maskDetails.stripe_id ? (
                              mask(
                                this.state.userData?.stripe_account?.account_id
                              )
                            ) : (
                              <a
                                target="_blank"
                                href={
                                  this.state.userData?.stripe_account
                                    ?.login_link
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#00ccff",
                                  textDecorationLine: "underline",
                                }}
                              >
                                {
                                  this.state.userData?.stripe_account
                                    ?.account_id
                                }
                              </a>
                            )
                          ) : (
                            "NA"
                          )}
                          {this.state.userData?.stripe_account?.account_id ? (
                            <img
                              src={`assets/images/${
                                this.state?.maskDetails?.stripe_id
                                  ? "no-eye"
                                  : "eye"
                              }.svg`}
                              onClick={() => this.handleMask("stripe_id")}
                              alt=""
                              className="eye-icon"
                            />
                          ) : null}
                        </p>
                      </div>
                      {/* <div class="ids-wrapper">
                        <p>
                          <span>PayPal ID :{"   "}</span>
                          {this.state.userData?.profile?.paypal_id
                            ? this.state?.maskDetails.paypal
                              ? mask(this.state.userData?.profile?.paypal_id)
                              : this.state.userData?.profile?.paypal_id
                            : "NA"}
                          {this.state.userData?.profile?.paypal_id ? (
                            <img
                              src={`assets/images/${
                                this.state?.maskDetails.paypal
                                  ? "no-eye"
                                  : "eye"
                              }.svg`}
                              onClick={() => this.handleMask("paypal")}
                              alt=""
                              className="eye-icon"
                            />
                          ) : null}
                        </p>
                      </div> */}
                      <div class="ids-wrapper">
                        <p>
                          <span>Discord Username :{"   "}</span>
                          {this.state.userData?.profile?.discord_id
                            ? this.state?.maskDetails.discord
                              ? mask(this.state.userData?.profile?.discord_id)
                              : this.state.userData?.profile?.discord_id
                            : "NA"}

                          {this.state.userData?.profile?.discord_id ? (
                            <img
                              src={`assets/images/${
                                this.state?.maskDetails.discord
                                  ? "no-eye"
                                  : "eye"
                              }.svg`}
                              onClick={() => this.handleMask("discord")}
                              alt=""
                              className="eye-icon"
                            />
                          ) : null}
                        </p>
                      </div>

                      <div class="ids-wrapper">
                        <p>
                          <span>Free Lobby Limit :{"   "}</span>
                          {this.state.userData?.profile?.free_lobby_limit === 0
                            ? "0"
                            : this.state.userData?.profile?.free_lobby_limit}
                        </p>
                      </div>

                      <div class="ids-wrapper">
                        <p>
                          <span>Private Account</span>
                        </p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={this.protectAccount}
                            checked={this.state.isAccountPublic}
                            className="react-switch"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="edit-profile-button mobile-view-button">
                      <Button
                        className="createTeam"
                        variant="secondary"
                        onClick={() => this.showCreateTeamMod()}
                      >
                        Create Team
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => this.showEditMod()}
                      >
                        Edit Profile
                      </Button>
                      <div class="progress-bar-wrapper">
                        {this.state.userData.profile?.avatar === "" ||
                        this.state.userData.profile?.avatar === "null" ||
                        this.state.userData.profile?.avatar ===
                          null ? null : this.state.userData.phone === null &&
                          this.state.userData.address.full === "" ? null : (
                          <>
                            <label for="file"> 100% Complete</label>
                            <span class="info-icon">
                              <img
                                src="/assets/images/info-icon.svg"
                                alt="info"
                                class="info-image"
                                data-tip
                                data-for="loginTip"
                              />
                            </span>
                            <ReactTooltip
                              id="loginTip"
                              place="left"
                              effect="solid"
                            >
                              Steps for profile completion
                              <br />
                              1. Sign Up
                              <br />
                              2. Enter Profile Information
                              <br />
                              3. Upload Profile Photo
                              <br />
                              4. Enter Game ID
                              <br />
                            </ReactTooltip>
                            <div class="progress">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <ProgressProfile
                    userData={this.state?.userData}
                    showisEditPictureMod={() => this.showisEditPictureMod()}
                    openGamerTagMenu={() => this.openGamerTagMenu()}
                    openEditProfile={() => this.showEditMod()}
                  />

                  <Tabs
                    id="uncontrolled-tab-example"
                    activeKey={this.state.key}
                    onSelect={(k) =>
                      this.setState({
                        key: k,
                        searchText: "",
                        showSuggestion: false,
                        hideAllYourFriendsList: false,
                        showFriendTable: false,
                        callTeamApi: false,
                        showCreateTeamModal: false,
                      })
                    }
                  >
                    <Tab eventKey="wallet" title="WALLET">
                      <div className="wallet-funds-wrapper">
                        <div>
                          <h5>Current Balance</h5>
                          <p>
                            {`$${this.state.userData?.profile?.wallet_balance}`}
                          </p>
                        </div>
                        <div>
                          <h5>Promo Bonus</h5>
                          <p>{`$${this.state.userData?.profile?.bonus_balance}`}</p>
                        </div>
                        <div className="redeem-wallet-wrapper">
                          <div className="redeem-btn">
                            <Button
                              variant="primary"
                              onClick={() => this.showWalletMod()}
                              style={{ marginRight: "20px" }}
                            >
                              Add Money To Wallet
                            </Button>
                          </div>
                          <div>
                            <Button
                              variant="secondary"
                              onClick={() => this.showRedeemMod()}
                            >
                              Redeem Funds
                            </Button>
                          </div>

                          {/* <div>
                            <Button
                              variant="secondary"
                              className="add-paypal-id"
                              onClick={() => this.showPaypalMod()}
                            >
                              {this.state.userData?.profile?.paypal_id === null
                                ? "Add PayPal ID"
                                : "Edit PayPal ID"}
                            </Button>
                          </div> */}
                          <div className="mt-3 mt-sm-0 ">
                            {this.state.userData?.stripe_account?.status ==
                            "4" ? (
                              <Button
                                variant="secondary"
                                className="add-paypal-id"
                                onClick={() => this.stripeConnect()}
                              >
                                Stripe Connect
                              </Button>
                            ) : this.state.userData?.stripe_account?.status ==
                              "3" ? (
                              <Button
                                variant="secondary"
                                className="add-paypal-id"
                                onClick={() => this.hanndleOnBoarding()}
                              >
                                Stripe Onboarding
                              </Button>
                            ) : (
                              <Button
                                variant="secondary"
                                className="add-paypal-id"
                                style={{ cursor: "default" }}
                              >
                                Stripe Connected
                              </Button>
                            )}
                            <div className="me-0 text-right">
                              <a
                                target="_blank"
                                href="https://stripe.com/global"
                                className="supportedCountries"
                                style={{
                                  cursor: "pointer",
                                  color: "#00ccff",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Supported Countries
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <WalletTransaction
                        data={this.state.userData?.profile?.wallet_balance}
                      />
                    </Tab>
                    <Tab eventKey="referfriend" title="REFER A FRIEND">
                      <Referrals
                        UserData={this.state.userData}
                        ActiveTab={this.state.key}
                        openSeeMore={() => this.openSeeMore()}
                      />
                    </Tab>
                    <Tab eventKey="settingsTab" title="SETTINGS">
                      <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={this.state.settingsKey}
                        onSelect={(k) => this.setState({ settingsKey: k })}
                      >
                        <Tab eventKey="gameIds" title="Game IDs">
                          <div className="game-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                this.handleGameModalOpen();
                              }}
                            >
                              Gamertag
                            </button>
                            <div className="game-flex">
                              {this.state.userData?.game_accounts.length ===
                              0 ? (
                                <div>
                                  <p>No ID(s) Added Yet</p>
                                </div>
                              ) : (
                                <GameIdsTable
                                  GameAccounts={
                                    this.state.userData?.game_accounts
                                  }
                                  fetchUserData={() => this.fetchUserData()}
                                />
                              )}
                            </div>
                          </div>
                        </Tab>

                        <Tab eventKey="changePassword" title="Change Password">
                          <ChangePassword />
                        </Tab>

                        <Tab eventKey="connectAccount" title="Socials">
                          <Socials
                            Provider={this.state.userData.providers}
                            TwitterLink={this.state.twitterLink}
                            HandleResponse={() => this.handleResponse()}
                            HandleError={() => this.handleError()}
                            FetchUserData={() => this.fetchUserData()}
                            LoaderTrue={() => this.ShowLoaderTrue()}
                            GoogleData={() => this.SetGoogleData()}
                            GoogleErrorData={() => this.SetGoogleErrorData()}
                          />
                        </Tab>
                        <Tab eventKey="notifications" title="Notifications">
                          <Notifications
                            profileData={this.state.userData?.profile}
                          />
                        </Tab>
                      </Tabs>
                    </Tab>

                    <Tab
                      eventKey="friends"
                      title="FRIENDS"
                      className="searchTableBox"
                    >
                      <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={this.state.friendsKey}
                        onSelect={(k) => this.setState({ friendsKey: k })}
                      >
                        <Tab eventKey="my-friends" title="MY FRIENDS">
                          <Friends
                          // ActiveTab={this.state.key}
                          // SubActiveTab={this.state.friendsKey}
                          />
                        </Tab>
                        <Tab
                          eventKey="prending-friends"
                          title="PENDING FRIEND REQUEST"
                        >
                          <FriendsRequest />
                        </Tab>
                      </Tabs>
                    </Tab>

                    <Tab eventKey="teams" title="TEAMS">
                      <TeamModule
                        activeKey={this.state.key}
                        callTeam={this.state.callTeamApi}
                        gameName={this.state.gameName}
                        gameMode={this.state.gameMode}
                        onCancel={() => this.cancel()}
                        showloader={() => this.showloader()}
                        hideloader={() => this.hideloader()}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <Modal
              className="profile-modal-box"
              id="upload-picture-modal"
              show={this.state.isEditPictureModOpen}
              onHide={() => this.hideisEditPictureMod()}
              backdrop="static"
            >
              <EditPicture
                closeEditPictureModal={() =>
                  this.hideisEditPictureModAfterUpload()
                }
              />
            </Modal>

            <Modal
              className="profile-modal-box"
              show={this.state.showEditModal}
              onHide={() => this.hideEditMod()}
              backdrop="static"
            >
              {this.state.spinnerLoadder ? (
                <Spinner animation="grow" />
              ) : (
                <EditProfile
                  userInfo={this.state.userData}
                  closeEditModal={() => this.hideEditMod()}
                  redirectToSocial={() => this.redirectToSocial()}
                />
              )}
            </Modal>
            <Modal
              contentClassName="qr-verification-wrapper"
              show={this.state.showWalletModal}
              onHide={() => this.hideWalletMod()}
              backdrop="static"
            >
              {this.state.userData.phone === null ||
              this.state.userData.phone === "" ? (
                <>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="money-added">
                    <div className="tick-image">
                      <img src="/assets/images/tick.svg" alt="" />
                    </div>
                    <h3>
                      {this.state.userData.phone === null ||
                      this.state.userData.phone === ""
                        ? this.state.userData.address.full === ""
                          ? "Please Update your profile"
                          : "Please Verify your phone number"
                        : "Please Update your profile"}
                    </h3>
                  </Modal.Body>
                </>
              ) : (
                <AddToWallet
                  prevAmount={this.state.prevAmount}
                  data={this.state.userData?.email}
                  walletBalance={this.state.userData?.profile.wallet_balance}
                  closeModal={() => this.hideWalletMod()}
                />
              )}
            </Modal>

            <Modal
              contentClassName="qr-verification-wrapper"
              show={this.state.showRedeemModal}
              onHide={() => this.hideRedeemMod()}
              backdrop="static"
            >
              {this.state.userData.phone === null ||
              this.state.userData.phone === "" ? (
                <>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="money-added">
                    <div className="tick-image">
                      <img src="/assets/images/tick.svg" alt="" />
                    </div>
                    <h3>
                      {this.state.userData.phone === null ||
                      this.state.userData.phone === ""
                        ? this.state.userData.address.full === ""
                          ? "Please Update your profile"
                          : "Please Verify your phone number"
                        : "Please Update your profile"}
                    </h3>
                  </Modal.Body>
                </>
              ) : (
                <RedeemFunds
                  wallet_balance={this.state.userData?.profile?.wallet_balance}
                  paypal_id={this.state.userData?.profile?.paypal_id}
                  fetchUserData={() => this.fetchUserData()}
                  stripeObject={this.state.userData?.stripe_account}
                />
              )}
            </Modal>

            <Modal
              show={this.state.isGameModalOpen}
              onHide={() => this.handleGameModalOpen()}
              backdrop="static"
            >
              <GameAccount
                addedGameAccounts={this.state.userData?.game_accounts}
                closeGameModal={() => this.handleGameModalOpen()}
              />
            </Modal>
            <Modal
              show={this.state.showPaypalModal}
              onHide={() => this.handlePaypalModalOpen()}
              backdrop="static"
            >
              <PaypalAccount
                paypalAccount={this.state.userData?.profile?.paypal_id}
                closePaypalModal={() => this.handlePaypalModalOpen()}
              />
            </Modal>
            {/* refer a friend benefits modal start */}
            <Modal
              show={this.state.openSeeMoreModal}
              onHide={() => this.hideOpenSeeMoreModal()}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="invite-body text-center">
                <div className="refer-friend-icons">
                  <img
                    src="/assets/images/refer-earn-Icon.svg"
                    alt="gift-icon"
                  />
                  <p className="invite-para">REFER A FRIEND BENEFITS</p>
                  <p className="benefit-content">
                    1. Refer a Friend and they get a $10 promo bonus (not
                    redeemable for cash) with a $20 initial deposit or more at
                    the time of sign up within the first 14 days if they sign up
                    for a new account and use your affiliate code. You may not
                    redeem the bonus money.
                  </p>
                  <p className="benefit-content">
                    2. The Ryvals affiliate program pays you 10% of the net
                    lobby fees collected from any referral using your code
                    during their first 60 days as a Ryvals customer. So, for
                    example, if Ryvals generates $100 in lobby fees from a
                    player’s game play, Ryvals will pay you $10 if that player
                    was using your affiliate code.
                  </p>
                  <Link
                    to={{ pathname: "/faq", data: "refer" }}
                    className="btn btn-primary enroll-button see-more-btn"
                  >
                    See More
                  </Link>
                </div>
              </Modal.Body>
            </Modal>

            {/* Public and Private account Modal */}

            {this.state.openAccountModal ? (
              <Modal
                show={this.state.showPublicModal}
                onHide={() => this.hidePublicMod()}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>

                <form onSubmit={this.onSubmitAccount}>
                  <Modal.Body className="invite-body text-center">
                    <div className="refer-friend-icons">
                      <p className="invite-para private-account">
                        PRIVATE ACCOUNT
                      </p>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Hide Friend List"
                        className="privateAccount"
                        name="hideFriend"
                        id="hideFriend"
                        onChange={this.handleCheckboxes}
                        defaultChecked={this.state.hideFriend}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Disable Add Friend option"
                        className="privateAccount"
                        name="disableAddFriend"
                        onChange={this.handleCheckboxes}
                        id="disableAddFriend"
                        defaultChecked={this.state.disableAddFriend}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Hide Profile details"
                        className="privateAccount"
                        name="hideProfile"
                        id="hideProfile"
                        onChange={this.handleCheckboxes}
                        defaultChecked={this.state.hideProfile}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="yes-btn" type="submit">
                      {this.state.publicLoading && (
                        <span className="spinner-border spinner-border-sm mr-4"></span>
                      )}
                      Submit
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            ) : null}
          </section>
        ) : null}

        <Modal
          className="create-team-modal-box"
          show={this.state.showCreateTeamModal}
          onHide={() => this.hideCreateTeamModal()}
          backdrop="static"
        >
          <TeamModal
            onComplete={(e) => this.closeTeamModal(e)}
            EditId={null}
            locationProps={this?.props?.location?.state}
          />
        </Modal>
      </>
    );
  }
}
export default withRouter(MyProfile);

import React, { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { getUrlChanger } from "../../helper/utilities.js";
import ReactTooltip from "react-tooltip";
const ReferralShare = (props) => {
  const shareUrl = getUrlChanger("referral");
  const [LinkCopied, setLinkCopied] = useState(false);
  const copyLink = (text) => {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(text);
    //toastify("info", "Referral code copied");
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 1000);
  };
  return (
    <div className="refer-friend-icons">
      <img src="/assets/images/refer.svg" alt="refer-icon" />
      <p className="invite-para">Refer Friends and earn</p>
      <FacebookShareButton
        url={`${shareUrl}${props.ReferCode}`}
        quote="Please use my referral code to start earning on ryvals"
        className="Demo__some-network__share-button"
      >
        <img
          src="/assets/images/facebook-one.svg"
          alt="facebook"
          style={{ cursor: "pointer" }}
          className="social-login refer-social"
          data-tip
          data-for="socialInvite"
        />
        <ReactTooltip id="socialInvite" place="bottom" effect="solid">
          Facebook
        </ReactTooltip>{" "}
      </FacebookShareButton>
      <TwitterShareButton
        url={`${shareUrl}${props.ReferCode}`}
        title="Please use my referral code to start earning on ryvals"
        className="Demo__some-network__share-button"
      >
        <img
          src="/assets/images/twitter-one.svg"
          alt="twitter"
          style={{ cursor: "pointer" }}
          className="social-login refer-social"
          data-tip
          data-for="socialInviteTwitter"
        />
        <ReactTooltip id="socialInviteTwitter" place="bottom" effect="solid">
          Twitter
        </ReactTooltip>{" "}
      </TwitterShareButton>
      <EmailShareButton
        url={`${shareUrl}${props.ReferCode}`}
        subject={`You have been invited by ${props.UserData.username} to sign up and enrol on Ryvals.`}
        body={`Dear,
You have been invited by ${props.UserData.username} to sign up and enrol on Ryvals.
Please enter this code ${props.ReferCode} during signup process, or click on the link below to navigate to the sign up page.
Thanks, 
Ryvals 
Link: `}
        className="Demo__some-network__share-button"
      >
        <img
          src="/assets/images/email-icon.svg"
          alt="Email"
          style={{ cursor: "pointer" }}
          className="social-login refer-social"
          data-tip
          data-for="socialInviteEmail"
        />
        <ReactTooltip id="socialInviteEmail" place="bottom" effect="solid">
          Email
        </ReactTooltip>{" "}
      </EmailShareButton>
      <WhatsappShareButton
        url={`${shareUrl}${props.ReferCode}`}
        title={`You have been invited by ${props.UserData.username} to sign up and enrol on Ryvals.`}
        separator=":: "
        className="Demo__some-network__share-button"
      >
        <img
          src="/assets/images/whatsapp-icon.svg"
          alt="whatsapp"
          style={{ cursor: "pointer" }}
          className="social-login refer-social"
          data-tip
          data-for="socialInviteWhat"
        />
        <ReactTooltip id="socialInviteWhat" place="bottom" effect="solid">
          Whatsapp
        </ReactTooltip>{" "}
      </WhatsappShareButton>
      <img
        src="/assets/images/copy-icon2.svg"
        alt="Copy url"
        style={{ cursor: "pointer" }}
        className="social-login refer-social"
        data-tip
        data-for="socialInviteCopy"
        onClick={() => copyLink(`${shareUrl}${props.ReferCode}`)}
      />
      <ReactTooltip id="socialInviteCopy" place="bottom" effect="solid">
        {LinkCopied ? "Invite link copied " : "Copy invite link"}
      </ReactTooltip>{" "}
    </div>
  );
};
export default ReferralShare;

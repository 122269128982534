import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GameContext } from "../../context/GameContext";
const GameTraditionalSection = (props) => {
  const { state } = useContext(GameContext);
  const loggedUser = localStorage.getItem("userProfile");
  return (
    <React.Fragment>
      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper">
          <div
            className={
              loggedUser
                ? "container games-container"
                : "container-container1 games-container"
            }
          >
            <div className="row justify-content-center">
              {state.game.length === 0
                ? ""
                : props.activeTabKey === "killRace"
                ? state.game.killRaceGames.map((game, i) => (
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2"
                      key={i}
                    >
                      <Link
                        to={`/${game.slug}/lobby`}
                        style={
                          game?.game_status == 2
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        <div className="sliders apexLegendsBox">
                          <img
                            src={game.background_image}
                            className="card-img "
                            alt="..."
                          />
                          <h6 className="newBlue-color">{game.name}</h6>
                        </div>
                      </Link>
                    </div>
                  ))
                : state.game.traditionalGames.map((game, i) => (
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2"
                      key={i}
                    >
                      <Link
                        to={{
                          pathname: `/${game.slug}/lobby`,
                          state: { challengeTab: true },
                        }}
                        style={
                          game?.game_status == 2
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        <div className="sliders apexLegendsBox">
                          <img
                            src={game.slug == "apex-legends" ? game.traditional_image:game.background_image}
                            className="card-img "
                            alt="..."
                          />
                          <h6 className="newBlue-color">{game.name}</h6>
                        </div>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GameTraditionalSection;

import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function communitylisting() {
  const url = baseURL + `players/community/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}
export async function getTopPlayerList() {
  const url = baseURL + `players/top-players/`;

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

import React, { useEffect, useState } from "react";
import { getBracketsTempalate } from "../../../services/tournament.service";
import { Spinner } from "react-bootstrap";

const ViewBracket = ({ bracketId }) => {
  const [htmlSource, setHtmlSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getBracketsTempalate(bracketId);
        const modifiedHTML = removeEmbedLogo(response);
        setHtmlSource(modifiedHTML);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    if (bracketId !== undefined) {
      setIsLoading(true);
      fetchData();
    }
  }, [bracketId]);

  const removeEmbedLogo = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const embedLogoElement = doc.getElementById("embed-logo");
    if (embedLogoElement) {
      embedLogoElement.remove();
    }
    return doc.documentElement.outerHTML;
  };
  return (
    <div>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <iframe
          id="my-iframe"
          title="Bracket Preview Tournament"
          srcdoc={htmlSource}
          width="100%"
          height="500"
        ></iframe>
      )}
    </div>
  );
};

export default ViewBracket;

import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Row, FormLabel, ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  createTeam,
  getTeamById,
  editTeam,
  removeTeamMate,
} from "../../services/user.service";
import toastify from "../../helper/toastify.jsx";
import { useGame } from "../../context/GameContext";

import { ConsoleHelper, teamStatusText } from "../../helper/utilities";
import SearchTeammate from "./SearchTeammate";
const TeamModal = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [spinnerLoader, setSpinnerLoadder] = useState(false);
  const [minimumTeamMateRequired, setMinimumTeamMateRequired] = useState(null);
  const { state } = useGame();
  const TEAM_LIMIT = 20;
  useEffect(() => {
    if (props.EditId != null) {
      setSpinnerLoadder(true);
      getTeamById(props.EditId)
        .then((res) => {
          setTeamData(res);
          reset(res);
          setSpinnerLoadder(false);
          setMinimumTeamMateRequired(res.mode - res.has_player);
        })
        .catch(() => {
          setSpinnerLoadder(false);
        });
    }
  }, []);
  // form validation rules
  const validationSchema = Yup.object().shape({
    game_id:
      props.EditId != null
        ? Yup.string().notRequired()
        : Yup.string().required("Game is required"),
    mode: Yup.string().required("Team Mode is required"),
    name: Yup.string()
      .required("Team name is required")
      .max(
        TEAM_LIMIT,
        `Maximum limit ${TEAM_LIMIT} characters reached for team name`
      ),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      game_id: props?.locationProps?.game || props.GameName,
      mode: props?.locationProps?.mode || teamData?.mode,
      name: teamData?.name,
    },
    resolver: yupResolver(validationSchema),
  });

  const GAME_ID = watch("game_id");

  const onSubmit = async (data) => {
    showLoader(true);
    if (props.EditId != null) {
      const body = {
        game_id: data.game,
        name: data.name,
        mode: data.mode,
      };
      await editTeam(body, props.EditId)
        .then((res) => {
          toastify("success", "Team updated successfully");
          showLoader(false);
          props.onComplete();
          props.callTeam();
        })
        .catch((err) => {
          showLoader(false);
        });
      showLoader(false);
    } else {
      await createTeam(data)
        .then((res) => {
          toastify("success", "Team created successfully");
          showLoader(false);
          props.onComplete(res);
          props.callTeam();
        })
        .catch((err) => {
          showLoader(false);
        });
    }
  };
  const showLoader = (load) => {
    setisLoading(load);
  };
  const handleRemoveTeamMate = (teamMateId) => {
    setSpinnerLoadder(true);
    removeTeamMate(props.EditId, teamMateId)
      .then((res) => {
        setSpinnerLoadder(false);
        toastify("success", "Teammate removed successfully");
        callTeamData();
      })
      .catch((err) => {
        setSpinnerLoadder(false);
        ConsoleHelper(err);
      });
  };
  const callTeamData = () => {
    setSpinnerLoadder(true);
    getTeamById(props.EditId)
      .then((res) => {
        setTeamData(res);
        reset(res);
        setSpinnerLoadder(false);
        setMinimumTeamMateRequired(res.mode - res.has_player);
      })
      .catch(() => {
        setSpinnerLoadder(false);
      });
  };
  return (
    <div className="challengeTeamModal">
      {spinnerLoader ? <div className="loading" /> : null}
      <Modal.Header closeButton>
        <Modal.Title>
          {props.EditId === null ? "Create Team" : "Edit Team"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="file-complaint-wrapper wallet-body">
        <div className="player-details">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="margins">
              <Form.Group
                as={Col}
                className="col-lg-6 col-md-6 col-sm-6 col-12 first-name-col"
                controlId="formGridGame"
              >
                <FormLabel className="createTeamName">Game Name</FormLabel>
                {props.EditId != null ? (
                  <Form.Control
                    name="game_id"
                    {...register("game_id")}
                    defaultValue={props.GameName}
                    disabled
                  />
                ) : (
                  <>
                    <Form.Select
                      aria-label="Default select example"
                      name="game_id"
                      {...register("game_id")}
                      className={`mb-0 input-field ${
                        errors.game_id ? "is-invalid" : ""
                      }`}
                      classNamePrefix="react-select-createteam "
                    >
                      <option value="">Select Game</option>
                      {state.game.length === 0
                        ? ""
                        : state.game?.allGames?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                    </Form.Select>
                    {errors.game_id?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.game_id?.message}
                      </div>
                    )}
                  </>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridMode">
                <FormLabel className="createTeamName createGameMode">
                  Team Mode
                </FormLabel>
                <Form.Select
                  aria-label="Default select example"
                  name="mode"
                  {...register("mode")}
                  className={`mb-0 input-field ${
                    errors.mode ? "is-invalid" : ""
                  }`}
                  disabled={props.EditId}
                >
                  <option value="">Select Mode</option>
                  {state?.game?.allGames
                    ?.find((gameObj) => {
                      return (
                        gameObj.id == GAME_ID || gameObj.id == teamData?.game
                      );
                    })
                    ?.game_allowed_mode?.map((item) => (
                      <option value={item.mode}>{item.mode_type}</option>
                    ))}
                </Form.Select>
                {errors.mode?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.mode?.message}
                  </div>
                )}
              </Form.Group>
            </Row>

            <Form.Group as={Col} controlId="formGridTeam">
              <FormLabel className="createTeamName mt--20">Team Name</FormLabel>
              <Form.Control
                placeholder="Team Name"
                name="name"
                {...register("name")}
                className={`${errors.name ? "is-invalid" : ""}`}
              />{" "}
              {errors.name?.message && (
                <div className="invalid-feedback d-block">
                  {errors.name?.message}
                </div>
              )}
            </Form.Group>
            <Row className="margins"></Row>
            <Button
              type="submit"
              variant="primary"
              disabled={isDirty ? isLoading : true}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
          {props.EditId === null ? null : (
            <div className="addNewTeamMates">
              <h4>
                Add{" "}
                {minimumTeamMateRequired == 0 ? null : minimumTeamMateRequired}{" "}
                Teammates
              </h4>
              <SearchTeammate
                TeamId={props.EditId}
                TeamList={teamData?.players}
                onInviteSend={() => callTeamData()}
              />

              {teamData?.players?.length == 0 ? (
                <ListGroup>
                  <ListGroup.Item>No team member</ListGroup.Item>
                </ListGroup>
              ) : (
                <ListGroup>
                  {teamData?.players &&
                    teamData?.players.map((data, idx) => {
                      const teamStatusConst = teamStatusText.map(
                        (type) => type
                      );
                      const statusOfTeamMemberRequest = teamStatusConst.filter(
                        (item) => item.value === data.status
                      );
                      return (
                        <ListGroup.Item
                          className="createList d-flex justify-content-between"
                          key={idx}
                        >
                          <p className="createPlayerName">
                            {data.player__username}
                          </p>
                          <div className="removeTeamMate">
                            <span>
                              {data.leader
                                ? null
                                : statusOfTeamMemberRequest[0].label}
                            </span>
                            {data.leader ? null : (
                              <Button
                                className="mt-0 ms-3"
                                onClick={() =>
                                  handleRemoveTeamMate(data.player__id)
                                }
                              >
                                x
                              </Button>
                            )}
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </div>
  );
};

export default TeamModal;

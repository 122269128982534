import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { changeUserPassword } from "../../services/user.service";
import toastify from "../../helper/toastify.jsx";
import { useDispatch } from "react-redux";
import { logout } from "../../services/authService";
import { logoutUser } from "../../store/auth/login/actions";
import PasswordValidation from "../passwordValidation";
import {
  passWordLeftBullets,
  PasswordRightBullets,
} from "../../constants/constantData.js";
import { VALIDATIONS_ERROR } from "../../helper/stringConstants";
import {
  YupSchemaConfirmPassword,
  YupSchemaPassword,
} from "../../helper/utilities";
const ChangePassword = () => {
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  // form validation rules
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .trim()
      .required(VALIDATIONS_ERROR.currentPassword),
    password: YupSchemaPassword(),
    confirmPassword: YupSchemaConfirmPassword(),
  });

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { currentPassword: "", password: "", confirmPassword: "" },
    resolver: yupResolver(validationSchema),
  });
  async function onSubmit(data) {
    setisLoading(true);
    const model = {
      current_password: data.currentPassword,
      new_password: data.password,
      confirm_password: data.confirmPassword,
    };
    await changeUserPassword(model)
      .then((res) => {
        setisLoading(false);

        toastify("success", res.message);

        setTimeout(() => {
          toastify("success", "Please sign in again");
          logout("changePassword");
          dispatch(logoutUser());
        }, 2000);
      })
      .catch((err) => {
        setisLoading(false);
      });
  }

  const togglePassword = () => {
    setshowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };
  const toggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
        className="col-md-6 col-sm-6"
      >
        {" "}
        <Form.Group
          as={Col}
          controlId="formGridEmail"
          className="col-lg-12 col-md-12 col-sm-12 col-12 first-name-col error-msgs change-password-fields"
        >
          <Form.Control
            type={showCurrentPassword ? "text" : "password"}
            name="currentPassword"
            placeholder="Current Password"
            {...register("currentPassword")}
            className={`${errors.currentPassword ? "is-invalid" : ""}`}
            autoComplete="off"
          />
          <img
            className="passowrd-field"
            onClick={toggleCurrentPassword}
            src={`assets/images/${showCurrentPassword ? "no-eye" : "eye"}.svg`}
            alt={showCurrentPassword ? "hide" : "show"}
            title={showCurrentPassword ? "hide" : "show"}
            style={{ cursor: "pointer" }}
          />
          {errors.currentPassword?.message && (
            <div className="invalid-feedback">
              {errors.currentPassword?.message}
            </div>
          )}
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="formGridEmail"
          className="col-lg-12 col-md-12 col-sm-12 col-12 first-name-col error-msgs change-password-field"
        >
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            id="item02"
            placeholder="Enter New Password"
            {...register("password")}
            className={`${errors.password ? "is-invalid" : ""}`}
            autoComplete="off"
          />
          <img
            className="passowrd-field"
            onClick={togglePassword}
            src={`assets/images/${showPassword ? "no-eye" : "eye"}.svg`}
            alt={showPassword ? "hide" : "show"}
            title={showPassword ? "hide" : "show"}
            style={{ cursor: "pointer" }}
          />
        </Form.Group>
        {errors.password === undefined ? (
          <div className="d-block password-guidelines mt-3">
            {" "}
            <PasswordValidation
              leftBulltets={passWordLeftBullets}
              rightBullets={PasswordRightBullets}
            />
          </div>
        ) : errors.password?.message === "Password is required" ? (
          <>
            <div className="invalid-feedback d-block mb-2">
              {errors.password?.message}
            </div>
            <div className="d-block password-guidelines mt-3">
              <PasswordValidation
                leftBulltets={passWordLeftBullets}
                rightBullets={PasswordRightBullets}
              />
            </div>
          </>
        ) : (
          errors.password?.message && (
            <div className="invalid-feedback d-block pass-error-msg mt-3">
              <PasswordValidation
                leftBulltets={passWordLeftBullets}
                rightBullets={PasswordRightBullets}
              />
            </div>
          )
        )}
        <Form.Group
          as={Col}
          controlId="formGridEmail"
          className="col-lg-12 col-md-12 col-sm-12 col-12 first-name-col error-msgs change-password-field"
        >
          <Form.Control
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            id="item03"
            placeholder="Confirm New Password"
            {...register("confirmPassword")}
            className={`${errors.confirmPassword ? "is-invalid" : ""}`}
            autoComplete="off"
          />
          <img
            onClick={toggleConfirmPassword}
            src={`assets/images/${showConfirmPassword ? "no-eye" : "eye"}.svg`}
            alt={showConfirmPassword ? "hide" : "show"}
            title={showConfirmPassword ? "hide" : "show"}
            style={{ cursor: "pointer" }}
          />
          {errors.confirmPassword?.message && (
            <div className="invalid-feedback d-block">
              {errors.confirmPassword?.message}
            </div>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" className="change-password-btn">
          {isLoading ? (
            <>
              <span className="spinner-border spinner-border-sm mr-4"> </span>{" "}
              <label> Please wait </label>
            </>
          ) : (
            "Update"
          )}
        </Button>
      </form>
    </>
  );
};

export default ChangePassword;

import React from "react";
import { Modal } from "react-bootstrap";
import AddToWallet from "../../payment/AddToWallet";

const AddWalletModal = ({ closeAddToWallet, games, details }) => {
  return (
    <Modal
      show={true}
      onHide={closeAddToWallet}
      contentClassName="qr-verification-wrapper"
      backdrop="static"
    >
      <AddToWallet
        gameName={games.filter((game) => details.game == game.id)?.[0]?.name}
        closeModal={closeAddToWallet}
      />
    </Modal>
  );
};

export default AddWalletModal;

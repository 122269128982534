import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { getReferData } from "../../services/user.service";
import ReactTooltip from "react-tooltip";
import Paginator from "../../helper/paginator/Paginator";
import ReferralShare from "./ReferralShare";
const Referrals = (props) => {
  const [referData, setReferData] = useState(null);
  const [referResult, setReferResult] = useState(null);
  const [referTotalCount, setReferTotalCount] = useState(null);
  const [ascUsername, setAscUsername] = useState(true);
  const [ascDate, setAscDate] = useState(true);
  const [ascMonthlyEarning, setAscMonthlyEarning] = useState(true);
  const [ascTotalEarning, setAscTotalEarning] = useState(true);

  const [pageNum, setPageNum] = useState(1);
  const [codeCopied, setCodeCopied] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (props.ActiveTab == "referfriend") FetchReferrals();
  }, [props.ActiveTab]);
  const FetchReferrals = async () => {
    const filter = {
      isPaginated: true,
      pageNum: pageNum,
      pageSize: 25,
    };
    setLoader(true);
    await getReferData(filter).then((res) => {
      if (filter.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
      }

      setLoader(false);
    });
  };
  const requestSortAsecFirst = async () => {
    const filters = filterModel("username", "asc");
    await getReferData(filters).then((res) => {
      if (filters.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
        setAscUsername(false);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
        setAscUsername(false);
      }
    });
  };
  const requestSortDsecFirst = async () => {
    const filters = filterModel("username", "desc");
    await getReferData(filters).then((res) => {
      if (filters.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
        setAscUsername(true);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
        setAscUsername(true);
      }
    });
  };

  const requestSortAsecDate = async () => {
    const filters = filterModel("creation", "asc");
    await getReferData(filters).then((res) => {
      if (filters.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
        setAscDate(false);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
        setAscDate(false);
      }
    });
  };
  const requestSortDsecDate = async () => {
    const filters = filterModel("creation", "desc");
    await getReferData(filters).then((res) => {
      if (filters.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
        setAscDate(true);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
        setAscDate(true);
      }
    });
  };
  const requestSortAsecMonthlyEarning = () => {
    const list = referResult.sort((a, b) =>
      a.monthly_ref_earn > b.monthly_ref_earn ? 1 : -1
    );
    setReferResult(list);
    setAscMonthlyEarning(false);
  };
  const requestSortDescMonthlyEarning = () => {
    const list = referResult.sort((a, b) =>
      a.monthly_ref_earn > b.monthly_ref_earn ? -1 : 1
    );
    setReferResult(list);
    setAscMonthlyEarning(true);
  };

  const requestSortAsecTotalEarning = () => {
    const list = referResult.sort((a, b) =>
      a.total_referral_earn > b.total_referral_earn ? 1 : -1
    );
    setReferResult(list);
    setAscTotalEarning(false);
  };
  const requestSortDescTotalEarning = () => {
    const list = referResult.sort((a, b) =>
      a.total_referral_earn > b.total_referral_earn ? -1 : 1
    );
    setReferResult(list);
    setAscTotalEarning(true);
  };
  function filterModel(column, order) {
    return {
      isPaginated: true,
      pageNum: pageNum,
      pageSize: 25,
      column: column,
      order: order,
    };
  }
  const handlePageClick = async (newPageNum) => {
    setPageNum(newPageNum);
    const filter = {
      isPaginated: true,
      pageNum: newPageNum,
      pageSize: 25,
    };
    await getReferData(filter).then((res) => {
      if (filter.isPaginated) {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.total);
      } else {
        setReferData(res.data.info);
        setReferResult(res.data.results);
        setReferTotalCount(res.length);
      }
    });
  };
  const copyText = (text) => {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(text);
    //toastify("info", "Referral code copied");
    setCodeCopied(true);
    setTimeout(() => {
      setCodeCopied(false);
    }, 1000);
  };

  const showInviteMod = () => {
    setShowInviteModal(true);
  };
  const hideInviteMod = () => {
    setShowInviteModal(false);
  };
  return (
    <>
      {loader ? (
        <Spinner animation="grow" />
      ) : (
        <>
          {" "}
          <div className="wallet-funds-wrapper">
            <div>
              <h5>Total Earnings</h5>
              <p className="referFriend-earnings">
                {referData?.total_earn === null
                  ? "$0.00"
                  : `$${referData?.total_earn.toFixed(2)}`}
              </p>
            </div>
            <div>
              <h5 className="d-flex">
                Monthly Earnings{"  "}
                <span class="info-icon monthly-info">
                  <img
                    src="/assets/images/info-icon.svg"
                    alt="info"
                    class="info-image"
                    data-tip
                    data-for="monthlyInfo"
                  />
                </span>
                <ReactTooltip id="monthlyInfo" place="right" effect="solid">
                  Starts on the first day of the month.
                </ReactTooltip>
              </h5>
              <p className="referFriend-earnings">
                {referData?.monthly_earn === null
                  ? "$0.00"
                  : `$${referData?.monthly_earn.toFixed(2)}`}
              </p>
            </div>
            <div className="redeem-wallet-wrapper">
              <div className="redeem-btn d-flex copy-code">
                <input
                  className="copy-text"
                  placeholder={referData?.referral_code}
                  disabled
                />
                <button
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => copyText(referData?.referral_code)}
                  data-tip
                  data-for="copy"
                >
                  <img src="/assets/images/copy-icon.svg" />
                  Copy
                </button>
                <ReactTooltip id="copy" place="bottom" effect="solid">
                  {codeCopied
                    ? "Referral code copied "
                    : "Copy referral code to clipboard"}
                </ReactTooltip>{" "}
              </div>
              <div>
                <Button
                  className="invite-btn"
                  variant="secondary"
                  onClick={() => showInviteMod()}
                >
                  INVITE
                </Button>
                <span class="info-icon">
                  <img
                    src="/assets/images/info-icon.svg"
                    alt="info"
                    class="info-image"
                    data-tip
                    data-for="clickBenefit"
                    onClick={() => props?.openSeeMore()}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <ReactTooltip id="clickBenefit" place="bottom" effect="solid">
                  Click here to see benefits
                </ReactTooltip>
              </div>
            </div>
          </div>
          {referTotalCount ? (
            <>
              <table style={{ color: "white" }}>
                <tr>
                  <th>
                    Friend's Username
                    {ascUsername ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecFirst()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDsecFirst()}
                      />
                    )}
                  </th>
                  <th>
                    Date
                    {ascDate ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecDate()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDsecDate()}
                      />
                    )}
                  </th>
                  <th>
                    Monthly Earning
                    {ascMonthlyEarning ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecMonthlyEarning()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDescMonthlyEarning()}
                      />
                    )}
                  </th>
                  <th>
                    Total Earning
                    {ascTotalEarning ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecTotalEarning()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDescTotalEarning()}
                      />
                    )}
                  </th>
                </tr>
                {referResult &&
                  referResult.map((data, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p style={{ textTransform: "uppercase" }}>
                            {data.username}
                          </p>
                        </td>
                        <td>
                          <p>
                            {new Date(data.date).toLocaleString("en-US", {
                              //weekday: "short", // long, short, narrow
                              day: "numeric", // numeric, 2-digit
                              year: "numeric", // numeric, 2-digit
                              month: "long", // numeric, 2-digit, long, short, narrow
                            })}
                          </p>
                        </td>
                        <td>
                          <p>
                            {data.monthly_ref_earn === null
                              ? "$0.00"
                              : `$${data.monthly_ref_earn.toFixed(2)}`}
                          </p>
                        </td>
                        <td>
                          <p>
                            {data.total_referral_earn === null
                              ? "$0.00"
                              : `$${data.total_referral_earn.toFixed(2)}`}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </table>
              <div>
                {/* {this.state.isPaginated ? ( */}
                <Paginator
                  totalCount={referTotalCount}
                  pageSize={25}
                  pageClick={handlePageClick}
                />
                {/* ) : null} */}
              </div>
            </>
          ) : (
            <h5 style={{ textAlign: "center", padding: "20px" }}>
              NO REFERRALS YET{" "}
            </h5>
          )}
        </>
      )}

      {/* refer a friend modal */}
      <Modal
        show={showInviteModal}
        onHide={() => hideInviteMod()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-body text-center">
          <ReferralShare {...props} ReferCode={referData?.referral_code} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Referrals;

import { CardElement } from "@stripe/react-stripe-js";
import React, { useLayoutEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PaymentRequestForm from "./PaymentRequestButton.js";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      backgroundColor: "transparent",

      "::placeholder": {
        color: "#979797",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function PaymentMethods({
  handleCardPayment,
  setSuccessMessage,
  amount,
  setWalletBalanceNew,
  walletBalance,
  successFullCallback,
}) {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [activePaymentMethod, setActivePaymentMethod] = useState(1);
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const cardPayment = () => {
    if (error) return;
    handleCardPayment(setProcessing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    cardPayment();
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="payment-method-container">
        <div className="d-flex gap-2  flex-column">
          <div className="notification-setting-item mb-4 payment-method-labels justify-content-start align-items-center">
            <div className="options">
              <Form.Check
                type="radio"
                aria-label="radio 1"
                className="friendPopup"
                name="Credit Card"
                checked={1 == activePaymentMethod}
                onChange={() => setActivePaymentMethod(1)}
              />
            </div>
            <div className="payment-method-label">
              <h4>Credit Card</h4>
            </div>
          </div>
          <div>
            {activePaymentMethod == 1 ? (
              <>
                <form className="form-wrapper paymentMeth">
                  <Form.Group>
                    <CardElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleChange}
                    />
                    {error && (
                      <div className="invalid-feedback d-block" role="alert">
                        {error}
                      </div>
                    )}
                  </Form.Group>
                </form>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={processing || disabled}
                  className="card-payment-button mb-4"
                >
                  Proceed To Pay
                </Button>{" "}
              </>
            ) : null}
            <div className="notification-setting-item mb-4 payment-method-labels justify-content-start align-items-center">
              <div className="options">
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  className="friendPopup"
                  name="Others Payment Methods"
                  checked={2 == activePaymentMethod}
                  onChange={() => setActivePaymentMethod(2)}
                />
              </div>
              <div className="payment-method-label">
                <h5 className="mb-0">Others Payment Methods</h5>
              </div>
            </div>

            {activePaymentMethod == 2 ? (
              <PaymentRequestForm
                key={amount}
                walletBalance={walletBalance}
                amount={amount}
                successFullCallback={successFullCallback}
                setProcessing={setProcessing}
              />
            ) : null}
          </div>
        </div>

        {activePaymentMethod == 1 ? (
          <>
            <Modal
              show={processing}
              onHide={() => setProcessing(false)}
              backdrop="static"
            >
              <Modal.Header></Modal.Header>
              <Modal.Body className="money-added">
                <h3>Please Wait , Payment Processing ...</h3>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </div>
    </>
  );
}

export default PaymentMethods;

import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { resetpassword } from "../../services/authService.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthWrapper from "./AuthWrapper.jsx";
import toastify from "../../helper/toastify.jsx";
import { withRouter } from "react-router";
import {
  passWordLeftBullets,
  PasswordRightBullets,
} from "../../constants/constantData.js";
import PasswordComponent from "../PasswordComponent/index.js";
import {
  YupSchemaConfirmPassword,
  YupSchemaPassword,
} from "../../helper/utilities.js";

const ResetPassword = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);

  const queryParam = useLocation().search;
  const id = new URLSearchParams(queryParam).get("id");
  const token = new URLSearchParams(queryParam).get("token");

  const validationSchema = Yup.object().shape({
    password: YupSchemaPassword(),
    confirmPassword: YupSchemaConfirmPassword(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit(data) {
    showLoader(true);
    await resetpassword(data.password, id, token)
      .then((res) => {
        showLoader(false);
        toastify("success", res.success);
        props.history.push("/signin");
      })
      .catch((err) => {
        showLoader(false);
        seterrorMsg(err);
      });
  }

  function showLoader(isLoad) {
    setisLoading(isLoad);
  }

  return (
    <Fragment>
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <div className="contact-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 image-class">
                  <AuthWrapper />
                </div>
                <div className="col-lg-6 form-class">
                  <div className="section-title form-columns text-left mb--50">
                    <div className="forgot-password-image">
                      <img
                        src="/assets/images/change-password.svg"
                        alt="change password"
                      />
                    </div>
                    <h2 className="title sign-in-title change-passwords">
                      Change Your
                      <span>Password</span>
                    </h2>
                    <p className="description continue">
                      Set a new password for your account
                    </p>
                  </div>
                  <div className="form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                      <PasswordComponent
                        PasswordRightBullets={PasswordRightBullets}
                        passWordLeftBullets={passWordLeftBullets}
                        errors={errors}
                        register={register}
                        htmlFor1="item05"
                        htmlFor2="item06"
                      />

                      {errorMsg && (
                        <div className="invalid-feedback d-block my-2">
                          {errorMsg}
                        </div>
                      )}

                      <button
                        className="rn-button-style--2 btn-solid mt-5"
                        type="submit"
                        id="mc-embedded-subscribe"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm mr-4"></span>
                        )}
                        Change Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default withRouter(ResetPassword);

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {
  Col,
  Row,
  InputGroup,
  Button,
  Modal,
  CloseButton,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import {
  editUserDetail,
  getCountries,
  resendOTPapi,
  verifyOTP,
} from "../../services/user.service";
import "react-phone-input-2/lib/style.css";
import toastify from "../../helper/toastify";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import ReactTooltip from "react-tooltip";
import {
  ConsoleHelper,
  getUrlChanger,
  loadScriptByURL,
  minusEighteen,
  recaptchaVerify,
} from "../../helper/utilities";
import Select from "react-select";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./Profile.scss";
const EditProfile = (props) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("First Name is required")
      .min(2, "First Name require a minimum of 2 characters")
      .max(50, "First Name couldn't have more than 50 characters"),
    lastName: Yup.string()
      .trim()
      .required("Last Name is required")
      .min(2, "Last Name require a minimum of 2 characters")
      .max(50, "Last Name couldn't have more than 50 characters"),
    street: Yup.string().trim().required("Street is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    postalCode: Yup.string().trim().required("Postal Code is required"),
    country: Yup.string()
      .min(1, "Country  is required")
      .required("Country is required"),
    countryCode: Yup.string().nullable().notRequired(),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test(
        "check-valid-no",
        "Please enter a valid phone number",
        function (phoneNoValue) {
          return isValidPhoneNumber(phoneNoValue, defaultCountry?.code2);
        }
      ),
  });
  const [isLoading, setisLoading] = useState(false);
  const image = "";
  const [verifyOTPModal, setVerifyOTPModal] = useState(false);
  const [code, setCode] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberDuplicate, setPhoneNumberDuplicate] = useState("");

  const [defaultCountry, setDefaultCountry] = useState("");
  const [ageError, setAgeError] = useState("");
  const [ageModal, setAgeModal] = useState(false);
  const [ageCheck, setAgeCheck] = useState();
  const [originalDOB, setDOB] = useState("");
  const [userData, setUserData] = useState(props.userInfo);
  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userData?.first_name,
      lastName: userData?.last_name,
      street: userData?.address?.street_address,
      city: userData?.address?.city,
      state: userData?.address?.state,
      postalCode: userData?.address?.postal_code,
      discordId:
        userData?.profile?.discord_id === null
          ? ""
          : userData?.profile?.discord_id,
      country:
        userData?.address?.country_id === null
          ? defaultCountry
          : userData?.address?.country_id,
      countryCode: userData?.address?.country_code,
      phoneNumber:
        userData?.phone === null
          ? ""
          : userData?.phone.substring(
              userData?.address?.country_code.length,
              userData?.phone - userData?.address?.country_code.length
            ),
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
      function () {
        ConsoleHelper("Script loaded! Successfully");
      }
    );
    getCountries()
      .then((res) => {
        setCountries(res);
        callDefaultCountry(res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setUserData(props.userInfo);
  }, [props.userInfo]);

  const DISCORD_ID = getValues("discordId");

  const callDefaultCountry = (data) => {
    if (
      userData.address?.code2 === null ||
      userData.address?.country_id === null
    ) {
      const defaultValues = data.filter((item) => {
        return (
          item.code2 ===
          (userData.address?.code2 === null ? "US" : userData.address?.code2)
        );
      });
      setValue("country", defaultValues[0].id);
      setValue("countryCode", defaultValues[0].phone);

      setDefaultCountry(defaultValues[0]);
      setDefaultCountryCode(defaultValues[0].phone);
    } else {
      const splitCountryCode = userData?.phone;
      const phoneNumberWithCountryCode = splitCountryCode.substring(
        userData?.address?.country_code.length,
        userData?.phone - userData?.address?.country_code.length
      );
      const defaultValues = data.filter((item) => {
        return item.id === userData.address?.country_id;
      });
      setDefaultCountry(defaultValues[0]);
      setPhoneNumber(phoneNumberWithCountryCode);
      setPhoneNumberDuplicate(phoneNumberWithCountryCode);
      setDefaultCountryCode(defaultValues[0].phone);
    }
  };
  const [countries, setCountries] = useState([]);
  const urlChanger = getUrlChanger("myprofile");

  const [value, onChange] = useState(
    userData.dob == null
      ? minusEighteen()
      : new Date(userData.dob).toLocaleString("en-US", {
          day: "numeric", // numeric, 2-digit
          year: "numeric", // numeric, 2-digit
          month: "numeric", // num,
          timeZone: "UTC",
        })
  );
  async function onSubmit(data) {
    const formatdDate = formatDate(value);
    if (userData.dob === formatdDate) {
      if (defaultCountryCode !== data.countryCode) {
        const body =
          data.discordId === ""
            ? {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                image: image,
                phone:
                  defaultCountryCode === ""
                    ? userData?.address?.country_code + data.phoneNumber
                    : defaultCountryCode + data.phoneNumber,
                dob: userData.dob,
              }
            : {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                image: image,
                discord_id: data.discordId,
                phone:
                  defaultCountryCode === ""
                    ? userData?.address?.country_code + data.phoneNumber
                    : defaultCountryCode + data.phoneNumber,
                dob: userData.dob,
              };
        showLoader(true);
        await editUserDetail(body)
          .then((res) => {
            showLoader(false);
            if (res.is_otp_required) {
              setVerifyOTPModal(true);
            } else {
              toastify("success", "Profile Updated Successfully");
              props.closeEditModal();
            }
          })
          .catch((err) => {
            showLoader(false);
          });
      } else if (phoneNumberDuplicate === phoneNumber) {
        const body =
          data.discordId === ""
            ? {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                image: image,
                dob: userData.dob,
              }
            : {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                image: image,
                discord_id: data.discordId,
                dob: userData.dob,
              };
        showLoader(true);
        await editUserDetail(body)
          .then((res) => {
            showLoader(false);
            if (res.is_otp_required) {
              setVerifyOTPModal(true);
            } else {
              toastify("success", "Profile Updated Successfully");
              props.closeEditModal();
            }
          })
          .catch((err) => {
            showLoader(false);
          });
      } else {
        const body =
          data.discordId === ""
            ? {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                phone:
                  defaultCountryCode === ""
                    ? userData?.address?.country_code + data.phoneNumber
                    : defaultCountryCode + data.phoneNumber,
                image: image,
                dob: userData.dob,
              }
            : {
                first_name: data.firstName,
                last_name: data.lastName,
                street_address: data.street,
                city: data.city,
                state: data.state,
                postal_code: data.postalCode,
                country_id: defaultCountry.id,
                image: image,
                phone:
                  defaultCountryCode === ""
                    ? userData?.address?.country_code + data.phoneNumber
                    : defaultCountryCode + data.phoneNumber,
                discord_id: data.discordId,
                dob: userData.dob,
              };
        showLoader(true);
        await editUserDetail(body)
          .then((res) => {
            showLoader(false);
            if (res.is_otp_required) {
              setVerifyOTPModal(true);
            } else {
              toastify("success", "Profile Updated Successfully");
              props.closeEditModal();
            }
          })
          .catch((err) => {
            showLoader(false);
          });
      }
    } else {
      if (ageCheck >= 18) {
        if (defaultCountryCode !== data.countryCode) {
          const body = {
            first_name: data.firstName,
            last_name: data.lastName,
            street_address: data.street,
            city: data.city,
            state: data.state,
            postal_code: data.postalCode,
            country_id: defaultCountry.id,
            image: image,
            dob: originalDOB,
            phone:
              defaultCountryCode === ""
                ? userData?.address?.country_code + data.phoneNumber
                : defaultCountryCode + data.phoneNumber,
          };

          showLoader(true);
          await editUserDetail(body)
            .then((res) => {
              showLoader(false);
              if (res.is_otp_required) {
                setVerifyOTPModal(true);
              } else {
                toastify("success", "Profile Updated Successfully");
                props.closeEditModal();
              }
            })
            .catch((err) => {
              showLoader(false);
            });
        } else if (phoneNumberDuplicate === phoneNumber) {
          const body =
            data.discordId === ""
              ? {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  street_address: data.street,
                  city: data.city,
                  state: data.state,
                  postal_code: data.postalCode,
                  country_id: defaultCountry.id,
                  image: image,
                  dob: originalDOB,
                }
              : {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  street_address: data.street,
                  city: data.city,
                  state: data.state,
                  postal_code: data.postalCode,
                  country_id: defaultCountry.id,
                  image: image,
                  discord_id: data.discordId,
                  dob: originalDOB,
                };
          showLoader(true);
          await editUserDetail(body)
            .then((res) => {
              showLoader(false);
              if (res.is_otp_required) {
                setVerifyOTPModal(true);
              } else {
                toastify("success", "Profile Updated Successfully");
                props.closeEditModal();
              }
            })
            .catch((err) => {
              showLoader(false);
            });
        } else {
          const body =
            data.discordId === ""
              ? {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  street_address: data.street,
                  city: data.city,
                  state: data.state,
                  postal_code: data.postalCode,
                  country_id: defaultCountry.id,
                  image: image,
                  phone:
                    defaultCountryCode === ""
                      ? userData?.address?.country_code + data.phoneNumber
                      : defaultCountryCode + data.phoneNumber,
                  dob: originalDOB,
                }
              : {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  street_address: data.street,
                  city: data.city,
                  state: data.state,
                  postal_code: data.postalCode,
                  country_id: defaultCountry.id,
                  image: image,
                  phone:
                    defaultCountryCode === ""
                      ? userData?.address?.country_code + data.phoneNumber
                      : defaultCountryCode + data.phoneNumber,
                  discord_id: data.discordId,
                  dob: originalDOB,
                };
          showLoader(true);
          await editUserDetail(body)
            .then((res) => {
              showLoader(false);
              if (res.is_otp_required) {
                setVerifyOTPModal(true);
              } else {
                toastify("success", "Profile Updated Successfully");
                props.closeEditModal();
              }
            })
            .catch((err) => {
              showLoader(false);
            });
        }
      } else if (value === "") {
        setAgeError("Date of birth is required ");
      } else {
        setAgeModal(true);
        setAgeError("Only users who are 18 or older can register ");
      }
    }
  }

  function showLoader(isLoad) {
    setisLoading(isLoad);
  }

  const handleOTP = async (token) => {
    try {
      await verifyOTP({
        otp: code,
        cid: defaultCountry.id,
        "g-recaptcha-response": token,
      });
      toastify("success", "Profile Updated Successfully");
      setVerifyOTPModal(false);
    } catch (error) {}
  };

  const resendOTP = async (token) => {
    try {
      const res = await resendOTPapi({ "g-recaptcha-response": token });
      toastify("success", res?.success);
    } catch (error) {}
  };

  const handleOTPwithCaptcha = (e) => {
    e.preventDefault();
    recaptchaVerify("submit", handleOTP);
  };

  const handleResendOTPwithCaptcha = (e) => {
    e.preventDefault();
    recaptchaVerify("submit", resendOTP);
  };

  const closeVerifyButton = () => {
    setVerifyOTPModal(false);
  };
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const handleCountry = (e) => {
    setDefaultCountry(e);
    setDefaultCountryCode(e.phone);
  };
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const closeAgeModal = () => {
    setAgeModal(false);
    setAgeError("");
  };
  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const onChangeDOB = (DOBValue) => {
    if (DOBValue === null) {
      onChange("");
    } else {
      const formatdDate = formatDate(DOBValue);
      setDOB(formatdDate);
      const diff_ms = Date.now() - DOBValue.getTime();
      const age_dt = new Date(diff_ms);
      const age = Math.abs(age_dt.getUTCFullYear() - 1970);
      setAgeCheck(age);
      if (age < 18) {
        setAgeModal(true);
        setAgeError("Only users who are 18 or older can register ");
      } else {
        setAgeModal(false);
        setAgeError("");
      }
      onChange(DOBValue);
    }
  };

  
  const redirectToDiscord = () => {
    props.closeEditModal();
    props.redirectToSocial();
  };

  return (
    <>
      {!verifyOTPModal ? (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          <Modal.Header closeButton>
            <Modal.Title className="profile-information-header">
              {" "}
              Profile Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="profile-body editProfileModal">
            <div className="change-profile">
              <div className="username">
                <h3>Username</h3>
                <h4>{userData.username}</h4>
              </div>
            </div>

            <Row className="margins">
              <Form.Group
                as={Col}
                controlId="formGridFirstName"
                className="col-lg-6 col-md-6 col-sm-6 col-12 first-name-col"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  {...register("firstName")}
                  className={`${errors.firstName ? "is-invalid" : ""}`}
                />
                {errors.firstName?.message && (
                  <div className="invalid-feedback">
                    {errors.firstName?.message}
                  </div>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  {...register("lastName")}
                  className={`${errors.lastName ? "is-invalid" : ""}`}
                />
                {errors.lastName?.message && (
                  <div className="invalid-feedback">
                    {errors.lastName?.message}
                  </div>
                )}
              </Form.Group>
            </Row>

            <Form.Group className="margins" controlId="formGridAddress1">
              <Form.Control
                placeholder="Street"
                name="street"
                {...register("street")}
                autoFocus
                className={`${errors.street ? "is-invalid" : ""}`}
              />{" "}
              {errors.street?.message && (
                <div className="invalid-feedback d-block">
                  {errors.street?.message}
                </div>
              )}
            </Form.Group>

            <Row className="margins">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Control
                  placeholder="City"
                  name="city"
                  {...register("city")}
                  className={`${errors.city ? "is-invalid" : ""}`}
                />{" "}
                {errors.city?.message && (
                  <div className="invalid-feedback">{errors.city?.message}</div>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Control
                  placeholder="State"
                  name="state"
                  {...register("state")}
                  className={`${errors.state ? "is-invalid" : ""}`}
                />{" "}
                {errors.state?.message && (
                  <div className="invalid-feedback">
                    {errors.state?.message}
                  </div>
                )}
              </Form.Group>
            </Row>

            <Row className="margins">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Control
                  placeholder="Postal Code"
                  name="postalCode"
                  {...register("postalCode")}
                  className={`${errors.postalCode ? "is-invalid" : ""}`}
                />
              </Form.Group>
              {errors.postalCode?.message && (
                <div className="invalid-feedback d-block">
                  {errors.postalCode?.message}
                </div>
              )}
            </Row>
            <Row className="margins">
              <Form.Group as={Col} controlId="formGridCity">
                <Controller
                  {...register("country")}
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="react-select-container-edit-profile"
                      classNamePrefix="react-select-edit-profile"
                      value={defaultCountry}
                      options={countries}
                      onChange={handleCountry}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "#00ccff",
                          primary: "#00ccff",
                        },
                      })}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
              </Form.Group>
              {errors.country?.message && (
                <div className="invalid-feedback d-block">
                  {errors.country?.message}
                </div>
              )}
            </Row>
            <Row className="margins profilePhone">
              <Form.Group as={Col} controlId="formGridCity">
                <div>
                  <InputGroup className="mb-1">
                    <InputGroup.Text
                      placeholder="Country code"
                      name="countryCode"
                      {...register("countryCode")}
                      id="basic-addon1"
                      value={
                        defaultCountryCode === ""
                          ? userData?.address?.country_code
                          : defaultCountryCode
                      }
                    >
                      {defaultCountryCode === ""
                        ? userData?.address?.country_code
                        : defaultCountryCode}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Phone Number"
                      name="phoneNumber"
                      {...register("phoneNumber")}
                      className={`${errors.phoneNumber ? "is-invalid" : ""}`}
                      onChange={(e) => {
                        handlePhoneNumber(e);
                      }}
                      autoComplete="off"
                    />
                  </InputGroup>
                </div>
              </Form.Group>
              {errors.phoneNumber?.message && (
                <div className="invalid-feedback d-block">
                  {errors.phoneNumber?.message}
                </div>
              )}
              <p className="otp">
                OTP will be sent to the registered Mobile for verification.
              </p>
            </Row>
            <Row className="margins dob-wrapper">
              <Col md={6}>
                <Form.Group className="margins" controlId="formGridPaypal">
                  <DatePicker
                    name="dob"
                    className={`${ageError ? "is-invalid" : ""}`}
                    placeholderText="Date of Birth"
                    onChange={onChangeDOB}
                    value={
                      value === null
                        ? ""
                        : typeof value === "string"
                        ? value.length === 0
                          ? ""
                          : new Date(value)
                        : value
                    }
                    maxDate={new Date()}
                    openCalendarOnFocus={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    minDate={new Date("01-01-1900")}
                  />
                  <p className="otp dob">Date of Birth</p>
                </Form.Group>
                {ageError ? (
                  <div className="invalid-feedback d-block">{ageError}</div>
                ) : (
                  ""
                )}
              </Col>
              <Col md={6}>
              <Button letiant="primary" type="submit" disabled={isLoading} className="update-btn">
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              )}
              Update
            </Button>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="profile-footer">
            <Row>
              <Col md={6}>
                {DISCORD_ID ? (
                    <>
                      <Form.Group
                        className="margins paypal-id margins-top discordinvaild"
                        controlId="formGridPaypal"
                      >
                        <Form.Control
                          placeholder="Discord Username"
                          name="discordId"
                          {...register("discordId")}
                          className={`${errors.discordId ? "is-invalid discord-invaild" : ""}`}
                          onKeyDown={handleKeyDown}
                          disabled={true}
                        />
                        <span title="">
                          <img
                            src="/assets/images/info-icon.svg"
                            alt="info"
                            class="info-image"
                            data-tip
                            data-for="loginTip"
                          />
                        </span>
                        <ReactTooltip id="loginTip" place="top" effect="solid">
                          Discord username to receive the lobby code
                        </ReactTooltip>
                      </Form.Group>
                      <span className="discord-social" onClick={redirectToDiscord}>Manage Discord</span>
                    </>
                  ) : (
                    <div>
                      <a
                        style={{ color: "white" }}
                        href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${urlChanger}&response_type=code&scope=identify%20email`}
                      >
                        <Button className="connect-discord-btn">Connect Discord</Button>
                      </a>
                    </div>
                  )}
              </Col>
            </Row>
              
          </Modal.Footer>
        </form>
      ) : (
        <>
          <Modal.Header>
            <CloseButton onClick={closeVerifyButton} />
            <Modal.Title>Verify Phone</Modal.Title>
          </Modal.Header>
          <Modal.Body className="wallet-body">
            <h2>Enter code</h2>
            <OtpInput
              className="verifyPhoneNo"
              inputStyle={{ color: "white", width: "50px" }}
              isInputNum
              value={code}
              onChange={setCode}
              numInputs={6}
              separator={<span>-</span>}
            />
          </Modal.Body>
          <Modal.Footer className="wallet-footer">
            <Button
              letiant="primary"
              disabled={code.length === 6 ? false : true}
              type="submit"
              onClick={handleOTPwithCaptcha}
            >
              Submit
            </Button>
            <Button
              letiant="primary"
              onClick={handleResendOTPwithCaptcha}
              style={{ marginLeft: "20px" }}
            >
              Resend OTP
            </Button>
          </Modal.Footer>
        </>
      )}
      {ageModal ? (
        <Modal show={ageModal} onHide={() => closeAgeModal()}>
          <Modal.Header closeButton className="warning-header"></Modal.Header>
          <Modal.Body className="money-added warning-modal">
            <div class="warning-icon">
              <img src="/assets/images/warning.svg" alt="warning" />
            </div>
            <h3>legal age restriction</h3>
            <h4>Only users who are 18 or older can register</h4>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

EditProfile.propTypes = {
  closeEditModal: PropTypes.func,
  userInfo: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      full: PropTypes.string,
      postal_code: PropTypes.string,
      state: PropTypes.string,
      street_address: PropTypes.string,
      country_id: PropTypes.number,
      country_code: PropTypes.number,
    }),
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    profile: PropTypes.shape({
      badge: PropTypes.string,
      is_verified: PropTypes.bool,
      verify_valid_end_date: PropTypes.string,
      verify_valid_start_date: PropTypes.string,
      wallet_balance: PropTypes.string,
      paypal_id: PropTypes.string,
    }),
  }).isRequired,
};

export default EditProfile;

import React, { useCallback, useEffect, useState } from "react";
import { useNotifications } from "../../context/NotificationsContext";
import Paginator from "../../helper/paginator/Paginator";
import { ConsoleHelper } from "../../helper/utilities";
import {
  getNotificationsList,
  putNotificationRead,
  removeNotifications,
} from "../../services/notifications.service";
import NotificationCard from "./NotificationCard";
function NotificationList({ setLoader }) {
  const pageSize = 10;
  const [totalSize,setTotalSize] = useState(0)
  const { state, dispatch: dispatchContext } = useNotifications();
  const [pageNum, setPageNum] = useState(1);
  const [notificationsData, setNotificationData] = useState(
    state?.notificationsData
  );
  const handlePageClick = (newPageNum) => {
    setPageNum(newPageNum);
  };

  const fetchNotificationsList = useCallback(
    (updateBellNotificationsData = false) => {
      const filter = {
        isPaginated: true,
        pageNum,
        pageSize,
      };
      setLoader(true);
      getNotificationsList(filter)
        .then((res) => {
          setNotificationData(res?.results);
          setTotalSize(res?.overall_count)
          if (updateBellNotificationsData) {
            dispatchContext({
              type: "UPDATE_NOTIFICATIONS_DATA",
              payload: res?.results,
            });
          }
          dispatchContext({
            type: "UPDATE_UNREAD_COUNT",
            payload: res?.notifications,
          });

          setLoader(false);
        })
        .catch((err) => {
          ConsoleHelper(err);
          setNotificationData([])
          setLoader(false);
        });
    },
    [pageNum, setLoader]
  );

  const clearAll = async () => {
    try {
      setLoader(true)
      await removeNotifications({type:2,notification:""})
      setTotalSize(0)
      setLoader(false)  
      setNotificationData([])
    } catch (error) {
      setLoader(false)  
    }
  }

  const removeSingleNotification =  async (id) => {
    try {
      setLoader(true)
      await removeNotifications({type:1,notification:id})
      setLoader(false)  
      fetchNotificationsList();
    } catch (error) {
      setLoader(false)  
    }
  }

  // set notification count to zero when visit notifications listing page
  useEffect(() => {
    putNotificationRead()
      .then(() => {
        dispatchContext({ type: "UPDATE_UNREAD_COUNT", payload: 0 });
      })
      .catch((err) => ConsoleHelper(err));
  }, [dispatchContext]);

  useEffect(() => {
    fetchNotificationsList();
  }, [fetchNotificationsList, pageNum]);

  // refetch only if action from notifications dropdown taken and page is 1
  useEffect(() => {
    if (state.fetchNotifications && pageNum === 1) {
      fetchNotificationsList();
    }
    dispatchContext({ type: "REFETCH", payload: false });
  }, [
    dispatchContext,
    fetchNotificationsList,
    pageNum,
    state.fetchNotifications,
  ]);

  // sync with socket data real time update in notifications
  useEffect(() => {
    if (pageNum === 1) {
      fetchNotificationsList();
    }
  }, [state?.notificationsData]);
  

  return (
    <section className="notification-outer-container noNotificationHeight">
      <div className="container">
        <div className="row">
          <div className="col">
            {notificationsData?.length ? (
              <div className="notification-list">
                <h3>NOTIFICATIONS</h3>
              </div>
            ) : null}

            {notificationsData?.length ? (
              <div className="notification-listing-box">
                {notificationsData?.map((notificationItem, idx) => {
                  return (
                    <NotificationCard
                      userData={notificationItem}
                      fetchNotificationsList={fetchNotificationsList}
                      key={notificationItem?.id}
                      setLoader={setLoader}
                      pageNum={pageNum}
                      removeSingleNotification={removeSingleNotification}
                    />
                  );
                })}
              </div>

            ) : (
              <h2 className="notifications-empty">No Notifications</h2>
            )}
          {totalSize > 0 ? <button className="accept-btn seeAll-btn notificationListing-clearAll"
            onClick={clearAll}>Clear All</button>:null}
            <div>
              <Paginator
                totalCount={totalSize}
                pageSize={pageSize}
                pageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(NotificationList);

import React from "react";
import { Accordion } from "react-bootstrap";
import { inviteStatus } from "../../../helper/utilities";
const TournamentTeamsTab = ({ teamData, isTournamentFree }) => {
  const comparator = (x) => (x.leader ? -1 : 1);
  return (
    <div className="col mb-4 left-card">
      <div className="card card_teams">
        <div className="card-body members-cards challengeCards">
          <div className="teams-information  w-100 challengesTeams mb--10">
            <div className="table-responsive ">
              <table className="tounamnet-standingtable-tab card-padding">
                <tr className="tr-first">
                  <th>TEAM NAME</th>
                  <th>NO OF REGISTERED PLAYERS</th>
                </tr>
                <Accordion>
                  {teamData &&
                    teamData.map((enrolledTeam, idx) => {
                      return (
                        <Accordion.Item eventKey={idx}>
                          <Accordion.Header>
                            <td>{enrolledTeam.name}</td>
                            <td>{enrolledTeam.players?.length}</td>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="card-body members-cards challengeCards">
                              <div className="teams-information  w-100 challengesTeams mb--10">
                                <div className="table-responsive">
                                  <table className="tounamnet-standing-tab card-padding table-width">
                                    <tr>
                                      <th>Username</th>
                                      <th>Email </th>
                                      <th>Invite Status</th>
                                      <th>Payment Status</th>
                                    </tr>
                                    {enrolledTeam.players
                                      .sort(comparator)
                                      .map((teamPlayer, index) => {
                                        return (
                                          <tr key={idx}>
                                            <td>
                                              {teamPlayer.username}{" "}
                                              {teamPlayer.leader ? (
                                                <>(Creator)</>
                                              ) : (
                                                <>(Member)</>
                                              )}
                                            </td>
                                            <td>{teamPlayer.email}</td>

                                            <td>
                                              {inviteStatus[teamPlayer.invite]}
                                            </td>
                                            <td>
                                              {teamPlayer.has_paid
                                                ? "Paid"
                                                : "Unpaid"}
                                              {isTournamentFree
                                                ? " (Free Entry) "
                                                : null}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentTeamsTab;

import React from "react";
import { Modal } from "react-bootstrap";
import PPKTeamTable from "../Table/PPKTeamTable";
const PPKTeamsModal = ({ show, teams, match, closeModal }) => {
  return (
    <Modal
      show={show}
      scrollable={true}
      onHide={closeModal}
      className="custom-modal w-706"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Match {match}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="events">
        <PPKTeamTable teams={teams} />
      </Modal.Body>
    </Modal>
  );
};

export default PPKTeamsModal;

import React from "react";
import { Modal } from "react-bootstrap";
import PlayerStats from "../../Lobby/PlayerStats";
function ViewStats({
  loader,
  show,
  player_stats,
  game_data,
  closeModal,
  isPPK,
}) {
  return (
    <Modal
      show={show}
      onHide={closeModal}
      scrollable={true}
      className="custom-modal w-706 view-more-stats"
      backdrop="static"
    >
      <Modal.Header closeButton tag="h4">
        <Modal.Title className="text-uppercase">View Stats</Modal.Title>
      </Modal.Header>

      <Modal.Body className="events">
        {loader ? (
          <div class="loading"></div>
        ) : (
          <PlayerStats
            player_stats={player_stats}
            game_data={game_data}
            isTournament={true}
            isPPK={isPPK}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ViewStats;

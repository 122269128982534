import moment from "moment";
import {
  CARD_BACKGROUNDS_PATHS,
  ERROR_MESSAGES,
  VALIDATIONS_ERROR,
} from "./stringConstants";
import * as Yup from "yup";

export const isTodayorTommorrow = (date) => {
  const today = moment().endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");

  //   0 -> Today , 1->  Yesteeday , -1 else
  if (moment(date).endOf("day").format() === today.format()) return 0;
  if (moment(date).endOf("day").format() === yesterday.format()) return 1;
  else return -1;
};

export const dateStringTodayTommorrow = (date) => {
  const isTodayorTommrowIndex = isTodayorTommorrow(date);
  if (isTodayorTommrowIndex === 0)
    return "Today, " + moment(date).format("h:mm A");
  if (isTodayorTommrowIndex === 1)
    return "Yesterday, " + moment(date).format("h:mm A");
  else return moment(date).format("DD MMM YYYY, h:mm A");
};

export const AddTeamMatefilterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: (option) =>
    `${option.label} ${option.data?.email} ${
      option.data?.first_name + " " + option.data?.last_name
    }`,
  trim: true,
};

export const convertTeamMatesToOptions = (arr = []) =>
  arr
    .map((item) => ({
      ...item,
      label: item.username,
      value: item?.id ? item?.id : item?.uid,
    }))
    .filter((item) => item?.is_available);

export const mask = (field) => {
  return new Array(field.length - 1).fill("*").join("");
};

export const gameEnum = {
  "apex-legends": 0,
  pubg: 1,
};
export const gameEnumFromValue = {
  0: "apex-legends",
  1: "pubg",
};
export const minusEighteen = () => {
  const eighteenYearsAgo = new Date();
  const date = new Date(eighteenYearsAgo),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear() - 18, mnth, day].join("-");
};
export const logoutEventPublicRoutes = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "logout-event") {
        window.location.replace("/signin");
      }
    },
    false
  );
};
export const logoutEventPrivateRoutes = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "logout-event") {
        window.location.reload();
      }
    },
    false
  );
};
export const enrollEventListener = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "enroll-event") {
        window.location.reload();
      }
    },
    false
  );
};

export const gamerTagInfo = (game) => {
  if (!game) return null;

  const isApexLegends = game.name === "Apex Legends";
  const isValorantOrWarzone =
    game.slug === "valorant" || game.slug === "call-of-duty-warzone";
  const gamertagLabel = game.label || game.gaming_account_name;
  const clanMessage = isApexLegends;

  let message = `* Please make sure your ${gamertagLabel} gamertag`;
  if (isValorantOrWarzone) {
    message += " (username#tagline)";
  }
  message += ` on your Ryvals profile, matches your current in-game ${game.name} name`;

  if (clanMessage) {
    message += " minus any clan tag";
  }

  message += " prior to lobby enrollment.";

  return message;
};
export const gameOptions = [
  { value: 0, label: "Apex", slugName: "apex-legends" },
  { value: 1, label: "Pubg", slugName: "pubg" },
];
export const lobbyFilter = [
  { value: "current", label: "Current lobbies" },
  { value: "previous", label: "Previously played lobbies" },
  { value: "enrolled", label: "Enrolled Lobbies", loginRequired: true },
];
export const tournamentFilters = [
  { value: "current", label: "Active" },
  { value: "upcoming", label: "Upcoming" },
  { value: "cancelled", label: "Cancelled" },
  { value: "ended", label: "Ended" },
];
export const lobbyAmountFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "$1" },
  { value: 5, label: "$5" },
];
export const lobbyModeFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
  { value: 3, label: "Trios" },
  { value: 4, label: "Quads" },
  { value: 5, label: "Penta" },
  { value: 6, label: "Hexa" },
];
export const challengeModeFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "1v1" },
  { value: 2, label: "2v2" },
  { value: 3, label: "3v3" },
  { value: 4, label: "4v4" },
  { value: 5, label: "5v5" },
  { value: 5, label: "6v6" },
];

export const lobbyModeFiltersForChallengesValorant = [
  { value: 5, label: "5v5" },
];
export const teamMode = [
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
  { value: 3, label: "Trios" },
  { value: 4, label: "Quads" },
  { value: 5, label: "Penta" },
];
export const teamModeForValorant = [{ value: 5, label: "5v5" }];
export const profileProgressData = [
  {
    message: "Please complete the sign up",
    completeMessage: "Sign up Completed",
    name: "signup",
    complete: true,
    order: 1,
  },
  {
    message: "Please enter profile information",
    completeMessage: "Profile information completed",
    name: "profile",
    complete: false,
    order: 2,
  },
  {
    message: "Please update profile picture",
    name: "avatar",
    completeMessage: "Profile picture updated",
    complete: false,
    order: 3,
  },
  {
    message: "Please enter any Game ID",
    completeMessage: "Game ID entered",
    name: "gameid",
    complete: false,
    order: 4,
  },
];

export const notificationSettingEnum = {
  noti_text_message: "TEXT MESSAGES",
  noti_email: "EMAILS",
  noti_discord: "DISCORD MESSAGES",
};

export const paymentMethodsEnum = [
  {
    label: "Credit Card",
    value: 1,
  },
  {
    label: "Wallets",
    value: 2,
  },
];

export const durationAsString = (ms, maxPrecission = 3) => {
  const duration = moment.duration(ms);

  const items = [];
  items.push({ timeUnit: "h", value: duration.hours() });
  items.push({ timeUnit: "m", value: duration.minutes() });
  items.push({ timeUnit: "s", value: duration.seconds() });

  const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
    if (
      accumulator.length >= maxPrecission ||
      (accumulator.length === 0 && value === 0)
    ) {
      return accumulator;
    }

    accumulator.push(`${value}${timeUnit}`);
    return accumulator;
  }, []);

  return formattedItems.length !== 0 ? formattedItems.join(" ") : "-";
};
export const convertMilliSecToTimeAndDate = (msTobeConverted) => {
  const date = new Date(msTobeConverted);
  const options = {
    weekday: "short",
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "America/New_York",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};
export const teamStatusText = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Accepted" },
  { value: 3, label: "Rejected" },
  { value: 4, label: "Removed" },
];
export const gameTypeEnum = {
  1: "PPK",
  2: "Kill Race",
  3: "Traditional",
};

export const gameTypeFilters = [
  { value: 1, label: "PPK" },
  { value: 2, label: "Kill Race" },
  { value: 3, label: "Traditional" },
];
export const lobbyModeForCODFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
];
export const lobbyModeForValorantFilters = [{ value: 5, label: "Penta" }];
export const gameStatusFilters = [
  { value: 1, label: "Active" },
  { value: 2, label: "Cancelled" },
  { value: 3, label: "Ended" },
];
export const gameChallengesFilters = [
  { value: 1, label: "Open" },
  { value: 2, label: "Private" },
];

export const getPlaceholder = (selectedGame) => {
  if (
    selectedGame?.slug == "valorant" ||
    selectedGame?.slug == "call-of-duty-warzone"
  ) {
    return "Add gamertag here : Ryvals#1234";
  } else {
    return "Add gamertag here";
  }
};

export const loadScriptByURL = (id, url, callback = () => {}) => {
  // if already added in script then ignore
  const isScriptExist = document.getElementById(id);
  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }
  if (isScriptExist && callback) callback();
};

export const recaptchaVerify = (action, callback = () => {}) => {
  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action })
      .then((token) => {
        callback(token);
      })
      .catch((err) => {
        ConsoleHelper({ err });
      });
  });
};

export const gameStatsTableHeadForApex = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Team placement",
  "Survival time",
  "Damage dealt",
  "Platform",
];
export const gameStatsTableHeadForValorant = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Assists",
  "Damage dealt",
  "Survival time",
  "Platform",
];

export const ConsoleHelper = (...data) => {
  if (process.env.NODE_ENV === "production") return;
  console.log(...data);
};

export const getUrlChanger = (type) => {
  return WEBSITE_URLS_TO_REDIRECT_MAP[type];
};

export const WEBSITE_URLS_TO_REDIRECT_MAP = {
  signup: window.location.origin + "/signup",
  myprofile: window.location.origin + "/myprofile",
  referral: window.location.origin + "/signup?referral=",
};

export const handleBannedLocationError = (errorMsgToDisplay) => {
  return errorMsgToDisplay.length === 70
    ? ERROR_MESSAGES.bannedLocation
    : errorMsgToDisplay;
};

export const getCardBackgroundImage = (gameSlug, type, isVerified = false) => {
  return `card active-card bg-dark text-white ${
    CARD_BACKGROUNDS_PATHS[gameSlug][type]
  } ${isVerified ? "verified-available-lobby" : ""}`;
};

export const YupSchemaPassword = () =>
  Yup.string()
    .transform((x) => (x === "" ? undefined : x))
    .concat(Yup.string().required(VALIDATIONS_ERROR.passwordRequired))
    .matches(
      /(?=.*[A-Za-z])(?=.*[\d])(?=.*[@$!%*#?&^_-])^[A-Za-z0-9][A-Za-z\d@$!%*#?&^_-]{7,14}$/,
      VALIDATIONS_ERROR.passwordPattern
    );

export const YupSchemaConfirmPassword = () =>
  Yup.string()
    .transform((x) => (x === "" ? undefined : x))
    .when("password", (password, schema) => {
      if (password) return schema.required(VALIDATIONS_ERROR.confirmPassword);
    })
    .oneOf([Yup.ref("password")], VALIDATIONS_ERROR.confirmPasswordMatch);
// 1 PPK , 2 KILL RACE , 3 TRADITIONAL

export const gamesToCategories = (games) => {
  const traditional = [];
  const ppk = [];
  const killRace = [];
  games.forEach((data) => {
    data.types_of_game.forEach((gameType) => {
      switch (gameType) {
        case "1":
          ppk.push({ ...data, value: data.id, label: data.name });
          break;
        case "2":
          killRace.push({ ...data, value: data.id, label: data.name });
          break;
        case "3":
          traditional.push({ ...data, value: data.id, label: data.name });
          break;
        default:
          break;
      }
    });
  });
  return {
    ppkGames: ppk,
    traditionalGames: traditional,
    killRaceGames: killRace,
    allGames: games.map((game) => ({
      ...game,
      value: game.id,
      label: game.name,
    })),
    modeObj: {
      1: ppk,
      2: killRace,
      3: traditional.filter((game) => game.slug != "apex-legends"),
    },
    modeObjTournaments: {
      1: ppk,
      2: killRace,
      3: traditional,
    },
    modeGameTypeObj: getModesOfGame([...ppk, ...traditional, ...killRace]),
    modeGameTypeTournamentObj: getModesOfTournamentGame([
      ...ppk,
      ...traditional,
      ...killRace,
    ]),
  };
};

export const getModesOfGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
    }));
  });
  temp["apex-legends"] = temp["apex-legends"].filter((mode) => mode.mode != 6);
  Object.keys(temp).forEach((gameKey) => {
    if (gameKey != "valorant")
      temp[gameKey] = [{ label: "All", value: 0, mode: 0 }, ...temp[gameKey]];
  });
  return temp;
};
export const getModesOfTournamentGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
      1: true,
      2: true,
      3: true,
    }));
  });

  temp["apex-legends"] = temp["apex-legends"].map((mode) => {
    if (mode.value == 1 || mode.value == 2) {
      return { ...mode, 3: false };
    } else if (mode.value == 6) {
      return { ...mode, 1: false };
    }
    return mode;
  });

  Object.keys(temp).forEach((gameKey) => {
    if (gameKey != "valorant")
      temp[gameKey] = [
        { label: "All", value: 0, mode: 0, 1: true, 2: true, 3: true },
        ...temp[gameKey],
      ];
  });
  return temp;
};
export const getModeLabel = (type, mode) =>
  type == 1 ? lobbyModeFilters[mode]?.label : challengeModeFilters[mode]?.label;

export const getModeLabelsSelect = (type, modeArr) => {
  if (!type) return [];
  return modeArr?.map((mode) => ({
    ...mode,
    label: getModeLabel(type.value, mode.mode),
  }));
};

export const getGameFromId = (gameId, games = []) => {
  const res = games?.filter(({ id }) => gameId == id)?.[0];
  return res;
};
export default function authHeader() {
  const obj = getUserDetailsFromLocalStorage();
  if (obj && obj.access_token) {
    return `Bearer ${obj.access_token}`;
  } else {
    return null;
  }
}

export const getCardBackground = (slug) => {
  return slug === "apex-legends"
    ? "card"
    : slug === "cod-mobile"
    ? "card card_cod-mobile"
    : slug === "pubg-mobile"
    ? "card card_pubg-mobile"
    : slug === "fortnite"
    ? "card card_fortnite"
    : slug === "valorant"
    ? "card card_valorant"
    : slug === "call-of-duty-warzone"
    ? "card card_warzone"
    : "card card_pubg";
};
const giveWidthSelect = () =>
  window.innerWidth <= 768 ? {} : { width: "198px" };

export const customStyles = {
  control: (base, selectState) => ({
    ...base,
    ...giveWidthSelect,
    background: "#0b0b0b",
    // match with menu
    borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: selectState.isFocused ? "#00ccff" : "#979797",
    boxShadow: null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "white" : "#00ccff",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    background: "#0b0b0b",
    padding: 0,
    ...giveWidthSelect,
    border: "1px solid #979797",
    overflow: "hidden",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
    zIndex: 1,
    color: "#fff",
    // width: "210px",
    ...giveWidthSelect,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    // override border radius to match the box
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
    ...giveWidthSelect,
  }),
};
export const customStylesSame = {
  control: (base, selectState) => ({
    ...base,
    ...giveWidthSelect,
    background: "#0b0b0b",
    // match with the menu
    borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: selectState.isFocused ? "#00ccff" : "#979797",
    boxShadow: null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "white" : "#00ccff",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    background: "#0b0b0b",
    padding: 0,
    border: "1px solid #979797",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
    zIndex: 1,
    color: "#fff",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    // override border radius to match the box
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
};

export const customStylesGames = {
  control: (base, selectState) => ({
    ...base,
    ...giveWidthSelect,
    background: "#0B0B0B",
    // match with menu
    borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: selectState.isFocused ? "#00CCFF" : "#979797",
    boxShadow: null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "white" : "#00CCFF",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    // override border radius to match the box
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
    ...giveWidthSelect,
    ...base,
    width: "100%",
    overflowY: "auto",
    "::-webkit-scrollbar-thumb": {
      background: "white", // imported color string
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "white",
    },
    "::-webkit-scrollbar-track": {
      background: "black",
    },
    "::-webkit-scrollbar": {
      width: "5px",
      background: "white",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
    zIndex: 1,
    color: "#fff",
    ...giveWidthSelect,
  }),
  menuList: (base) => ({
    ...base,
    background: "#0B0B0B",
    border: "1px solid #979797",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "black",
    },
    "::-webkit-scrollbar-thumb": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "white",
    },
  }),
};

export const challengeStatus = {
  1: "Open",
  2: "Closed",
  3: "Active",
  4: "Cancelled",
  5: "Completed",
};
export const isChallengeActive = (mode) => mode == 1 || mode == 2;
export const appendSuffixToTournamentPosition = (position) => {
  return ["st", "nd", "rd"][((((position + 90) % 100) - 10) % 10) - 1] || "th";
};
export const dateFormatTournament = (format) => {
  if (format !== undefined) {
    const [month, day, year] = format.split("-");
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const options = {
      weekday: "short",
      day: "numeric",
      year: "numeric",
      month: "long",
    };

    return date.toLocaleString("en-US", options);
  }
};

// export const dateFormatTournament = (format) => {
//   const date = new Date(format);
//   const options = {
//     weekday: "short",
//     day: "numeric",
//     year: "numeric",
//     month: "long",
//   };
//   return date.toLocaleString("en-US", options);
// };

export const redirectToPrevPath = (history, location, isChallenge = false) => {
  localStorage.setItem("previousPath", location.pathname);
  return {
    pathname: "/signin",
    state: { prevPath: location.pathname, challengeTab: isChallenge },
  };
};
export const dateTimeFormatTournament = (format) => {
  const [dateString, timeString, timeZoneString] = format.split(" ");
  const [month, day, year] = dateString.split("-");

  const date = new Date(year, month - 1, day);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return `${formattedDate} at ${timeString} ${timeZoneString}`;
};

export const getUserDetailsFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("user") || null);

export const baseURL = process.env.REACT_APP_APIURL;
export const getTransactionStatus = (trans, paymentConstFilter) => {
  const transObj = getTransactionObject(paymentConstFilter);
  const isTransObjStatus =
    trans.status == 2 || trans.status == 3 || trans.status == 4;
  return trans?.type == 3 && trans?.is_free_lobby
    ? `${paymentConstFilter[0].label} (Free Entry)`
    : isTransObjStatus
    ? transObj[trans.status]
    : trans.transaction_mode === 1
    ? "PROMO BONUS"
    : `${paymentConstFilter[0].label}`;
};

export const getTransactionObject = (paymentConstFilter) => {
  return {
    2: "FAILED",
    3: `${paymentConstFilter[0].label} (pending)`,
    4: "ON HOLD",
  };
};
export const inviteStatus = {
  1: "Accepted",
  2: "Rejected",
  3: "Pending",
};

export const handleNotificationNavigate = ({history,userData}) => {
    switch (userData.type_of_notification) {
      case 1: {
        history.push(`/player-profile/${userData?.from_user}`, {
          id: userData?.from_user,
          notificationId: userData?.id,
          prevPath: "/notifications",
          type: userData?.type_of_notification,
        });

        break;
      }
      case 2: {
        history.push(
          `/${userData?.lobby_slug}/lobby/${userData?.lobby}`,
          {
            participantTabActive: true,
          }
        );
        break;
      }
      case 4: {
        if (userData?.extras?.tournament_id === null) {
          history.push(
            `/${userData?.lobby_slug}/lobby/${userData?.lobby}`
          );
        } else {
          history.push(
            `/tournaments/${userData?.lobby_slug}/${userData?.extras?.tournament_id}`
          );
        }
        break;
      }
      case 5: {
        history.push(`/myProfile`, {
          key: "teams",
        });

        break;
      }

      case 6: {
        // challenge redirect
        if (userData?.extras?.challenge) {
          history.push(
            `/${userData?.extras?.game}/lobby/versus/${userData?.extras?.challenge}`,
            {
              participantTabActive:
                userData?.extras?.action == "result"
                  ? "stats"
                  : "participants",
            }
          );
        }

        break;
      }
      case 7: {
        // tournament redirect
        if (userData?.extras) {
          history.push(
            `/tournaments/${userData?.extras?.game}/${userData?.extras?.tournament_id}`,
            {
              result: userData?.extras?.action === "result",
            }
          );
        }
        break;
      }

      default:
        break;
    }
  };
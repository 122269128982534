import React, { useEffect, useRef, useState } from "react";
import Select, { createFilter } from "react-select";
import {
  AddTeamMatefilterConfig,
  convertTeamMatesToOptions,
} from "../../helper/utilities";
import { getTeamMatesLobby } from "../../services/teams.service";

import ReactTooltip from "react-tooltip";
const AddTeamMate = (props) => {
  const {
    lobbyId,
    minTeamMatesRequired,
    setCanRegisterTeamMate,
    selectedTeamMates,
    setSelectedTeamMates,
    canRegisterTeamMate,
    setReplaceFriend = () => {},
    replace = false,
    replaceId = null,
    teamId = null,
  } = props;
  const [minTeamMates, setMinTeamMate] = useState(minTeamMatesRequired - 1);
  const [teamMate, setTeamMate] = useState(null);
  const [teamMatesOptions, setTeamMatesOptions] = useState([]);
  const intialTeamMatesOptions = useRef([]);
  useEffect(() => {
    if (lobbyId)
      getTeamMatesLobby({ lobby_id: lobbyId, teamId }).then((teamMates) => {
        intialTeamMatesOptions.current = convertTeamMatesToOptions(teamMates);
        if (replaceId) {
          setTeamMatesOptions(
            convertTeamMatesToOptions(teamMates).filter(
              (data) => data.id != replaceId
            )
          );
        } else {
          setTeamMatesOptions(convertTeamMatesToOptions(teamMates));
        }
      });
  }, [lobbyId]);

  const addTeamMate = () => {
    setSelectedTeamMates((prev) => [...prev, teamMate]);
    setTeamMate(null);
  };
  const deleteTeamMate = (teamMateObj) => { 
    setSelectedTeamMates((prev) =>
      prev?.filter((prevTeamMate) => {
        return prevTeamMate?.id
          ? prevTeamMate?.id != teamMateObj?.id && prevTeamMate?.id != teamMateObj?.uid
          : prevTeamMate?.uid != teamMateObj?.id &&
          prevTeamMate?.uid != teamMateObj?.uid;
      })
    );
    setTeamMate(null);
  };

  useEffect(() => {
    setMinTeamMate(minTeamMatesRequired - 1);
  }, [minTeamMates]);

  useEffect(() => {
    setCanRegisterTeamMate(minTeamMates <= selectedTeamMates?.length);
    setTeamMatesOptions(
      intialTeamMatesOptions.current?.filter((item) => {
        return (
          selectedTeamMates?.findIndex(
            (selectedTeamMate) => selectedTeamMate.value == item.value
          ) === -1
        );
      })
    );
  }, [selectedTeamMates]);

  // for getting friend for replace
  useEffect(() => {
    if (replace) {
      setReplaceFriend(teamMate);
    }
  }, [teamMate]);

  return (
    <>
      <div className="add-teammate-container">
        {replace ? (
          <div className="mb-4"></div>
        ) : (
          <label>
            Add {minTeamMates} Teammates{" "}
            <span title="">
              <img
                src="/assets/images/info-icon.svg"
                alt="info"
                class="info-image"
                data-tip
                data-for="loginTip"
              />
            </span>
            <ReactTooltip id="loginTip" place="right" effect="solid">
              While creating teams only friends could be added as team members
            </ReactTooltip>{" "}
          </label>
        )}
        <div className="d-flex">
          <Select
            className="react-select-container-teammates chooseTeam"
            classNamePrefix="react-select-teammates"
            filterOption={createFilter(AddTeamMatefilterConfig)}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            placeholder="Choose Teammate"
            isSearchable
            isClearable
            onChange={(teamMateSelected) => {
              setTeamMate(teamMateSelected);
            }}
            value={teamMate}
            options={teamMatesOptions}
          />
          {replace ? null : (
            <div class="input-group-append addTeamamtes">
              {minTeamMates <= selectedTeamMates?.length ? (
                <span className="plus-icon tickIcon">
                  <img src="/assets/images/tick-icon.svg" />
                </span>
              ) : (
                <button
                  style={{ background: "cyan" }}
                  class="btn btn-secondary plus-icon"
                  type="button"
                  onClick={addTeamMate}
                  disabled={!teamMate}
                >
                  +
                </button>
              )}
            </div>
          )}
        </div>
        {props.showError && !canRegisterTeamMate ? (
          <div className="invalid-feedback  d-block">
            Please add {minTeamMates} teammates
          </div>
        ) : canRegisterTeamMate && !replace ? (
          <div style={{ color: "white" }}>
            Maximum no. of teammates are added{" "}
          </div>
        ) : null}
      </div>
      {selectedTeamMates?.map((teamMateItem, idx) => {
        return (
          <div
            className="d-flex justify-content-between addTeaMates-box"
            key={teamMateItem.username}
          >
            <div className="addTeaMatesBox">
              <span>{idx + 1}.</span>
              <span className="teamMateUsername">{teamMateItem.username}</span>
            </div>
            <div>
              {teamMateItem?.status == 2 ?  <span className="deactivated-teamMember">Deactivated</span>:null}
              <img
                onClick={() => deleteTeamMate(teamMateItem)}
                src="/assets/images/cross-icon.svg"
                alt=""
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AddTeamMate;

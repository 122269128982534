import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";
export async function getTeamMatesLobby({ lobby_id, teamId }) {
  const url =
    baseURL +
    `players/lobby/${lobby_id}/teammates/${teamId ? "?team=" + teamId : ""}`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function postFriendRequest({
  status,
  promo_choice,
  lobbyId,
  teamId,
}) {
  const url =
    baseURL +
    `players/lobby/${lobbyId}/${teamId}/${status}-invite/`;
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    promo_choice: promo_choice,
  };

  return await axiosApi.post(url, body, { headers });
}

export async function postReplaceTeamMate({
  lobbyId,
  teamId,
  removeId,
  newId,
}) {
  const url =
    baseURL +
    `players/lobby/${lobbyId}/${teamId}/replace-teammate/`;
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    remove_player: removeId,
    new_player: newId,
  };

  return await axiosApi.post(url, body, { headers });
}
export async function callPreMadeTeams(lobby_id) {
  const url =
    baseURL + `players/lobby/${lobby_id}/existing-teams/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function callLeaveTeam(data) {
  const url =
    baseURL +
    `players/lobby/${data.lobby_id}/${data.team_id}/leave-team/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, { headers });
}
export async function makePaymentByOwner(data) {
  const url = baseURL + `players/lobby/make-payment/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function getPlayersForChallengeReplace({
  name = "",
  team,
  challenge_id,
}) {
  const url =
    baseURL +
    `players/challenge/${challenge_id}/teammates`;
  const headers = {
    "Content-Type": "application/json",
  };
  const params = new URLSearchParams();
  params.append("name", name);
  params.append("team", team);

  return await axiosApi.get(url, { headers, params });
}

export async function replaceMemberChallenge({ data, challenge_id, team_id }) {
  const url =
    baseURL +
    `players/challenge/${challenge_id}/${team_id}/replace-teammate/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function leaveMemberChallenge({ challenge_id, team_id }) {
  const url =
    baseURL +
    `players/challenge/${challenge_id}/${team_id}/leave-team/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, { headers });
}

export async function makePaymentChallenge(data) {
  const url = baseURL + `players/challenge/make-payment/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function putTeamRequestProfile({ action, teamId }) {
  const url = baseURL + `players/versus/${teamId}/action/`;

  const body = {
    action: action ? "accept" : "cancel",
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import Team from "./Team";
import CountDownTeams from "./CountDownTeams";

function Participants(props) {
  const [canModify, setCanModify] = useState(false);
  const [canReplace, setCanReplace] = useState(true);
  const [owner, setOwner] = useState(false);

  return !props?.participantData?.length ? (
    <h4>You are not registered in this lobby</h4>
  ) : props?.participantData?.length > 1 ? (
    <div className={`row ${props.fullView ? "" : "row-cols-1 row-cols-md-2"}`}>
      <div className="participantsHeader d-flex justify-content-end">
        <CountDownTeams
          server_current_datetime={props?.lobbyData?.countdown_time}
          owner={owner}
          setCanModify={setCanModify}
          setCanReplace={setCanReplace}
        />
      </div>
      {props?.participantData?.map((item, idx) => (
        <Team
          {...props}
          item={item}
          fullView={false}
          owner={owner}
          canModify={canModify}
          setOwner={setOwner}
          canReplace={canReplace}
          key={idx}
        />
      ))}
    </div>
  ) : (
    <div>
      <div className="participantsHeader d-flex justify-content-end">
        <CountDownTeams
          server_current_datetime={props?.lobbyData?.countdown_time}
          owner={owner}
          setCanModify={setCanModify}
          setCanReplace={setCanReplace}
        />
      </div>
      <Team
        {...props}
        item={props?.participantData?.[0]}
        fullView={true}
        canModify={canModify}
        setOwner={setOwner}
        owner={owner}
        canReplace={canReplace}
      />
    </div>
  );
}

export default Participants;

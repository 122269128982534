import React, { useState } from "react";
import ImageCropper from "../Image-clipper/ImageCropper";
import { uploadAvatarImage } from "../../services/user.service";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import toastify from "../../helper/toastify.jsx";
const EditPicture = (props) => {
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);
  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => setImageToCrop(reader.result));

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const uploadAvatar = () => {
    const image = localStorage.getItem("imageLink");
    setisLoading(true);
    uploadAvatarImage(image)
      .then(() => {
        toastify("success", "Avatar uploaded successfully");
        props.closeEditPictureModal();
      })
      .catch((err) => {
        props.showisEditPictureMod();
        setisLoading(false);
      });
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Upload Avatar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="profile-body">
        <input type="file" accept="image/*" onChange={onUploadFile} />
        <div class="upload-wrapper">
          <div class="upload-img">
            <ImageCropper
              imageToCrop={imageToCrop}
              onImageCropped={(newCroppedImage) => {
                setCroppedImage(newCroppedImage);
              }}
            />
          </div>
          {croppedImage && (
            <div class="upload-img">
              <h2>Cropped Image</h2>
              <img
                alt="Cropped"
                src={croppedImage}
                style={{ height: "190px", width: "180px" }}
              />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={uploadAvatar}
          disabled={
            croppedImage === undefined ? true : isLoading ? true : false
          }
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-4"></span>
          )}
          Upload
        </Button>
      </Modal.Footer>
    </>
  );
};
EditPicture.propTypes = {
  closeEditPictureModal: PropTypes.func,
};

export default EditPicture;

import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import {
  communitylisting,
  getTopPlayerList,
} from "../../services/community.service";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import PlayerOfWeek from "./PlayerOfWeek";
import Clips from "./Clips";
import CommunitySocials from "./CommunitySocials";
import SubmitClip from "./SubmitClip";
const Community = () => {
  const [show, setShow] = useState(false);
  const [community, setCommunity] = useState([]);
  const [players, setTopPlayers] = useState([]);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getCommunityList();
    getPlayerList();
    logoutEventPublicRoutes();
  }, []);
  const getCommunityList = () => {
    setSpinnerLoader(true);
    communitylisting()
      .then((res) => {
        setCommunity(res);
        setSpinnerLoader(false);
      })
      .catch((err) => {
        setSpinnerLoader(false);
      });
  };
  const getPlayerList = () => {
    getTopPlayerList().then((res) => {
      setTopPlayers(res);
    });
  };
  const [playing, setPlaying] = useState(false);
  const startPlaying = () => {
    setPlaying(true);
  };
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax profile-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">
                      Community
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      <PlayerOfWeek players={players} />
      <section class="kill-streaks">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="clip-submission">
                <h2>Kill streaks of the week</h2>
                <button class="btn btn-primary" onClick={handleShow}>
                  Submit a clip
                </button>
              </div>

              <div class="row row-cols-1 row-cols-md-3 g-4">
                {spinnerLoader ? (
                  <div class="loading"></div>
                ) : (
                  <Clips
                    community={community}
                    playing={playing}
                    startPlaying={startPlaying}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommunitySocials />
      <SubmitClip handleClose={handleClose} show={show} />
    </>
  );
};
export default Community;

import React from "react";
import CountDown from "../../Lobby/CountDown";
import PlayerAddRank from "../Form/PlayerAddRank";

const TournamentDetailEnrollAction = ({
  details,
  isTimerReached,
  ChallengeCountdownEnded,
  canEnroll,
  setCanEnroll,
  handleEnroll,
  profile,
  reCallApi,
}) => {
  return (
    <div class="tournamemt-enroll-wrapper enroll-wrapper w-100">
      <div className="">
        <h3 className="mb-0">{details.name}</h3>
      </div>

      <div className="">
        <p className="theme-color">
          Entry : ${details?.entry_fee} Per player entry fee
        </p>
      </div>
      {/* Enroll box */}
      {details.players_to_add_rank_status && details.is_enrolled ? (
        <PlayerAddRank
          tournamentDetails={details}
          {...profile}
          reCallApi={reCallApi}
        />
      ) : null}
      <div className="enroll-box ">
        <div className="enrolled">
          {details.countdown_time === undefined ? (
            <></>
          ) : (
            <>
              {isTimerReached ? null : (
                <CountDown
                  isTournament={true}
                  server_current_datetime={details?.countdown_time}
                  time_reached={(e) => {
                    ChallengeCountdownEnded();
                  }}
                  stopEnrollTeam={(isComplete) => {
                    if (canEnroll) {
                      setCanEnroll(false);
                    }
                  }}
                />
              )}
            </>
          )}
          </div>
          <button
            className="actionsChallenge btn btn-outline-primary enroll-btn"
            disabled={!canEnroll || details.is_enrolled}
            onClick={handleEnroll}
          >
            {details.is_enrolled ? "Enrolled" : "Enroll Team"}
          </button>
        
      </div>
    </div>
  );
};

export default TournamentDetailEnrollAction;

import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import { gamelisting, gameLobbyListing } from "../../services/game.service";
import LobbyCard from "./LobbyCard";
import { Button, Modal, Placeholder } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import Paginator from "../../helper/paginator/Paginator";
import Select from "react-select";
import {
  logoutEventPublicRoutes,
  lobbyFilter,
  lobbyAmountFilters,
  gameTypeFilters,
  gameStatusFilters,
  getModeLabelsSelect,
  customStylesSame,
  customStylesGames,
  customStyles,
} from "../../helper/utilities";
import { useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ChallengesTable from "./ChallengesTable";
import CreateLobbyForm from "./CreateLobbyForm";
import { getChallengeList } from "../../services/challenges.service";
import { getUserDetail } from "../../services/user.service";
import { useGame } from "../../context/GameContext";
import { gameTypeEnum } from "../../helper/utilities";
const LobbyList = (props) => {
  const { slug } = useParams();
  const history = useHistory();
  const [lobbies, setlobbies] = useState([]);
  const { state } = useGame();
  const games = state?.game?.allGames;
  const [lobbyTypeFilter, setlobbyTypeFilter] = useState();
  const [lobbyAmountFilter, setlobbyAmountFilter] = useState();
  const [gameTypeMode, setGameType] = useState({ value: 1, label: "PPK" });
  const [gameStatus, setGameStatus] = useState(gameStatusFilters[0]);
  const gameChallenges = 0;
  const [lobbyGame, setLobbyGame] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [verified, setVerified] = useState(false);
  const [pageNum, setpageNum] = useState(1);
  const [gamesList, setGamesList] = useState([]);
  const [modeSelected, setmodeSelected] = useState(null);
  const pageSize = 25;
  const pagination = true;
  const loggedUser = useSelector((reduxState) => reduxState);
  const [challenges, setChallenges] = useState([]);
  const [isLoadingChallenge, setLoadingChallenge] = useState(false);
  const [selectedTraditionalGame, setSelectedTraditionalGame] = useState(null);
  const [user, setUser] = useState();
  const [key, setKey] = useState("lobby");

  const fetchChallenges = async () => {
    if (!selectedTraditionalGame?.slug) return;
    const filter = {
      pageNum: pageNum,
      pageSize: pageSize,
      status: gameChallenges,
      type: gameStatus.value,
      game: selectedTraditionalGame?.slug,
    };
    setLoadingChallenge(true);
    try {
      const res = await getChallengeList(filter);

      setChallenges(res.results);
      setLoadingChallenge(false);
      settotalCount(res.total);
    } catch (error) {
      setLoadingChallenge(false);
    }
  };
  useEffect(() => {
    gamelisting().then((res) => {
      setGamesList(
        res
          ?.map((game) => ({ ...game, value: game.id, label: game.name }))
          .filter((game) => game.game_status == 1)
      );
    });

    if (loggedUser?.user) {
      getUserDetail().then((res) => {
        setUser(res.profile.free_lobby_limit);
      });
    }
  }, []);
  useEffect(() => {
    if (slug) {
      if (key == "lobby") {
        getLobbies();
      }
    }
    const userData =
      JSON.parse(localStorage.getItem("userProfile")) !== null
        ? JSON.parse(localStorage.getItem("userProfile"))
        : null;
    if (userData !== null) {
      setVerified(userData.profile.is_verified);
    } else {
      setVerified(false);
    }
    logoutEventPublicRoutes();
  }, [
    pageNum,
    slug,
    lobbyTypeFilter,
    lobbyAmountFilter,
    modeSelected,
    gameTypeMode?.value,
    key,
  ]);

  useEffect(() => {
    if (key != "lobby") {
      fetchChallenges();
    }
  }, [
    pageNum,
    gameStatus.value,
    gameChallenges,
    selectedTraditionalGame?.value,
    key,
    slug,
  ]);

  useEffect(() => {
    const defaultGameValue = state.game?.allGames.filter(
      (val) => val.slug === slug
    );
    for (const typeKey in state?.game?.modeObj) {
      if (
        state?.game?.modeObj[typeKey]?.find((gameObj) => {
          return gameObj.slug == slug;
        })
      ) {
        setmodeSelected(
          getModeLabelsSelect(
            { value: typeKey },
            state?.game?.modeGameTypeObj?.[slug]
          )?.[0]
        );
        // apex will match with PPK type first so we skip if route comes from challenges
        if (typeKey == 1 && props.location?.state?.challengeTab) continue;
        setGameType({ value: typeKey, label: gameTypeEnum[typeKey] });
        break;
      }
    }

    setLobbyGame(defaultGameValue?.[0]);
    setSelectedTraditionalGame(
      state?.game?.traditionalGames?.filter((game) => game.slug == slug)?.[0]
    );
  }, [slug, state?.game, props.location?.state]);

  const onLobbyFilterChange = (event) => {
    const val = event.value;
    setlobbyTypeFilter(val);
    setpageNum(1);
  };
  const onLobbyAmountChange = (event) => {
    const val = event.value;
    setlobbyAmountFilter(val);
    setpageNum(1);
  };
  const onLobbyGameChange = (event, gameTypeValue = -1) => {
    setmodeSelected(
      getModeLabelsSelect(
        typeof gameTypeValue != "object" && gameTypeValue != -1
          ? { value: gameTypeValue }
          : gameTypeMode,
        state?.game?.modeGameTypeObj?.[event?.slug]
      )?.[0]
    );
    setLobbyGame(event);
    setpageNum(1);

    const SLUG = event.slug;
    history.push(`/${SLUG}/lobby`);
  };
  const onChallengeGameChange = (event) => {
    setpageNum(1);
    setSelectedTraditionalGame(event);
  };
  const onLobbyModeChange = (event) => {
    setpageNum(1);
    setmodeSelected(event);
  };
  const onGameTypeChange = (event) => {
    const isGameExistInCurrentType = state?.game?.modeObj?.[event?.value]?.find(
      (gameObj) => gameObj.value == lobbyGame?.value
    );
    if (
      !isGameExistInCurrentType &&
      state?.game?.modeObj?.[event?.value]?.[0]
    ) {
      onLobbyGameChange(
        state?.game?.modeObj?.[event?.value]?.[0],
        event?.value
      );
    } else {
      setmodeSelected(
        getModeLabelsSelect(
          { value: event?.value },
          state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
        )?.[0]
      );
    }
    setGameType(event);
    setpageNum(1);
  };

  const onGameStatusChange = (event) => {
    setGameStatus(event);
    setpageNum(1);
  };

  const getLobbies = async () => {
    const filter = {
      game_slug: slug,
      type: lobbyTypeFilter,
      rewardAmount: lobbyAmountFilter === "0" ? null : lobbyAmountFilter,
      isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
      mode: modeSelected?.value,
      gameType: gameTypeMode.value,
    };
    setisLoading(true);
    await gameLobbyListing(filter)
      .then((res) => {
        if (pagination) {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.total);
        } else {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.length);
        }
      })
      .catch((_) => {
        setlobbies(null);
        setisLoading(false);
      });
  };
  const handlePageClick = (newPageNum) => {
    setpageNum(newPageNum);
  };

  const reCallLobbies = async () => {
    const filter = {
      game_slug: slug,
      type: lobbyTypeFilter,
      rewardAmount: lobbyAmountFilter === "0" ? null : lobbyAmountFilter,
      isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    setisLoading(true);
    await gameLobbyListing(filter)
      .then((res) => {
        if (pagination) {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.total);
        } else {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.length);
        }
      })
      .catch((_) => {
        setlobbies(null);
        setisLoading(false);
      });
  };

  const handleSuccessChallenge = () => {
    handleRegisterTeamModal();
  };

  const [resgisterTeam, showRegisterTeamModal] = useState(false);
  const handleRegisterTeamModal = () => {
    if (!loggedUser?.user?.id) history.push("/signin");
    showRegisterTeamModal(!resgisterTeam);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    if (props.location?.state?.challengeTab) {
      setKey("challenges");
    }
  }, [props.location?.state?.challengeTab]);

  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/lobbies-background.jpg"}
          strength={1000}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">Lobbies</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {/*Lobby list*/}
      <section className="lobby-section lobby-section-fullwidth">
        <div className="container">
          <div className="row">
            <div className="col">
              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  setpageNum(1);
                }}
              >
                <Tab eventKey="lobby" title="Lobby">
                  <div className="dash-available-lobbies vipLobbies">
                    <h3 className="vip-lobbies">VIP Lobbies</h3>
                    <h3 className="user-lobbies">User Enrolled Lobbies</h3>
                  </div>
                  <div className="lobby-dropdowns lobby-dropdown-width">
                    <div className="lobby">
                      <h2>Game Type</h2>
                      <p>Type</p>
                      <Select
                        className="LobbyModeFilters"
                        value={gameTypeMode}
                        options={gameTypeFilters}
                        onChange={onGameTypeChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Game</h2>
                      <p>Game</p>
                      <Select
                        key={gameTypeMode?.value}
                        value={lobbyGame}
                        options={state?.game?.modeObj?.[gameTypeMode.value]}
                        onChange={onLobbyGameChange}
                        styles={customStylesGames}
                        // maxMenuHeight={250}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Lobby Mode</h2>
                      <p>Mode</p>
                      <Select
                        key={gameTypeMode}
                        defaultValue={
                          getModeLabelsSelect(
                            gameTypeMode,
                            state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
                          )?.[0]
                        }
                        className="LobbyModeFilters"
                        value={modeSelected}
                        options={getModeLabelsSelect(
                          gameTypeMode,
                          state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
                        )}
                        onChange={onLobbyModeChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Lobby</h2>
                      <p>Lobby</p>
                      <Select
                        defaultValue={lobbyFilter[0]}
                        options={lobbyFilter.filter((lobbyOption) =>
                          lobbyOption?.loginRequired ? loggedUser?.user : true
                        )}
                        onChange={onLobbyFilterChange}
                        styles={customStyles}
                        isSearchable={false}
                      />
                    </div>

                    <div className="lobby">
                      <h2>Lobby Amount</h2>
                      <p>Amount</p>
                      <Select
                        defaultValue={lobbyAmountFilters[0]}
                        options={lobbyAmountFilters}
                        onChange={onLobbyAmountChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>

                    <div className="dash-available-lobbies vipLobbies1">
                      <h3 className="vip-lobbies">VIP Lobbies</h3>
                      <h3 className="user-lobbies">User Enrolled Lobbies</h3>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-2">
                    {isLoading ? (
                      <p>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder animation="glow">
                          <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                          <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                          <Placeholder xs={8} />
                        </Placeholder>
                      </p>
                    ) : totalCount === undefined || totalCount === 0 ? (
                      <h5>No lobbies yet</h5>
                    ) : verified ? (
                      lobbies &&
                      lobbies?.map((lobby, index) => (
                        <LobbyCard
                          {...lobby}
                          callLobbies={() => {
                            reCallLobbies();
                          }}
                          data={props.location?.state?.ipAddress}
                          key={index}
                          isTeam={true}
                          games={games}
                          userDataForFreeLimitCount={user}
                        />
                      ))
                    ) : lobbies && lobbies?.every((e) => e?.is_verified) ? (
                      isLoading ? (
                        <p>
                          <Placeholder animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                          <Placeholder animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                            <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                            <Placeholder xs={8} />
                          </Placeholder>
                        </p>
                      ) : (
                        <>
                          <h5>No lobbies yet</h5>
                        </>
                      )
                    ) : (
                      lobbies &&
                      lobbies?.map((lobby, index) =>
                        lobby.is_verified ? (
                          ""
                        ) : (
                          <LobbyCard
                            {...lobby}
                            callLobbies={() => {
                              reCallLobbies();
                            }}
                            data={props.location?.state?.ipAddress}
                            key={index}
                            games={games}
                            userDataForFreeLimitCount={user}
                          />
                        )
                      )
                    )}
                  </div>
                  <div></div>
                </Tab>
                {props?.match?.params?.slug === "apex-legends" ||
                props?.match?.params?.slug === "valorant" ? (
                  <Tab eventKey="challenges" title="Challenges">
                    <div className="lobby-dropdowns justify-content-between challengedrop">
                      <div className="d-flex">
                        <div className="d-flex challengeDropdowns  me-4">
                          <div className="lobby">
                            <h2>Game</h2>
                            <p>Game</p>
                            <Select
                              value={selectedTraditionalGame}
                              options={state.game?.traditionalGames.map(
                                (game) => ({
                                  ...game,
                                  value: game.id,
                                  label: game.name,
                                })
                              )}
                              onChange={onChallengeGameChange}
                              styles={customStylesGames}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        <div className="d-flex challengeDropdowns">
                          <div className="lobby">
                            <h2>Challenge Status</h2>
                            <p>Status</p>
                            <Select
                              className="LobbyModeFilters"
                              defaultValue={gameStatusFilters[0]}
                              options={gameStatusFilters}
                              onChange={onGameStatusChange}
                              styles={customStylesGames}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <h2>&emsp;</h2>
                        <p>&emsp;</p>
                        <Button
                          className="addChallenge"
                          variant="secondary"
                          onClick={() => handleRegisterTeamModal()}
                        >
                          Add Challenge
                        </Button>
                      </div>
                    </div>
                    {isLoadingChallenge ? (
                      <div className="row row-cols-1 row-cols-md-2">
                        <p>
                          <Placeholder animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                          <Placeholder animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                            <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                            <Placeholder xs={8} />
                          </Placeholder>
                        </p>
                      </div>
                    ) : challenges?.length ? (
                      <ChallengesTable
                        key={key}
                        games={games}
                        challenges={challenges}
                        fetchChallenges={fetchChallenges}
                        totalCountChallenges={totalCount}
                        challengesPageSize={pageSize}
                        handlePageClickChallenges={handlePageClick}
                        pageNumChallenge={pageNum}
                        status={gameStatus.value}
                      />
                    ) : (
                      <h5>
                        No {gameStatus.label?.toLowerCase()} challenges yet
                      </h5>
                    )}
                  </Tab>
                ) : null}
              </Tabs>

              <Paginator
                key={key}
                totalCount={totalCount}
                pageSize={pageSize}
                pageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
      </section>
      {/*Lobby list*/}

      <Modal
        show={resgisterTeam}
        onHide={() => handleRegisterTeamModal()}
        backdrop="static"
      >
        <CreateLobbyForm
          games={gamesList}
          handleRegisterTeamModal={handleRegisterTeamModal}
          handleSuccessChallenge={handleSuccessChallenge}
        />
      </Modal>
    </>
  );
};
export default withRouter(LobbyList);

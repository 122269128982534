import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";

import { Placeholder } from "react-bootstrap";
import { getCMSData } from "../../services/cms.service";
const TermsOfUse = () => {
  const [loading, setLoading] = useState(false);
  const [termsAndCondition, setTermsAndConditions] = useState("");
  useEffect(() => {
    setLoading(true);
    const body = {
      title: "terms-and-conditions",
    };
    getCMSData(body).then((res) => {
      setLoading(false);
      setTermsAndConditions(res);
    });
  }, []);
  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient privacy-policy">
                      Ryvals Terms Of Use
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {!loading ? (
        <section className="terms-of-use">
          <div className="container">
            <div className="row">
              <div className="col">
                <p
                  dangerouslySetInnerHTML={{
                    __html: termsAndCondition.content,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="privacy-policy-section">
            <div className="container">
              <div className="row">
                <div className="col">
                  <p>
                    <Placeholder animation="glow">
                      <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                      <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                      <Placeholder xs={8} />
                    </Placeholder>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default TermsOfUse;

import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import dateToUtc from "../../helper/dateToUtc";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useConfig } from "../../context/ConfigContext";

const CountDownTeams = (props) => {
  const [endTime, setEndTime] = useState(null);
  const { state } = useConfig();

  useEffect(() => {
    setEndTime(
      props.owner
        ? dateToUtc(
            new Date(
              moment(props.server_current_datetime).subtract(
                state?.config?.team_lobby_buffer_time,
                "minutes"
              )
            )
          )
        : dateToUtc(
            new Date(
              moment(props.server_current_datetime).subtract(
                state?.config?.team_lobby_buffer_time +
                  state?.config?.team_owner_mod_time,
                "minutes"
              )
            )
          )
    );
  }, [
    JSON.stringify(state?.config),
    props.server_current_datetime,
    props.owner,
  ]);

  useEffect(() => {
    if (endTime  < dateToUtc(new Date())) {
      props.setCanReplace(false);
      props.setCanModify(false);
    }
  }, [endTime]);

  const countdownTimer = (timerProps) => {
    const minsLeft = timerProps.total / 1000 / 60;
    const {  hours, minutes, seconds, completed } = timerProps;
    if (minsLeft < 0 || completed) {
      props.setCanModify(false);
    } else {
      props.setCanModify(true);
    }
    if (minsLeft < state?.config?.team_owner_mod_time && props.owner) {
      props.setCanReplace(false);
    } else {
      props.setCanReplace(true);
    }

    if (completed  || props.challengeList || 
      (props?.owner
        ? minsLeft > state?.config?.team_owner_mod_time
        : minsLeft > state?.config?.team_member_mod_time)) {
      return <span></span>;
    } else {
      return (
        <div className="modify-timer ">
          <span class="info-icon">
            <img
              src="/assets/images/info-icon.svg"
              alt="info"
              class="info-image"
              data-tip
              data-for="timerTip"
            />
          </span>
          <ReactTooltip id="timerTip" place="left" effect="float">
            {minutes.toString().padStart(2, 0)} Minutes left to do modifications
            for team
          </ReactTooltip>
          <img
            src="/assets/images/fluent_timer.svg"
            className="teamsTimer"
            alt="timer"
          />
          <span style={{ fontSize: "16px", textTransform: "none" }}>
            <span className="timer-of-start">
              {hours.toString().padStart(2, 0) + " : "}
              {minutes.toString().padStart(2, 0) + " : "}
              {seconds.toString().padStart(2, 0)}
            </span>{" "}
            Left
          </span>
        </div>
      );
    }
  };

  return endTime > dateToUtc(new Date()) ? (
    <Countdown date={endTime} renderer={countdownTimer} />
  ) : null;
};

export default CountDownTeams;

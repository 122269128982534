import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";

export async function faqlisting({ type }) {
  const url = baseURL + "pages/faqs/";

  const params = new URLSearchParams();

  params.append("type", type);
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}
export async function sendFeedBack(body) {
  const url = baseURL + "emails/feedback/";

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

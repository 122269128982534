import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const GuLagInfo = (props) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>DISCORD REQUIRED TO PLAY</Modal.Title>
      </Modal.Header>
      <Modal.Body className="respawn-wrapper">
        <p class="respawn-text">
          CODE or PASSWORD will be sent via text, email and on Discord 10
          minutes before lobby start time
        </p>
        <p class="respawn-text">
          <span>
            {" "}
            Please note DISCORD IS REQUIRED to participate in PPK lobbies.
            Players must ensure they have accurately stored their Discord
            username into their player profiles prior to enrolling in a lobby.
            Once enrolled, players will receive an email with a code to enter
            the lobby’s private discord channel, where Admins will start the
            match and communicate with players.
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a
          href="https://discord.com/invite/Nr4xmYZzQa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="discrodSignUp">Discord Sign Up</Button>
        </a>
      </Modal.Footer>
      <Modal.Header className="respawning">
        <Modal.Title>RESPAWNING TEAMMATES IS NOT ALLOWED</Modal.Title>
      </Modal.Header>
      <Modal.Body className="respawn-wrapper">
        <p class="respawn-text">
          <span>
            WARNING: Players MAY NOT RESPAWN teammates back into the match under
            any circumstance. Once a player is killed, they are eliminated from
            the match. Players are strictly prohibited from taking their
            teammate’s banner to a respawn beacon and respawning a teammate.
            Players MAY revive DOWNED BUT NOT DEAD teammates, but they CANNOT
            respawn dead/eliminated teammates. Failure to abide by this rule
            will subject players to strict penalties, including forfeiture of
            funds and suspension/termination of their account.
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div>{props.children}</div>
      </Modal.Footer>
    </>
  );
};

GuLagInfo.propTypes = {
  onAgreeBtnClick: PropTypes.func,
};

export default GuLagInfo;

import React from "react";
import { appendSuffixToTournamentPosition } from "../../../helper/utilities";

const PrizeTab = ({ prizelist }) => {
  return (
    <div class="row prize-boxes">
      {prizelist?.map((price, index) => {
        const prizeLength = prizelist.length === 1;
        return prizeLength ? (
          <div className="col-sm-12">
            <div className="prize--box">
              <img src="/assets/images/Trophy.svg" alt="Trophy" />

              <p className="mt-2 mb-0">
                Winning Team will get $ {price.price} as Reward
              </p>
            </div>
          </div>
        ) : (
          <div className="col-sm-12 col-md-4">
            <div className="prize--box">
              <img src="/assets/images/Trophy.svg" alt="Trophy" />
              <h3 className="mt-3 mb-2 theme-color">${price.price}</h3>
              <p className="mb-0">
                {`${price.position}${appendSuffixToTournamentPosition(
                  price.position
                )}`}{" "}
                Prize
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrizeTab;

import React from "react";
import { Link } from "react-router-dom";
import { useGame } from "../../context/GameContext";
const GameSection = () => {
  const { state } = useGame();
  const loggedUser = localStorage.getItem("userProfile");
  return (
    <React.Fragment>
      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper">
          <div
            className={
              loggedUser
                ? "container games-container"
                : "container-container1 games-container"
            }
          >
            <div className="row justify-content-center">
              {state.game.ppkGames.map((game, i) => (
                <div
                  className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2"
                  key={i}
                >
                  <Link
                    to={`/${game.slug}/lobby`}
                    style={
                      game?.game_status == 2 ? { pointerEvents: "none" } : null
                    }
                  >
                    <div className="sliders apexLegendsBox">
                      <img
                        src={game.background_image}
                        className="card-img "
                        alt="..."
                      />
                      <h6 className="newBlue-color">{game.name}</h6>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GameSection;

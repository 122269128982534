import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GameSection from "./GameSection";
import Cookies from "js-cookie";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GameTraditionalSection from "./GameTraditionalSection";
import { STRINGS_CONSTANTS } from "../../helper/stringConstants";
const Home = () => {
  const [checkAcceptCookies, setCheckCookiesAccept] = useState("false");
  const [checkDenialCookie, setCheckCookiesDeny] = useState("false");
  useEffect(() => {
    const checkCookies = Cookies.get("Accept") === undefined ? false : true;
    const checkDenialCookieValue =
      Cookies.get("Deny") === undefined ? false : true;
    setCheckCookiesDeny(checkDenialCookieValue);
    setCheckCookiesAccept(checkCookies);
    logoutEventPublicRoutes();
  }, []);
  const handleDenial = () => {
    const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
    Cookies.set("Deny", "true", { expires: inFifteenMinutes });
    Cookies.remove("Accept");
    setCheckCookiesDeny(true);
    setCheckCookiesAccept(false);
  };
  const handleAccept = () => {
    Cookies.set("Accept", "true", { expires: 62 });
    Cookies.remove("Deny");
    setCheckCookiesDeny(false);
    setCheckCookiesAccept(true);
  };
  const loggedUser = localStorage.getItem("userProfile");
  const [key, setKey] = useState("ppk");
  return (
    <>
      {/* Signup Today section */}
      <section className="playWin-section d-sm-none d-md-block d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12 col-md-6">
              <div className="playWinBox">
                <h4>
                  <span>We’re leveling out </span>the playing field.{" "}
                </h4>
                <h5>Play PPK® and win cash!</h5>
                <p>
                  Competitive Gaming should be available to everyone, not just
                  &nbsp;
                  <span> “elite”</span> gamers. You do not need to be the best,
                  only better than one other player to win cash.
                </p>
                {loggedUser ? (
                  <Link to="/games">
                    {" "}
                    <button className="communityButton invite-btn">
                      Play now
                    </button>
                  </Link>
                ) : (
                  <Link to="/signup">
                    {" "}
                    <button className="communityButton invite-btn">
                      {STRINGS_CONSTANTS.signup + " today! "}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-lg-none d-md-none mobile-banner">
        <div className="container">
          <div className="d-sm-12">
            <img src="/assets/images/mobile-banner-image.png"></img>
          </div>
          <div className="d-sm-12">
            <div className="playWinBox">
              <h4>
                <span>We’re leveling out </span>the playing field.{" "}
              </h4>
              <h5>Play PPK® and win cash!</h5>
              {loggedUser ? (
                <Link to="/games">
                  {" "}
                  <button className="communityButton invite-btn">
                    Play now
                  </button>
                </Link>
              ) : (
                <Link to="/signup">
                  {" "}
                  <button className="communityButton invite-btn">
                    Sign up today!
                  </button>
                </Link>
              )}
              <p>
                Competitive Gaming should be available to everyone, not
                just&nbsp;
                <span>“elite”</span> gamers. You do not need to be the best,
                only better than one other player to win cash.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Play PPK section */}
      <section className="newPPK-Traditional">
        <div className="container">
          (
          <Tabs
            id="uncontrolled-tab-example"
            className="mb-3 nav-justified mt-5"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="ppk" title="PPK">
              <section className="playPPK-section">
                <div className="container">
                  <div className="row">
                    <div className="d-sm-12 d-md-12">
                      <div className="playPpkBox text-center">
                        <h4>
                          <span>Play PPK®</span>{" "}
                          <p>to win cash per elimination.</p>
                        </h4>
                        <p className="playPpkdesc">
                          Ryvals PPK® is the next evolution in online gaming.
                          Ryvals is the exclusive home of patent pending PPK®{" "}
                          <span>
                            <Link to={"/ppk"}>(What is PPK®)</Link>
                          </span>
                          . PPK® uses the popular battle royale gaming format
                          and turns it on its head. Players win real cash, not
                          credits, for every opponent eliminated. There is no
                          need to be the last player standing. The more players
                          you eliminate, the more money you make. Your fate is
                          in your hands.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <GameSection
                      className={loggedUser ? "ninty-percent" : ""}
                      activeTabKey={key}
                    />
                    <p className="gamesDesc">
                      You can access Ryvals on PC, mobile and all major consoles
                      in the US, Canada, Europe, and parts of Asia-Pacific.{" "}
                    </p>
                  </div>
                </div>
              </section>
            </Tab>
            <Tab eventKey="traditional" title="TRADITIONAL">
              <section className="playPPK-section">
                <div className="container">
                  <div className="row">
                    <div className="d-sm-12 d-md-12">
                      <div className="playPpkBox text-center">
                        <p className="playPpkdesc">
                          Try out our “traditional” game mode matches. This game
                          mode is a standard winner-take-all, for non-battle
                          royale game modes. Our traditional matches also
                          utilize Ryvals’ automatic scoring and cash payouts.
                          Forget the credits and screenshots and get right back
                          into the action!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <GameTraditionalSection
                      className={loggedUser ? "ninty-percent" : ""}
                      activeTabKey={key}
                    />
                    <p className="gamesDesc">
                      You can access Ryvals on PC, mobile and all major consoles
                      in the US, Canada, Europe, and parts of Asia-Pacific.{" "}
                    </p>
                  </div>
                </div>
              </section>
            </Tab>
            <Tab eventKey="killRace" title="KILL RACE">
              <section className="playPPK-section">
                <div className="container">
                  <div className="row">
                    <div className="d-sm-12 d-md-12">
                      <div className="playPpkBox text-center">
                        <p className="playPpkdesc">
                          Ryvals Kill Race features a two-vs-two kill format. In
                          this format, the opposing teams drop into a match and
                          try to score the most kills in a given number of
                          rounds. The team with the most kills wins the prize
                          money. Kill Race matches are cash only and utilize
                          Ryval's automatic scoring.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <GameTraditionalSection
                      className={loggedUser ? "ninty-percent" : ""}
                      activeTabKey={key}
                    />
                    <p className="gamesDesc">
                      You can access Ryvals on PC, mobile and all major consoles
                      in the US, Canada, Europe, and parts of Asia-Pacific.{" "}
                    </p>
                  </div>
                </div>
              </section>
            </Tab>
          </Tabs>
          )
        </div>
      </section>

      {/* Ryvals Difference section */}
      <section className="ryvals-difference d-sm-none d-md-block d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12 col-md-6">
              <div className="ryvalsBox">
                <h4>
                  <span>The Ryvals</span> Difference
                </h4>
                <p className="my-4">
                  Get Ready for a New Gaming Experience with Ryvals! Say goodbye
                  to the old way of gaming, and hello to the Ryvals Difference.
                  Our focus is on everyday gamers like you, giving everyone the
                  chance to compete and earn money. No more random public
                  lobbies, self-reporting, or outdated credits.
                </p>
                <p>
                  With Ryvals PPk® enjoy the convenience, accuracy, and speed of
                  our custom lobbies with automatic scoring. Say hello to fast
                  and fair payouts, and experience the best gaming has to offer
                  with Ryvals!{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-lg-none d-md-none mobile-ryvals-difference">
        <div className="container">
          <div className="d-sm-12">
            <img src="/assets/images/mobile-controller-image.png"></img>
          </div>
          <div className="d-sm-12">
            <div className="ryvalsBox">
              <h4>
                <span>The Ryvals</span> Difference
              </h4>
              <p className="my-4">
                Ryvals focuses on everyday gamers, not the elites. Everyone is
                invited and has a chance to make money. No more outdated
                credits, self-reporting or matches against random public
                players.
              </p>
              <p>
                Ryvals PPK® lobbies are played in custom lobbies with automatic
                scoring for convenience, accuracy, and faster payouts.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How does PPK work section */}
      <section className="ppkSection d-sm-none d-md-block d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-6"></div>
            <div className="col-lg-8 col-sm-12 col-md-6">
              <div className="ppkBox">
                <h4 className="ppkHeading">
                  <span>How does </span>
                </h4>
                <h4 className="ppkHeading">PPK® work?</h4>
                <p className="ppkDesc">
                  Ryvals PPK® is Ryvals patent pending tournament model. PPK®
                  uses the popular battle royale gaming format for
                  single-elimination matches. Rather than wanting to be the last
                  person standing, eliminations are all that matter in PPK®. You
                  have one life to eliminate as many of your opponents as
                  possible. Each elimination earns you real money.
                  <p className="ppkDescription">It’s as easy as that.</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-lg-none d-md-none mobile-ppkSection">
        <div className="container">
          <div className="d-sm-12">
            <img src="/assets/images/mobile-ppk-image.png"></img>
          </div>
          <div className="d-sm-12">
            <div className="ppkBox">
              <h4 className="ppkHeading">
                <span>How does </span>&nbsp;PPK® work?
              </h4>
              <p className="ppkDesc">
                Ryvals PPK® is Ryvals patent pending tournament model. PPK® uses
                the popular battle royale gaming format for single-elimination
                matches. Rather than wanting to be the last person standing,
                eliminations are all that matter in PPK®. You have one life to
                eliminate as many of your opponents as possible. Each
                elimination earns you real money.
                <p className="ppkDescription">It’s as easy as that.</p>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our community section */}
      <section className="communitySection d-sm-none d-md-none d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="communityBox">
              <h4 className="ourCommunity">
                Our <span>Community</span>
              </h4>
              <p className="communityDesc">
                Join the spotlight! Our community section is all about shining a
                light on the amazing gamers who use our platform. We believe
                that every player deserves a chance to shine, just like the
                pros. That's why we're dedicated to helping you grow your
                following by showcasing your best clips and promoting your
                socials. Get ready to take center stage and show the world your
                gaming skills with Ryvals!
              </p>
              <Link to="/community">
                <button className="communityButton invite-btn">
                  See our community
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="d-lg-none mobile-community">
        <div className="container">
          <div className="d-sm-12">
            <div className="communityBox">
              <h4 className="ourCommunity">
                Our <span>Community</span>
              </h4>
              <p className="communityDesc">
                Join the spotlight! Our community section is all about shining a
                light on the amazing gamers who use our platform. We believe
                that every player deserves a chance to shine, just like the
                pros. That's why we're dedicated to helping you grow your
                following by showcasing your best clips and promoting your
                socials. Get ready to take center stage and show the world your
                gaming skills with Ryvals!
              </p>
              <Link to="/community">
                <button className="communityButton invite-btn">
                  See our community
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*Cookies*/}
      {checkAcceptCookies ? (
        ""
      ) : checkDenialCookie ? (
        ""
      ) : (
        <section className="cookies">
          <div className="container">
            <div className="row">
              <div className="col">
                <p>
                  <span>
                    We use cookies on our website to give you the best user
                    experience. By using this website, you agree to its use of
                    cookies.
                  </span>
                </p>
                <div className="cookies-buttons">
                  <div>
                    <button className="deny-button" onClick={handleDenial}>
                      Deny
                    </button>
                  </div>
                  <div>
                    <Link to="/manage-cookies">
                      <button className="btn btn-outline-secondary">
                        Manage
                      </button>
                    </Link>
                  </div>
                  <div>
                    <button className="btn btn-primary" onClick={handleAccept}>
                      I Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/*Cookies*/}
    </>
  );
};
export default Home;

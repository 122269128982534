import React from "react";
const StandingsTab = ({ standingsData }) => {
  return (
    <div className="col mb-4 left-card">
      <div className="card card_teams">
        <div className="card-body members-cards challengeCards">
          <div className="teams-information  w-100 challengesTeams mb--10">
            <div className="table-responsive">
              <table className="tounamnet-standing-tab card-padding table-width">
                <tr>
                <th>Rank</th>
                <th>Team Name</th>
                <th>Number Of kills</th>
                <th>Score</th>
              </tr>
              {standingsData &&
                standingsData.map((standings, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{standings.position}</td>
                      <td>{standings.team_name}</td>
                      <td>{standings.kills}</td>
                      <td>{standings.points}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandingsTab;

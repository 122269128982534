import React from "react";
import ReactTooltip from "react-tooltip";

const WinnerEntryBox = ({ data, responsiveClassName, isActive = true }) => {
  return (
    <div className={`entry-box ${responsiveClassName}`}>
      <div className="enrolled-box">
        Win
        <span class="info-icon">
          <img
            src="/assets/images/info-icon.svg"
            alt="info"
            class="info-image"
            data-tip
            data-for="loginTip"
          />
        </span>
        <ReactTooltip id="loginTip" place="right" effect="solid">
          {data.game.slug === "valorant" ||
          data.game.slug === "call-of-duty-warzone"
            ? "Actual amount paid to the team"
            : "Actual amount paid to the user"}
        </ReactTooltip>
        <span>:</span>
      </div>
      <div
        className={`count-box ${
          isActive ? "dollar-wrapper" : "paid-every-kill"
        } blue-color`}
      >
        {data.game.slug === "valorant" ||
        data.game.slug === "call-of-duty-warzone"
          ? `$${data.reward}/Winning Team`
          : `$${data.reward} Paid Every Kill`}
      </div>
    </div>
  );
};

export default WinnerEntryBox;

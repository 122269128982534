import { axiosApi } from ".";
import { baseURL } from "../helper/utilities";
import store from "../store"
export async function getChallengeList({
  pageSize,
  pageNum,
  status,
  type,
  game,
}) {
  const state = store.getState();
  const isLoggedIn = state?.user?.id;
  const url =
    baseURL +
    (isLoggedIn ? "players/challenges/" : "players/open-challenges");
  const params = new URLSearchParams();
  params.append("page_size", pageSize);
  params.append("page", pageNum);
  if (status) params.append("status", status);
  if (type) params.append("type", type);
  if (game) params.append("game", game);

  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}
export async function getTeams({ game_id, mode }) {
  const url = baseURL + "players/challenges-team/";

  const headers = {
    "Content-Type": "application/json",
  };
  const params = new URLSearchParams();
  params.append("game_id", game_id);
  params.append("mode", mode);

  return await axiosApi.get(url, { headers, params });
}

export async function getOpponentTeams({ game_id, mode, opponentName }) {
  const url = baseURL + "players/challenges-opp-team/";

  const headers = {
    "Content-Type": "application/json",
  };
  const params = new URLSearchParams();
  params.append("game_id", game_id);
  params.append("mode", mode);
  params.append("team", opponentName);

  return await axiosApi.get(url, { headers, params });
}

export async function addChallenge(model) {
  const url = baseURL + "players/challenges/";
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, model, { headers });
}

export async function deleteLobbyChallenge(challengeId) {
  const url =
    baseURL + `players/challenges/${challengeId}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.delete(url, { headers });
}

export async function getChallengesById(lobbyId) {
  const url = baseURL + `players/challenges/${lobbyId}/`;

  const headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");

  return await axiosApi.get(url, { headers });
}
export async function openChallengeAccept({ challengeId, body }) {
  const url =
    baseURL + `players/challenge-accept/${challengeId}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

export async function closedChallengeAction({ challengeId, body }) {
  const url =
    baseURL + `players/challenge-invite/${challengeId}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

import React from "react";
import "./index.scss";
function PasswordValidation({ leftBulltets = [], rightBullets = [] }) {
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-sm-around">
      <div className="flex-1">
        <ul className="password-conditions-ul">
          {leftBulltets?.map((bullet, idx) => {
            return <li key={idx}>{bullet}</li>;
          })}
        </ul>
      </div>
      <div className="flex-1">
        <ul className="password-conditions-ul">
          {rightBullets?.map((bullet, idx) => {
            return <li key={idx}>{bullet}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}

export default PasswordValidation;

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { getWalletBalance, getUserDetail } from "../../services/user.service";
import {
  postFriendRequest,
  makePaymentByOwner,
} from "../../services/teams.service";
import { useSelector } from "react-redux";
import AddToWallet from "../payment/AddToWallet";
import { withRouter } from "react-router";
import GuLagInfo from "./GuLagInfo";
import Form from "react-bootstrap/Form";
import { enrollEventListener, gamerTagInfo } from "../../helper/utilities";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
const MakePayment = (props) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    checkBalance();
    enrollEventListener();
  }, []);
  const promoBonus =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))?.profile?.bonus_balance
      : "";
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [openEnrollModal, setopenEnrollModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [openWalletModal, setopenWalletModal] = useState(false);
  const [openRespawnModal, setopenRespawnModal] = useState(false);

  const [isInsufficientBalance, setisInsufficientBalance] = useState(false);
  const [gamertag,setGamerTag] = useState(null)
  const loggedUser = useSelector((state) => state);
  const [openEnrollSuccessModal, setopenEnrollSuccessModal] = useState(false);
  const [walletBalance, setwalletBalance] = useState(null);
  const [promo_choice, setCheckPromo] = useState(false);
  const [errorMsg, seterrorMsg] = useState(null);
  const [selectedGame,setSelectedGame] = useState(null)
  const [isCheckedGamerTag, setIsCheckedGamerTag] = useState(false);
  useEffect(() => {
    setSelectedGame(props?.games?.find((item) => item.id == props?.lobbyData?.game?.id));
  },[props?.lobbyData?.games])

  useEffect(() => {
    if(loggedUser?.user)
    {
      getUserDetail().then((res) => {
        setUser(res);
      });
    }
  },[])
  
  useEffect(() => {
    if(user?.game_accounts?.length && selectedGame?.gaming_account_name)
    {
      const game_account = user?.game_accounts.filter(gameItem => gameItem?.game?.gaming_account_name == selectedGame?.gaming_account_name )
      setGamerTag(game_account?.[0]?.gaming_account)
    }
  },[user,selectedGame])



  const handleOnChange = () => {
    setIsCheckedGamerTag(!isCheckedGamerTag);
  };

  const checkBalance = () => {
    setisInsufficientBalance(false);
    setCheckPromo(false);
    getWalletBalance().then((res) => {
      setopenEnrollModal(true);
      setwalletBalance(res.wallet_balance);
      if (+res.wallet_balance < props.entry_fee) {
        setisInsufficientBalance(true);
      }
    });
    getUserDetail().then((res) => {
      setUser(res);
    });
  };
  const closeEnrollModalStatus = () => {
    setopenEnrollModal(false);
  };
  const closeEnrollModalOnHide = () => {
    setopenEnrollModal(false);
    props.closePaymentModal();
  };
  const openEnrollSuccessModalStatus = () => {
    setopenEnrollSuccessModal(true);
  };
  const closeEnrollSuccessModalStatus = () => {
    setopenEnrollSuccessModal(false);
    if (props.location.pathname !== `/${props.game.slug}/lobby/${props.id}`) {
      props.callLobbies();
    }
    if (props.location.pathname == `/${props.game.slug}/lobby`) {
      props.callLobbies();
    }
    getUserDetail().then((res) => {
      setUser(res);
    });
  };

  const openWalletModalStatus = () => {
    closeEnrollModalStatus();
    setopenWalletModal(true);
  };
  const closeWalletModalOnHide = () => {
    setopenWalletModal(false);
    closeEnrollModalOnHide();
  };
  async function acceptInvite() {
    setisLoading(true);
    setSpinnerLoader(true);
    setopenRespawnModal(false);
  
    if (props.owner) {
      const payload = {
        promo_choice: promo_choice,
        lobby_id: props?.participantData?.team?.lobby,
        team_id: props?.participantData?.team.id,
        user_id: props?.playerUID,
      };
      await makePaymentByOwner(payload)
        .then((res) => {
          if (res !== undefined) {
            setisLoading(false);
            closeEnrollModalStatus();
            openEnrollSuccessModalStatus();
            setSpinnerLoader(false);
            if (props.match.url === "/dashboard") {
              window.location.reload();
            }
            if (props.onEnrollComplete) {
              props.onEnrollComplete();
              props.closePaymentModal();
            }
            localStorage.setItem("enroll-event", "enroll" +window.crypto.getRandomValues(new Uint32Array(1))[0]);
          } else {
            setisLoading(false);
            setSpinnerLoader(false);
            props.closePaymentModal();
          }
        })
        .catch((err) => {
          seterrorMsg(err);
          setisLoading(false);
          setSpinnerLoader(false);
          props.closePaymentModal();
        });
    } else {
      const payload = {
        status: "accept",
        promo_choice: promo_choice,
        lobbyId: props?.participantData?.team?.lobby,
        teamId: props?.participantData?.team.id,
      };
      await postFriendRequest(payload)
        .then((res) => {
          if (res !== undefined) {
            setisLoading(false);
            closeEnrollModalStatus();
            openEnrollSuccessModalStatus();
            setSpinnerLoader(false);
            if (props.match.url === "/dashboard") {
              window.location.reload();
            }
            if (props.onEnrollComplete) {
              props.onEnrollComplete();
              props.closePaymentModal();
            }
            localStorage.setItem("enroll-event", "enroll" +window.crypto.getRandomValues(new Uint32Array(1))[0]);
          } else {
            setisLoading(false);
            setSpinnerLoader(false);
            props.closePaymentModal();
          }
        })
        .catch((err) => {
          seterrorMsg(err);
          setisLoading(false);
          setSpinnerLoader(false);
          props.closePaymentModal();
        });
    }
  }
  const openRespawnModalStatus = () => {
    setopenRespawnModal(true);
    closeEnrollModalStatus();
  };

  const closeRespawnModalOnHide = () => {
    setIsCheckedGamerTag(false)
    setopenRespawnModal(false);
    closeEnrollModalOnHide();
  };
  const onChangePromo = (e) => {
    const check = e.target.checked;
    setCheckPromo(check);
    if (props.lobbyData.entry_fee > +promoBonus + +walletBalance) {
      setisInsufficientBalance(true);
    } else {
      setisInsufficientBalance(false);
    }
    if (!check && +walletBalance < props.lobbyData.entry_fee) {
      setisInsufficientBalance(true);
    }
  };
  return (
    <>
      {/* make-payment-2 */}
      <Modal show={openEnrollModal} onHide={closeEnrollModalOnHide}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-4">Make Payment</Modal.Title>
        </Modal.Header>

        <Modal.Body className="make-payment">
          <div className="d-flex justify-content-between">
            <div>
              <h3>Wallet</h3>
              <h4>Total Balance</h4>
              <h5>${walletBalance}</h5>
              {props.free_lobby
                ? null
                : isInsufficientBalance && (
                    <>
                      <p>Insufficient Balance Add Money in Wallet</p>
                      <button
                        className="btn btn-secondary"
                        onClick={() => openWalletModalStatus()}
                      >
                        Add Money to wallet
                      </button>
                    </>
                  )}
            </div>
            <div className="promo-bonus ">
              <h3>Promo Bonus</h3>
              <h4>Total Balance</h4>
              <h5>${promoBonus}</h5>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    className="make-payment-checkbox"
                    type="checkbox"
                    label="Avail Promo Bonus to enroll into lobby"
                    onChange={onChangePromo}
                    checked={promo_choice}
                    disabled={!parseInt(promoBonus)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="total-money total">
          <div>
            <h3>{props.lobbyData.game.name}</h3>
            <div className="total-display-box">
              <div>
                <p>Total</p>
                <h6>
                  {props.lobbyData.free_lobby
                    ? " $0.00 (Free)"
                    : "$" + props.lobbyData.entry_fee.toFixed(2)}
                </h6>
              </div>
              <div>
                <p>Display Name</p>
                <div className="gamertag-edit">
                 <h6>{gamertag? gamertag:'-'}</h6>
                  <Link
                    to={{
                      pathname: "/myProfile",
                      data: "settingsTab",
                      settingsKey: "gameIds",
                    }}
                  >
                    <img
                      src="/assets/images/edit-gamertag-payment-popup.svg"
                      alt="edit-icon"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-3"
          data-tip
          data-for="loginTip"
          style={{cursor:"pointer"}}>
              <span class="info-icon">
                <img
                  src="/assets/images/info-icon.svg"
                  alt="info"
                  class="info-image"
                />
                <span className="gamerTagInfoMessage">{gamerTagInfo(selectedGame)}</span>
              </span>
              <div className="helper-image-container enrollHelperImage">
                <ReactTooltip id="loginTip" place="top" effect="solid">
                  <img src={selectedGame?.helper_image} alt="helper-image" />
                </ReactTooltip>{" "}
              </div>
            </div>
          <div>
            <Button
              variant="primary"
              onClick={() => openRespawnModalStatus()}
              disabled={
                props.lobbyData.free_lobby ? false : isInsufficientBalance
              }
            >
              Pay
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* add to wallet module - start */}
      <Modal
        show={openWalletModal}
        onHide={closeWalletModalOnHide}
        contentClassName="qr-verification-wrapper"
      >
        <AddToWallet
          prevPath={props.location.pathname}
          gameName={props.lobbyData.game}
          lobbyId={props.lobbyData.id}
          walletBalance={walletBalance}
        />
      </Modal>
      {spinnerLoader ? (
        <div class="loading"></div>
      ) : openRespawnModal ? (
        <Modal
          className="respawn-modal"
          show={openRespawnModal}
          onHide={closeRespawnModalOnHide}
        >
          <GuLagInfo>
            {errorMsg ? (
              <div className="invalid-feedback d-block">{errorMsg}</div>
            ) : null}
            <div className="d-flex">
              <Form.Check
                className="gamertag-checkbox"
                type="checkbox"
                checked={isCheckedGamerTag}
                onChange={handleOnChange}
              />
              <p className="gamertag-prompt gamertag-text">
                {gamerTagInfo(selectedGame)}
              </p>
            </div>
            <Button
              variant="primary"
              onClick={() => acceptInvite()}
              disabled={isLoading || !isCheckedGamerTag}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Agree"
              )}
            </Button>
          </GuLagInfo>
        </Modal>
      ) : (
        <Modal
          show={openEnrollSuccessModal}
          onHide={closeEnrollSuccessModalStatus}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <div className="tick-image">
              <img src="/assets/images/tick.svg" alt="" />
            </div>
            <h3>
              {props.payForPlayer
                ? `Player Successfully Enrolled for ${props.lobbyData.game.name} Lobby`
                : `Successfully Enrolled for ${props.lobbyData.game.name} Lobby`}
            </h3>
            <p>Wallet Balance</p>
            <h4>
              $
              {props.free_lobby
                ? parseInt(walletBalance) + parseInt(promoBonus)
                : (
                    parseInt(walletBalance) +
                    parseInt(promoBonus) -
                    props.lobbyData.entry_fee
                  ).toFixed(2)}
            </h4>
          </Modal.Body>
        </Modal>
      )}

      {/* add to wallet module - end */}
    </>
  );
};

MakePayment.propTypes = {
  callLobbies: PropTypes.func,
  closePaymentModal: PropTypes.func,
};

export default withRouter(MakePayment);

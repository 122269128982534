import React, { Component } from "react";
import HeaderNav from "./Navbar";
import PropTypes from "prop-types";
class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.CLoseMenuTrigger2 = this.CLoseMenuTrigger2.bind(this);
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  CLoseMenuTrigger2() {
    if (document.querySelector(".header-wrapper") !== null) {
      document.querySelector(".header-wrapper").classList.remove("menu-open");
    } else return;
  }
  state = {
    backgroundColor: "transparent",
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 10) {
      this.setState({ backgroundColor: "black" });
    } else {
      this.setState({ backgroundColor: "transparent" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <header
        className="header-area formobile-menu header--transparent default-color"
        style={{ backgroundColor: this.state.backgroundColor }}
      >
        <div className="header-wrapper " id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/Logo.png" alt="logo" />
              </a>
            </div>
          </div>
          <div className="header-right">
            <HeaderNav call={() => this.CLoseMenuTrigger2()} />
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <img src="/assets/images/hamburger.svg" alt="hamburger" />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
Header.propTypes = {
  call: PropTypes.func,
};
export default Header;

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toastify from "../../helper/toastify.jsx";
import { deleteTeamById } from "../../services/user.service";
const DeleteModal = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const showLoader = (load) => {
    setisLoading(load);
  };
  const handleDelete = async () => {
    showLoader(true);
    await deleteTeamById(props.Id)
      .then((res) => {
        toastify("success", "Team deleted successfully");
        showLoader(false);
        props.onDelete();
      })
      .catch((err) => {
        showLoader(false);
      });
  };
  return (
    <>
      <Modal.Header closeButton disabled={isLoading}>
      </Modal.Header>
      <Modal.Body className="file-complaint-wrapper text-center">
        <div className="player-details">
          <img src="/assets/images/tick-mark-icon.svg" alt="gift-icon" />
          <p className="remove-heading invite-para">Delete Team</p>
          <p className="deleteNewTeam">
            Are you sure, you want to delete your entire team ?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="delete-footer">
          <Button
            type="button"
            className="cancel-btn"
            onClick={() => props.onCancel()}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={isLoading}
            onClick={() => handleDelete()}
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Yes"
            )}
          </Button>
      </Modal.Footer>
    </>
  );
};

export default DeleteModal;

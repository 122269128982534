import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { logout } from "../../services/authService";
import { logoutUser } from "../../store/auth/login/actions";

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    logout();
    dispatch(logoutUser());
  }, []);

  return <></>;
};

export default withRouter(Logout);

import React, { useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { dateTimeFormatterForTournamentMatchinfo } from "../utils";
import ViewMoreTeamsModal from "../Modal/ViewMoreTeamsModal";
import ViewStats from "../Modal/ViewStats";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { gameLobbyDetail } from "../../../services/game.service";
const TournamentMatchInfoTab = ({
  roundData,
  gameType,
  teamsInMatchesInfo,
}) => {
  const { slug } = useParams();
  const [showMoreModalVisibilities, setShowMoreModalVisibilities] = useState(
    []
  );
  const [showMoreTeam, setShowMoreTeamData] = useState([]);
  const handleShowMore = (index, showMoreTeamData) => {
    const newShowMoreModalVisibilities = [...showMoreModalVisibilities];
    newShowMoreModalVisibilities[index] = true;
    setShowMoreModalVisibilities(newShowMoreModalVisibilities);
    setShowMoreTeamData(showMoreTeamData);
  };

  const handleClose = (index) => {
    const newShowMoreModalVisibilities = [...showMoreModalVisibilities];
    newShowMoreModalVisibilities[index] = false;
    setShowMoreModalVisibilities(newShowMoreModalVisibilities);
    setShowMoreTeamData([]);
  };
  const [statsModalVisibilities, setStatsModalVisibilities] = useState([]);
  const handleShowStats = (index, lobbyId) => {
    const newStatsModalVisibilities = [...statsModalVisibilities];
    newStatsModalVisibilities[index] = true;
    setStatsModalVisibilities(newStatsModalVisibilities);
    getTournament(lobbyId);
  };

  const [loader, setLoader] = useState(false);
  const [stats, setStats] = useState([]);
  const [gameData, setGameData] = useState(null);
  const getTournament = async (lobbyId) => {
    try {
      setLoader(true);
      const res = await gameLobbyDetail(slug, lobbyId);
      setStats(res.player_stats);
      setGameData(res.game);

      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const handleCloseStats = (index) => {
    const newStatsModalVisibilities = [...statsModalVisibilities];
    newStatsModalVisibilities[index] = false;
    setStatsModalVisibilities(newStatsModalVisibilities);
  };

  return (
    <div className="col mb-4 left-card">
      <div className="card card_teams">
        <div className="card-body members-cards challengeCards">
          <div className="teams-information  w-100 challengesTeams mb--10">
            <Accordion>
              {roundData &&
                roundData.map((rounds, idx) => {
                  return (
                    <Accordion.Item eventKey={idx}>
                      <Accordion.Header>
                        {" "}
                        {rounds.round}{" "}
                        {rounds.tour_bracket_style === 2 &&
                        rounds.round_type != null
                          ? `[${rounds.round_type}]`
                          : null}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive">
                          <table className="tournament-match-info card-padding table-width">
                            {rounds.matches.length === 0 ? (
                              <tr>
                                <td>Matches not generated yet</td>
                              </tr>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th scope="col" rowSpan="2">
                                      Match No.
                                    </th>
                                    <th scope="col" rowSpan="2">
                                      Start Date Time
                                    </th>
                                    <th scope="col" rowSpan="2">
                                      Versus
                                    </th>
                                    <th scope="col" rowspan="2">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {rounds &&
                                  rounds.matches.map((item, index) => {
                                    let isPPK = gameType === "PPK";
                                    const matchWithSameId = teamsInMatchesInfo
                                      ? teamsInMatchesInfo.find((match) =>
                                          match.matches.some(
                                            (m) => m.match_id === item.id
                                          )
                                        )
                                      : null;
                                    const specificMatch =
                                      matchWithSameId?.matches.find(
                                        (m) => m.match_id === item.id
                                      );
                                    // Check if match_number is 0 and display "NA" if true for traditional
                                    const isMatchNumberZero =
                                      specificMatch?.match_number === 0 ||
                                      !specificMatch;
                                    const teamsArray = specificMatch?.teams.map(
                                      (team) => ({
                                        id: team.team_id,
                                        name: team.name,
                                      })
                                    );
                                    let teamsNotAvailableInMatches =
                                      teamsArray?.length === 0 ||
                                      teamsArray === undefined;

                                    let versusMatchInTradtion =
                                      isMatchNumberZero
                                        ? "NA vs NA"
                                        : `${teamsArray[0]?.name} vs ${teamsArray[1]?.name}`;
                                    if (
                                      item.sub_match &&
                                      item.sub_match.length === 0
                                    ) {
                                      return (
                                        <tr key={index} id={index}>
                                          <td className="text-capitalize">
                                            {item.match === "match 0" ||
                                            item.match === "Match 0"
                                              ? `Match ${idx}`
                                              : item.match}
                                          </td>
                                          <td className="text-capitalize">
                                            NA
                                          </td>
                                          <td>
                                            {isPPK ? (
                                              <>
                                                {teamsNotAvailableInMatches ? (
                                                  <span>No Team Found</span>
                                                ) : (
                                                  <>
                                                    {teamsArray
                                                      .slice(0, 1)
                                                      .map(
                                                        (listTeams, indx) => {
                                                          return (
                                                            <li
                                                              key={indx}
                                                              id={indx}
                                                            >
                                                              {listTeams.name}
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    {teamsArray.length > 1 ? (
                                                      <Button
                                                        onClick={() =>
                                                          handleShowMore(
                                                            index,
                                                            teamsArray
                                                          )
                                                        }
                                                        variant="link"
                                                        className="theme-color"
                                                      >
                                                        View more
                                                      </Button>
                                                    ) : null}
                                                  </>
                                                )}
                                              </>
                                            ) : teamsNotAvailableInMatches ? (
                                              "NA vs NA"
                                            ) : (
                                              <>{versusMatchInTradtion}</>
                                            )}
                                          </td>
                                          <td>No Stats Available</td>
                                        </tr>
                                      );
                                    } else if (item.sub_match) {
                                      return item.sub_match.map(
                                        (submatch, idx) => {
                                          let matchStatus;
                                          if (
                                            submatch.lobby.stats_status <= 3
                                          ) {
                                            matchStatus = "No Stats Available";
                                          } else if (
                                            submatch.lobby.stats_status === 5
                                          ) {
                                            matchStatus = (
                                              <Button
                                                variant="danger"
                                                disabled={true}
                                              >
                                                Cancelled
                                              </Button>
                                            );
                                          } else if (
                                            submatch.lobby.stats_status === 4
                                          ) {
                                            matchStatus = (
                                              <Button
                                                onClick={() =>
                                                  handleShowStats(
                                                    index,
                                                    submatch.lobby.id
                                                  )
                                                }
                                                variant="primary"
                                                className="theme-color view-stats-btn"
                                              >
                                                View stats
                                              </Button>
                                            );
                                          } else {
                                            matchStatus = null;
                                          }
                                          return (
                                            <tr key={index} id={index}>
                                              <td className="text-capitalize">
                                                {submatch.sub_match || "NA"}{" "}
                                                <span className="text-lowercase">
                                                  of
                                                </span>{" "}
                                                {item.match === "match 0" ||
                                                item.match === "Match 0"
                                                  ? ` Match ${idx}`
                                                  : item.match}{" "}
                                              </td>
                                              <td className="text-capitalize">
                                                {dateTimeFormatterForTournamentMatchinfo(
                                                  submatch.lobby.start_date +
                                                    " " +
                                                    submatch.lobby.start_time
                                                ) || "NA"}
                                              </td>
                                              <td>
                                                {isPPK ? (
                                                  <>
                                                    {teamsNotAvailableInMatches ? (
                                                      <span>No Team Found</span>
                                                    ) : (
                                                      <>
                                                        {teamsArray
                                                          .slice(0, 1)
                                                          .map(
                                                            (
                                                              listTeams,
                                                              indx
                                                            ) => {
                                                              return (
                                                                <li
                                                                  key={indx}
                                                                  id={indx}
                                                                >
                                                                  {
                                                                    listTeams.name
                                                                  }
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        {teamsArray.length >
                                                        1 ? (
                                                          <Button
                                                            onClick={() =>
                                                              handleShowMore(
                                                                index,
                                                                teamsArray
                                                              )
                                                            }
                                                            variant="link"
                                                            className="theme-color"
                                                          >
                                                            View more
                                                          </Button>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </>
                                                ) : teamsNotAvailableInMatches ? (
                                                  "NA vs NA"
                                                ) : (
                                                  <>{versusMatchInTradtion}</>
                                                )}
                                              </td>
                                              <td>{matchStatus}</td>
                                              <ViewMoreTeamsModal
                                                show={
                                                  showMoreModalVisibilities[
                                                    index
                                                  ] || false
                                                }
                                                teamList={showMoreTeam}
                                                closeModal={() =>
                                                  handleClose(index)
                                                }
                                              />
                                              {stats && gameData && (
                                                <ViewStats
                                                  loader={loader}
                                                  show={
                                                    statsModalVisibilities[
                                                      index
                                                    ] || false
                                                  }
                                                  teamList={showMoreTeam}
                                                  player_stats={stats}
                                                  game_data={gameData}
                                                  closeModal={() =>
                                                    handleCloseStats(index)
                                                  }
                                                  isPPK={isPPK}
                                                />
                                              )}
                                            </tr>
                                          );
                                        }
                                      );
                                    }
                                    return null;
                                  })}
                              </>
                            )}
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentMatchInfoTab;

import * as React from "react";

const ConfigContext = React.createContext();

const initialState = {
  config: {},
};

function countReducer(state = initialState, { type, payload } = {}) {
  if (type == "UPDATE_CONFIG") {
    return {
      ...state,
      config: { ...payload, solo_greyout: 10, tournament_greyout: 120 },
    };
  } else {
    throw new Error(`Unhandled action type: ${type}`);
  }
}

function ConfigProvider({ children }) {
  const [state, dispatch] = React.useReducer(countReducer, initialState);
  const value = { state, dispatch };
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

function useConfig() {
  const context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useNotifications must be used within a CountProvider");
  }
  return context;
}

export { ConfigProvider, useConfig };

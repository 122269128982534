import React from "react";
import PropTypes from "prop-types";
import {
  gameStatsTableHeadForApex,
  gameStatsTableHeadForValorant,
} from "../../helper/utilities";

const PlayerStats = (props) => {
  const player_stats1 = props.player_stats;
  const player_stats =
    props?.type === "challenge"
      ? props.game_data.official_stats === null
        ? ""
        : player_stats1.sort(function (a, b) {
            return b.winnings - a.winnings;
          })
      : player_stats1.length === undefined || player_stats1.length === 0
      ? ""
      : props.game_data?.slug === "valorant"
      ? player_stats1.sort(function (a, b) {
          return b.winnings - a.winnings;
        })
      : player_stats1.sort(function (a, b) {
          return b.kills - a.kills;
        });
  return (
    <div className="stats-wrapper">
      {props?.type === "challenge" ? (
        <table style={{ color: "white" }}>
          <tr>
            <th>Team name</th>
            <th>Player name</th>
            <th>Kills</th>
            <th>Winnings</th>
            <th>Assists</th>
            <th>Damage dealt</th>
            <th>Survival time</th>
            <th>Platform</th>
          </tr>
          {player_stats === "" ? (
            <div className="boxx">
              <p> No Stat(s) yet </p>
            </div>
          ) : (
            player_stats &&
            player_stats.map((stats, idx) => {
              return stats.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p>{data.teams.name}</p>
                    </td>
                    <td>
                      <p>{data.player_name || "NA"}</p>
                    </td>
                    <td>
                      <p>{data.kills}</p>
                    </td>
                    <td>
                      <p>{`$${data.player_winning}`}</p>
                    </td>
                    <td>
                      <p>{data.assists}</p>
                    </td>
                    <td>
                      <p>{data.damage_dealt}</p>
                    </td>
                    <td>
                      <p>{`${data.survival_time} sec`}</p>
                    </td>
                    <td>
                      <p>{data.hardware || "NA"}</p>
                    </td>
                  </tr>
                );
              });
            })
          )}
        </table>
      ) : (
        <table style={{ color: "white" }}>
          {props.game_data.slug == "apex-legends" ? (
            <tr>
              {gameStatsTableHeadForApex.map((data, i) => {
                if (props?.isTournament && data === "Winnings") {
                  if (props.isPPK) {
                    return <th key={i}>{data}</th>;
                  } else {
                    return null;
                  }
                }
                return <th key={i}>{data}</th>;
              })}
            </tr>
          ) : props.game_data.slug == "valorant" ? (
            <tr>
              {gameStatsTableHeadForValorant.map((data, i) => {
                if (props?.isTournament && data === "Winnings") {
                  return null;
                }
                return <th key={i}>{data}</th>;
              })}
            </tr>
          ) : (
            <tr>
              <th>Team number</th>
              <th>Team name</th>
              <th>Player name</th>
              <th>Kills</th>
              <th>Winnings</th>
              <th>Team placement</th>
              {props.game_data.slug == "call-of-duty-warzone" ? (
                <th>Rank</th>
              ) : (
                <th>Survival time</th>
              )}
              <th>Assists</th>
              <th>Damage dealt</th>
              <th>Platform</th>
            </tr>
          )}

          {player_stats === "" ? (
            <div className="boxx">
              <p> No Stat(s) yet </p>
            </div>
          ) : player_stats.length === undefined || player_stats.length === 0 ? (
            player_stats &&
            player_stats.map((stats, idx) => {
              let winnings = null;
              let showWinnings = true;
              if (props?.isTournament) {
                if (props.isPPK) {
                  winnings = stats.winnings;
                  showWinnings = true;
                } else {
                  winnings = null;
                  showWinnings = false;
                }
              } else {
                winnings = stats.winnings;
                showWinnings = true;
              }
              return props.game_data.slug == "apex-legends" ? (
                <tr key={idx} id={idx}>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills || "NA"}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{winnings || "NA"}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.team_placement || "NA"}</p>
                  </td>
                  <td>
                    <p>{`${stats.survival_time} seconds` || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              ) : props.game_data.slug == "valorant" ? (
                <tr>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills || "NA"}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{winnings || "NA"}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.assists || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt || "NA"}</p>
                  </td>
                  <td>
                    <p>{`${stats.survival_time} seconds` || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <p>{stats.team_number || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills || "NA"}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{winnings || "NA"}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.team_placement || "NA"}</p>
                  </td>
                  {props.game_data?.slug == "call-of-duty-warzone" ? (
                    <p>{stats.survival_time}</p>
                  ) : (
                    <p>{`${stats.survival_time} seconds` || "NA"}</p>
                  )}
                  <td>
                    <p>{stats.assists || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt || "NA"}</p>
                  </td>{" "}
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              );
            })
          ) : (
            player_stats &&
            player_stats.map((stats, idx) => {
              let winnings = null;
              let showWinnings = true;
              if (props?.isTournament) {
                if (props.isPPK) {
                  winnings = stats.winnings;
                  showWinnings = true;
                } else {
                  winnings = null;
                  showWinnings = false;
                }
              } else {
                winnings = stats.winnings;
                showWinnings = true;
              }
              return props.game_data.slug == "apex-legends" ? (
                <tr key={idx} id={idx}>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{`$ ${winnings}`}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.team_placement}</p>
                  </td>
                  <td>
                    <p>{`${stats.survival_time} sec` || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt}</p>
                  </td>
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              ) : props.game_data.slug == "valorant" ? (
                <tr key={idx} id={idx}>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{`$ ${winnings}`}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.assists}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt}</p>
                  </td>
                  <td>
                    <p>{`${stats.survival_time} sec` || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              ) : (
                <tr key={idx} id={idx}>
                  <td>
                    <p>{stats.team_number}</p>
                  </td>
                  <td>
                    <p>{stats.team_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.player_name || "NA"}</p>
                  </td>
                  <td>
                    <p>{stats.kills}</p>
                  </td>
                  {showWinnings ? (
                    <td>
                      <p>{`$ ${winnings}`}</p>
                    </td>
                  ) : null}
                  <td>
                    <p>{stats.team_placement}</p>
                  </td>
                  <td>
                    {props.game_data?.slug == "call-of-duty-warzone" ? (
                      <p>{stats.survival_time}</p>
                    ) : (
                      <p>{`${stats.survival_time} sec` || "NA"}</p>
                    )}
                  </td>
                  <td>
                    <p>{stats.assists}</p>
                  </td>
                  <td>
                    <p>{stats.damage_dealt}</p>
                  </td>
                  <td>
                    <p>{stats.hardware || "NA"}</p>
                  </td>
                </tr>
              );
            })
          )}
        </table>
      )}
    </div>
  );
};

PlayerStats.propTypes = {
  player_stats: PropTypes.shape({
    assists: PropTypes.number,
    damage_dealt: PropTypes.number,
    extras: PropTypes.object,
    hardware: PropTypes.string,
    kills: PropTypes.number,
    survival_time: PropTypes.number,
    team_name: PropTypes.string,
    team_number: PropTypes.number,
    team_placement: PropTypes.number,
    winnings: PropTypes.number,
  }),
  lobby_stats: PropTypes.shape({
    username: PropTypes.string,
    winnings: PropTypes.number,
  }),
  enrolled_players: PropTypes.number,
};

export default PlayerStats;

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    exact
    render={(props) => {
      if (isAuthProtected) {
        if (!localStorage.getItem("user")) {
          return (
            <Redirect
              to={{ pathname: "/home", state: { from: props.location } }}
            />
          );
        } else {
          return <Component />;
        }
      } else {
        if (localStorage.getItem("user")) {
          return (
            <Redirect
              to={{ pathname: "/myprofile", state: { from: props.location } }}
            />
          );
        } else {
          return <Component />;
        }
      }
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
};

export default Authmiddleware;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import { redeemAmount } from "../../services/user.service";

function Stripe(props) {
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Please enter a valid amount")
      .positive("Amount cannot be 0")
      .test(
        "invalid-decimal",
        "Amount doesn't have more than 2 decimal points",
        (value) => (value + "").match(/^(\d+(\.\d{1,2})?)$/)
      )
      .required("Amount is required")
      .min(10, "Amount has to be more than or equal to $10")
      .max(+props.wallet_balance, (obj) => {
        if (obj.max < 10) {
          return `Your wallet balance is low :$${props.wallet_balance}, Minimum withdrawal amount has to be more than or equal to $10`;
        } else {
          return `Your wallet balance :$${props.wallet_balance}, Amount has to be less than or equal to $${props.wallet_balance} but greater than or equal to $10`;
        }
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { paypal: props.paypal_id },
    resolver: yupResolver(validationSchema),
  });

  const [payoutType, setPayoutType] = useState(
    props?.stripeObject?.instant_payouts_enabled ? "instant" : "normal"
  );

  const onRedeemMoneyStripe = async (data) => {
    props.setisLoading(true);
    await redeemAmount({
      amount: data.amount,
      payment_type: "stripe",
      payment_mode: payoutType,
    })
      .then((res) => {
        props.setisLoading(false);
        props.setisRedeemProcessDone(true);
        props.setdeductedAmount(data.amount);
        props.setupdatedWallet(res.amount);
        props.fetchUserData();
      })
      .catch(() => {
        props.setisLoading(false);
        props.setisRedeemProcessDone(false);
      });
  };

  const stripeConnect = async (url = "") => {
    try {
      if (!url) {
        props.setisLoading(true);
        const { link } = await redeemAmount({
          amount: "50.00",
          payment_type: "stripe",
          payment_mode: "normal",
        });
        props.setisLoading(false);
        window.location.href = link;
      } else {
        window.location.href = url;
      }
    } catch (error) {
      props.setisLoading(false);
    }
  };

  const setInstantPayout = () => setPayoutType("instant");
  const setNormalPayout = () => setPayoutType("normal");

  return (
    <>
      <a
        target="_blank"
        href="https://stripe.com/global"
        style={{
          cursor: "pointer",
          color: "#00ccff",
          textDecorationLine: "underline",
          marginBottom: props?.stripeObject?.status === 1 ? "0px" : "8px",
          display: "inline-block",
        }}
      >
        Supported Countries
      </a>
      {props?.stripeObject?.status === 1 ? (
        <Form onSubmit={handleSubmit(onRedeemMoneyStripe)}>
          <div className="d-flex justify mt-4 redeem-payouts-container  justify-content-around mb-4 paymentMeth instant-pay stripe">
            <div className="notification-setting-item payment-method-labels justify-content-start align-items-center ">
              <div className="options">
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  className="friendPopup"
                  name="instant"
                  checked={"instant" === payoutType}
                  onChange={setInstantPayout}
                  disabled={!props.stripeObject?.instant_payouts_enabled}
                />
              </div>
              <div className="payment-method-label stripe-methods">
                <h4
                  className={`${
                    !props.stripeObject?.instant_payouts_enabled
                      ? "text-muted"
                      : "text-white"
                  }`}
                >
                  Instant Transfer
                </h4>
              </div>
              {!props.stripeObject?.instant_payouts_enabled ? (
                <>
                  <span class="info-icon ms-2 stripe-info-icon">
                    <img
                      src="/assets/images/info-icon.svg"
                      alt="info"
                      class="info-image"
                      data-tip
                      data-for="instantTip1"
                    />
                  </span>
                  <ReactTooltip id="instantTip1" place="top" effect="solid">
                    Payout within 30 mins to 3 hours
                    <br />
                    Note :Please ensure your debit card is added in stripe
                    account
                  </ReactTooltip>
                </>
              ) : (
                <>
                  <span class="info-icon ms-2 stripe-info-icon">
                    <img
                      src="/assets/images/info-icon.svg"
                      alt="info"
                      class="info-image"
                      data-tip
                      data-for="instantTip1"
                    />
                  </span>
                  <ReactTooltip id="instantTip1" place="top" effect="solid">
                    Payout within 30 mins to 3 hours
                  </ReactTooltip>
                </>
              )}
            </div>
            <div className="notification-setting-item ms-3 payment-method-labels justify-content-start align-items-center">
              <div className="options">
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  className="friendPopup"
                  name="normal"
                  onChange={setNormalPayout}
                  checked={"normal" === payoutType}
                  disabled={!props.stripeObject?.payouts_enabled}
                />
              </div>
              <div className="payment-method-label stripe-methods">
                <h4
                  className={`${
                    !props.stripeObject?.payouts_enabled
                      ? "text-muted"
                      : "text-white"
                  }`}
                >
                  Standard Transfer
                </h4>
              </div>
              {!props.stripeObject?.payouts_enabled ? (
                <>
                  <span class="info-icon ms-2 stripe-info-icon">
                    <img
                      src="/assets/images/info-icon.svg"
                      alt="info"
                      class="info-image"
                      data-tip
                      data-for="instantTip2"
                    />
                  </span>
                  <ReactTooltip id="instantTip2" place="top" effect="solid">
                    Payout within 2 days to 7 days
                    <br />
                    Note :Please ensure either debit card or bank account is
                    added in stripe account
                  </ReactTooltip>
                </>
              ) : (
                <>
                  <span class="info-icon ms-2 stripe-info-icon">
                    <img
                      src="/assets/images/info-icon.svg"
                      alt="info"
                      class="info-image"
                      data-tip
                      data-for="instantTip2"
                    />
                  </span>
                  <ReactTooltip id="instantTip2" place="top" effect="solid">
                    Payout within 2 days to 7 days
                  </ReactTooltip>
                </>
              )}
            </div>
          </div>
          <h3 className="text-white mt-4 mb-2 payment-method-bold">
            Amount to be Redeemed
          </h3>

          <div className="input-money">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              <Form.Control
                placeholder="Amount"
                {...register("amount")}
                name="amount"
                className={`${errors.amount ? "is-invalid" : ""}`}
              />
            </InputGroup>
            {errors.amount?.message && (
              <div className="invalid-feedback d-block">
                {errors.amount?.message}
              </div>
            )}
          </div>
          <p class="deposit-note">
            {" "}
            Note: Fee of {payoutType == "instant"
              ? "3% + $2.50"
              : "3% + $0.30"}{" "}
            would be charged on redeeming funds.
          </p>
          <Modal.Footer className="redeem-button mt-2">
            <Button
              className="flex-grow-1"
              variant="primary"
              type="submit"
              disabled={
                props.isLoading ||
                !props.stripeObject?.payouts_enabled
              }
            >
              {props.isLoading ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Redeem"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      ) : (
        <>
          <div className="payment-unavailable-container mb-3">
            <p className="payment-method-unavailable-message">
              Note : The user must have connected his stripe account to Ryvals
              account
            </p>
            <p
              className="payment-error"
              onClick={() => stripeConnect(props?.stripeObject?.link)}
            >
              Click here to onboard
            </p>
          </div>
          <Modal.Footer className="redeem-button">
            <Button
              className="flex-grow-1"
              variant="primary"
              type="submit"
              disabled={true}
            >
              Redeem
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
}

export default Stripe;

import dateFormat from "dateformat";

function DateTimeFormat(dateTime) {
  return dateFormat(dateTime, "mmm dd, yyyy hh:MM TT Z");
}

function DateFormat(dateTime) {
  return dateFormat(dateTime, "mmm dd, yyyy");
}

function TimeFormat(dateTime) {
  return dateFormat(dateTime, "hh:MM TT Z");
}

export { DateTimeFormat, DateFormat, TimeFormat };

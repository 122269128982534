import React, { useMemo, useState } from "react";
import {
  appendSuffixToTournamentPosition,
  dateFormatTournament,
  getModeLabelsSelect,
  redirectToPrevPath,
} from "../../helper/utilities";
import LobbyStatus from "../LobbyCurrentStatus/LobbyStatus";
import CountDown from "../Lobby/CountDown";
import TournamentEnroll from "./Modal/TournamentEnroll";
import { getTeams } from "../../services/tournament.service";
import { useGame } from "../../context/GameContext";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import PlayerAddRank from "./Form/PlayerAddRank";
import ShowMorePrizesModal from "./Modal/ShowMorePrizesModal";

function TournamentCard({ data, fetchTournaments }) {
  const { state } = useGame();
  const modeObjStore = state?.game?.modeGameTypeTournamentObj;
  const loggedUser = useSelector((storeState) => storeState);
  const history = useHistory();
  const location = useLocation();
  const profilePhone =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).phone;
  const profileAddress =
    localStorage.getItem("userProfile") === null
      ? ""
      : JSON.parse(localStorage.getItem("userProfile")).address.full;
  const [isTimerReached, setTimerReached] = useState(false);
  const [canEnroll, setCanEnroll] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showTeamSelectModel, setShowTeamSelectModal] = useState(false);
  const [playerTeams, setPlayerTeams] = useState([]);
  const game = useMemo(() => {
    return state?.game?.allGames.filter(
      (gameObj) => gameObj.slug == data.slug
    )?.[0];
  }, [data.slug]);
  const ChallengeCountdownEnded = (isComplete, id) => {
    setTimerReached(isComplete);
  };
  const hideEnrollModal = () => {
    fetchTournaments();
  };
  const [updateProfile, setUpdateProfile] = useState(false);
  const handleEnroll = () => {
    if (!loggedUser.user) {
      localStorage.setItem("previousPath", window.location.pathname);
      history.push({
        pathname: "/signin",
        state: { prevPath: window.location.pathname },
      });
      return;
    } else if (profilePhone === "" || profilePhone === null) {
      setUpdateProfile(true);
    } else {
      setUpdateProfile(false);
      getTeamsList({
        game_id: game.id,
        mode: data.player_per_team,
        creatorTeam: "1",
      });
      setShowPaymentModal(true);
      setShowTeamSelectModal(true);
    }
  };

  const getTeamsList = async ({ game_id, mode, creatorTeam }) => {
    try {
      const res = await getTeams({ game_id, mode, creatorTeam });
      setPlayerTeams(res);
    } catch (error) {}
  };

  const modeTag = getModeLabelsSelect(
    data?.game_type == "PPK" ? { value: 1 } : { value: 3 },
    modeObjStore?.[data?.slug]
  );
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(true);
  };
  const handleDetails = () => {
    if (!loggedUser?.user?.id) {
      return redirectToPrevPath(history, location, true);
    }
    return {
      pathname: `${window.location.pathname}/${data.slug}/${data.id}`,
      data: data,
    };
  };
  const reCallApi = () => {
    fetchTournaments();
  };
  const handleHideShowMorePrizes = () => {
    setShowMore(false);
  };

  return (
    <>
      {" "}
      <div className="col-md-6 left-card mb-4">
        <div
          className={
            data.is_enrolled
              ? "card card_warzone enrolled-card"
              : "card card_warzone "
          }
        >
          <div className="card-body">
            <h3 className="card-title lobbyTitle w-100">{data.name}</h3>
            <div className="px-4 height-70">
            {data.price.length === 0 ? null : (
              <div className="d-flex prizes">
                  {data.price.slice(0, 3).map((item, idx) => {
                    return (
                      <div key={idx} className="prize-div" id={idx}>
                        <p className="mb-0">
                          {`${item.position}${appendSuffixToTournamentPosition(
                            item.position
                          )}`}{" "}
                          Prize
                        </p>
                        <p className="mb-0 theme-default-color">
                          $ {item.price}
                        </p>
                      </div>
                    );
                  })}

                  {data.price.length > 3 ? (
                    <Button
                      onClick={handleShowMore}
                      variant="link"
                      className="theme-color"
                    >
                      View more
                    </Button>
                  ) : null}
              </div>
            )}
            </div>
            <div className="playing-information ValorantNewCard">
              <div className="left-side">
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Game</span>
                  <span className="count-box dollar-wrapper">{data.game}</span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Entry Fee / Player</span>
                  <span className="count-box dollar-wrapper entry-fees tags">
                    $ {data.entry_fee}
                    {data.free ? " Free Entry" : ""}
                  </span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Min Players</span>
                  <span className="count-box dollar-wrapper">
                    {data.player_per_team}
                  </span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Min Teams</span>
                  <span className="count-box dollar-wrapper">
                    {data.min_team}
                  </span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Max Teams</span>
                  <span className="count-box dollar-wrapper">
                    {data.max_team}
                  </span>
                </p>
                <p className="entry-box tags">
                  <span className="count-box dollar-wrapper">
                    No. of Enrolled <br />
                    Teams
                  </span>
                  <span>{data.total_teams_enrolled}</span>
                </p>
              </div>
              <div className="right-side">
                <p className="entry-box tags">
                  <span>Status</span>
                  <LobbyStatus data={data} />
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Date</span>
                  <span>{dateFormatTournament(data.start_date)}</span>
                </p>
                <p className="entry-box tags">
                  <span>Start Time</span>
                  <span>{data.start_time}</span>
                </p>
                <p className="entry-box tags">
                  <span>End Time</span>
                  <span>{data.end_time || "N/A"}</span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Last Entry Time</span>
                  <span>{data.last_entry_time}</span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Game Type</span>
                  <span>{data.game_type}</span>
                </p>
                <p className="entry-box tags">
                  <span className="tags enrolled-box">Mode</span>
                  <span>
                    {
                      modeTag?.filter?.(
                        (mode) => data?.player_per_team == mode?.value
                      )?.[0]?.label
                    }
                  </span>
                </p>
              </div>
            </div>

            <div class="start-time-wrapper">
              <div class="enrolled-box d-flex">
                {" "}
                {isTimerReached ? null : (
                  <div className="challengeStatus">
                    <CountDown
                      isTournament={true}
                      server_current_datetime={data?.countdown_time}
                      time_reached={(e) => {
                        ChallengeCountdownEnded();
                      }}
                      stopEnrollTeam={(isComplete) => {
                        if (canEnroll) {
                          setCanEnroll(false);
                        }
                      }}
                    />
                  </div>
                )}
                <div className="bottom-btn d-flex">
                  <Link to={handleDetails()} className="enroll-button">
                    <Button className="actionsChallenge" variant="primary">
                      View Details
                    </Button>
                  </Link>
                  {data.players_to_add_rank_status && data.is_enrolled ? (
                    <PlayerAddRank
                      tournamentDetails={data}
                      reCallApi={reCallApi}
                    />
                  ) : null}

                  <button
                    className="actionsChallenge btn btn-outline-primary"
                    disabled={!canEnroll || data.is_enrolled}
                    onClick={handleEnroll}
                  >
                    {data.is_enrolled ? "Enrolled" : "Enroll Team"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {updateProfile ? (
        <Modal
          show={updateProfile}
          onHide={() => setUpdateProfile(false)}
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <div className="tick-image">
              <img src="/assets/images/tick.svg" alt="" />
            </div>
            <h3>
              {profilePhone === "" || profilePhone === null
                ? profileAddress === ""
                  ? "Please Update your profile"
                  : "Please Verify your phone number"
                : "Please Update your profile"}
            </h3>
          </Modal.Body>
        </Modal>
      ) : (
        <TournamentEnroll
          hideEnrollModal={hideEnrollModal}
          tournament={data}
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
          showTeamSelectModel={showTeamSelectModel}
          setShowTeamSelectModal={setShowTeamSelectModal}
          playerTeams={playerTeams}
          game={game}
          mode={data.player_per_team}
        />
      )}
      <ShowMorePrizesModal
        show={showMore}
        details={data}
        handleHideShowMorePrizes={handleHideShowMorePrizes}
      />
    </>
  );
}

export default TournamentCard;

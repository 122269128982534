import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddRankForm from "../Modal/AddRankForm";
const PlayerAddRank = ({ tournamentDetails, reCallApi }) => {
  const loggedUser = useSelector((storeState) => storeState);
  const history = useHistory();
  const [addRankModal, setShowAddRankModal] = useState(false);
  const handleAddRank = () => {
    if (!loggedUser.user) {
      localStorage.setItem("previousPath", window.location.pathname);
      history.push({
        pathname: "/signin",
        state: { prevPath: window.location.pathname },
      });
      return;
    } else {
      setShowAddRankModal(true);
    }
  };
  const hideAddRankFormModal = () => {
    setShowAddRankModal(false);
  };
  return (
    <>
      <button
        className="actionsChallenge btn btn-outline-primary"
        onClick={handleAddRank}
        disabled={tournamentDetails.is_rank_added}
      >
        {tournamentDetails.is_rank_added ? "Added Rank" : "Add Rank"}
      </button>
      <AddRankForm
        tournamentDetails={tournamentDetails}
        show={addRankModal}
        hideAddRankFormModal={hideAddRankFormModal}
        reCallApi={reCallApi}
      />
    </>
  );
};

export default PlayerAddRank;

import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

const Paginator = (props) => {
  const handlePageChange = ({ selected }) => {
    props.pageClick(selected + 1);
  };

  return (
    <>
      <div className="mt-5 page">
        {props.totalCount > props.pageSize ? (
          <ReactPaginate
            pageCount={props.totalCount / props.pageSize}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            onPageChange={handlePageChange}
            initialPage={0}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-link"}
            breakLinkClassName={"page-item"}
            activeClassName={"active"}
            activeLinkClassName={"active"}
            disabledClassName={"disabled"}
          />
        ) : null}
      </div>
    </>
  );
};

Paginator.propTypes = {
  pageClick: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Paginator;

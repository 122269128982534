import React, { useEffect, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
  authRoutes,
  privateRoutes,
  publicRoutes,
  wildCardRoute,
} from "../../routes/Routes";
import Authmiddleware from "../../routes/AuthMiddleware";
import { useNotifications } from "../../context/NotificationsContext";

import { Helmet } from "react-helmet";
function App() {
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const { state } = useNotifications();
  function showNotificationCount(count) {
    const pattern = /^\(\d+\)/;
    if (count === 0 || pattern.test(document.title)) {
      document.title = document.title.replace(
        pattern,
        count === 0 ? "" : "(" + count + ") "
      );
    } else {
      document.title = "(" + count + ") " + document.title;
    }
  }

  useEffect(() => {
    if (loggedUser?.user) {
      showNotificationCount(state.unreadCount);
    }
  }, [loggedUser, state]);

  return (
    <Fragment>
      <Helmet>
        <title>Ryvals</title>
      </Helmet>
      <ToastContainer autoClose={7000} />
      <div className="active-dark">
        <Header logo="symbol-dark" />
        <Switch>
          {publicRoutes.map((route) => (
            <Route
              exact
              path={`${process.env.PUBLIC_URL + route.path}`}
              component={route.component}
              key={route.path}
            />
          ))}

          {privateRoutes.map((route) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={route.path}
              isAuthProtected={true}
            />
          ))}

          {authRoutes.map((route) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={route.path}
              isAuthProtected={false}
            />
          ))}
          {wildCardRoute.map((route) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={route.path}
              isAuthProtected={false}
            />
          ))}
        </Switch>

        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
